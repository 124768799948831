import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { Table, Checkbox, Button, Form, message, Input, Select } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FormComponentProps } from 'antd/lib/form';
import isEqual from 'lodash/isEqual';

import * as OrganizationsActions from '../../store/actions/organizations';
import * as RolesActions from '../../store/actions/roles';
import { Role, PermissionRight, Organization, Permission } from '../../store/api/types';
import { getUpdates, getCreates } from '../../store/reducers/roles';
import { RequestState } from '../../store/reducers';

import FormMessages from '../../locale/Form.messages';
import GenericMessages from '../../locale/Generic.messages';
import messages from './Roles.messages';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { getUser, AuthUser } from '../../store/reducers/auth';
import { getPermissions } from '../../store/reducers/permissions';
import { hasOwnProperty } from '../../utils';
import { isUserAllowed } from '../../utils/permissions';
import { Can } from '../../components/auth';
import EmptyResult from '../../components/EmptyResult';
import { getOrganizations, getPagination } from '../../store/reducers/organizations';
import { PaginationRequestState } from '../../store/reducers/_generics';
import MainMenuMessages from '../../components/MainMenu.messages';

enum PermissionType {
    disabled = 'disabled',
    edit = 'edit',
    see = 'see',
}

interface RoleTableProps extends FormComponentProps, InjectedIntlProps {
    creates: RequestState;
    create: typeof RolesActions.create;
    fetchOrganizations: typeof OrganizationsActions.list;
    onCreateSuccess?: () => void;
    onCreateCancel?: () => void;
    onUpdateSuccess?: () => void;
    organizations: Organization[];
    organizationsPagination: PaginationRequestState;
    permissions?: Permission[];
    role?: Role;
    update: typeof RolesActions.update;
    updates: RequestState;
    user: AuthUser;
}

interface RoleRule {
    disabled: boolean;
    edit: boolean;
    see: boolean;
}

interface RoleTableRow extends RoleRule {
    name: Permission['name'];
    reference: Permission['reference'];
}

class RoleTable extends React.Component<RoleTableProps> {
    private columns: Array<ColumnProps<RoleTableRow>> = [
        {
            dataIndex: 'name',
            title: <FormattedMessage {...messages.permission} />,
        }, {
            dataIndex: 'see',
            title: <FormattedMessage {...messages.see} />,
            render: (checked, record) => this.props.form.getFieldDecorator(`permissions.${record.reference}.see`, {
                initialValue: checked,
                valuePropName: 'checked',
            })(
                <Checkbox
                    disabled={(
                        !isUserAllowed(this.props.user, 'roles', PermissionRight.write) ||
                        this.props.form.getFieldValue(`permissions.${record.reference}.edit`) !== undefined ?
                            this.props.form.getFieldValue(`permissions.${record.reference}.edit`) :
                            record.edit
                    )}
                    onChange={this.onCheckboxChange.bind(null, PermissionType.see, record.reference)}
                />,
            ),
        }, {
            dataIndex: 'edit',
            title: <FormattedMessage {...messages.edit} />,
            render: (checked, record) => this.props.form.getFieldDecorator(`permissions.${record.reference}.edit`, {
                initialValue: checked,
                valuePropName: 'checked',
            })(
                <Checkbox
                    disabled={!isUserAllowed(this.props.user, 'roles', PermissionRight.write)}
                    onChange={this.onCheckboxChange.bind(null, PermissionType.edit, record.reference)}
                />,
            ),
        }, {
            dataIndex: 'disabled',
            title: <FormattedMessage {...messages.disabled} />,
            render: (checked, record) => this.props.form.getFieldDecorator(`permissions.${record.reference}.disabled`, {
                initialValue: checked,
                valuePropName: 'checked',
            })(
                <Checkbox
                    disabled={!isUserAllowed(this.props.user, 'roles', PermissionRight.write)}
                    onChange={this.onCheckboxChange.bind(null, PermissionType.disabled, record.reference)}
                />,
            ),
        // }, {
        //     key: 'permissionEdit',
        //     title: <FormattedMessage {...messages.permissionEdit} />,
        //     render: () => (
        //         <Checkbox />
        //     ),
        },
    ];

    public componentDidUpdate(prevProps: RoleTableProps) {
        const { creates, onCreateSuccess, onUpdateSuccess, updates } = this.props;

        if (
            typeof onUpdateSuccess === 'function' &&
            prevProps.updates.loading &&
            !updates.loading &&
            updates.success
        ) {
            onUpdateSuccess();
        }

        if (
            typeof onCreateSuccess === 'function' &&
            prevProps.creates.loading &&
            !creates.loading &&
            creates.success
        ) {
            onCreateSuccess();
        }
    }

    public onCheckboxChange = (
        permissionType: PermissionType,
        permissionReference: RoleTableRow['reference'],
        e: CheckboxChangeEvent,
    ) => {
        switch (permissionType) {
            case 'disabled':
                if (e.target.checked) {
                    this.props.form.setFieldsValue({
                        [`permissions.${permissionReference}.${PermissionType.edit}`]: false,
                        [`permissions.${permissionReference}.${PermissionType.see}`]: false,
                    });
                }
                break;

            case 'edit':
                if (e.target.checked) {
                    this.props.form.setFieldsValue({
                        [`permissions.${permissionReference}.${PermissionType.disabled}`]: false,
                        [`permissions.${permissionReference}.${PermissionType.see}`]: true,
                    });
                }
                break;

            case 'see':
                if (e.target.checked) {
                    this.props.form.setFieldsValue({
                        [`permissions.${permissionReference}.${PermissionType.disabled}`]: false,
                        [`permissions.${permissionReference}.${PermissionType.see}`]: false,
                    });
                }
                break;
        }
    }

    public onSubmit = (e?: React.FormEvent) => {
        const { create, form, update, role } = this.props;

        if (e) {
            e.preventDefault();
        }

        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }

            const permissions: Role['permissions'] = this.formatPermissions(val.permissions);

            if (role === undefined) {
                create({
                    name: val.name,
                    organization: val.organization,
                    permissions,
                });
            } else if (!isEqual(permissions, role.permissions)) {
                update(role.id, { permissions });
            }
        });
    }

    public onOrganizationChange = (organization: Organization) => {
        this.props.form.setFieldsValue({ organization: organization.id });
    }

    public fetchOrganizations = async (search?: string) => {
        await this.props.fetchOrganizations({ search, throttling: 200 });
    }

    public formatPermissions: (val: { [key: string]: RoleRule }) => Role['permissions'] = (val) => {
        const initialValue: Role['permissions'] = {};

        return Object.keys(val).reduce((result, key) => {
            if (val[key].disabled) {
                result[key.replace('_', '.')] = PermissionRight.disabled;
            }
            if (val[key].see) {
                result[key.replace('_', '.')] = PermissionRight.read;
            }
            if (val[key].edit) {
                result[key.replace('_', '.')] = PermissionRight.write;
            }

            return result;
        }, initialValue);
    }

    public rowKey = (record: any) => record.reference;

    public renderRoleFooter = () => {
        const { creates, form, onCreateCancel, role, updates } = this.props;
        const values = form.getFieldsValue();

        let disabledSubmit = true;

        if (values.permissions && !!Object.keys(values.permissions).length) {
            const permissions = this.formatPermissions(values.permissions);
            disabledSubmit = isEqual(permissions, role ? role.permissions : {});
        }

        return (
            <>
                {role === undefined && (
                    <Button
                        onClick={onCreateCancel}
                        style={{ marginRight: 16 }}
                    >
                        <FormattedMessage {...GenericMessages.cancel} />
                    </Button>
                )}
                <Button
                    htmlType="submit"
                    type="primary"
                    loading={creates.loading || updates.loading}
                    disabled={disabledSubmit}
                >
                    <FormattedMessage {...GenericMessages.save} />
                </Button>
            </>
        );
    }

    public render() {
        const {
            creates, form, intl, organizations, organizationsPagination, permissions, role, updates,
        } = this.props;

        const tableData = permissions ?
            permissions.map((permission: Permission) => {
                const result = {
                    name: permission.name,
                    reference: permission.reference.replace('.', '_'),
                    see: false,
                    edit: false,
                    disabled: false,
                };

                if (role && role.permissions && hasOwnProperty(role.permissions, permission.reference)) {
                    result.see = role.permissions[permission.reference] === PermissionRight.read ||
                                 role.permissions[permission.reference] === PermissionRight.write;

                    result.edit = role.permissions[permission.reference] === PermissionRight.write;
                    result.disabled = role.permissions[permission.reference] === PermissionRight.disabled;
                }

                return result;
            }) :
            [];

        return (
            <Form onSubmit={this.onSubmit}>
                {role === undefined && (
                    <div id="role-create-header">
                        {form.getFieldDecorator('name', {
                            rules: [{
                                required: true,
                                message: intl.formatMessage(FormMessages.requiredError),
                            }],
                        })(
                            <Input placeholder={intl.formatMessage(messages.roleNamePlaceholder)} />,
                        )}
                        <Can edit="su">
                            <div id="role-create-organization">
                                <FormattedMessage {...messages.forOrganization} />
                                {form.getFieldDecorator('organization', {
                                    rules: [{
                                        required: true,
                                        message: intl.formatMessage(FormMessages.requiredError),
                                    }],
                                })(
                                    <Select
                                        placeholder={<FormattedMessage {...MainMenuMessages.organizations} />}
                                        onSearch={this.fetchOrganizations}
                                        onChange={this.onOrganizationChange}
                                        loading={organizationsPagination.loading}
                                        notFoundContent={<EmptyResult />}
                                        filterOption={false}
                                        showSearch={true}
                                    >
                                        {organizations.map((organization) => (
                                            <Select.Option
                                                key={organization.id}
                                                value={organization.id}
                                            >
                                                {organization.name}
                                            </Select.Option>
                                        ))}
                                    </Select>,
                                )}
                            </div>
                        </Can>
                    </div>
                )}
                <Table
                    columns={this.columns}
                    dataSource={tableData}
                    footer={this.renderRoleFooter}
                    loading={updates.loading || creates.loading}
                    pagination={false}
                    rowKey={this.rowKey}
                />
            </Form>
        );
    }
}

const RoleTableForm = Form.create<RoleTableProps>()(RoleTable);

const mapStateToProps = (state: any) => {
    const user = getUser(state);

    return {
        creates: getCreates(state),
        organizations: getOrganizations(state),
        organizationsPagination: getPagination(state),
        permissions: getPermissions(state),
        updates: getUpdates(state),
        user,
    };
};

export default injectIntl(connect(
    mapStateToProps,
    {
        create: RolesActions.create,
        fetchOrganizations: OrganizationsActions.list,
        update: RolesActions.update,
    },
)(RoleTableForm));
