import { ActionCreator } from 'redux';

import { OrganizationsListResponse, OrganizationUpdateData } from '../api/organizations';
import * as reduxTypes from '../types/organizations';
import { DataAction, QueryListPayload, QueryListAction, IdAction } from '.';
import { Organization } from '../api/types';

// LIST

export const list: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    ...payload,
    type: reduxTypes.LIST,
});

export const listSuccess: ActionCreator<DataAction> = (data: OrganizationsListResponse) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAILED,
    data,
});

// CREATE

export const create: ActionCreator<DataAction> = (data: OrganizationUpdateData) => ({
    type: reduxTypes.CREATE,
    data,
});

export const createSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_SUCCESS,
    data,
});

export const createFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_FAILED,
    data,
});

// DETAILS

export const details: ActionCreator<IdAction> = (id: Organization['id']) => ({
    type: reduxTypes.DETAILS,
    id,
});

export const detailsSuccess: ActionCreator<DataAction> = (data: Organization, id: Organization['id']) => ({
    type: reduxTypes.DETAILS_SUCCESS,
    data,
    id,
});

export const detailsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_FAILED,
    data,
});

// UPDATE

export const update: ActionCreator<IdAction & DataAction> = (id: Organization['id'], data: OrganizationUpdateData) => ({
    type: reduxTypes.UPDATE,
    data,
    id,
});

export const updateSuccess: ActionCreator<IdAction & DataAction> = (data: Organization, id: Organization['id']) => ({
    type: reduxTypes.UPDATE_SUCCESS,
    data,
    id,
});

export const updateFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_FAILED,
    data,
});
