import * as React from 'react';
import { Table, Input, ConfigProvider, Skeleton, Row, Col } from 'antd';
import { TableProps } from 'antd/lib/table';
import { PaginatedListState } from '../../store/reducers';
import { ListTitle, ListPagination } from '.';
import { IconAngle } from '../icons';
import { Bone, SkeletonRow } from '../skeleton/';

const Cell: React.SFC = ({ children }) => (
    <td><div className="list-cell">{children}</div></td>
);

export interface ListProps<T> extends TableProps<T> {
    dataState: PaginatedListState<T>;
    emptyStateRenderer?: () => React.ReactNode;
    listTitle?: string | React.ReactNode;
    onChangePage?: (page: number, pageSize?: number | undefined) => void;
    onRowClick?: (record: T) => void;
    onSearch: (searchQuery: string) => void;
    hasPagination?: boolean;
    headerExtraRenderer?: () => React.ReactNode;
    skeletonRenderer?: (index: number) => React.ReactNode;
    subTableRenderer?: TableProps<T>['expandedRowRender'];
}

class List<T> extends React.Component<ListProps<T>> {
    public static defaultProps = {
        hasPagination: true,
    };

    public onRowEvents = (record: T) => {
        const { onRowClick } = this.props;

        if (onRowClick) {
            return {
                onClick: onRowClick.bind(null, record),
            };
        }

        return {};
    }

    public onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onSearch(event.target.value);
    }

    public getColumns = () => {
        const { columns, onRowClick } = this.props;
        const cols = columns ? [
            ...columns,
        ] : undefined;

        if (onRowClick && cols) {
            cols.push({
                key: 'action',
                width: 60,
                render: () => {
                    return <IconAngle direction="right" style={{ marginLeft: 'auto' }} />;
                },
            });
        }

        return cols;
    }

    public rowClassName = (record: T, index: number) => {
        const { onRowClick } = this.props;
        const evenOdd = index % 2 === 0 ? 'table-row-odd' : 'table-row-even';
        const clickable = onRowClick ? ' table-row-clickable' : '';

        return `${evenOdd}${clickable}`;
    }

    public renderListHeader = () => {
        return (
            <div className="list-header">
                <Row>
                    <Col xs={24} md={10}>
                        <Input.Search
                            className="list-search"
                            placeholder="Rechercher"
                            onChange={this.onSearch}
                        />
                    </Col>
                    <Col xs={24} md={14} className="list-header-extra">
                        {typeof this.props.headerExtraRenderer === 'function' &&
                            this.props.headerExtraRenderer()
                        }
                    </Col>
                </Row>
            </div>
        );
    }

    public renderSkeletonItem(index: number) {
        return (
            <SkeletonRow key={index}>
                <Bone style={{ flex: '0 1 116px' }} />
                <Bone style={{ flex: '0 1 58px' }} />
                <Bone style={{ flex: '0 1 10px' }} />
            </SkeletonRow>
        );
    }

    public renderSkeleton() {
        const { skeletonRenderer } = this.props;
        const itemRenderer = typeof skeletonRenderer === 'function' ?
            skeletonRenderer :
            this.renderSkeletonItem;

        return (
            <div className="list-skeleton">
                {Array.from(Array(30).keys()).map(itemRenderer)}
            </div>
        );
    }

    public render() {
        const {
            dataState, emptyStateRenderer, hasPagination, listTitle, onChangePage, rowKey,
            subTableRenderer,
        } = this.props;
        const header: React.ReactNode[] = [];

        if (listTitle) {
            header.push((
                <ListTitle<T>
                    count={dataState.count}
                    key="list-title"
                    filteredCount={dataState.filteredCount}
                    title={listTitle}
                    totalCount={dataState.totalCount}
                />
            ));
        }

        if (hasPagination) {
            header.push((
                <ListPagination<T>
                    key="list-pagination"
                    count={dataState.count}
                    current={dataState.page + 1}
                    onChange={onChangePage}
                    pageSize={dataState.pageSize}
                    total={dataState.filteredCount}
                />
            ));
        }

        return (
            <div className="list">
                {header.length && (
                    <div className="list-top">
                        {header.map((c) => c)}
                    </div>
                )}
                {this.renderListHeader()}
                <ConfigProvider renderEmpty={emptyStateRenderer ? emptyStateRenderer : undefined}>
                    {dataState.loading ?
                        this.renderSkeleton() : (
                        <Table<T>
                            columns={this.getColumns()}
                            components={{
                                body: {
                                    cell: Cell,
                                },
                            }}
                            dataSource={dataState.data || []}
                            expandedRowRender={subTableRenderer}
                            loading={dataState.loading}
                            onRow={this.onRowEvents}
                            pagination={false}
                            rowClassName={this.rowClassName}
                            rowKey={rowKey}
                            scroll={{ x: 'max-content' }}
                            showHeader={false}
                        />
                    )}
                </ConfigProvider>
            </div>
        );
    }
}

export default List;
