import { User, PermissionRight } from '../store/api/types';
import { hasOwnProperty } from '.';
import { AuthUser } from '../store/reducers/auth';

export const isUserAllowed = (user: User | AuthUser, permission: string, permissionRight: PermissionRight) => {
    if (
        hasOwnProperty(user, 'role') &&
        user.role &&
        hasOwnProperty(user.role, 'permissions') &&
        user.role.permissions &&
        hasOwnProperty(user.role.permissions, permission)
    ) {
        if (user.role.permissions[permission] === PermissionRight.disabled) {
            return PermissionRight.disabled;
        }

        switch (permissionRight) {
            case PermissionRight.read:
                return user.role.permissions[permission] === PermissionRight.read ||
                    user.role.permissions[permission] === PermissionRight.write;

            case PermissionRight.write:
                return user.role.permissions[permission] === PermissionRight.write;

            default:
                return false;
        }
    } else {
        return false;
    }
};
