import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl, InjectedIntlProps, FormattedMessage, FormattedNumber } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Card, Skeleton, Avatar, Row, Col } from 'antd';

import * as DeliveryMenActions from '../../store/actions/deliveryMen';
import { DeliveryMan } from '../../store/api/types';
import {
    getDeliveryManById, getReads, DeliveryManStatsResult, getDeliveryManStatsById,
} from '../../store/reducers/deliveryMen';
import { MainReducerState, RequestState } from '../../store/reducers';

import Header from '../../components/header/Header';
import Content from '../../components/Content';
import HeaderNav from '../../components/header/HeaderNav';
import listMessages from '../../locale/List.messages';
import GenericMessages from '../../locale/Generic.messages';
import MainMenuMessages from '../../components/MainMenu.messages';
import StatsMessages from '../../locale/Stats.messages';

import { getRoute, RoutePathName } from '../../routes';
import { getFullName, capitalizeWords } from '../../utils';
import avatar from '../../assets/images/avatar.jpg';
import avatar2x from '../../assets/images/avatar@2x.jpg';
import avatar3x from '../../assets/images/avatar@3x.jpg';
import { Languages } from '../../components/IntlProvider';
import Metric from '../../components/Metric';
import MetricCard from '../../components/MetricCard';
import DeliveryTourListWrapper, { DeliveryTourListColumns } from '../deliveryTours/DeliveryTourListWrapper';
import RelativeDateWithTooltip from '../../components/RelativeDateWithTooltip';

interface MatchParams {
    id: DeliveryMan['id'];
}

interface DeliveryMenDetailsProps extends InjectedIntlProps, RouteComponentProps<MatchParams> {
    fetchDeliveryMan: typeof DeliveryMenActions.details;
    fetchDeliveryManStats: typeof DeliveryMenActions.stats;
    deliveryMan: DeliveryMan;
    reads: RequestState;
    stats: DeliveryManStatsResult;
}

class DeliveryMenDetails extends React.Component<DeliveryMenDetailsProps> {

    public componentDidMount() {
        const { fetchDeliveryMan, fetchDeliveryManStats, match } = this.props;
        fetchDeliveryMan(match.params.id);
        fetchDeliveryManStats(match.params.id);
    }

    public setColumns = (columns: DeliveryTourListColumns) => {
        const newColumns = [...columns];

        newColumns.splice(1, 1, {
            dataIndex: 'date',
            render: (d) => (
                <RelativeDateWithTooltip
                    value={d}
                />
            ),
        });

        return newColumns;
    }

    public render() {
        const { deliveryMan, intl, history, location, reads, stats } = this.props;
        const fullName = deliveryMan ?
            getFullName(
                deliveryMan.firstname,
                deliveryMan.lastname,
            ).toLowerCase() :
            '';
        const headerLinks = {
            [getRoute(intl.locale as Languages, RoutePathName.deliveryMenList)]:
                intl.formatMessage(listMessages.contentHeaderNavItemList),
            [location.pathname]: capitalizeWords(fullName),
        };

        return (
            <>
                <Header>
                    <HeaderNav links={headerLinks} />
                </Header>
                <Content>
                    <Row gutter={32} type="flex" className="fill-height" style={{ marginBottom: 40 }}>
                        <Col xs={24} sm={12} lg={9}>
                            <Card className="profile-card">
                                <Skeleton loading={reads.loading} avatar active>
                                    {deliveryMan && (
                                        <>
                                            <Card.Meta
                                                avatar={
                                                    <Avatar
                                                        size="large"
                                                        src={avatar}
                                                        srcSet={`${avatar} 1x, ${avatar2x} 2x, ${avatar3x} 3x`}
                                                    />
                                                }
                                                title={fullName}
                                                description={
                                                    <span className="profile-reference">
                                                        {deliveryMan.reference}
                                                    </span>
                                                }
                                            />
                                            {stats && (
                                                <Row type="flex" justify="space-around" align="middle">
                                                    <Col span={4}>
                                                        <Metric
                                                            label={
                                                                <FormattedMessage
                                                                    {...MainMenuMessages.deliveryTours}
                                                                />
                                                            }
                                                            loading={stats.loading}
                                                            value={stats.totalDeliveryTour}
                                                        />
                                                    </Col>
                                                    <Col span={4}>
                                                        <Metric
                                                            label={<FormattedMessage {...GenericMessages.tasks} />}
                                                            loading={stats.loading}
                                                            value={stats.totalDeliveries}
                                                        />
                                                    </Col>
                                                </Row>
                                            )}
                                        </>
                                    )}
                                </Skeleton>
                            </Card>
                        </Col>
                        {stats && (
                            <>
                                <Col xs={24} sm={12} lg={5}>
                                    <MetricCard
                                        altValue={stats.lateDeliveries}
                                        altValueMessage={
                                            <FormattedMessage
                                                {...StatsMessages.outOfSlotTasks}
                                                values={{count: stats.lateDeliveries}}
                                            />
                                        }
                                        label={<FormattedMessage {...StatsMessages.punctualityRatio} />}
                                        loading={stats.loading}
                                        suffix="%"
                                        value={stats.loading || stats.totalDeliveries === 0 ? undefined : (
                                            <FormattedNumber
                                                value={
                                                    ((stats.totalDeliveries - stats.lateDeliveries) * 100) /
                                                    stats.totalDeliveries
                                                }
                                            />
                                        )}
                                    />
                                </Col>
                                <Col xs={24} sm={12} lg={5}>
                                    <MetricCard
                                        altValue={stats.totalDeliveries === 0 ? undefined : (
                                            `${intl.formatNumber(
                                                (stats.criticalyLateDeliveries * 100) / stats.totalDeliveries,
                                            )}%`
                                        )}
                                        label={<FormattedMessage {...StatsMessages.lateTasksCount} />}
                                        loading={stats.loading}
                                        value={stats.loading || stats.criticalyLateDeliveries}
                                    />
                                </Col>
                                <Col xs={24} sm={12} lg={5}>
                                    <MetricCard
                                        label={<FormattedMessage {...StatsMessages.serviceRatio} />}
                                        loading={stats.loading}
                                        suffix="%"
                                        value={stats.loading || stats.totalDeliveries === 0 ? undefined : (
                                            <FormattedNumber
                                                value={(stats.sucessfullDeliveries * 100) / stats.totalDeliveries}
                                            />
                                        )}
                                    />
                                </Col>
                            </>
                        )}
                    </Row>
                    {deliveryMan ?
                        <DeliveryTourListWrapper
                            fetchPayload={{ deliveryMan: deliveryMan.id }}
                            history={history}
                            listTitle={<FormattedMessage {...MainMenuMessages.deliveryTours} />}
                            setColumns={this.setColumns}
                        /> : null
                    }
                </Content>
            </>
        );
    }
}

const mapStateToProps = (state: MainReducerState, { match }: DeliveryMenDetailsProps) => ({
    deliveryMan: getDeliveryManById(state, match.params.id),
    reads: getReads(state),
    stats: getDeliveryManStatsById(state, match.params.id),
});

export default injectIntl(connect(
    mapStateToProps,
    {
        fetchDeliveryMan: DeliveryMenActions.details,
        fetchDeliveryManStats: DeliveryMenActions.stats,
    },
)(DeliveryMenDetails));
