import { ActionCreator } from 'redux';

import { UsersListResponse, UserUpdateData } from '../api/users';
import * as reduxTypes from '../types/users';
import { DataAction, QueryListPayload, QueryListAction, IdAction } from '.';
import { User } from '../api/types';

// LIST

export const list: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    ...payload,
    type: reduxTypes.LIST,
});

export const listSuccess: ActionCreator<DataAction> = (data: UsersListResponse) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAILED,
    data,
});

// DETAILS

export const details: ActionCreator<IdAction> = (id: User['id']) => ({
    type: reduxTypes.DETAILS,
    id,
});

export const detailsSuccess: ActionCreator<DataAction> = (data: User, id: User['id']) => ({
    type: reduxTypes.DETAILS_SUCCESS,
    data,
    id,
});

export const detailsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_FAILED,
    data,
});

// CREATE

export const create: ActionCreator<DataAction> = (data: UserUpdateData) => ({
    type: reduxTypes.CREATE,
    data,
});

export const createSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_SUCCESS,
    data,
});

export const createFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_FAILED,
    data,
});

// UPDATE

export const update: ActionCreator<IdAction & DataAction> = (id: User['id'], data: UserUpdateData) => ({
    type: reduxTypes.UPDATE,
    data,
    id,
});

export const updateSuccess: ActionCreator<IdAction & DataAction> = (data: User, id: User['id']) => ({
    type: reduxTypes.UPDATE_SUCCESS,
    data,
    id,
});

export const updateFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_FAILED,
    data,
});

// DELETE

export const del: ActionCreator<IdAction> = (id: User['id']) => ({
    type: reduxTypes.DELETE,
    id,
});

export const delSuccess: ActionCreator<IdAction & DataAction> = (data: User, id: User['id']) => ({
    type: reduxTypes.DELETE_SUCCESS,
    data,
    id,
});

export const delFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DELETE_FAILED,
    data,
});
