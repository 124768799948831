import { ActionCreator } from 'redux';

import { CustomersListResponse } from '../api/customers';
import * as reduxTypes from '../types/customers';
import { DataAction, QueryListPayload, QueryListAction, IdAction } from '.';
import { Customer } from '../api/types';

// LIST

export const list: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    ...payload,
    type: reduxTypes.LIST,
});

export const listSuccess: ActionCreator<DataAction> = (data: CustomersListResponse) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAILED,
    data,
});

// DETAILS

export const details: ActionCreator<IdAction> = (id: Customer['id']) => ({
    type: reduxTypes.DETAILS,
    id,
});

export const detailsSuccess: ActionCreator<DataAction> = (data: Customer, id: Customer['id']) => ({
    type: reduxTypes.DETAILS_SUCCESS,
    data,
    id,
});

export const detailsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_FAILED,
    data,
});
