import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <path fill="#FFF" fillRule="evenodd" d="M12.688 7.143V3.25L7 1.286 1.312 3.25v3.893c0 3.077 2.547 5.57 5.688 5.57 3.14 0 5.688-2.493 5.688-5.57zM7 0l7 2.286v4.857C14 10.93 10.866 14 7 14s-7-3.07-7-6.857V2.286L7 0zM4.565 6.34L6.47 8.21l3.587-3.512a.63.63 0 0 1 .877 0l.05.05h.002c.24.238.24.622 0 .86L6.91 9.597a.63.63 0 0 1-.876 0l-.038-.037-2.36-2.31v-.002a.632.632 0 0 1 0-.907h.003c.255-.25.67-.25.925 0z" />{/* tslint:disable:max-line-length */}
    </svg>
);

const IconRoles: React.SFC<IconProps> = (props) => (
    <Icon component={svg} className="anticon icon-roles" {...props} />
);

export default IconRoles;
