import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

class ButtonLink extends React.Component<LinkProps & ButtonProps> {

    public render() {
        const { children, to, ...rest } = this.props;

        return (
            <Link to={to}>
                <Button {...rest}>
                    {children}
                </Button>
            </Link>
        );
    }
}

export default ButtonLink;
