import * as React from 'react';
import { Pagination } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';
import { IconAngle } from '../icons';
import { PaginatedListState } from '../../store/reducers';

interface ListPaginationProps<T> extends PaginationProps {
    count: PaginatedListState<T>['count'];
}

class ListPagination<T> extends React.PureComponent<ListPaginationProps<T>> {
    public renderItem: PaginationProps['itemRender'] = (page, type, originalElement) => {
        switch (type) {
            case 'prev':
                return (
                    <a className="ant-pagination-item-link">
                        <IconAngle direction="left" />
                    </a>
                );

            case 'next':
                return (
                    <a className="ant-pagination-item-link">
                        <IconAngle direction="right" />
                    </a>
                );

            default:
                return originalElement;
        }
    }

    public render() {
        const { count, ...restProps } = this.props;

        return count > 0 ? (
            <div className="list-pagination">
                <Pagination itemRender={this.renderItem} {...restProps} />
            </div>
        ) : null;
    }
}

export default ListPagination;
