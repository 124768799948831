import { Action } from 'redux';
import { delay } from 'redux-saga';
import { call, takeLatest, put } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import * as reduxActions from '../actions/roles';
import * as reduxTypes from '../types/roles';
import * as api from '../api/roles';
import { SearchQuery } from '../api';
import { DataAction, IdAction } from '../actions';
import { arrayOfRoles, role } from '../schema';
import { Role } from '../api/types';

export function* listSaga(action: Action & SearchQuery) {
    try {
        yield delay(action.throttling || 0);
        const {items, ...rest} = yield call(api.list, action);
        const normalizedItems = normalize(items, arrayOfRoles);

        return yield put(reduxActions.listSuccess({
            ...normalizedItems,
            ...rest,
        }));
    } catch (error) {
        return yield put(reduxActions.listFailed(error));
    }
}

export function* createSaga(action: DataAction<api.RoleUpdateData>) {
    try {
        const response = yield call(api.create, action.data);
        return yield put(reduxActions.createSuccess(response));
    } catch (error) {
        return yield put(reduxActions.createFailed(error));
    }
}

export function* updateSaga(action: DataAction<api.RoleUpdateData> & IdAction<Role['id']>) {
    try {
        const response = yield call(api.update, action.id, action.data);
        return yield put(reduxActions.updateSuccess(response));
    } catch (error) {
        return yield put(reduxActions.updateFailed(error));
    }
}

export function* deleteSaga(action: DataAction<api.RoleUpdateData> & IdAction<Role['id']>) {
    try {
        const response = yield call(api.del, action.id, action.data);
        return yield put(reduxActions.delSuccess(response, action.id));
    } catch (error) {
        return yield put(reduxActions.delFailed(error, action.id));
    }
}

export default function* rolesSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.CREATE, createSaga);
    yield takeLatest(reduxTypes.UPDATE, updateSaga);
    yield takeLatest(reduxTypes.DELETE, deleteSaga);
}
