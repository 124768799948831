import * as agencies from './agencies';
import * as auth from './auth';
import * as customers from './customers';
import * as deliveries from './deliveries';
import * as deliveryMen from './deliveryMen';
import * as deliveryTours from './deliveryTours';
import * as organizations from './organizations';
import * as permissions from './permissions';
import * as providers from './providers';
import * as telecoms from './telecoms';
import * as roles from './roles';
import * as users from './users';
import * as vehicles from './vehicles';
import { DeliveryMan, Customer, Agency } from './types';

export type ApiResponse = Record<string, any>;

export enum DateStep {
    second = 'second',
    minute = 'minute',
    hour = 'hour',
    day = 'day',
    month = 'month',
    year = 'year',
}

export interface DateQuery {
    fromDate?: string;
    toDate?: string;
    step?: DateStep;
}

export interface PaginationQuery {
    page?: number;
    pageSize?: number;
    sort?: string;
    sortOrder?: string;
}

export interface SearchQuery {
    search?: string;
    throttling?: number;
    customer?: Customer['id'];
    deliveryMan?: DeliveryMan['id'];
    agency?: Agency['id'];
}

export type SearchPaginationQuery = PaginationQuery & SearchQuery & DateQuery;

export interface SearchQueryComponentState {
    search?: string;
}

export interface RequestError {
    data?: any;
    headers?: any;
    message: string;
    status: number;
    request?: XMLHttpRequest;
}

export default {
    agencies,
    auth,
    customers,
    deliveries,
    deliveryMen,
    deliveryTours,
    organizations,
    permissions,
    providers,
    telecoms,
    roles,
    users,
    vehicles,
};
