import * as React from 'react';
import { injectIntl, InjectedIntlProps, FormattedMessage, FormattedNumber } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import Qs from 'query-string';
import moment from 'moment';
import { Typography, DatePicker, Button, Row, Col, Card, Divider, Spin } from 'antd';
import { RangePickerValue } from 'antd/lib/date-picker/interface';

import '../../assets/styles/Dashboard.less';

import * as DashboardActions from '../../store/actions/dashboard';
import { MainReducerState } from '../../store/reducers';

import Header from '../../components/header/Header';
import Content from '../../components/Content';
import HeaderNav from '../../components/header/HeaderNav';
import MainMenuMessages from '../../components/MainMenu.messages';
import MetricCard from '../../components/MetricCard';
import DeliveryTourSiderMessages from '../deliveryTours/DeliveryTourSider.messages';
import GenericMessages from '../../locale/Generic.messages';
import StatsMessages from '../../locale/Stats.messages';
import messages from './Dashboard.messages';
import { DeliveryStatus, getDeliveryStatus, getSimplerDeliveryStatus } from '../../store/api/types';
import { getDashboardStatsByDate, DashboardStatsState } from '../../store/reducers/dashboard';
import QualityAndIncidentsChart from './QualityAndIncidentsChart';
import { DateStep } from '../../store/api';

interface DashboardProps extends InjectedIntlProps, RouteComponentProps {
    fetchStats: typeof DashboardActions.stats;
    filters: {
        fromDate: number;
        toDate: number;
        step: DateStep;
    };
    stats: DashboardStatsState;
}

class Dashboard extends React.Component<DashboardProps> {
    public componentDidMount() {
        this.fetchData();
    }

    public componentDidUpdate(prevProps: DashboardProps) {
        if (
            prevProps.filters.fromDate !== this.props.filters.fromDate ||
            prevProps.filters.toDate !== this.props.filters.toDate
        ) {
            this.fetchData();
        }
    }

    public onSetToday = () => {
        this.onChangeDate([moment(), moment()]);
    }

    public onSetThisWeek = () => {
        this.onChangeDate([moment().startOf('week'), moment().endOf('week')]);
    }

    public onChangeDate = (dates: RangePickerValue) => {
        const { history, location } = this.props;
        const query = Qs.parse(location.search);
        const search: {[key: string]: any} = {
            ...query,
        };

        if (dates[0]) {
            search.fromDate = dates[0].startOf('day').valueOf();
        }

        if (dates[1]) {
            search.toDate = dates[1].endOf('day').valueOf();
        }

        history.push({
            pathname: location.pathname,
            search: Qs.stringify(search),
        });
    }

    public fetchData = () => {
        const { fetchStats, filters } = this.props;

        fetchStats({
            fromDate: moment(filters.fromDate).toISOString(),
            toDate: moment(filters.toDate).toISOString(),
            step: filters.step,
        });
    }

    public renderFilters() {
        const { filters } = this.props;
        const isToday = moment(filters.fromDate).isSame(moment(), 'day') &&
            moment(filters.toDate).isSame(moment(), 'day');

        const isThisWeek = moment(filters.fromDate).isSame(moment().startOf('week'), 'day') &&
            moment(filters.toDate).isSame(moment().endOf('week'), 'day');

        return (
            <Row id="dashboard-filters" type="flex" justify="space-between" gutter={24}>
                <Col>
                    <Button
                        className={isToday ? 'selected' : undefined}
                        onClick={this.onSetToday}
                        type="link"
                    >
                        <FormattedMessage {...GenericMessages.today} />
                    </Button>
                </Col>
                <Col>
                    <Button
                        className={isThisWeek ? 'selected' : undefined}
                        onClick={this.onSetThisWeek}
                        type="link"
                    >
                        <FormattedMessage {...GenericMessages.thisWeek} />
                    </Button>
                </Col>
                <Col>
                    <DatePicker.RangePicker
                        allowClear={false}
                        format="L"
                        onChange={this.onChangeDate}
                        size="small"
                        value={[moment(filters.fromDate), moment(filters.toDate)]}
                    />
                </Col>
            </Row>
        );
    }

    public renderTaskStatusesStatsRow = (row: { status: DeliveryStatus, count?: number }) => {
        return (
            <Row type="flex" justify="space-between" className="task-statuses-row" key={row.status}>
                <Col>
                    <Typography.Text className="task-status">
                        <FormattedMessage
                            {...DeliveryTourSiderMessages[getDeliveryStatus(row.status)]}
                        />
                    </Typography.Text>
                </Col>
                <Col>
                    <Typography.Text
                        className={`task-statuses-value-${getSimplerDeliveryStatus(row.status)}`}
                        disabled={row.count === undefined}
                    >
                        {row.count !== undefined ? (
                            <FormattedNumber value={row.count} />
                        ) : '--'}
                    </Typography.Text>
                </Col>
            </Row>
        );
    }

    public renderTaskStatusesStats = () => {
        const { stats } = this.props;

        const values: Array<{
            status: DeliveryStatus,
            count?: number,
        }> = Object.values(DeliveryStatus)
            .map((status) => ({
                status,
                count: !stats.loading && stats.data && stats.data.deliveryStatusCount[status] || undefined,
            }))
            .sort((a, b) => {
                if (typeof a.count === 'number' && typeof b.count === 'number' && a.count && b.count) {
                    return b.count - a.count;
                } else {
                    return 0;
                }
            });

        return (
            <Spin spinning={stats.loading}>
                <Card id="task-statuses-wrapper" className="metric-card">
                    <div className="metric">
                        <p className="metric-label">
                            <FormattedMessage {...messages.taskStatuses} />
                        </p>
                    </div>
                    <div id="task-statuses">
                        <div className="task-statuses-col">
                            {values.slice(0, 5).map(this.renderTaskStatusesStatsRow)}
                        </div>
                        <div className="task-statuses-divider">
                            <Divider type="vertical" style={{ height: '100%' }} />
                        </div>
                        <div className="task-statuses-col">
                            {values.slice(5).map(this.renderTaskStatusesStatsRow)}
                        </div>
                    </div>
                </Card>
            </Spin>
        );
    }

    public render() {
        const { filters, intl, stats } = this.props;
        const headerLinks = {
            [location.pathname]: intl.formatMessage(MainMenuMessages.dashboard),
        };

        return (
            <>
                <Header fixed>
                    <HeaderNav links={headerLinks} />
                    {this.renderFilters()}
                </Header>
                <Content>
                    <Row gutter={32} type="flex" className="fill-height" style={{ marginBottom: 40 }}>
                        <Col xs={24} sm={12} lg={6}>
                            <MetricCard
                                label={
                                    <FormattedMessage
                                        {...MainMenuMessages.deliveryTours}
                                    />
                                }
                                loading={stats.loading}
                                value={stats.loading || stats.data && stats.data.totalDeliveryTour}
                            />
                        </Col>
                        <Col xs={24} sm={12} lg={6}>
                            <MetricCard
                                altValue={
                                    stats.loading ||
                                    (stats.data && stats.data.totalDeliveries === 0) ||
                                    (stats.data && stats.data.totalDeliveryTour === 0) ||
                                    !stats.data ?
                                    undefined : (
                                        <FormattedNumber
                                            value={
                                                stats.data.totalDeliveries /
                                                stats.data.totalDeliveryTour
                                            }
                                            maximumFractionDigits={2}
                                        />
                                    )
                                }
                                altValueMessage={
                                    stats.loading ||
                                    (stats.data && stats.data.totalDeliveries === 0) ||
                                    (stats.data && stats.data.totalDeliveryTour === 0) ||
                                    !stats.data ?
                                    undefined : (
                                        <FormattedMessage
                                            {...StatsMessages.perDeliveryTour}
                                        />
                                    )
                                }
                                label={<FormattedMessage {...GenericMessages.tasks} />}
                                loading={stats.loading}
                                value={
                                    stats.loading ||
                                    !stats.data ?
                                    undefined : (
                                        <FormattedNumber
                                            value={stats.data.totalDeliveries}
                                            maximumFractionDigits={2}
                                        />
                                    )
                                }
                            />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                            {this.renderTaskStatusesStats()}
                        </Col>
                    </Row>
                    <Typography.Title level={2}>
                        <FormattedMessage {...messages.qualityAndIncidents} />
                    </Typography.Title>
                    <Row gutter={32} type="flex" className="fill-height" style={{ marginBottom: 40 }}>
                        <Col xs={24} sm={12} lg={5}>
                            <MetricCard
                                altValue={stats.data && stats.data.lateDeliveries}
                                altValueMessage={
                                    <FormattedMessage
                                        {...StatsMessages.outOfSlotTasks}
                                        values={{ count: stats.data && stats.data.lateDeliveries }}
                                    />
                                }
                                label={<FormattedMessage {...StatsMessages.punctualityRatio} />}
                                loading={stats.loading}
                                suffix="%"
                                value={
                                    stats.loading ||
                                    (stats.data && stats.data.totalDeliveries === 0) ||
                                    !stats.data ?
                                        undefined : (
                                        <FormattedNumber
                                            value={
                                                ((stats.data.totalDeliveries - stats.data.lateDeliveries) * 100) /
                                                stats.data.totalDeliveries
                                            }
                                            maximumFractionDigits={2}
                                        />
                                    )
                                }
                            />
                        </Col>
                        <Col xs={24} sm={12} lg={5}>
                            <MetricCard
                                altValue={
                                    stats.loading ||
                                    (stats.data && stats.data.totalDeliveries === 0) ||
                                    !stats.data ?
                                    undefined : (
                                        `${intl.formatNumber(
                                            (stats.data.criticalyLateDeliveries * 100) / stats.data.totalDeliveries,
                                        )}%`
                                    )
                                }
                                label={<FormattedMessage {...StatsMessages.lateTasksCount} />}
                                loading={stats.loading}
                                value={stats.loading || stats.data && stats.data.criticalyLateDeliveries}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={5}>
                            <MetricCard
                                label={<FormattedMessage {...StatsMessages.serviceRatio} />}
                                loading={stats.loading}
                                suffix="%"
                                value={
                                    stats.loading ||
                                    (stats.data && stats.data.totalDeliveries === 0) ||
                                    !stats.data ?
                                    undefined : (
                                        <FormattedNumber
                                            value={(stats.data.sucessfullDeliveries * 100) / stats.data.totalDeliveries}
                                            maximumFractionDigits={2}
                                        />
                                    )
                                }
                            />
                        </Col>
                        {/* <Col xs={24} sm={12} lg={5}>
                            <MetricCard
                                label={<FormattedMessage {...StatsMessages.customerComplaintRatio} />}
                                loading={stats.loading}
                                suffix="%"
                                value={stats.loading || stats.totalDeliveries === 0 ? undefined : (
                                    <FormattedNumber
                                        value={(stats.sucessfullDeliveries * 100) / stats.totalDeliveries}
                                        maximumFractionDigits={2}
                                    />
                                )}
                            />
                        </Col> */}
                    </Row>
                    <QualityAndIncidentsChart
                        filters={filters}
                        stats={stats}
                    />
                </Content>
            </>
        );
    }
}

const mapStateToProps = (state: MainReducerState, { location }: DashboardProps) => {
    const query = Qs.parse(location.search);
    const filters = {
        fromDate: query.fromDate && typeof query.fromDate === 'string' ?
            parseInt(query.fromDate, 10) :
            moment().startOf('day').valueOf(),
        toDate: query.toDate && typeof query.toDate === 'string' ?
            parseInt(query.toDate, 10) :
            moment().endOf('day').valueOf(),
        step: DateStep.hour,
    };

    if (
        query.fromDate && typeof query.fromDate === 'string' &&
        query.toDate && typeof query.toDate === 'string' &&
        Math.ceil(moment(filters.toDate).diff(moment(filters.fromDate), 'day', true)) > 1
    ) {
        filters.step = DateStep.day;
    }

    return {
        filters,
        stats: getDashboardStatsByDate(state, {
            fromDate: moment(filters.fromDate).toISOString(),
            toDate: moment(filters.toDate).toISOString(),
        }),
    };
};

export default injectIntl(connect(
    mapStateToProps,
    {
        fetchStats: DashboardActions.stats,
    },
)(Dashboard));
