import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { Delivery, getDeliveryStatusColor } from '../../store/api/types';

import '../../assets/styles/DeliveryTourListProgress.less';

import DeliveryTourSiderMessages from './DeliveryTourSider.messages';

const deliveryColorMap: { [color: string]: string } = {
    red: '#ff4d4f', // @red-5
    orange: '#ffc069', // @orange-4
    green: '#95de64', // @green-4
    primary: '#d9d9d9', // @grey-5
};

interface DeliveryTourListProgressProps {
    deliveries?: Delivery[];
}

class DeliveryTourListProgress extends PureComponent<DeliveryTourListProgressProps> {

    public renderTasks = (deliveries: Delivery[]) => {
        return deliveries!.map((delivery) => (
            <div
                key={delivery.reference}
                className="progress-task"
                style={{
                    backgroundColor: deliveryColorMap[getDeliveryStatusColor(delivery.status)],
                }}
            />
        ));
    }

    public render() {
        const { deliveries } = this.props;

        return (
            <div className="delivery-tour-list-progress">
                <div className="progress-infos">
                    <FormattedMessage
                        {...DeliveryTourSiderMessages.numberOfTasks}
                        values={{ count: (deliveries || []).length }}
                    />
                </div>
                <div className="progress-track">
                    {!!(deliveries || []).length && (
                        this.renderTasks(deliveries!)
                    )}
                </div>
            </div>
        );
    }
}

export default DeliveryTourListProgress;
