import * as React from 'react';
import { Drawer, Card, Avatar, Row, Col, Alert, Tag } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import {
    FormattedMessage, injectIntl, InjectedIntlProps, FormattedTime, FormattedDate,
} from 'react-intl';

import {
   DeliveryTour, Vehicle, getVehicleAnomalyType,
} from '../../store/api/types';

import avatar from '../../assets/images/avatar.jpg';
import avatar2x from '../../assets/images/avatar@2x.jpg';
import avatar3x from '../../assets/images/avatar@3x.jpg';
import GenericMessages from '../../locale/Generic.messages';
import { getFullName } from '../../utils';
import EmptyStateText from '../../components/typography/EmptyStateText';
import messages from './DeliveryTourDeliveryManDetailsDrawer.messages';
import DeliveryTourMessages from './DeliveryTour.messages';
import { DescriptiveList } from '../../components/list';
import TemperatureChart from './TemperatureChart';
import { Can } from '../../components/auth';

interface DeliveryTourDeliveryManDetailsDrawerProps extends DrawerProps, InjectedIntlProps {
    deliveryTour?: DeliveryTour;
    onClose?: DrawerProps['onClose'];
    visible: boolean;
}

class DeliveryTourDeliveryManDetailsDrawer extends React.Component<
    DeliveryTourDeliveryManDetailsDrawerProps
> {
    public onClose: DrawerProps['onClose'] = (e) => {
        if (typeof this.props.onClose === 'function') {
            this.props.onClose(e);
        }
    }

    public renderProfileCard = (deliveryTour: DeliveryTour) => {
        const deliveryMan = (
            deliveryTour.deliveryMen &&
            deliveryTour.deliveryMen.length &&
            deliveryTour.deliveryMen[0]
        ) || undefined;

        const fullName = deliveryMan ?
            getFullName(
                deliveryMan.firstname,
                deliveryMan.lastname,
            ).toLowerCase() :
            '';

        return (
            <Card className="profile-card">
                <Card.Meta
                    avatar={
                        <Avatar
                            size="large"
                            src={avatar}
                            srcSet={`${avatar} 1x, ${avatar2x} 2x, ${avatar3x} 3x`}
                        />
                    }
                    title={fullName}
                    description={
                        <span className="profile-reference">
                            {deliveryMan && deliveryMan.reference}
                        </span>
                    }
                />
            </Card>
        );
    }

    public renderVehicleAnomalies = (anomalies: Vehicle['anomalies']) => {
        return anomalies ? anomalies.map((anomaly) => (
            <Alert
                message={(
                    <Row type="flex" justify="space-between">
                        <Col style={{ flex: '1 0' }}>
                            <FormattedMessage
                                {...GenericMessages.anomalyOnDate}
                                values={{
                                    date: (
                                        <FormattedDate
                                            value={anomaly.registeredAt}
                                        />
                                    ),
                                    time: (
                                        <FormattedTime
                                            value={anomaly.registeredAt}
                                        />
                                    ),
                                }}
                            />
                        </Col>
                        <Col>
                            <Tag>
                                <FormattedMessage
                                    {...messages[getVehicleAnomalyType(anomaly.type)]}
                                />
                            </Tag>
                        </Col>
                    </Row>
                )}
                description={(
                    <p>
                        {anomaly.comment && (
                            <>
                                <strong>
                                    <FormattedMessage
                                        {...GenericMessages.comments}
                                    />
                                </strong><br />
                                {anomaly.comment}
                            </>
                        )}
                        {anomaly.imageUrl && (
                            <>
                                <br />
                                <img
                                    alt="anomaly"
                                    src={anomaly.imageUrl}
                                />
                            </>
                        )}
                    </p>
                )}
                type="error"
                showIcon
            />
        )) : null;
    }

    public render() {
        const { deliveryTour, onClose, visible, ...rest } = this.props;
        const vehicle = deliveryTour && deliveryTour.vehicles && deliveryTour.vehicles[0];

        const descriptiveListData = [];

        if (vehicle) {
            descriptiveListData.push({
                term: <FormattedMessage {...GenericMessages.licensePlate} />,
                description: <Tag>{vehicle.licensePlate}</Tag>,
            });
        }

        return (
            <Drawer
                closable
                onClose={this.onClose}
                placement="right"
                title={<FormattedMessage {...DeliveryTourMessages.deliveryManInformations} />}
                visible={visible}
                width={758}
                {...rest}
            >
                {deliveryTour ? (
                    <div className="delivery-details-drawer">
                        <Row gutter={32} type="flex" className="fill-height" style={{ marginBottom: 40 }}>
                            <Col xs={24} sm={12}>
                                {this.renderProfileCard(deliveryTour)}
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card
                                    title={<FormattedMessage {...GenericMessages.vehicle} />}
                                >
                                    {vehicle ? (
                                        <>
                                            <DescriptiveList
                                                data={descriptiveListData}
                                                termResponsive="ant-col-16"
                                                descriptionResponsive="ant-col-8"
                                            />
                                            {this.renderVehicleAnomalies(vehicle.anomalies)}
                                        </>
                                    ) : (
                                        <EmptyStateText style={{ textTransform: 'lowercase' }}>
                                            <FormattedMessage {...GenericMessages.noData} />
                                        </EmptyStateText>
                                    )}
                                </Card>
                            </Col>
                        </Row>
                        <Can see="vehicleSensor">
                            <TemperatureChart
                                deliveryTour={deliveryTour}
                            />
                        </Can>
                    </div>
                ) : null}
            </Drawer>
        );
    }
}

export default injectIntl(DeliveryTourDeliveryManDetailsDrawer);
