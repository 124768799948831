import React, { PureComponent, CSSProperties } from 'react';

interface DescriptiveListProps {
    data: Array<{
        term: any;
        description: any;
    }>;
    style?: CSSProperties;
    termResponsive?: string;
    descriptionResponsive?: string;
}

class DescriptiveList extends PureComponent<DescriptiveListProps> {
    public render() {
        const {
            data,
            descriptionResponsive = 'ant-col-15',
            termResponsive = 'ant-col-9',
            ...rest
        } = this.props;
        return (
            <dl className="ant-row" {...rest}>
                {data.map((datum, index: number) => (
                    <React.Fragment key={`${datum.term}-${index}`}>
                        <dt className={termResponsive}>{datum.term}</dt>
                        <dd className={descriptionResponsive}>{datum.description}</dd>
                    </React.Fragment>
                ))}
            </dl>
        );
    }
}

export default DescriptiveList;
