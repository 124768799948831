import api, { PaginatedListResponse, urlWithQuery } from './_client';
import { QueryListPayload } from '../actions';
import { Delivery } from './types';

export interface DeliveriesListResponse extends PaginatedListResponse<Delivery> {}

export const list = (payload: QueryListPayload) => {
    return api.get<DeliveriesListResponse>(urlWithQuery('/admin/deliveries', payload));
};

export const sendMessage = (id: Delivery['id'], payload: { message: string }) => {
    return api.post(`/admin/deliveries/${id}/message`, payload);
};
