import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Tag, Select } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import * as DeliveryToursActions from '../../store/actions/deliveryTours';
import { SearchQueryComponentState, SearchPaginationQuery } from '../../store/api';
import { DeliveryTour, getDeliveryTourStatus, Agency } from '../../store/api/types';
import {
    getDeliveryTours, getPagination,
} from '../../store/reducers/deliveryTours';

import { getRoute, RoutePathName } from '../../routes';
import { getFullName } from '../../utils';

import messages from './DeliveryTour.messages';
import { ListItemTitle, List } from '../../components/list';
import EmptyStateText from '../../components/typography/EmptyStateText';
import DeliveryTourListProgress from './DeliveryTourListProgress';
import { Languages } from '../../components/IntlProvider';
import { ListProps } from '../../components/list/List';
import { PaginationRequestState } from '../../store/reducers/_generics';
import { Bone, SkeletonRow } from '../../components/skeleton';
import { getUser, AuthUser } from '../../store/reducers/auth';

export type DeliveryTourListColumns = Array<ColumnProps<DeliveryTour>>;

interface DeliveryTourListWrapperProps extends InjectedIntlProps,
                                               Partial<RouteComponentProps>,
                                               Partial<ListProps<DeliveryTour>> {
    deliveryTours: DeliveryTour[];
    fetchDeliveryTours: typeof DeliveryToursActions.list;
    fetchPayload?: {};
    pagination: PaginationRequestState;
    setColumns?: (columns: DeliveryTourListColumns) => DeliveryTourListColumns;
    user: AuthUser;
}

interface DeliveryTourListWrapperState extends SearchQueryComponentState {
    agency?: string;
}

class DeliveryTourListWrapper extends React.Component<DeliveryTourListWrapperProps, DeliveryTourListWrapperState> {
    public state: DeliveryTourListWrapperState = {};
    private columns: Array<ColumnProps<DeliveryTour>> = [
        {
            dataIndex: 'id',
            render: (text, deliveryTour, index) => (
                <ListItemTitle
                    index={(this.props.pagination.pageSize * this.props.pagination.page) + index + 1}
                    text={deliveryTour.label}
                />
            ),
        }, {
            dataIndex: 'deliveryMen',
            render: (deliveryMen) => deliveryMen ? (
                <span className="text-capitalize">
                    {
                        getFullName(
                            deliveryMen[0].firstname,
                            deliveryMen[0].lastname,
                        ).toLowerCase() ||
                        deliveryMen[0].reference
                    }
                </span>
            ) : (
                <EmptyStateText style={{ textTransform: 'lowercase' }}>
                    <FormattedMessage {...messages.noDeliveryMan} />
                </EmptyStateText>
            ),
        }, {
            dataIndex: 'status',
            render: (status) => (
                <Tag>
                    <FormattedMessage {...messages[getDeliveryTourStatus(status)]} />
                </Tag>
            ),
        }, {
            dataIndex: 'deliveries',
            width: '50%',
            render: (deliveries) => (
                <DeliveryTourListProgress
                    deliveries={deliveries}
                />
            ),
        },
    ];

    public componentDidMount() {
        this.fetchData();
    }

    public fetchData = (query?: SearchPaginationQuery) => {
        const { fetchDeliveryTours, fetchPayload, pagination } = this.props;
        const { agency } = this.state;
        const { page, pageSize } = pagination;

        fetchDeliveryTours({
            page,
            pageSize,
            ...fetchPayload,
            ...query,
            agency,
        });
    }

    public rowKey = (record: DeliveryTour) => record.id!.toString();

    public onAgencyChange = (agencyId: Agency['id']) => {
        this.setState({ agency: agencyId === '0' ? undefined : agencyId }, this.fetchData);
    }

    public onRowClick = (record: DeliveryTour) => {
        const { history, intl } = this.props;
        if (history) {
            history.push(getRoute(intl.locale as Languages, RoutePathName.deliveryTour, { id: record.id! }));
        }
    }

    public onChangePage = (page: number) => {
        const { search } = this.state;

        this.fetchData({
            search,
            page: page - 1,
        });
    }

    public onSearch = (search: string) => {
        this.setState({ search });
        this.fetchData({
            search,
            throttling: 200,
        });
    }

    public headerExtraRenderer = () => {
        const { user } = this.props;

        if (user.scope && user.scope.agencies && user.scope.agencies.length) {
            return (
                <Select
                    placeholder="none"
                    onChange={this.onAgencyChange}
                    filterOption={false}
                    defaultValue="0"
                >
                    <Select.Option
                        value="0"
                    >
                        <FormattedMessage {...messages.allAgencies} />
                    </Select.Option>
                    {user.scope.agencies.map((agency: Agency) => (
                        <Select.Option
                            key={agency.id}
                            value={agency.id}
                        >
                            {agency.name}
                        </Select.Option>
                    ))}
                </Select>
            );
        }

        return null;
    }

    public skeletonItemRenderer(index: number) {
        return (
            <SkeletonRow key={index}>
                <Bone style={{ flex: '0 1 auto' }} />
                <Bone style={{ flex: '0 0 116px' }} />
                <Bone style={{ flex: '0 1 auto' }} />
                <Bone style={{ flex: '0 1 auto' }} />
                <Bone style={{ flex: '0 0 50%' }} />
                <Bone style={{ flex: '0 0 10px' }} />
            </SkeletonRow>
        );
    }

    public render() {
        const { deliveryTours, emptyStateRenderer, listTitle, pagination, setColumns } = this.props;

        const columns = typeof setColumns === 'function' ? setColumns(this.columns) : this.columns;

        return (
            <List<DeliveryTour>
                columns={columns}
                dataState={{
                    data: deliveryTours,
                    loading: pagination.loading,
                    ...pagination,
                }}
                emptyStateRenderer={emptyStateRenderer}
                headerExtraRenderer={this.headerExtraRenderer}
                listTitle={listTitle}
                onChangePage={this.onChangePage}
                onRowClick={this.onRowClick}
                onSearch={this.onSearch}
                rowKey={this.rowKey}
                skeletonRenderer={this.skeletonItemRenderer}
            />
        );
    }
}

const mapStateToProps = (state: any) => ({
    deliveryTours: getDeliveryTours(state),
    pagination: getPagination(state),
    user: getUser(state),
});

export default injectIntl(connect(
    mapStateToProps,
    { fetchDeliveryTours: DeliveryToursActions.list },
    undefined,
    { forwardRef: true },
)(DeliveryTourListWrapper), { withRef: true });
