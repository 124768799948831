import { CompositeLayer, IconLayer, WebMercatorViewport } from 'deck.gl';
import rbush from 'rbush';

function getIconName(datum: any, props: any, size: number) {
    if (size === 0 || size === 1) {
        return props._subLayerProps.getIcon(datum);
    } else {
        return `cluster-${size}`;
    }
}

function getIconSize(datum: any, props: any, size: number) {
    if (size === 0 || size === 1) {
        return props._subLayerProps.getIconSize(datum);
    } else {
        return 48;
    }
}

export default class MapMarkerClusterLayer<D> extends CompositeLayer<D> {
    public initializeState() {
        this.setState({
            // build spatial index
            tree: rbush(9, ['.x', '.y', '.x', '.y']),
            version: -1,
            z: -1,
            width: 0,
            height: 0,
            hoveredDatum: null,
        });
    }

    public shouldUpdateState({ changeFlags }: any) {
        return changeFlags.somethingChanged;
    }

    public updateState({ props, oldProps, changeFlags }: any) {
        // @ts-ignore
        const { viewport } = this.context;
        const { width, height } = viewport;

        if (
            changeFlags.dataChanged ||
            props.sizeScale !== oldProps.sizeScale ||
            this.state.width !== width ||
            this.state.height !== height
        ) {
            const version = this._updateCluster(props, viewport);
            this.setState({ version, width, height });
        }
        this.setState({
            z: Math.floor(viewport.zoom),
        });
    }

    // handle onHover enhancement for highlighting pins
    public onHover(info: any) {
        const { onHover } = this.props;
        const { object, picked } = info;
        const deckglElement = document.getElementById('deckgl-overlay');

        this.setState({
            hoveredDatum: picked ? object : null,
        });

        if (picked) {
            deckglElement!.classList.add('pointer');
        } else {
            deckglElement!.classList.remove('pointer');
        }

        if (typeof onHover === 'function') {
            onHover(object);
        }
    }

    // handle onClick enhancement for zooming when clicking on a cluster
    public onClick(info: any) {
        const { onClusterClick, onIconClick } = this.props;
        const { z } = this.state;
        const { object } = info;

        if (object.zoomLevels && object.zoomLevels[z] && object.zoomLevels[z].points) {
            const points = object.zoomLevels[z].points;

            if (points.length > 1) {
                const pointsHaveSameLocation = !points.some((point: any) =>
                    point.coordinates.latitude !== points[0].coordinates.latitude ||
                    point.coordinates.longitude !== points[0].coordinates.longitude,
                );

                if (pointsHaveSameLocation) {
                    // spiderify
                } else {
                    onClusterClick(points);
                }
            } else if (typeof onIconClick === 'function') {
                onIconClick(object);
            }
        } else if (typeof onIconClick === 'function') {
            onIconClick(object);
        }
    }

    public renderLayers() {
        const { hoveredDatum, version, z } = this.state;
        const { fp64, iconAtlas, iconMapping, sizeScale, getPosition, pickable } = this.props._subLayerProps;

        return new IconLayer(
            this.getSubLayerProps({
                id: 'icon',
                data: this.props.data,
                fp64,
                getColor: (d: any) => [0, 0, 0, hoveredDatum === d ? 191 : 255],
                getIcon: (d: any) => d.zoomLevels[z] && d.zoomLevels[z].icon,
                getPosition,
                getSize: (d: any) => d.zoomLevels[z] && d.zoomLevels[z].size,
                iconAtlas,
                iconMapping,
                onClick: this.onClick,
                onHover: this.onHover,
                pickable,
                sizeScale,
                updateTriggers: {
                    getIcon: { version, z },
                    getSize: { version, z },
                    getColor: { hoveredDatum },
                },
            }),
        );
    }

    // Compute icon clusters
    // We use the projected positions instead of longitude and latitude to build
    // the spatial index, because this particular dataset is distributed all over
    // the world, we can't use some fixed deltaLon and deltaLat
    private _updateCluster(props: any, viewport: any) {
        const { data, _subLayerProps } = props;
        const { tree, version } = this.state;

        if (!data) {
            return version;
        }

        const transform = new WebMercatorViewport({
            ...viewport,
            zoom: 0,
        });

        data.forEach((datum: any) => {
            const screenCoords = transform.project(_subLayerProps.getPosition(datum));
            datum.x = screenCoords[0];
            datum.y = screenCoords[1];
            datum.zoomLevels = [];
        });

        tree.clear();
        tree.load(data);

        for (let z = 0; z <= 24; z++) {
            const radius = 15 / Math.sqrt(2) / Math.pow(2, z);

            data.forEach((datum: any) => {
                if (datum.zoomLevels[z] === undefined) {
                    // this point does not belong to a cluster
                    const { x, y } = datum;

                    // find all points within radius that do not belong to a cluster
                    const neighbors = tree
                        .search({
                            minX: x - radius,
                            minY: y - radius,
                            maxX: x + radius,
                            maxY: y + radius,
                        })
                        .filter((neighbor: any) => neighbor.zoomLevels[z] === undefined);

                    // only show the center point at this zoom level
                    neighbors.forEach((neighbor: any) => {
                        if (neighbor === datum) {
                            datum.zoomLevels[z] = {
                                icon: getIconName(datum, props, neighbors.length),
                                size: getIconSize(datum, props, neighbors.length),
                                points: neighbors,
                            };
                        } else {
                            neighbor.zoomLevels[z] = null;
                        }
                    });
                }
            });
        }

        return version + 1;
    }
}
