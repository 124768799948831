import { defineMessages } from 'react-intl';

export default defineMessages({
    administration: {
        id: 'main_menu.section.administration',
        defaultMessage: 'Administration',
        description: 'Main menu section',
    },
    supervision: {
        id: 'main_menu.section.supervision',
        defaultMessage: 'Supervision',
        description: 'Main menu section',
    },
    dashboard: {
        id: 'main_menu.dashboard',
        defaultMessage: 'Dashboard',
        description: 'Main menu item',
    },
    agencies: {
        id: 'main_menu.agencies',
        defaultMessage: 'Agencies',
        description: 'Main menu item',
    },
    customers: {
        id: 'main_menu.customers',
        defaultMessage: 'Customers',
        description: 'Main menu item',
    },
    deliveryTours: {
        id: 'main_menu.deliveryTours',
        defaultMessage: 'Delivery tours',
        description: 'Main menu item',
    },
    deliveryMen: {
        id: 'main_menu.deliveryMen',
        defaultMessage: 'Delivery men',
        description: 'Main menu item',
    },
    monitoring: {
        id: 'main_menu.monitoring',
        defaultMessage: 'Monitoring',
        description: 'Main menu item',
    },
    users: {
        id: 'main_menu.users',
        defaultMessage: 'Users',
        description: 'Main menu item',
    },
    roles: {
        id: 'main_menu.roles',
        defaultMessage: 'Roles',
        description: 'Main menu item',
    },
    organizations: {
        id: 'main_menu.organizations',
        defaultMessage: 'Organizations',
        description: 'Main menu item',
    },
});
