import React, { CSSProperties } from 'react';
import {
    XYPlot, XAxis, YAxis, HorizontalGridLines, VerticalGridLines, Crosshair, AreaSeries, LineSeries,
    LineMarkSeries, VerticalBarSeries, YAxisProps, AbstractSeriesProps, XYPlotProps, XAxisProps,
    LineSeriesProps, AreaSeriesProps, LineMarkSeriesProps, HorizontalBarSeriesProps, LineSeriesCanvas,
} from 'react-vis';
import AutoSizer from 'react-virtualized-auto-sizer';

interface ChartData {
    [key: string]: any;
    areaSeries?: AreaSeriesProps[];
    barSeries?: VerticalBarSeries[] | HorizontalBarSeriesProps[];
    lineMarkSeries?: LineMarkSeriesProps[];
    lineSeries?: LineSeriesProps[];
}

interface MultiSeriesChartProps {
    data: ChartData;

    /**
     * Wether we display a right side Y axis
     */
    hasRightYAxis?: boolean;

    /**
     * Text to display when there are no data to display
     */
    noDataText?: React.ReactChild;

    /**
     * Function called when clicking on an area. Also adds cursor pointer when hovering an area.
     */
    onAreaClick?: (index: number) => void;

    /**
     * Function called when hovering out of an area.
     */
    onAreaMouseOut?: (index: number) => void;

    /**
     * Function called when hovering into an area.
     */
    onAreaMouseOver?: (index: number) => void;

    /**
     * Domain for the right y axis
     */
    rightYAxisDomain?: AbstractSeriesProps<any>['yDomain'];

    /**
     * Formats the tick labels on the X axis
     */
    rightYAxisTickLabelFormatter?: YAxisProps['tickFormat'];

    /**
     * Title for the right Y axis
     */
    rightYAxisTitle?: YAxisProps['title'];

    /**
     * Wether to stack the series on an axis. Do not use with `hasArea` enabled as it will stack
     * the areas along with the lines as separate series.
     */
    stackBy?: XYPlotProps['stackBy'];

    /**
     * style
     */
    style?: CSSProperties;

    /**
     * Formats the tooltip's content
     * @param {Object} value Value.
     * @returns {Object} title-value pairs.
     */
    tooltipFormatter?: ([]) => React.ReactChild | null;

    /**
     * Domain for the x axis
     */
    xAxisDomain?: AbstractSeriesProps<any>['xDomain'];

    /**
     * Range for the x axis
     */
    xAxisRange?: AbstractSeriesProps<any>['xRange'];

    /**
     * Angle of the ticks on the x axis
     */
    xAxisTickLabelAngle?: XAxisProps['tickLabelAngle'];

    /**
     * Formats the tick labels on the X axis
     */
    xAxisTickLabelFormatter?: XAxisProps['tickFormat'];

    /**
     * Total number of ticks for the x axis
     */
    xAxisTickTotal?: XAxisProps['tickTotal'];

    /**
     * Array of tick values to display on the X axis
     */
    xAxisTickValues?: XAxisProps['tickValues'];

    /**
     * Scale type of the y axis
     */
    xAxisType?: AbstractSeriesProps<any>['xType'];

    /**
     * Domain for the y axis
     */
    yAxisDomain?: AbstractSeriesProps<any>['yDomain'];

    /**
     * Formats the tick labels on the X axis
     */
    yAxisTickLabelFormatter?: YAxisProps['tickFormat'];

    /**
     * Title for the Y axis
     */
    yAxisTitle?: YAxisProps['title'];
}

/**
 * Generic Chart component for multi series charts
 */
export default class MultiSeriesChart extends React.Component<MultiSeriesChartProps> {
    public static defaultProps = {
        data                         : {
            areaSeries     : [],
            barSeries      : [],
            lineMarkSeries : [],
            lineSeries     : [],
        },
        hasRightYAxis                : false,
        rightYAxisTickLabelFormatter : (d: any) => d,
        xAxisType                    : 'linear',
        yAxisTickLabelFormatter      : (d: any) => d,
    };

    public state = {
        crosshairValues   : [],
        highlightedSeries : -1,
    };

    public onNearestX = (value: any, { index }: any) => {
        const {data} = this.props;
        const crosshairValues: any = [];

        Object.keys(data).forEach((seriesType) =>
            // @ts-ignore
            data[seriesType].forEach((series: any) => crosshairValues.push(series.data[index])),
        );

        this.setState({
            crosshairValues,
        });
    }

    public onSeriesClick = (index: number) => {
        const {onAreaClick} = this.props;
        if (onAreaClick) {
            onAreaClick(index);
        }
    }

    public onSeriesMouseOver = (index: number) => {
        this.setState({highlightedSeries: index}, () => {
            const {onAreaMouseOver} = this.props;
            if (onAreaMouseOver) {
                onAreaMouseOver(index);
            }
        });
    }

    public onSeriesMouseOut = () => {
        this.setState({highlightedSeries: -1}, () => {
            const {onAreaMouseOut} = this.props;
            if (onAreaMouseOut) {
                onAreaMouseOut(-1);
            }
        });
    }

    public onMouseLeave = () => {
        this.setState({crosshairValues: []});
    }

    public render() {
        const {
            children, data, hasRightYAxis, noDataText, onAreaClick, rightYAxisDomain,
            rightYAxisTickLabelFormatter, rightYAxisTitle, stackBy, tooltipFormatter, xAxisDomain,
            xAxisRange, xAxisTickLabelAngle, xAxisTickLabelFormatter, xAxisTickTotal,
            xAxisTickValues, xAxisType, yAxisDomain, yAxisTickLabelFormatter, yAxisTitle, ...rest
        } = this.props;
        const {crosshairValues, highlightedSeries} = this.state;
        const seriesComponents: {
            [key: string]: any;
        } = {
            areaSeries       : AreaSeries,
            barSeries        : VerticalBarSeries,
            lineMarkSeries   : LineMarkSeries,
            lineSeries       : LineSeries,
            lineSeriesCanvas : LineSeriesCanvas,
        };
        const plotScaleProps: any = {};
        const yAxisScaleProps: any = {};
        const rightYAxisScaleProps: any = {};
        let seriesIndex = 0;
        let noData = true;

        const series = Object.keys(data).map((seriesType: string) =>
            data[seriesType].map((s: any) => {
                const SeriesComponent = seriesComponents[seriesType];
                const mainIndex = seriesIndex;
                const seriesStyle = {
                    strokeWidth: seriesType === 'areaSeries' ? 0 : null,
                    ...(s.style || {}),
                };

                if (s.data.length) {
                    noData = false;
                }

                const seriesOpacity = seriesType === 'areaSeries' ?
                    (highlightedSeries === mainIndex ? 0.5 : 0.35) :
                    1;
                const additionalProps: any = {};

                if (s.yDomain) {
                    additionalProps.yDomain = s.yDomain;
                }

                if (s.curve) {
                    additionalProps.curve = s.curve;
                }

                seriesIndex++;
                return (
                    <SeriesComponent
                        key={`${seriesType}-${mainIndex}-${s.color}`}
                        data={s.data}
                        color={s.color}
                        fill={s.fill}
                        onNearestX={this.onNearestX}
                        onSeriesClick={this.onSeriesClick.bind(null, mainIndex)}
                        onSeriesMouseOver={this.onSeriesMouseOver.bind(null, mainIndex)}
                        onSeriesMouseOut={this.onSeriesMouseOut}
                        opacity={seriesOpacity}
                        size={s.size}
                        stack={s.stack}
                        style={seriesStyle}
                        {...additionalProps}
                    />
                );
            }),
        );

        if (stackBy) {
            plotScaleProps.stackBy = stackBy;
        }

        if (xAxisRange) {
            plotScaleProps.xRange = xAxisRange;
        }

        if (xAxisDomain) {
            plotScaleProps.xDomain = xAxisDomain;
        }

        if (yAxisDomain) {
            plotScaleProps.yDomain = yAxisDomain;
        }

        if (rightYAxisTickLabelFormatter) {
            rightYAxisScaleProps.tickFormat = rightYAxisTickLabelFormatter;
        }

        if (rightYAxisTitle) {
            rightYAxisScaleProps.title = rightYAxisTitle;
        }

        if (rightYAxisDomain) {
            rightYAxisScaleProps.yDomain = rightYAxisDomain;
        }

        if (yAxisTitle) {
            yAxisScaleProps.title = yAxisTitle;
        }

        if (yAxisDomain) {
            yAxisScaleProps.yDomain = yAxisDomain;
        }

        return (
            <div
                className="chart multi-series-chart"
                style={onAreaClick ? { cursor: highlightedSeries !== -1 ? 'pointer' : 'auto' } : undefined}
                {...rest}
            >
                <AutoSizer>
                    {({ width }: { width: number }) => (
                        <XYPlot
                            dontCheckIfEmpty={noData}
                            height={350}
                            margin={{ left: 50 }}
                            onMouseLeave={this.onMouseLeave}
                            width={width}
                            xType={xAxisType}
                            {...plotScaleProps}
                        >
                            <XAxis
                                tickFormat={xAxisTickLabelFormatter}
                                tickLabelAngle={xAxisTickLabelAngle}
                                tickTotal={xAxisTickTotal}
                                tickValues={xAxisTickValues}
                            />
                            <YAxis
                                tickFormat={yAxisTickLabelFormatter}
                                {...yAxisScaleProps}
                            />
                            <HorizontalGridLines />
                            <VerticalGridLines />
                            {hasRightYAxis && (
                                <YAxis
                                    className="right-axis"
                                    orientation="right"
                                    {...rightYAxisScaleProps}
                                />
                            )}
                            {children}
                            {series}
                            {noData && (
                                <LineSeries
                                    fill="transparent"
                                    stroke="transparent"
                                    data={[
                                        { x: 0, y: 0 },
                                        { x: 1, y: 1 },
                                        { x: 2, y: 2 },
                                        { x: 3, y: 3 },
                                        { x: 4, y: 4 },
                                        { x: 5, y: 5 },
                                    ]}
                                />
                            )}
                            <Crosshair values={crosshairValues}>
                                {typeof tooltipFormatter === 'function' && tooltipFormatter(crosshairValues)}
                            </Crosshair>
                        </XYPlot>
                    )}
                </AutoSizer>
                {noData && (
                    <div className="chart-no-data-text">{noDataText}</div>
                )}
            </div>
        );
    }
}
