import { ActionCreator } from 'redux';

import * as reduxTypes from '../types/vehicles';
import { DataAction, QueryListPayload, QueryListAction, IdAction } from '.';
import { Vehicle, SensorData } from '../api/types';

// TEMPERATURE

export const temperature: ActionCreator<
    QueryListAction & IdAction<Vehicle['id']>
> = (id: Vehicle['id'], payload: QueryListPayload) => ({
    ...payload,
    id,
    type: reduxTypes.TEMPERATURE,
});

export const temperatureSuccess: ActionCreator<
    DataAction & IdAction<Vehicle['id']>
> = (id: Vehicle['id'], data: SensorData) => ({
    type: reduxTypes.TEMPERATURE_SUCCESS,
    id,
    data,
});

export const temperatureFailed: ActionCreator<
    DataAction & IdAction<Vehicle['id']>
> = (id: Vehicle['id'], data: any) => ({
    type: reduxTypes.TEMPERATURE_FAILED,
    id,
    data,
});
