import { defineMessages } from 'react-intl';

export default defineMessages({
    welcome: {
        id: 'login.title',
        defaultMessage: 'Welcome',
        description: 'Login page title',
    },
    description: {
        id: 'login.description',
        defaultMessage: 'Login to access the platform.',
        description: 'Login page description above the login form',
    },
    emailPlaceholder: {
        id: 'login.email.placeholder',
        defaultMessage: 'Email',
        description: 'Login email field placeholder',
    },
    emailError: {
        id: 'login.email.error',
        defaultMessage: 'Please enter a valid email',
        description: 'Login email field error',
    },
    invalidCredentials: {
        id: 'login.error.invalid_credentials',
        defaultMessage: 'Invalid credentials provided.',
        description: 'Login error message when credentials provided aren\'t valid',
    },
    passwordPlaceholder: {
        id: 'login.password.placeholder',
        defaultMessage: 'Password',
        description: 'Login password field placeholder',
    },
    passwordError: {
        id: 'login.password.error',
        defaultMessage: 'Please enter your password',
        description: 'Login password field error',
    },
    loginButton: {
        id: 'login.submit',
        defaultMessage: 'Log in',
        description: 'Login submit button text',
    },
    forgotPassword: {
        id: 'login.forgotten_password',
        defaultMessage: 'Forgot password?',
        description: 'Login forgot password link',
    },
    rememberMe: {
        id: 'login.remember_me',
        defaultMessage: 'Remember me',
        description: 'Login remember me checkbox text',
    },
});
