import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

export type IconMapDeliveryStatuses = 'default' | 'success' | 'warning' | 'error';
type StatusesColors = {
    [key in IconMapDeliveryStatuses]: {
        fill: string;
        text: string;
    };
};

const statusColors: StatusesColors = {
    default: {
        fill: '#b39cff', // @primary-4;
        text: '#4a34d9', // @primary-7;
    },
    success: {
        fill: '#95de64', // @green-4;
        text: '#237804', // @green-8;
    },
    error: {
        fill: '#ff7875', // @red-4;
        text: '#820014', // @red-9;
    },
    warning: {
        fill: '#ffd666', // @gold-4;
        text: '#876800', // @yellow-9;
    },
};

interface IconMapDeliveryProps extends IconProps {
    status?: IconMapDeliveryStatuses;
    value: string | number;
}

const svg = (status: IconMapDeliveryProps['status'] = 'default', value: string | number) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 20 24">
        <g fill="none" fillRule="evenodd">
            <path
                fill={statusColors[status].fill}
                d="M10 24c5-3 10-8.477 10-14S15.523 0 10 0 0 4.477 0 10s5 11 10 14z"
            />
            <text
                fill={statusColors[status].text}
                textAnchor="middle"
                alignmentBaseline="central"
                fontFamily="BrandonGrotesque-Bold, Brandon Grotesque"
                fontSize="10"
                fontWeight="bold"
                x="10"
                y="11"
            >
                {value}
            </text>
        </g>
    </svg>
);

const IconMapDelivery: React.SFC<IconMapDeliveryProps> = (props) => (
    <Icon component={svg.bind(null, props.status, props.value)} className="icon-map-delivery" {...props} />
);

export default IconMapDelivery;
