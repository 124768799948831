import { defineMessages } from 'react-intl';

export default defineMessages({
    punctualityRatio: {
        id: 'stats.metric.punctuality_ratio.label',
        defaultMessage: 'Punctuality ratio',
        description: 'Metric label',
    },
    lateTasksCount: {
        id: 'stats.metric.late_tasks_count.label',
        defaultMessage: 'Tasks over an hour late',
        description: 'Metric label',
    },
    serviceRatio: {
        id: 'stats.metric.service_ratio.label',
        defaultMessage: 'Service ratio',
        description: 'Metric label',
    },
    perDeliveryTour: {
        id: 'stats.per_delivery_tour',
        defaultMessage: 'Per delivery tour',
        description: 'Metric alt text',
    },
    outOfSlotTasks: {
        id: 'stats.metric.punctuality_ratio.alt',
        defaultMessage: `
            {count, plural,
                =0 {Out of slot tasks}
                one {Out of slot task}
                other {Out of slot tasks}
            }
        `,
        description: 'Metric label',
    },
});
