import { defineMessages } from 'react-intl';

export default defineMessages({
    createUser: {
        id: 'user.create_button',
        defaultMessage: 'Add a user',
        description: 'Add user button',
    },
    updateUser: {
        id: 'user.update_button',
        defaultMessage: 'Update a user',
        description: 'Update user button',
    },
});
