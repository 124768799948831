import * as React from 'react';
import { FormattedRelative, FormattedDate } from 'react-intl';
import { Tooltip } from 'antd';

interface RelativeDateWithTooltipProps {
    value: string | Date;
    title?: string | React.ReactNode;
}

const RelativeDateWithTooltip: React.SFC<RelativeDateWithTooltipProps> = ({ value, title }) => {
    const tooltipTitle = title || (
        <FormattedDate value={value} />
    );

    return (
        <Tooltip title={tooltipTitle}>
            <span>
                <FormattedRelative value={value} />
            </span>
        </Tooltip>
    );
};

export default RelativeDateWithTooltip;
