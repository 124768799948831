export default Object.freeze({
    COORDINATES: Object.freeze({
        fr: Object.freeze({
            latitude: 46.71109,
            longitude: 1.7191036,
        }),
        en: Object.freeze({
            latitude: 55.3617609,
            longitude: -3.4433238,
        }),
    }),
    MAPBOX_TOKEN: process.env.REACT_APP_MAPBOX_TOKEN ||
        'pk.eyJ1Ijoic3RzcGVubGUiLCJhIjoiY2pzbmdwOTd6MDIzMDQ1bzZxZjF2aDE2eSJ9.fylh1Tp6QJaj1LNG-daiyw',
    MAPBOX_STYLE: 'mapbox://styles/stspenle/cjtiu9n8i1er21fmkv77swydp',
    PAGE_SIZE: 100,
    CONTENT_HEADER_HEIGHT: 88,
    PASSWORD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*{}[\]()\\\-_/'"`~,;:.<>])(.{10,})$/,
});
