import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <path fill="#FFF" fillRule="evenodd" d="M4.36 6.964a4.48 4.48 0 0 1 .052-1.274.14.14 0 0 0-.08-.154 2.23 2.23 0 0 1-1.32-2.106c.015-.56.24-1.095.634-1.497a2.225 2.225 0 0 1 1.63-.676 2.234 2.234 0 0 1 1.86 1.028.14.14 0 0 0 .16.056c.31-.106.634-.18.968-.216a.14.14 0 0 0 .11-.203A3.502 3.502 0 0 0 5.304 0C3.363-.03 1.75 1.56 1.75 3.494c0 1.098.505 2.077 1.296 2.718a5.255 5.255 0 0 0-1.51 1.056A5.198 5.198 0 0 0 0 10.833a.14.14 0 0 0 .14.144h.98a.14.14 0 0 0 .14-.135A3.95 3.95 0 0 1 2.426 8.16a3.946 3.946 0 0 1 1.83-1.044.14.14 0 0 0 .105-.152M8.74 8.74c-.6 0-1.16-.233-1.583-.656a2.24 2.24 0 0 1 1.56-3.82 2.25 2.25 0 0 1 1.59.64c.433.425.67.994.67 1.598a2.22 2.22 0 0 1-.654 1.582c-.42.42-.99.657-1.584.655m3.708 1.536a5.27 5.27 0 0 0-1.51-1.056 3.488 3.488 0 0 0 1.297-2.718c0-1.936-1.615-3.525-3.552-3.495A3.497 3.497 0 0 0 6.537 9.22a5.26 5.26 0 0 0-1.51 1.056 5.21 5.21 0 0 0-1.53 3.564.14.14 0 0 0 .14.143h.977a.14.14 0 0 0 .14-.135 3.95 3.95 0 0 1 1.166-2.683 3.96 3.96 0 0 1 2.82-1.167 3.96 3.96 0 0 1 2.818 1.167c.72.72 1.13 1.67 1.166 2.683a.14.14 0 0 0 .14.135h.98a.14.14 0 0 0 .14-.143 5.198 5.198 0 0 0-1.536-3.564" />{/* tslint:disable:max-line-length */}
    </svg>
);

const IconUsers: React.SFC<IconProps> = (props) => (
    <Icon component={svg} className="anticon icon-users" {...props} />
);

export default IconUsers;
