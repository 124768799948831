import { defineMessages } from 'react-intl';

const messages = defineMessages({
    submit: {
        id: 'form.submit',
        defaultMessage: 'Submit',
        description: 'Form submit button',
    },
    cancel: {
        id: 'form.cancel',
        defaultMessage: 'Cancel',
        description: 'Form cancel button',
    },
    addField: {
        id: 'form.add_field',
        defaultMessage: 'Add field',
        description: 'Form add field button',
    },
    defaultValidationError: {
        id: 'form.validation.default_error',
        defaultMessage: 'Validation error',
        description: 'Form validation',
    },
    requiredError: {
        id: 'form.validation.required',
        defaultMessage: 'The field is required',
        description: 'Form validation',
    },
    enumError: {
        id: 'form.validation.enum',
        defaultMessage: '{value} must be one of {enum}',
        description: 'Form validation',
    },
    whitespaceError: {
        id: 'form.validation.whitespace',
        defaultMessage: 'The field cannot be empty',
        description: 'Form validation',
    },
    dateFormatError: {
        id: 'form.validation.date.format',
        defaultMessage: '{date} date is invalid for format {format}',
        description: 'Form validation',
    },
    dateParseError: {
        id: 'form.validation.date.parse',
        defaultMessage: '{date} date could not be parsed, it is invalid',
        description: 'Form validation',
    },
    dateInvalidError: {
        id: 'form.validation.date.invalid',
        defaultMessage: '{date} date is invalid',
        description: 'Form validation',
    },
});

export default messages;

// {
//     types: {
//         string: '%s is not a %s',
//         method: '%s is not a %s (function)',
//         array: '%s is not an %s',
//         object: '%s is not an %s',
//         number: '%s is not a %s',
//         date: '%s is not a %s',
//         boolean: '%s is not a %s',
//         integer: '%s is not an %s',
//         float: '%s is not a %s',
//         regexp: '%s is not a valid %s',
//         email: '%s is not a valid %s',
//         url: '%s is not a valid %s',
//         hex: '%s is not a valid %s',
//     },
//     string: {
//         len: '%s must be exactly %s characters',
//         min: '%s must be at least %s characters',
//         max: '%s cannot be longer than %s characters',
//         range: '%s must be between %s and %s characters',
//     },
//     number: {
//         len: '%s must equal %s',
//         min: '%s cannot be less than %s',
//         max: '%s cannot be greater than %s',
//         range: '%s must be between %s and %s',
//     },
//     array: {
//         len: '%s must be exactly %s in length',
//         min: '%s cannot be less than %s in length',
//         max: '%s cannot be greater than %s in length',
//         range: '%s must be between %s and %s in length',
//     },
//     pattern: {
//         mismatch: '%s value %s does not match pattern %s',
//     },
// };
