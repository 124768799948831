export const LIST = 'roles/LIST';
export const LIST_SUCCESS = 'roles/LIST_SUCCESS';
export const LIST_FAILED = 'roles/LIST_FAILED';

export const CREATE = 'roles/CREATE';
export const CREATE_SUCCESS = 'roles/CREATE_SUCCESS';
export const CREATE_FAILED = 'roles/CREATE_FAILED';

export const UPDATE = 'roles/UPDATE';
export const UPDATE_SUCCESS = 'roles/UPDATE_SUCCESS';
export const UPDATE_FAILED = 'roles/UPDATE_FAILED';

export const DELETE = 'roles/DELETE';
export const DELETE_SUCCESS = 'roles/DELETE_SUCCESS';
export const DELETE_FAILED = 'roles/DELETE_FAILED';
