import * as React from 'react';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { connect } from 'react-redux';
import { DecorativeAxis } from 'react-vis';
import moment from 'moment';
import { Card, Icon } from 'antd';
import { lime, geekblue, cyan } from '@ant-design/colors';

import '../../assets/styles/TemperatureChart.less';

import * as VehiclesActions from '../../store/actions/vehicles';
import { MainReducerState } from '../../store/reducers';
import { DeliveryTour, ParcelType } from '../../store/api/types';
import { getTemperatureByVehicleId, VehicleTemperatureState } from '../../store/reducers/vehicles';

import { MultiSeriesChart, ChartTooltip } from '../../components/charts';
import GenericMessages from '../../locale/Generic.messages';
import { DescriptiveList } from '../../components/list';
import DeliveryTourDeliveryManDetailsDrawerMessages from './DeliveryTourDeliveryManDetailsDrawer.messages';

const chartColors = [
    cyan[5], geekblue[5], lime[5],
];

interface TemperatureChartProps extends InjectedIntlProps {
    deliveryTour?: DeliveryTour;
    fetchTemperatures?: typeof VehiclesActions.temperature;
    temperatures?: VehicleTemperatureState;
}

interface Datum {
    x: number;
    y: number;
}

type LineSeriesCanvas = Array<{
    color: string;
    curve: string;
    data: any;
    name: React.ReactChild;
}>;

class TemperatureChart extends React.Component<TemperatureChartProps> {

    public componentDidMount() {
        const { deliveryTour, fetchTemperatures } = this.props;

        if (deliveryTour && fetchTemperatures && deliveryTour.vehicles && deliveryTour.vehicles[0]) {
            let startDate = moment(deliveryTour.date);
            let endDate = moment(deliveryTour.date).endOf('day');

            if (deliveryTour) {
                if (deliveryTour.startDate) {
                    startDate = moment(deliveryTour.startDate);
                    endDate = startDate.clone().endOf('day');
                }
                if (deliveryTour.endDate) {
                    endDate = moment(deliveryTour.endDate);
                }
            }
            fetchTemperatures(deliveryTour.vehicles[0].id, {
                fromDate: startDate.toISOString(),
                toDate: endDate.toISOString(),
            });
        }
    }

    public getComputedData = () => {
        const { temperatures } = this.props;

        if (!temperatures || temperatures.loading || !temperatures.data) {
            return {
                lineSeriesCanvas: [],
            };
        }

        const computedData: {
            lineSeriesCanvas: LineSeriesCanvas;
        } = {
            lineSeriesCanvas: temperatures.data.map((temperatureData, index) => ({
                color: chartColors[index % 3],
                curve: 'curveMonotoneX',
                data: temperatureData.values.map((datum) => ({
                    x: +(new Date(datum.date)),
                    y: typeof datum.value === 'string' ? parseFloat(datum.value) : datum.value,
                })),
                name: temperatureData.name,
            })),
        };

        return computedData;
    }

    public formatXAxisTickLabels = (tick: any) => this.props.intl.formatTime(tick);

    public formatYAxisTickLabels = (tick: any) => `${tick} °C`;

    public renderTooltip = (lineSeriesCanvas: LineSeriesCanvas, data: Datum[]) => {
        const { intl } = this.props;

        if (data.some((d) => !!d)) {
            const currentDate = moment(data.filter(Boolean)[0].x).toDate();
            const title = intl.formatTime(currentDate, {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });

            const descriptiveListData = data.map((datum, index) => ({
                term: (
                    <span style={{ color: lineSeriesCanvas[index].color }}>
                        {lineSeriesCanvas[index].name}
                    </span>
                ),
                description: (
                    <span
                        style={{
                            color: lineSeriesCanvas[index].color,
                            display: 'block',
                            textAlign: 'right',
                            width: '100%',
                        }}
                    >
                        {datum ? `${intl.formatNumber(datum.y)} °C` : '--'}
                    </span>
                ),
            }));

            return (
                <ChartTooltip
                    title={title}
                    style={{ minWidth: 250 }}
                >
                    <DescriptiveList
                        data={descriptiveListData}
                        style={{ marginBottom: 0, width: '100%' }}
                        descriptionResponsive="ant-col-8"
                        termResponsive="ant-col-16"
                    />
                </ChartTooltip>
            );
        } else {
            return null;
        }
    }

    public render() {
        const { deliveryTour, temperatures } = this.props;
        let startDate = moment();
        let endDate = moment().endOf('day');

        if (deliveryTour) {
            startDate = moment(deliveryTour.date);
            endDate = moment(deliveryTour.date).endOf('day');

            if (deliveryTour.startDate) {
                startDate = moment(deliveryTour.startDate);
                endDate = startDate.clone().endOf('day');
            }
            if (deliveryTour.endDate) {
                endDate = moment(deliveryTour.endDate);
            }
        }

        const computedData = this.getComputedData();
        const referenceLines: Array<React.ReactElement<DecorativeAxis>> = [];

        if (deliveryTour && deliveryTour.params.temperatures) {
            Object.keys(deliveryTour.params.temperatures).forEach((type) => {
                const lineColor = type === ParcelType.frozen ? 'temperature-frozen' : 'temperature-fresh';
                referenceLines.push((
                    // @ts-ignore
                    <DecorativeAxis
                        key={`${type}min`}
                        axisStart={{
                            x: startDate.valueOf(),
                            y: deliveryTour.params.temperatures[type].range[0],
                        }}
                        axisEnd={{
                            x: endDate.valueOf(),
                            y: deliveryTour.params.temperatures[type].range[0],
                        }}
                        axisDomain={[startDate.valueOf(), endDate.valueOf()]}
                        className={lineColor}
                        // @ts-ignore
                        style={{ line: { strokeDasharray: 8 }}}
                        tickSize={0}
                        // tslint:disable-next-line: jsx-no-lambda
                        tickValue={() => null}
                        title={type}
                    />
                ), (
                    // @ts-ignore
                    <DecorativeAxis
                        key={`${type}max`}
                        axisStart={{
                            x: startDate.valueOf(),
                            y: deliveryTour.params.temperatures[type].range[1],
                        }}
                        axisEnd={{
                            x: endDate.valueOf(),
                            y: deliveryTour.params.temperatures[type].range[1],
                        }}
                        axisDomain={[startDate.valueOf(), endDate.valueOf()]}
                        className={lineColor}
                        // @ts-ignore
                        style={{ line: { strokeDasharray: 8 }}}
                        tickSize={0}
                        // tslint:disable-next-line: jsx-no-lambda
                        tickValue={() => null}
                        title={type}
                    />
                ));
            });
        }

        return (
            <Card
                title={(
                    <FormattedMessage {...DeliveryTourDeliveryManDetailsDrawerMessages.temperaturesOverTime} />
                )}
                extra={temperatures && temperatures.loading ? <Icon type="spinning" /> : null}
            >
                <div
                    id="temperature-chart"
                >
                    <MultiSeriesChart
                        data={computedData}
                        noDataText={(
                            <FormattedMessage {...GenericMessages.noData} />
                        )}
                        tooltipFormatter={this.renderTooltip.bind(null, computedData.lineSeriesCanvas)}
                        xAxisTickLabelFormatter={this.formatXAxisTickLabels}
                        xAxisDomain={[
                            startDate.valueOf(),
                            endDate.valueOf(),
                        ]}
                        yAxisDomain={[-50, 40]}
                        yAxisTickLabelFormatter={this.formatYAxisTickLabels}
                    >
                        {referenceLines}
                    </MultiSeriesChart>
                </div>
            </Card>
        );
    }
}

const mapStateToProps = (state: MainReducerState, { deliveryTour }: TemperatureChartProps) => {
    const vehicleId = deliveryTour &&
        deliveryTour.vehicles &&
        deliveryTour.vehicles[0] &&
        deliveryTour.vehicles[0].id ||
        '';

    return {
        temperatures: getTemperatureByVehicleId(state, vehicleId),
    };
};

export default injectIntl(connect(
    mapStateToProps,
    { fetchTemperatures: VehiclesActions.temperature },
)(TemperatureChart));
