import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {
    renderTrackVerticalDefault,
    renderThumbVerticalDefault,
} from 'react-custom-scrollbars/lib/Scrollbars/defaultRenderElements';
import Autosizer from 'react-virtualized-auto-sizer';

class ScrollBars extends React.Component {
    public scrollbarsRef = React.createRef<Scrollbars>();

    public scrollTo(position: number) {
        if (this.scrollbarsRef.current) {
            // @ts-ignore
            this.scrollbarsRef.current.view.scroll({
                top: position,
                left: 0,
                behavior: 'smooth',
            });
        }
    }

    public render() {
        const { children } = this.props;
        return (
            <Autosizer>
                {({ width, height }: { [prop: string]: number }) => (
                    <Scrollbars
                        ref={this.scrollbarsRef}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderTrackVertical={(props) =>
                            renderTrackVerticalDefault({ ...props, className: 'scrollbar-track' })
                        }
                        // tslint:disable-next-line: jsx-no-lambda
                        renderThumbVertical={(props) =>
                            renderThumbVerticalDefault({ ...props, className: 'scrollbar-thumb' })
                        }
                        style={{ width, height }}
                    >
                    {children}
                    </Scrollbars>
                )}
            </Autosizer>
        );
    }
}

export default ScrollBars;
