import { Action } from 'redux';
import { delay } from 'redux-saga';
import { call, takeLatest, put } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import * as reduxActions from '../actions/deliveries';
import * as reduxTypes from '../types/deliveries';
import * as api from '../api/deliveries';
import { SearchPaginationQuery } from '../api';
import { arrayOfDeliveries } from '../schema';
import { DataAction, IdAction } from '../actions';

export function* listSaga(action: Action & SearchPaginationQuery) {
    try {
        yield delay(action.throttling || 0);
        const { items, ...rest } = yield call(api.list, action);
        const normalizedItems = normalize(items, arrayOfDeliveries);

        return yield put(reduxActions.listSuccess({
            ...normalizedItems,
            ...rest,
        }));
    } catch (error) {
        return yield put(reduxActions.listFailed(error));
    }
}
export function* sendMessageSaga(action: DataAction<{ message: string }> & IdAction) {
    try {
        const response = yield call(api.sendMessage, action.id, action.data);
        return yield put(reduxActions.sendMessageSuccess(action.id, response));
    } catch (error) {
        return yield put(reduxActions.sendMessageFailed(action.id, error));
    }
}

export default function* deliveriesSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.SEND_MESSAGE, sendMessageSaga);
}
