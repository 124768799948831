import { defineMessages } from 'react-intl';

export default defineMessages({
    notFoundTitle: {
        id: 'notFound.title',
        defaultMessage: '404 Page not found',
        description: 'Not found page title',
    },
    notFoundDescription: {
        id: 'notFound.description',
        defaultMessage: 'The requested page was not found.',
        description: 'Not found page description',
    },
});
