import { defineMessages } from 'react-intl';

export default defineMessages({
    loggedRequestsInError: {
        id: 'monitoring.loggedRequestsInError',
        defaultMessage: 'Requests In Error',
        description: '',
    },
    loggedRequestsOk: {
        id: 'monitoring.loggedRequestsOk',
        defaultMessage: 'Requests Ok',
        description: '',
    },
    diagnosticApiKO: {
        id: 'monitoring.diagnosticApiKO',
        defaultMessage: 'The Remote API is *not* working properly. Please contact IT Support.',
        description: '',
    },
    diagnosticApiOK: {
        id: 'monitoring.diagnosticApiOK',
        // tslint:disable-next-line:max-line-length
        defaultMessage: 'The Remote API is working properly. There is no need to contact IT Support. Please advice ezeeworld support if the problem persist.',
        description: '',
    },
    diagnosticRunning: {
        id: 'monitoring.diagnosticApiRunning',
        defaultMessage: 'Running API disagnostic',
        description: '',
    },
    title: {
        id: 'monitoring.title',
        defaultMessage: 'Remote API calls today',
        description: '',
    },
    diagnosticHeader: {
        id: 'monitoring.diagnosticHeader',
        defaultMessage: 'Remote API diagnostic',
        description: '',
    },
});
