import { defineMessages } from 'react-intl';

export default defineMessages({
    toRecipient: {
        id: 'send_message_popover.recipient',
        defaultMessage: 'To: {recipient}',
        description: 'Send message popover recipient line',
    },
    messageSent: {
        id: 'send_message_popover.message_sent',
        defaultMessage: 'Message sent',
        description: 'Send message popover success text',
    },
});
