import api, { PaginatedListResponse, urlWithQuery } from './_client';
import { QueryListPayload } from '../actions';
import { MonitoringSynthesis, LoggedRequest } from './types';
import { DateQuery } from '.';

export interface LoggedRequestResponse extends PaginatedListResponse<LoggedRequest> { }

export const list = (payload: QueryListPayload) => {
    return api.get<LoggedRequestResponse>(urlWithQuery('/admin/loggedRequests', payload));
};

export const synthesis = (payload: DateQuery) => {
    return api.get<MonitoringSynthesis>(urlWithQuery('/admin/monitoringSynthesis', payload));
};
