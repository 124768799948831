import { combineReducers } from 'redux';

import * as reduxTypes from '../types/organizations';

import { MainReducerState, RequestState } from '.';
import { Organization } from '../api/types';
import {
    requestReducer, paginateReducer, PaginationRequestState, entityReducer, EntityReducerState,
} from './_generics';
import { denormalize } from 'normalizr';
import { arrayOfOrganizations, organization, Entities } from '../schema';

export interface OrganizationsState {
    readonly entities: EntityReducerState<Organization>;
    readonly creates: RequestState;
    readonly pagination: PaginationRequestState;
    readonly reads: RequestState;
    readonly updates: RequestState;
}

export default combineReducers<OrganizationsState>({
    creates: requestReducer({
        START: reduxTypes.CREATE,
        SUCCESS: reduxTypes.CREATE_SUCCESS,
        FAILED: reduxTypes.CREATE_FAILED,
    }),
    entities: entityReducer([
        reduxTypes.LIST_SUCCESS,
        reduxTypes.DETAILS_SUCCESS,
    ], Entities.organizations),
    pagination: paginateReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    }),
    reads: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAILED,
    }),
    updates: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAILED,
    }),
});

export const getIds = (state: MainReducerState) => state.organizations.pagination.ids;
export const getOrganizationById = (state: MainReducerState, id: Organization['id']) =>
    denormalize(id, organization, { [Entities.organizations]: state.organizations.entities }) || undefined;
export const getOrganizations = (state: MainReducerState) =>
    denormalize(getIds(state), arrayOfOrganizations, { [Entities.organizations]: state.organizations.entities });
export const getPagination = (state: MainReducerState) => state.organizations.pagination;
export const getCreates = (state: MainReducerState) => state.organizations.creates;
export const getUpdates = (state: MainReducerState) => state.organizations.updates;
export const getReads = (state: MainReducerState) => state.organizations.reads;
