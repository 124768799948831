import * as React from 'react';
import { Card } from 'antd';
import { CardProps } from 'antd/lib/card';

class ChartTooltip extends React.PureComponent<CardProps> {
    public render() {
        const { children, title, ...rest } = this.props;

        return (
            <Card
                className="chart-tooltip"
                size="small"
                style={{ minWidth: 220 }}
                title={title}
                {...rest}
            >
                {children}
            </Card>
        );
    }
}

export default ChartTooltip;
