import { defineMessages } from 'react-intl';

export default defineMessages({
    deliveryNoCoordinates: {
        id: 'delivery.no_coordinates',
        defaultMessage: 'No coordinates !',
        description: 'Error message when a delivery has no coordinates',
    },
    deliveryStatusPending: {
        id: 'delivery.status.pending',
        defaultMessage: 'Pending',
        description: 'Pending status of a delivery',
    },
    deliveryStatusReady: {
        id: 'delivery.status.ready',
        defaultMessage: 'Ready',
        description: 'Ready for delivery tour status of a delivery',
    },
    deliveryStatusPostponed: {
        id: 'delivery.status.postponed',
        defaultMessage: 'Postponed',
        description: 'Postponed status of a delivery',
    },
    deliveryStatusStarted: {
        id: 'delivery.status.started',
        defaultMessage: 'Delivering',
        description: 'Started delivery status of a delivery',
    },
    deliveryStatusArrived: {
        id: 'delivery.status.arrived',
        defaultMessage: 'Arrived',
        description: 'Arrived at delivery location status of a delivery',
    },
    deliveryStatusRefused: {
        id: 'delivery.status.refused',
        defaultMessage: 'Refused',
        description: 'Package refused status of a delivery',
    },
    deliveryStatusPartial: {
        id: 'delivery.status.partial',
        defaultMessage: 'Partial',
        description: 'Partial package content status of a delivery',
    },
    deliveryStatusDelivered: {
        id: 'delivery.status.delivered',
        defaultMessage: 'Delivered',
        description: 'Delivered status of a delivery',
    },
    deliveryStatusFailed: {
        id: 'delivery.status.failed',
        defaultMessage: 'Failed',
        description: 'Failed status of a delivery',
    },
    deliveryStatusTransferred: {
        id: 'delivery.status.transferred',
        defaultMessage: 'Transferred',
        description: 'Transferred status of a delivery',
    },
    deliveryStatusUnknown: {
        id: 'delivery.status.unknown',
        defaultMessage: 'Unknown',
        description: 'Unknown status of a delivery',
    },
    deliveryItemStatusPending: {
        id: 'delivery_item.status.pending',
        defaultMessage: 'Pending',
        description: 'status of a delivery item',
    },
    deliveryItemStatusDamaged: {
        id: 'delivery_item.status.damaged',
        defaultMessage: 'Damaged',
        description: 'status of a delivery item',
    },
    deliveryItemStatusReady: {
        id: 'delivery_item.status.ready',
        defaultMessage: 'Ready',
        description: 'status of a delivery item',
    },
    deliveryItemStatusMissing: {
        id: 'delivery_item.status.missing',
        defaultMessage: 'Missing',
        description: 'status of a delivery item',
    },
    deliveryItemStatusDamagedDuringDelivery: {
        id: 'delivery_item.status.damaged_during_delivery',
        defaultMessage: 'Damaged during delivery',
        description: 'status of a delivery item',
    },
    deliveryItemStatusLost: {
        id: 'delivery_item.status.lost',
        defaultMessage: 'Lost',
        description: 'status of a delivery item',
    },
    deliveryItemStatusRefused: {
        id: 'delivery_item.status.refused',
        defaultMessage: 'Refused',
        description: 'status of a delivery item',
    },
    deliveryItemStatusDelivered: {
        id: 'delivery_item.status.delivered',
        defaultMessage: 'Delivered',
        description: 'status of a delivery item',
    },
    deliveryItemStatusReturned: {
        id: 'delivery_item.status.returned',
        defaultMessage: 'Returned',
        description: 'status of a delivery item',
    },
    deliveryItemStatusUnknown: {
        id: 'delivery_item.status.unknown',
        defaultMessage: 'Unknown',
        description: 'status of a delivery item',
    },
    deliveryItemTypeProduct: {
        id: 'delivery_item.type.product',
        defaultMessage: 'Product',
        description: 'type of a delivery item',
    },
    deliveryItemTypeParcel: {
        id: 'delivery_item.type.parcel',
        defaultMessage: 'Parcel',
        description: 'type of a delivery item',
    },
    deliveryItemTypeExtra: {
        id: 'delivery_item.type.extra',
        defaultMessage: 'Extra',
        description: 'type of a delivery item',
    },
    deliveryItemTypeOther: {
        id: 'delivery_item.type.other',
        defaultMessage: 'Other',
        description: 'type of a delivery item',
    },
    deliveryItemTypeUnknown: {
        id: 'delivery_item.type.unknown',
        defaultMessage: 'Unknown',
        description: 'type of a delivery item',
    },
    parcelTypeFresh: {
        id: 'parcel.type.fresh',
        defaultMessage: 'Fresh',
        description: 'type of a parcel',
    },
    parcelTypeFrozen: {
        id: 'parcel.type.frozen',
        defaultMessage: 'Frozen',
        description: 'type of a parcel',
    },
    parcelTypeGrocery: {
        id: 'parcel.type.grocery',
        defaultMessage: 'Grocery',
        description: 'type of a parcel',
    },
    parcelTypeMultiDelivery: {
        id: 'parcel.type.multiDelivery',
        defaultMessage: 'Multiple deliveries',
        description: 'type of a parcel',
    },
    parcelTypeOther: {
        id: 'parcel.type.other',
        defaultMessage: 'Other',
        description: 'type of a parcel',
    },
    parcelTypeUnknown: {
        id: 'parcel.type.unknown',
        defaultMessage: 'Unknown',
        description: 'type of a parcel',
    },
    deliveryItemAnomalyTypeDamaged: {
        id: 'delivery_item.anomaly.type.damaged',
        defaultMessage: 'Damaged',
        description: 'type of a delivery item anomaly',
    },
    deliveryItemAnomalyTypeMissing: {
        id: 'delivery_item.anomaly.type.missing',
        defaultMessage: 'Missing',
        description: 'type of a delivery item anomaly',
    },
    deliveryItemAnomalyTypeLabelError: {
        id: 'delivery_item.anomaly.type.label_error',
        defaultMessage: 'Label error',
        description: 'type of a delivery item anomaly',
    },
    deliveryItemAnomalyTypeOther: {
        id: 'delivery_item.anomaly.type.other',
        defaultMessage: 'Other',
        description: 'type of a delivery item anomaly',
    },
    deliveryItemAnomalyTypeUnknown: {
        id: 'delivery_item.anomaly.type.unknown',
        defaultMessage: 'Unknown',
        description: 'type of a delivery item anomaly',
    },
    timelinePreparation: {
        id: 'delivery_tour.timeline.preparation',
        defaultMessage: 'Preparation',
        description: 'Delivery tour details timeline : title of the preparation section',
    },
    timelineTasks: {
        id: 'delivery_tour.timeline.tasks',
        defaultMessage: 'Tasks',
        description: 'Delivery tour details timeline : title of the tasks section',
    },
    deliveryTypeBasic: {
        id: 'delivery.type.basic',
        defaultMessage: 'Delivery',
        description: 'Delivery type',
    },
    deliveryTypeThirdParty: {
        id: 'delivery.type.third_party',
        defaultMessage: 'Delivery for {thirdParty}',
        description: 'Delivery type',
    },
    deliveryTypePickUp: {
        id: 'delivery.type.pick_up',
        defaultMessage: 'Pick up',
        description: 'Delivery type',
    },
    deliveryTypeVisit: {
        id: 'delivery.type.visit',
        defaultMessage: 'Visit',
        description: 'Delivery type',
    },
    deliveryTypeUnknown: {
        id: 'delivery.type.unknown',
        defaultMessage: 'Unknown',
        description: 'Unknown delivery type',
    },
    noDeliveryRecipientName: {
        id: 'delivery.contact.no_name',
        defaultMessage: 'No contact name',
        description: 'No contact name message',
    },
    numberOfParcels: {
        id: 'delivery.number_of_parcels',
        defaultMessage: `
            {count, plural,
                =0 {{count} parcels}
                one {{count} parcel}
                other {{count} parcels}
            }
        `,
        description: 'Count of parcels',
    },
    numberOfEvents: {
        id: 'delivery.number_of_events',
        defaultMessage: `
            {count, plural,
                =0 {{count} events}
                one {{count} event}
                other {{count} events}
            }
        `,
        description: 'Count of events',
    },
    numberOfTasks: {
        id: 'delivery.number_of_tasks',
        defaultMessage: `
            {count, plural,
                =0 {{count} tasks}
                one {{count} task}
                other {{count} tasks}
            }
        `,
        description: 'Count of tasks',
    },
    deliveryManEventLogin: {
        id: 'event.delivery_man.login',
        defaultMessage: 'Delivery man login',
        description: 'event',
    },
    deliveryManEventLogout: {
        id: 'event.delivery_man.logout',
        defaultMessage: 'Delivery man logout',
        description: 'event',
    },
    deliveryManEventUnknown: {
        id: 'event.delivery_man.unknown',
        defaultMessage: 'Unknown',
        description: 'event',
    },
    deliveryTourEventStart: {
        id: 'event.delivery_tour.start',
        defaultMessage: 'Delivery tour start',
        description: 'event',
    },
    deliveryTourEventEnd: {
        id: 'event.delivery_tour.end',
        defaultMessage: 'Delivery tour end',
        description: 'event',
    },
    deliveryTourEventCashCount: {
        id: 'event.delivery_tour.cash_count',
        defaultMessage: 'Delivery tour cash count',
        description: 'event',
    },
    deliveryTourEventEventStart: {
        id: 'event.delivery_tour.event_start',
        defaultMessage: 'Delivery tour event start',
        description: 'event',
    },
    deliveryTourEventEventEnd: {
        id: 'event.delivery_tour.event_end',
        defaultMessage: 'Delivery tour event end',
        description: 'event',
    },
    deliveryTourEventUnknown: {
        id: 'event.delivery_tour.unknown',
        defaultMessage: 'Unknown',
        description: 'event',
    },
    transferEventInit: {
        id: 'event.transfer.init',
        defaultMessage: 'Delivery transfer init',
        description: 'event',
    },
    transferEventRejected: {
        id: 'event.transfer.rejected',
        defaultMessage: 'Delivery transfer rejected',
        description: 'event',
    },
    transferEventAccepted: {
        id: 'event.transfer.accepted',
        defaultMessage: 'Delivery transfer accepted',
        description: 'event',
    },
    transferEventCompleted: {
        id: 'event.transfer.completed',
        defaultMessage: 'Delivery transfer completed',
        description: 'event',
    },
    transferEventUnknown: {
        id: 'event.transfer.unknown',
        defaultMessage: 'Unknown',
        description: 'event',
    },
    deliveryEventStart: {
        id: 'event.delivery.start',
        defaultMessage: 'Delivery start',
        description: 'event',
    },
    deliveryEventArrived: {
        id: 'event.delivery.arrived',
        defaultMessage: 'Delivery man arrived',
        description: 'event',
    },
    deliveryEventSignature: {
        id: 'event.delivery.signature',
        defaultMessage: 'Signature',
        description: 'event',
    },
    deliveryEventCustomerComment: {
        id: 'event.delivery.customer_comment',
        defaultMessage: 'Customer comment',
        description: 'event',
    },
    deliveryEventDeliveryManComment: {
        id: 'event.delivery.delivery_man_comment',
        defaultMessage: 'Delivery man comment',
        description: 'event',
    },
    deliveryEventValidated: {
        id: 'event.delivery.validated',
        defaultMessage: 'Delivery validated',
        description: 'event',
    },
    deliveryEventFailed: {
        id: 'event.delivery.failed',
        defaultMessage: 'Delivery failed',
        description: 'event',
    },
    deliveryEventCancel: {
        id: 'event.delivery.cancel',
        defaultMessage: 'Delivery cancel',
        description: 'event',
    },
    deliveryEventPreorder: {
        id: 'event.delivery.preorder',
        defaultMessage: 'Delivery pre-order',
        description: 'event',
    },
    deliveryEventDiscount: {
        id: 'event.delivery.discount',
        defaultMessage: 'Delivery discount',
        description: 'event',
    },
    deliveryEventPayment: {
        id: 'event.delivery.payment',
        defaultMessage: 'Delivery payment',
        description: 'event',
    },
    deliveryEventRemovePayment: {
        id: 'event.delivery.remove_payment',
        defaultMessage: 'Delivery remove payment',
        description: 'event',
    },
    deliveryEventUpdateAddressLocation: {
        id: 'event.delivery.updateAddressLocation',
        defaultMessage: 'Update delivery address location',
        description: 'event',
    },
    deliveryEventUnknown: {
        id: 'event.delivery.unknown',
        defaultMessage: 'Unknown',
        description: 'event',
    },
    vehicleEventChange: {
        id: 'event.vehicle.change',
        defaultMessage: 'Vehicle change',
        description: 'event',
    },
    vehicleEventDamaged: {
        id: 'event.vehicle.damaged',
        defaultMessage: 'Vehicle damaged',
        description: 'event',
    },
    vehicleEventMissing: {
        id: 'event.vehicle.missing',
        defaultMessage: 'Vehicle missing',
        description: 'event',
    },
    vehicleEventUpdate: {
        id: 'event.vehicle.update',
        defaultMessage: 'Vehicle update',
        description: 'event',
    },
    vehicleEventUnknown: {
        id: 'event.vehicle.unknown',
        defaultMessage: 'Unknown',
        description: 'event',
    },
    itemEventAdd: {
        id: 'event.item.add',
        defaultMessage: 'Item {item} added',
        description: 'event',
    },
    itemEventRemove: {
        id: 'event.item.remove',
        defaultMessage: 'Item {item} removed',
        description: 'event',
    },
    itemEventReady: {
        id: 'event.item.ready',
        defaultMessage: 'Item {item} ready',
        description: 'event',
    },
    itemEventReset: {
        id: 'event.item.reset',
        defaultMessage: 'Item {item} reset',
        description: 'event',
    },
    itemEventMissing: {
        id: 'event.item.missing',
        defaultMessage: 'Item {item} missing',
        description: 'event',
    },
    itemEventDamaged: {
        id: 'event.item.damaged',
        defaultMessage: 'Item {item} damaged',
        description: 'event',
    },
    itemEventRefused: {
        id: 'event.item.refused',
        defaultMessage: 'Item {item} refused',
        description: 'event',
    },
    itemEventDelivered: {
        id: 'event.item.delivered',
        defaultMessage: 'Item {item} delivered',
        description: 'event',
    },
    itemEventReturn: {
        id: 'event.item.return',
        defaultMessage: 'Item {item} return',
        description: 'event',
    },
    itemEventUnknown: {
        id: 'event.item.unknown',
        defaultMessage: 'Unknown',
        description: 'event',
    },
    noTasks: {
        id: 'delivery.no_tasks',
        defaultMessage: 'No tasks',
        description: 'Delivery tour timeline message when there are no tasks',
    },
});
