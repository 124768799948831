import api, { PaginatedListResponse, urlWithQuery } from './_client';
import { QueryListPayload } from '../actions';
import { DeliveryMan, DeliveryManStats } from './types';

export interface DeliveryMenListResponse extends PaginatedListResponse<DeliveryMan> {}

export const list = (payload: QueryListPayload) => {
    return api.get<DeliveryMenListResponse>(urlWithQuery('/admin/deliveryMen', payload));
};

export const details = (id: DeliveryMan['id']) => {
    return api.get<DeliveryMan>(`/admin/deliveryMen/${id}`);
};

export const stats = (id: DeliveryMan['id']) => {
    return api.get<DeliveryManStats>(`/admin/deliveryMen/${id}/stats`);
};
