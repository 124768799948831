
export function toRadians(degrees: number) {
  return (degrees * Math.PI) / 180.0;
}
// Converts from radians to degrees.
export function toDegrees(radians: number) {
  return (radians * 180.0) / Math.PI;
}

export function rotationFromCoordinates(src: number[], dst: number[]) {
  const startLat = toRadians(src[0]);
  const startLng = toRadians(src[1]);
  const destLat = toRadians(dst[0]);
  const destLng = toRadians(dst[1]);

  const y = Math.sin(destLng - startLng) * Math.cos(destLat);
  const x = Math.cos(startLat) * Math.sin(destLat)
      - Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
  return (toDegrees(Math.atan2(y, x)) + 360) % 360;
}
