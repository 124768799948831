import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <path fill="#FFF" d="M.016 13.35L1.2 8.23a.548.548 0 0 1 .465-.42l6.613-.685c.187-.016.187-.296 0-.327l-6.613-.64a.514.514 0 0 1-.466-.403L.015.65C-.094.214.374-.144.778.058l12.93 6.475c.39.202.39.763 0 .965L.778 13.942c-.404.202-.87-.156-.762-.592z" />{/* tslint:disable:max-line-length */}
    </svg>
);

const IconSend: React.SFC<IconProps> = (props) => (
    <Icon component={svg} className="anticon icon-send" {...props} />
);

export default IconSend;
