import { combineReducers, Reducer } from 'redux';
import { denormalize } from 'normalizr';

import * as reduxTypes from '../types/monitoring';

import { MainReducerState, RequestState } from '.';

import {
    EntityReducerState, PaginationRequestState, entityReducer, paginateReducer, requestReducer,
} from './_generics';
import { loggedRequest, arrayOfLoggedRequests, Entities } from '../schema';
import { LoggedRequest, MonitoringSynthesis } from '../api/types';

export enum MonitoringDateMode {
    today = 'today',
    yesterday = 'yesterday',
    before_yesterday = 'before_yesterday',
}

export type MonitoringSynthesisState = RequestState & {
    data?: MonitoringSynthesis;
};

export interface MonitoringState {
    readonly dateMode: MonitoringDateMode;
    readonly entities: EntityReducerState<LoggedRequest>;
    readonly pagination: PaginationRequestState;
    readonly synthesis: MonitoringSynthesisState;
}

const statsInitialState: MonitoringSynthesisState = {
    loading: false,
    error: undefined,
    data: {},
};

const dateMode: Reducer<MonitoringDateMode> = (state = MonitoringDateMode.today, action) => {
    switch (action.type) {
        case reduxTypes.LIST:
            return action.dateMode || MonitoringDateMode.today;
        default:
            return state;
    }
};

const synthesis: Reducer<MonitoringSynthesisState> = (state = statsInitialState, { type, data, query }) => {
    switch (type) {
        case reduxTypes.SYNTHESIS:
            return {
                data,
                error: undefined,
                loading: true,
            };
        case reduxTypes.SYNTHESIS_SUCCESS: {
            return {
                data,
                loading: false,
            };
        }
        case reduxTypes.SYNTHESIS_FAILED:
            return {
                data,
                loading: false,
                error: data,
            };

        default:
            return state;
    }
};

export default combineReducers<MonitoringState>({
    dateMode,
    entities: entityReducer([
        reduxTypes.LIST_SUCCESS,
    ], Entities.loggedRequest),
    pagination: paginateReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    }),
    synthesis,
});

export const getIds = (state: MainReducerState) => state.monitoring.pagination.ids;

export const getLoggedRequestById = (state: MainReducerState, id: LoggedRequest['id']) =>
    denormalize(id, loggedRequest, {
        [Entities.loggedRequest]: state.monitoring.entities,
    }) || undefined;

export const getLoggedRequests = (state: MainReducerState) =>
    denormalize(getIds(state), arrayOfLoggedRequests, {
        [Entities.loggedRequest]: state.monitoring.entities,
    });

export const getSynthesis = (state: MainReducerState) => state.monitoring.synthesis;

export const getPagination = (state: MainReducerState) => state.monitoring.pagination;

export const getDateMode = (state: MainReducerState) => state.monitoring.dateMode;
