import { combineReducers, Reducer } from 'redux';
import * as reduxTypes from '../types/providers';

import { PaginatedListState, MainReducerState } from '.';
import { Provider } from '../api/types';
import constants from '../../utils/constants';

export interface ProvidersListState extends PaginatedListState<Provider['type']> {}

export interface ProvidersState {
    readonly list: ProvidersListState;
}

const listInitialState: ProvidersListState = {
    count: 0,
    data: [],
    filteredCount: 0,
    loading: false,
    page: 0,
    pageSize: constants.PAGE_SIZE,
    totalCount: 0,
};

const list: Reducer<ProvidersListState> = (state = listInitialState, { type, data }) => {
    switch (type) {
        case reduxTypes.LIST:
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        case reduxTypes.LIST_SUCCESS: {
            return {
                ...state,
                data,
                loading: false,
            };
        }
        case reduxTypes.LIST_FAILED:
            return {
                ...state,
                loading: false,
                error: data,
            };

        default:
            return state;
    }
};

export default combineReducers<ProvidersState>({
    list,
});

export const getProviders = (state: MainReducerState) => state.providers.list;
