import React, { Component } from 'react';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';
import { Spin, Button, Icon } from 'antd';

import {
    DeliveryTour, Delivery,
} from '../../store/api/types';

import ScrollBars from '../../components/ScrollBars';
import DeliveryTourOverview from './DeliveryTourOverview';
import DeliveryTourTimelineItem from './DeliveryTourTimelineItem';
import messages from './DeliveryTourSider.messages';
import EmptyStateText from '../../components/typography/EmptyStateText';
import { DeliveryDetailsDrawer } from '../deliveries';
import { IconAngle } from '../../components/icons';

interface DeliveryTourSiderProps extends InjectedIntlProps {
    deliveryTour?: DeliveryTour;
    loading: boolean;
}

interface DeliveryTourSiderState {
    collapsed: boolean;
    deliveryDetails?: Delivery;
}

class DeliveryTourSider extends Component<DeliveryTourSiderProps, DeliveryTourSiderState> {
    public scrollBarsRef = React.createRef<ScrollBars>();
    public state: DeliveryTourSiderState = {
        collapsed: true,
    };

    public scrollToTask = (task: Delivery) => {
        if (this.scrollBarsRef.current) {
            const element = document.getElementById(`timeline-item-${task.id}`);
            this.scrollBarsRef.current.scrollTo(element!.offsetTop - 64);
            element!.classList.remove('flash');
            element!.classList.add('flash');
            window.setTimeout(() => {
                element!.classList.remove('flash');
            }, 2000);
        }
    }

    public onTaskClick(task: Delivery) {
        if (this.state.collapsed) {
            this.setState({collapsed: false}, () => {
                this.scrollToTask(task);
            });
        } else {
            this.scrollToTask(task);
        }
    }

    public onCollapse = () => {
        this.setState(({ collapsed }) => ({ collapsed: !collapsed }));
    }

    public renderTimelineData = (deliveryTour?: DeliveryTour) => {
        const tasksContent = [];
        const preparationContent = [];

        if (deliveryTour) {
            if (deliveryTour.agency && deliveryTour.agency.coordinates) {
                preparationContent.push(
                    (
                        <div key="timeline-section-title-preparation" className="timeline-section-title first">
                            <FormattedMessage
                                {...messages.timelinePreparation}
                                tagName="h2"
                            />
                        </div>
                    ), (
                        <DeliveryTourTimelineItem
                            agency={deliveryTour.agency}
                            deliveryTourStatus={deliveryTour.status}
                            key="timeline-agency"
                            preparationTags={deliveryTour.preparationTags}
                        />
                    ),
                );
            }
            tasksContent.push((
                <div key="timeline-section-title-tasks" className="timeline-section-title">
                    <FormattedMessage
                        {...messages.timelineTasks}
                        tagName="h2"
                    />
                </div>
            ));
            (deliveryTour.deliveries || []).forEach((delivery: Delivery, index: number) => {
                if (delivery.address) {
                    tasksContent.push(
                        <DeliveryTourTimelineItem
                            delivery={delivery}
                            index={index}
                            isLast={(deliveryTour.deliveries || []).length === index + 1}
                            key={`timeline-delivery-${index}`}
                            onClickDetails={this.showDeliveryDetailsDrawer}
                        />,
                    );
                }
            });

            if (tasksContent.length === 1) {
                tasksContent.push((
                    <div className="no-tasks" key="timeline-no-tasks">
                        <EmptyStateText>
                            <FormattedMessage {...messages.noTasks} />
                        </EmptyStateText>
                    </div>
                ));
            }
        }

        return (
            <>
                {preparationContent.length > 0 && (
                    <div>
                        {preparationContent}
                    </div>
                )}
                <div>
                    {tasksContent}
                </div>
            </>
        );
    }

    public render() {
        const { deliveryTour } = this.props;
        const { collapsed, deliveryDetails } = this.state;
        return (
            <div id="delivery-tour-timeline" className={collapsed ? 'collapsed' : undefined}>
                <Button
                    id="delivery-tour-timeline-collapse-button"
                    onClick={this.onCollapse}
                    shape="circle"
                    type="primary"
                >
                    {collapsed ? (
                        <IconAngle direction="left" />
                    ) : (
                        <Icon type="close" />
                    )}
                </Button>
                {deliveryTour &&
                 deliveryTour.deliveries &&
                 !!deliveryTour.deliveries.length && (
                    <DeliveryTourOverview
                        deliveryTour={deliveryTour}
                    />
                )}
                <div id="timeline-wrapper">
                    <ScrollBars ref={this.scrollBarsRef}>
                        <Spin spinning={!deliveryTour}>
                            {this.renderTimelineData(deliveryTour)}
                        </Spin>
                    </ScrollBars>
                </div>
                <DeliveryDetailsDrawer
                    onClose={this.onCloseDeliveryDetailsDrawer}
                    delivery={deliveryDetails}
                />
            </div>
        );
    }

    private onCloseDeliveryDetailsDrawer = () => {
        this.setState({
            deliveryDetails: undefined,
        });
    }

    private showDeliveryDetailsDrawer = (delivery: Delivery) => {
        this.setState({
            deliveryDetails: delivery,
        });
    }
}

export default injectIntl(DeliveryTourSider, { withRef: true });
