import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18">
        <path fill="#BFBFBF" d="M11.323 4.453c2.08.38 3.677 2.13 3.677 4.263v4.973c0 2.368-1.984 4.31-4.403 4.31H4.403C1.983 18 0 16.058 0 13.69V8.762c0-2.13 1.597-3.93 3.677-4.263v-.758C3.677 1.705 5.42 0 7.5 0c2.08 0 3.823 1.658 3.823 3.742v.71zM7.5 1.705c-1.16 0-2.08.9-2.08 2.037v.663h4.16v-.663c0-1.137-.92-2.037-2.08-2.037zm5.758 11.937V8.716c0-1.47-1.21-2.605-2.66-2.605H4.402c-1.5 0-2.66 1.185-2.66 2.606v4.926c0 1.47 1.21 2.605 2.66 2.605h6.194c1.5 0 2.66-1.184 2.66-2.605zM7.5 8.052c1.494 0 2.66 1.195 2.66 2.62v.92c0 1.424-1.213 2.62-2.66 2.62s-2.66-1.15-2.66-2.62v-.92c0-1.425 1.166-2.62 2.66-2.62zm.98 3.585v-.92c0-.55-.42-.964-.98-.964s-.98.414-.98.965v.92c0 .55.42.964.98.964s.98-.413.98-.965z" />{/* tslint:disable:max-line-length */}
    </svg>
);

const IconLock: React.SFC<IconProps> = (props) => (
    <Icon component={svg} className="anticon icon-lock" {...props} />
);

export default IconLock;
