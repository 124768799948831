import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
        <path fill="#8F73FF" d="M6 12A6 6 0 1 1 6 0a6 6 0 0 1 0 12z" />
        <circle cx="6" cy="6" r="4" fill="#31323F" />
    </svg>
);

const IconTimelineTag: React.SFC<IconProps> = (props) => (
    <Icon component={svg} className="icon-timeline-tag" {...props} />
);

export default IconTimelineTag;
