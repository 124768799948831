import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <path fill="#FFF" d="M13.3 0c.387 0 .7.298.7.667v6.666c0 .37-.313.667-.7.667H8.4c-.387 0-.7-.298-.7-.667V.667c0-.37.313-.667.7-.667h4.9zm-.7 6.667V1.333H9.1v5.334h3.5zM5.6 6c.387 0 .7.298.7.667v6.666c0 .37-.313.667-.7.667H.7c-.387 0-.7-.298-.7-.667V6.667C0 6.297.313 6 .7 6h4.9zm-.7 6.667V7.333H1.4v5.334h3.5zM5.6 0c.387 0 .7.298.7.667v4c0 .368-.313.666-.7.666H.7c-.387 0-.7-.298-.7-.666v-4C0 .297.313 0 .7 0h4.9zm-.7 4V1.333H1.4V4h3.5zm8.4 4.667c.387 0 .7.298.7.666v4c0 .37-.313.667-.7.667H8.4c-.387 0-.7-.298-.7-.667v-4c0-.368.313-.666.7-.666h4.9zm-.7 4V10H9.1v2.667h3.5z" />{/* tslint:disable:max-line-length */}
    </svg>
);

const IconDashboard: React.SFC<IconProps> = (props) => (
    <Icon component={svg} className="anticon icon-dashboard" {...props} />
);

export default IconDashboard;
