import { ActionCreator } from 'redux';

import { DeliveriesListResponse } from '../api/deliveries';
import * as reduxTypes from '../types/deliveries';
import { DataAction, QueryListPayload, QueryListAction, IdAction } from '.';
import { Delivery } from '../api/types';

// LIST

export const list: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    ...payload,
    type: reduxTypes.LIST,
});

export const listSuccess: ActionCreator<DataAction> = (data: DeliveriesListResponse) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAILED,
    data,
});

// SEND MESSAGE

export const sendMessage: ActionCreator<DataAction & IdAction> = (id: Delivery['id'], data) => ({
    type: reduxTypes.SEND_MESSAGE,
    id,
    data,
});

export const sendMessageSuccess: ActionCreator<DataAction & IdAction> = (id: Delivery['id'], data: any) => ({
    type: reduxTypes.SEND_MESSAGE_SUCCESS,
    id,
    data,
});

export const sendMessageFailed: ActionCreator<DataAction & IdAction> = (id: Delivery['id'], data: any) => ({
    type: reduxTypes.SEND_MESSAGE_FAILED,
    id,
    data,
});
