import { defineMessages } from 'react-intl';

export default defineMessages({
    deliveryTourStatusPending: {
        id: 'delivery_tour.status.pending',
        defaultMessage: 'Pending',
        description: 'status of a delivery tour',
    },
    deliveryTourStatusReady: {
        id: 'delivery_tour.status.ready',
        defaultMessage: 'Ready',
        description: 'status of a delivery tour',
    },
    deliveryTourStatusStarted: {
        id: 'delivery_tour.status.started',
        defaultMessage: 'Started',
        description: 'status of a delivery tour',
    },
    deliveryTourStatusFinished: {
        id: 'delivery_tour.status.finished',
        defaultMessage: 'Finished',
        description: 'status of a delivery tour',
    },
    deliveryTourStatusUnknown: {
        id: 'delivery_tour.status.unknown',
        defaultMessage: 'Unknown',
        description: 'status of a delivery tour',
    },
    noDeliveryMan: {
        id: 'delivery_tour.no_delivery_man',
        defaultMessage: 'No delivery man',
        description: 'Delivery tour no delivery man message',
    },
    everyNotDeliveredCustomers: {
        id: 'delivery_tour.send_all_recipient',
        defaultMessage: 'Every customer whose delivery isn\'t finished',
        description: 'Delivery tour send message to all recipients',
    },
    deliveryManInformations: {
        id: 'delivery_tour.delivery_man_informations',
        defaultMessage: 'Delivery man & vehicle',
        description: 'Button label of the delivery man details',
    },
    todaysDeliveryTours: {
        id: 'delivery_tour_list.list_title.today',
        defaultMessage: 'Today\'s delivery tours',
        description: 'List title',
    },
    yesterdaysDeliveryTours: {
        id: 'delivery_tour_list.list_title.yesterday',
        defaultMessage: 'Yesterday\'s delivery tours',
        description: 'List title',
    },
    allDeliveryTours: {
        id: 'delivery_tour_list.list_title.all',
        defaultMessage: 'All delivery tours',
        description: 'List title',
    },
    seeLastDeliveryTours: {
        id: 'delivery_tour_list.see_last_delivery_tours',
        defaultMessage: 'See last delivery tours',
        description: 'Button label when there are no delivery tours for the selected date',
    },
    allAgencies: {
        id: 'delivery_tour_list.all_agencies',
        defaultMessage: 'All agencies',
        description: 'Dropdown value for all agencies filter',
    },
});
