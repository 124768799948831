import { defineMessages } from 'react-intl';

export default defineMessages({
    goBackToHome: {
        id: 'go_back_to_home',
        defaultMessage: 'Go back to the homepage',
        description: 'Button text to go back to the homepage',
    },
    madeBy: {
        id: 'made_by',
        defaultMessage: 'Product by',
        description: 'Sidebar bottom text',
    },
    logout: {
        id: 'logout',
        defaultMessage: 'Log out',
        description: 'User menu item logout',
    },
    error: {
        id: 'generic_error',
        defaultMessage: 'An error occured, please try again later or contact support',
        description: 'Generic error mostly due to server 5xx error',
    },
    update: {
        id: 'update_notification.button',
        defaultMessage: 'Update',
        description: 'Update button label',
    },
    updateTitle: {
        id: 'update_notification.title',
        defaultMessage: 'An update is available',
        description: 'Update notification title',
    },
    updateMessage: {
        id: 'update_notification.message',
// tslint:disable-next-line: max-line-length
        defaultMessage: 'A new version of the application is available, click on the button below to update to the new version',
        description: 'Update notification message',
    },
    tasks: {
        id: 'tasks',
        defaultMessage: 'Tasks',
        description: 'Tasks',
    },
    address: {
        id: 'address',
        defaultMessage: 'Address {index}',
        description: 'Address',
    },
    comments: {
        id: 'comments',
        defaultMessage: 'Comments',
        description: 'Comments',
    },
    noComments: {
        id: 'no_comments',
        defaultMessage: 'No comments',
        description: 'No comments',
    },
    noSignature: {
        id: 'no_signature',
        defaultMessage: 'No signature',
        description: 'No signature',
    },
    signature: {
        id: 'signature',
        defaultMessage: 'Signature',
        description: 'Signature',
    },
    parcel: {
        id: 'parcel',
        defaultMessage: 'Parcel {index}',
        description: 'Parcel',
    },
    reference: {
        id: 'reference',
        defaultMessage: 'Reference',
        description: 'Reference',
    },
    barCodes: {
        id: 'barcodes',
        defaultMessage: 'Barcodes',
        description: 'Barcodes',
    },
    noData: {
        id: 'no_data',
        defaultMessage: 'No data',
        description: 'No data generic message',
    },
    dateAndTime: {
        id: 'date_and_time',
        defaultMessage: 'On {date} at {time}',
        description: 'Generic display of date and time',
    },
    price: {
        id: 'price',
        defaultMessage: 'Price',
        description: 'Price',
    },
    priceInclTax: {
        id: 'price_incl_tax',
        defaultMessage: 'Price incl tax',
        description: 'Price including taxes',
    },
    priceWithoutTax: {
        id: 'price_without_tax',
        defaultMessage: 'Price excl tax',
        description: 'Price without taxes',
    },
    initialPrice: {
        id: 'initial_price',
        defaultMessage: 'Initial price',
        description: 'Price details',
    },
    initialWithoutTax: {
        id: 'initial_price_without_tax',
        defaultMessage: 'Initial price excl tax',
        description: 'Price details',
    },
    priceAdjusted: {
        id: 'adjusted_price',
        defaultMessage: 'Adjusted',
        description: 'Price details',
    },
    priceDamaged: {
        id: 'damaged_price',
        defaultMessage: 'Damaged cash',
        description: 'Price details',
    },
    missing: {
        id: 'missing',
        defaultMessage: 'Missing',
        description: 'Missing',
    },
    refused: {
        id: 'refused',
        defaultMessage: 'Refused',
        description: 'Refused',
    },
    additional: {
        id: 'additional',
        defaultMessage: 'Additional',
        description: 'Additional',
    },
    discount: {
        id: 'discount',
        defaultMessage: `
            {count, plural,
                =0 {Discounts}
                one {Discount}
                other {Discounts}
            }
        `,
        description: 'Discount',
    },
    coupon: {
        id: 'coupon',
        defaultMessage: `
            {count, plural,
                =0 {Coupons}
                one {Coupon}
                other {Coupons}
            }
        `,
        description: 'Coupon',
    },
    method: {
        id: 'method',
        defaultMessage: 'Method',
        description: 'method',
    },
    today: {
        id: 'today',
        defaultMessage: 'Today',
        description: 'today',
    },
    thisWeek: {
        id: 'this_week',
        defaultMessage: 'This week',
        description: 'This week',
    },
    late: {
        id: 'late',
        defaultMessage: 'Late',
        description: 'Late',
    },
    gps: {
        id: 'gps',
        defaultMessage: 'GPS',
        description: 'GPS',
    },
    overTwoHoursLate: {
        id: 'over_two_hours_late',
        defaultMessage: 'Late > 2h',
        description: 'Over two hours late',
    },
    deleteConfirm: {
        id: 'delete_confirm',
        defaultMessage: 'Are you sure you want to delete this ?',
        description: 'Description text when deleting an item (usually displayed in a pop confirm)',
    },
    save: {
        id: 'save',
        defaultMessage: 'Save',
        description: 'Save',
    },
    cancel: {
        id: 'cancel',
        defaultMessage: 'Cancel',
        description: 'Cancel',
    },
    details: {
        id: 'details',
        defaultMessage: 'Details',
        description: 'Details',
    },
    textMessage: {
        id: 'textMessage',
        defaultMessage: 'Text message',
        description: 'Text message',
    },
    message: {
        id: 'message',
        defaultMessage: 'Message',
        description: 'Message',
    },
    payment: {
        id: 'payment',
        defaultMessage: `
            {count, plural,
                =0 {Payments}
                one {Payment}
                other {Payments}
            }
        `,
        description: 'Payment',
    },
    vehicle: {
        id: 'vehicle',
        defaultMessage: 'Vehicle',
        description: 'Vehicle',
    },
    licensePlate: {
        id: 'license_plate',
        defaultMessage: 'License plate',
        description: 'Vehicle license plate',
    },
    anomalyOnDate: {
        id: 'anomaly.date',
        defaultMessage: 'Anomaly on {date} at {time}',
        description: 'Anomaly title with date and time',
    },
    agency: {
        id: 'agency',
        defaultMessage: `
            {count, plural,
                =0 {Agencies}
                one {Agency}
                other {Agencies}
            }
        `,
        description: 'Agencie',
    },
    organization: {
        id: 'organization',
        defaultMessage: `
            {count, plural,
                =0 {Organizations}
                one {Organization}
                other {Organizations}
            }
        `,
        description: 'Organization',
    },
    none: {
        id: 'none',
        defaultMessage: 'None',
        description: 'None',
    },
});
