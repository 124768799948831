import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Row, Col, Popconfirm, Button, Icon, Collapse } from 'antd';

import * as OrganizationsActions from '../../store/actions/organizations';
import * as PermissionsActions from '../../store/actions/permissions';
import * as RolesActions from '../../store/actions/roles';
import { Role, PermissionRight } from '../../store/api/types';
import { getRoles, getDeletes } from '../../store/reducers/roles';

import Header from '../../components/header/Header';
import Content from '../../components/Content';
import HeaderNav from '../../components/header/HeaderNav';
import MainMenuMessages from '../../components/MainMenu.messages';
import GenericMessages from '../../locale/Generic.messages';
import { IconAngle } from '../../components/icons';

import '../../assets/styles/Roles.less';
import { RequestState } from '../../store/reducers';
import { RoleTableForm, RoleCreate } from '.';
import { Can } from '../../components/auth';
import { isUserAllowed } from '../../utils/permissions';
import { getUser, AuthUser } from '../../store/reducers/auth';

interface RolesProps extends InjectedIntlProps, RouteComponentProps {
    deleteRole: typeof RolesActions.del;
    deletes: RequestState;
    fetchOrganizations: typeof OrganizationsActions.list;
    fetchPermissions: typeof PermissionsActions.list;
    fetchRoles: typeof RolesActions.list;
    roles: Role[];
    user: AuthUser;
}

class Roles extends React.Component<RolesProps> {
    public componentDidMount() {
        const { fetchOrganizations, fetchPermissions, user } = this.props;
        this.fetchRoles();
        fetchPermissions();

        if (isUserAllowed(user, 'su', PermissionRight.write)) {
            fetchOrganizations();
        }
    }

    public componentDidUpdate(prevProps: RolesProps) {
        const { deletes } = this.props;

        if (prevProps.deletes.loading && !deletes.loading && deletes.success) {
            this.fetchRoles();
        }
    }

    public onDeleteConfirm = (id: Role['id'], e: React.MouseEvent<any> | undefined) => {
        if (e) {
            e.stopPropagation();
        }
        this.props.deleteRole(id);
    }

    public onClickDeleteButton = (e: React.MouseEvent<any>) => {
        e.stopPropagation();
    }

    public fetchRoles = () => {
        this.props.fetchRoles();
    }

    public renderPanelIcon = ({ isActive }: any) => <IconAngle direction={isActive ? 'bottom' : 'right'} />;

    public render() {
        const { intl, roles } = this.props;
        const headerLinks = {
            [location.pathname]: intl.formatMessage(MainMenuMessages.roles),
        };

        return (
            <>
                <Header>
                    <HeaderNav links={headerLinks} />
                </Header>
                <Content>
                    <Collapse
                        bordered={false}
                        className="roles-collapse"
                    >
                        {roles.map((role: Role, index: number) => (
                            <Collapse.Panel
                                header={
                                    <Row
                                        type="flex"
                                        justify="space-between"
                                        align="middle"
                                        style={{ flex: '1 1 auto' }}
                                    >
                                        <Col>
                                            <Can edit="su">
                                                <small className="role-organization">
                                                    {role.organization ? role.organization.name : null}
                                                </small>
                                            </Can>
                                            {role.name}
                                        </Col>
                                        <Can edit="roles">
                                            {role.removable ? (
                                                <Col>
                                                    <Popconfirm
                                                        title={<FormattedMessage {...GenericMessages.deleteConfirm} />}
                                                        onConfirm={this.onDeleteConfirm.bind(null, role.id)}
                                                        placement="leftTop"
                                                    >
                                                        <Button
                                                            onClick={this.onClickDeleteButton}
                                                            shape="circle"
                                                            size="small"
                                                            type="danger"
                                                        >
                                                            <Icon type="delete" />
                                                        </Button>
                                                    </Popconfirm>
                                                </Col>
                                            ) : null}
                                        </Can>
                                    </Row>
                                }
                                key={`${index}`}
                            >
                                <RoleTableForm
                                    onUpdateSuccess={this.fetchRoles}
                                    role={role}
                                />
                            </Collapse.Panel>
                        ))}
                        <Can edit="roles">
                            <RoleCreate
                                onCreateSuccess={this.fetchRoles}
                            />
                        </Can>
                    </Collapse>
                </Content>
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
    roles: getRoles(state),
    deletes: getDeletes(state),
    user: getUser(state),
});

export default injectIntl(connect(
    mapStateToProps,
    {
        deleteRole: RolesActions.del,
        fetchOrganizations: OrganizationsActions.list,
        fetchPermissions: PermissionsActions.list,
        fetchRoles: RolesActions.list,
    },
)(Roles));
