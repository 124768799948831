import * as React from 'react';
import { Menu, Icon, Dropdown, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import genericMessages from '../locale/Generic.messages';

import * as AuthActions from '../store/actions/auth';
import { getUser, AuthUser } from '../store/reducers/auth';

import '../assets/styles/UserMenu.less';

import { getFullName } from '../utils';
import { IconAngle } from './icons';

interface UserMenuProps {
    logout: () => void;
    user: AuthUser;
}

class UserMenu extends React.Component<UserMenuProps> {
    public onClickLogout = () => {
        this.props.logout();
    }

    public render() {
        const { user } = this.props;
        const menu = (
            <Menu>
                <Menu.Item key="0" onClick={this.onClickLogout}>
                    <FormattedMessage {...genericMessages.logout} />
                </Menu.Item>
            </Menu>
        );

        return (
            <div id="user-menu" role="listbox" tabIndex={0}>
                <Spin spinning={!user.isConnected}>
                    <Dropdown overlay={menu} trigger={['click']}>
                        <p>
                            <span className="organization-name">
                                <span>
                                    {user.organization ? user.organization.name : ''}
                                </span>
                                <IconAngle direction="bottom" />
                            </span>
                            <span className="user-name">
                                <span>- {getFullName(user.firstname, user.lastname)}</span>
                            </span>
                            <Icon type="compass" />
                        </p>
                    </Dropdown>
                </Spin>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    user: getUser(state),
});

export default connect(
    mapStateToProps,
    { logout: AuthActions.logout },
)(UserMenu);
