import { Action } from 'redux';
import { delay } from 'redux-saga';
import { call, takeLatest, put } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import * as reduxActions from '../actions/customers';
import * as reduxTypes from '../types/customers';
import * as api from '../api/customers';
import { SearchPaginationQuery } from '../api';
import { arrayOfCustomers, customer } from '../schema';
import { IdAction } from '../actions';
import { Customer } from '../api/types';

export function* listSaga(action: Action & SearchPaginationQuery) {
    try {
        yield delay(action.throttling || 0);
        const { items, ...rest } = yield call(api.list, action);
        const normalizedItems = normalize(items, arrayOfCustomers);

        return yield put(reduxActions.listSuccess({
            ...normalizedItems,
            ...rest,
        }));
    } catch (error) {
        return yield put(reduxActions.listFailed(error));
    }
}

export function* detailsSaga(action: IdAction<Customer['id']>) {
    try {
        const response = yield call(api.details, action.id);
        const normalizedResponse = normalize(response, customer);

        return yield put(reduxActions.detailsSuccess(normalizedResponse, action.id));
    } catch (error) {
        return yield put(reduxActions.detailsFailed(error, action.id));
    }
}

export default function* customersSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.DETAILS, detailsSaga);
}
