import { Action } from 'redux';
import { delay } from 'redux-saga';
import { call, takeLatest, put } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import * as reduxActions from '../actions/deliveryMen';
import * as reduxTypes from '../types/deliveryMen';
import * as api from '../api/deliveryMen';
import { SearchQuery } from '../api';
import { IdAction } from '../actions';
import { DeliveryMan } from '../api/types';
import { deliveryMan, arrayOfDeliveryMen } from '../schema';

export function* listSaga(action: Action & SearchQuery) {
    try {
        yield delay(action.throttling || 0);
        const { items, ...rest } = yield call(api.list, action);
        const normalizedItems = normalize(items, arrayOfDeliveryMen);

        return yield put(reduxActions.listSuccess({
            ...normalizedItems,
            ...rest,
        }));
    } catch (error) {
        return yield put(reduxActions.listFailed(error));
    }
}

export function* detailsSaga(action: IdAction<DeliveryMan['id']>) {
    try {
        const response = yield call(api.details, action.id);
        const normalizedResponse = normalize(response, deliveryMan);

        return yield put(reduxActions.detailsSuccess(normalizedResponse, action.id));
    } catch (error) {
        return yield put(reduxActions.detailsFailed(error, action.id));
    }
}

export function* statsSaga(action: IdAction<DeliveryMan['id']>) {
    try {
        const response = yield call(api.stats, action.id);
        return yield put(reduxActions.statsSuccess(response, action.id));
    } catch (error) {
        return yield put(reduxActions.statsFailed(error, action.id));
    }
}

export default function* deliveryMenSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.DETAILS, detailsSaga);
    yield takeLatest(reduxTypes.STATS, statsSaga);
}
