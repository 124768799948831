import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';

import * as DeliveryMenActions from '../../store/actions/deliveryMen';
import { SearchQueryComponentState, SearchPaginationQuery } from '../../store/api';
import { DeliveryMan } from '../../store/api/types';
import { getDeliveryMen, DeliveryMenListState, getPagination } from '../../store/reducers/deliveryMen';

import Header from '../../components/header/Header';
import Content from '../../components/Content';
import ListMessages from '../../locale/List.messages';
import HeaderNav from '../../components/header/HeaderNav';
import { ListItemTitle, List } from '../../components/list';
import MainMenuMessages from '../../components/MainMenu.messages';
import messages from './DeliveryMenList.messages';
import { getFullName } from '../../utils';
import EmptyStateText from '../../components/typography/EmptyStateText';
import { PaginationRequestState } from '../../store/reducers/_generics';
import { getRoute, RoutePathName } from '../../routes';
import { Languages } from '../../components/IntlProvider';
import { SkeletonRow, Bone } from '../../components/skeleton';

interface DeliveryMenProps extends InjectedIntlProps, RouteComponentProps {
    fetchDeliveryMen: typeof DeliveryMenActions.list;
    deliveryMen: DeliveryMan[];
    pagination: PaginationRequestState;
}

class DeliveryMen extends React.Component<DeliveryMenProps, SearchQueryComponentState> {
    public state: SearchQueryComponentState = {};
    private columns: Array<ColumnProps<DeliveryMan>> = [
        {
            dataIndex: 'id',
            render: (text, deliveryMan, index) => (
                <ListItemTitle
                    className="delivery-man-list-item-title"
                    index={(this.props.pagination.pageSize * this.props.pagination.page) + index + 1}
                    text={
                        getFullName(deliveryMan.firstname, deliveryMan.lastname).toLowerCase() ?
                            ` ${getFullName(deliveryMan.firstname, deliveryMan.lastname).toLowerCase()}` : (
                            <EmptyStateText>
                                <FormattedMessage {...messages.noName} />
                            </EmptyStateText>
                        )
                    }
                />
            ),
        }, {
            dataIndex: 'reference',
        },
    ];

    public componentDidMount() {
        this.fetchData();
    }

    public skeletonItemRenderer(index: number) {
        return (
            <SkeletonRow key={index}>
                <Bone style={{ flex: '0 1 116px' }} />
                <Bone style={{ background: 'none', flex: '0 1 200px' }} />
                <Bone style={{ flex: '0 1 58px' }} />
                <Bone style={{ flex: '0 1 10px' }} />
            </SkeletonRow>
        );
    }

    public render() {
        const { deliveryMen, intl, pagination } = this.props;
        const headerLinks = {
            [location.pathname]: intl.formatMessage(ListMessages.contentHeaderNavItemList),
        };

        return (
            <>
                <Header>
                    <HeaderNav links={headerLinks} />
                </Header>
                <Content>
                    <List<DeliveryMan>
                        columns={this.columns}
                        dataState={{
                            data: deliveryMen,
                            loading: pagination.loading,
                            ...pagination,
                        }}
                        listTitle={<FormattedMessage {...MainMenuMessages.deliveryMen} />}
                        onChangePage={this.onChangePage}
                        onRowClick={this.onRowClick}
                        onSearch={this.onSearch}
                        rowKey={this.rowKey}
                        skeletonRenderer={this.skeletonItemRenderer}
                    />
                </Content>
            </>
        );
    }

    private rowKey = (record: DeliveryMan) => record.id!.toString();

    private onChangePage = (page: number) => {
        const { search } = this.state;

        this.fetchData({
            search,
            page: page - 1,
        });
    }

    private onRowClick = (record: DeliveryMan) => {
        const { history, intl } = this.props;
        history.push(getRoute(intl.locale as Languages, RoutePathName.deliveryMan, { id: record.id! }));
    }

    private onSearch = (search: string) => {
        this.setState({ search });
        this.fetchData({
            search,
            throttling: 200,
        });
    }

    private fetchData = (query?: SearchPaginationQuery) => {
        const { page, pageSize } = this.props.pagination;
        this.props.fetchDeliveryMen({
            page,
            pageSize,
            ...query,
        });
    }
}

const mapStateToProps = (state: any) => ({
    deliveryMen: getDeliveryMen(state),
    pagination: getPagination(state),
});

export default injectIntl(connect(
    mapStateToProps,
    { fetchDeliveryMen: DeliveryMenActions.list },
)(DeliveryMen));
