// Events

export enum DeliveryManEvent {
    login         = 'deliveryMan.login',
    logout        = 'deliveryMan.logout',
}

export const getDeliveryManEventMessage = (event?: AllEvents) => {
    switch (event) {
        case DeliveryManEvent.login: return 'deliveryManEventLogin';
        case DeliveryManEvent.logout: return 'deliveryManEventLogout';
        default:
            return 'deliveryManEventUnknown';
    }
};

export enum DeliveryTourEvent {
    start         = 'deliveryTour.start',
    end           = 'deliveryTour.end',
    cashCount     = 'deliveryTour.cashCount',
    eventStart    = 'deliveryTour.event.start',
    eventEnd      = 'deliveryTour.event.end',
}

export const getDeliveryTourEventMessage = (event?: AllEvents) => {
    switch (event) {
        case DeliveryTourEvent.start: return 'deliveryTourEventStart';
        case DeliveryTourEvent.end: return 'deliveryTourEventEnd';
        case DeliveryTourEvent.cashCount: return 'deliveryTourEventCashCount';
        case DeliveryTourEvent.eventStart: return 'deliveryTourEventEventStart';
        case DeliveryTourEvent.eventEnd: return 'deliveryTourEventEventEnd';
        default:
            return 'deliveryTourEventUnknown';
    }
};

export enum DeliveryEvent {
    start                 = 'delivery.start',
    arrived               = 'delivery.arrived',
    signature             = 'delivery.signature',
    customerComment       = 'delivery.customerComment',
    deliveryManComment    = 'delivery.deliveryManComment',
    validated             = 'delivery.validated',
    failed                = 'delivery.failed',
    cancel                = 'delivery.cancel',

    // Payment
    preorder              = 'delivery.preorder',
    discount              = 'delivery.discount',
    payment               = 'delivery.payment',
    removePayment         = 'delivery.removePayment',

    // Update
    updateAddressLocation = 'delivery.updateAddressLocation',
}

export const getDeliveryEventMessage = (event?: AllEvents) => {
    switch (event) {
        case DeliveryEvent.start: return 'deliveryEventStart';
        case DeliveryEvent.arrived: return 'deliveryEventArrived';
        case DeliveryEvent.signature: return 'deliveryEventSignature';
        case DeliveryEvent.customerComment: return 'deliveryEventCustomerComment';
        case DeliveryEvent.deliveryManComment: return 'deliveryEventDeliveryManComment';
        case DeliveryEvent.validated: return 'deliveryEventValidated';
        case DeliveryEvent.failed: return 'deliveryEventFailed';
        case DeliveryEvent.cancel: return 'deliveryEventCancel';
        case DeliveryEvent.preorder: return 'deliveryEventPreorder';
        case DeliveryEvent.discount: return 'deliveryEventDiscount';
        case DeliveryEvent.payment: return 'deliveryEventPayment';
        case DeliveryEvent.removePayment: return 'deliveryEventRemovePayment';
        case DeliveryEvent.updateAddressLocation: return 'deliveryEventUpdateAddressLocation';
        default:
            return 'deliveryEventUnknown';
    }
};

export enum TransferEvent {
    init          = 'transfer.init',
    rejected      = 'transfer.rejected',
    accepted      = 'transfer.accepted',
    completed     = 'transfer.completed',
}

export const getTransferEventMessage = (event?: AllEvents) => {
    switch (event) {
        case TransferEvent.init: return 'transferEventInit';
        case TransferEvent.rejected: return 'transferEventRejected';
        case TransferEvent.accepted: return 'transferEventAccepted';
        case TransferEvent.completed: return 'transferEventCompleted';
        default:
            return 'transferEventUnknown';
    }
};

export enum VehicleEvent {
    change        = 'vehicle.change',
    damaged       = 'vehicle.damaged',
    missing       = 'vehicle.missing',
    update        = 'vehicle.update',
}

export const getVehicleEventMessage = (event?: AllEvents) => {
    switch (event) {
        case VehicleEvent.change:   return 'vehicleEventChange';
        case VehicleEvent.damaged:   return 'vehicleEventDamaged';
        case VehicleEvent.missing:   return 'vehicleEventMissing';
        case VehicleEvent.update:   return 'vehicleEventUpdate';
        default:
            return 'vehicleEventUnknown';
    }
};

export enum ItemEvent {
    add           = 'item.add',
    remove        = 'item.remove',
    ready         = 'item.ready',
    reset         = 'item.reset',
    missing       = 'item.missing',
    damaged       = 'item.damaged',
    refused       = 'item.refused',
    delivered     = 'item.delivered',
    return        = 'item.return',
}

export const getItemEventMessage = (event?: AllEvents) => {
    switch (event) {
        case ItemEvent.add:       return 'itemEventAdd';
        case ItemEvent.remove:    return 'itemEventRemove';
        case ItemEvent.ready:     return 'itemEventReady';
        case ItemEvent.reset:     return 'itemEventReset';
        case ItemEvent.missing:   return 'itemEventMissing';
        case ItemEvent.damaged:   return 'itemEventDamaged';
        case ItemEvent.refused:   return 'itemEventRefused';
        case ItemEvent.delivered: return 'itemEventDelivered';
        case ItemEvent.return:    return 'itemEventReturn';
        default:
            return 'itemEventUnknown';
    }
};

export enum NoteEvent {
    update = 'note.update',
    delete = 'note.delete',
}

export enum ProductEvent {
    prepared      = 'product.prepared',
    missing       = 'product.missing',
    damaged       = 'product.damaged',
    removeMissing = 'product.removeMissing',
    removeDamaged = 'product.removeDamaged',
}

export type AllEvents = DeliveryManEvent | DeliveryTourEvent | DeliveryEvent | VehicleEvent |
                        ItemEvent | NoteEvent | ProductEvent | TransferEvent;

// Enums

export enum PermissionRight {
    read = 'r',
    write = 'w',
    disabled = 'disabled',
}

export enum DeliveryTourStatus {
    pending       = 'pending',
    ready         = 'ready',
    started       = 'started',
    finished      = 'finished',
}

export const getDeliveryTourStatus = (status: DeliveryTourStatus) => {
    switch (status) {
        case DeliveryTourStatus.pending: return 'deliveryTourStatusPending';
        case DeliveryTourStatus.ready: return 'deliveryTourStatusReady';
        case DeliveryTourStatus.started: return 'deliveryTourStatusStarted';
        case DeliveryTourStatus.finished: return 'deliveryTourStatusFinished';
        default:
            return 'deliveryTourStatusUnknown';
    }
};

export enum DeliveryStatus {
    pending       = 'pending',
    ready         = 'ready',
    postponed     = 'postponed',
    started       = 'started',
    arrived       = 'arrived',
    refused       = 'refused',
    partial       = 'partial',
    delivered     = 'delivered',
    failed        = 'failed',
    transferred   = 'transferred',
}

export const getDeliveryStatus = (status?: DeliveryStatus) => {
    switch (status) {
        case DeliveryStatus.pending: return 'deliveryStatusPending';
        case DeliveryStatus.ready: return 'deliveryStatusReady';
        case DeliveryStatus.postponed: return 'deliveryStatusPostponed';
        case DeliveryStatus.started: return 'deliveryStatusStarted';
        case DeliveryStatus.arrived: return 'deliveryStatusArrived';
        case DeliveryStatus.refused: return 'deliveryStatusRefused';
        case DeliveryStatus.partial: return 'deliveryStatusPartial';
        case DeliveryStatus.delivered: return 'deliveryStatusDelivered';
        case DeliveryStatus.failed: return 'deliveryStatusFailed';
        case DeliveryStatus.transferred: return 'deliveryStatusTransferred';
        default:
            return 'deliveryStatusUnknown';
    }
};

export const getSimplerDeliveryStatus = (status: DeliveryStatus) => {
    switch (status) {
        case DeliveryStatus.failed:
        case DeliveryStatus.refused:
            return 'danger';
        case DeliveryStatus.postponed:
        case DeliveryStatus.partial:
        case DeliveryStatus.transferred:
            return 'warning';
        case DeliveryStatus.delivered:
            return 'success';
        default:
            return 'default';
    }
};

export const getDeliveryStatusColor = (status: DeliveryStatus) => {
    switch (status) {
        case DeliveryStatus.started:
            return 'blue';
        case DeliveryStatus.failed:
        case DeliveryStatus.refused:
            return 'red';
        case DeliveryStatus.postponed:
        case DeliveryStatus.partial:
        case DeliveryStatus.transferred:
            return 'orange';
        case DeliveryStatus.delivered:
            return 'green';
        default:
            return 'primary';
    }
};

export enum DeliveryType {
    basic         = 'basic',
    thirdParty    = 'thirdParty',
    pickUp        = 'pickUp',
    visit         = 'visit',
}

export const getDeliveryType = (type?: DeliveryType) => {
    switch (type) {
        case DeliveryType.basic: return 'deliveryTypeBasic';
        case DeliveryType.thirdParty: return 'deliveryTypeThirdParty';
        case DeliveryType.pickUp: return 'deliveryTypePickUp';
        case DeliveryType.visit: return 'deliveryTypeVisit';
        default:
            return 'deliveryTypeUnknown';
    }
};

export enum DeliveryFailureReason {
    postponed     = 'postponed',
    absent        = 'absent',
    refused       = 'refused',
    other         = 'other',
}

export enum DeliveryItemType {
    product       = 'product',
    parcel        = 'parcel',
    extra         = 'extra',
    other         = 'other',
}

export const getDeliveryItemType = (type?: DeliveryItemType) => {
    switch (type) {
        case DeliveryItemType.product: return 'deliveryItemTypeProduct';
        case DeliveryItemType.parcel: return 'deliveryItemTypeParcel';
        case DeliveryItemType.extra: return 'deliveryItemTypeExtra';
        case DeliveryItemType.other: return 'deliveryItemTypeOther';
        default:
            return 'deliveryItemTypeUnknown';
    }
};

export enum ParcelType {
    fresh         = 'fresh',
    frozen        = 'frozen',
    grocery       = 'grocery',
    multiDelivery = 'multiDelivery',
    other         = 'other',
}

export const getParcelType = (type?: ParcelType) => {
    switch (type) {
        case ParcelType.fresh: return 'parcelTypeFresh';
        case ParcelType.frozen: return 'parcelTypeFrozen';
        case ParcelType.grocery: return 'parcelTypeGrocery';
        case ParcelType.multiDelivery: return 'parcelTypeMultiDelivery';
        case ParcelType.other: return 'parcelTypeOther';
        default:
            return 'parcelTypeUnknown';
    }
};

export enum DeliveryItemSource {
    order         = 'order',
    preorder      = 'preorder',
    additional    = 'additional',
}

export enum DeliveryItemStatus {
    pending               = 'pending',
    damaged               = 'damaged',
    ready                 = 'ready',
    missing               = 'missing',
    damagedDuringDelivery = 'damagedDuringDelivery',
    lost                  = 'lost',
    refused               = 'refused',
    delivered             = 'delivered',
    returned              = 'returned',
}

export const getDeliveryItemStatus = (status?: DeliveryItemStatus) => {
    switch (status) {
        case DeliveryItemStatus.pending: return 'deliveryItemStatusPending';
        case DeliveryItemStatus.damaged: return 'deliveryItemStatusDamaged';
        case DeliveryItemStatus.ready: return 'deliveryItemStatusReady';
        case DeliveryItemStatus.missing: return 'deliveryItemStatusMissing';
        case DeliveryItemStatus.damagedDuringDelivery: return 'deliveryItemStatusDamagedDuringDelivery';
        case DeliveryItemStatus.lost: return 'deliveryItemStatusLost';
        case DeliveryItemStatus.refused: return 'deliveryItemStatusRefused';
        case DeliveryItemStatus.delivered: return 'deliveryItemStatusDelivered';
        case DeliveryItemStatus.returned: return 'deliveryItemStatusReturned';
        default:
            return 'deliveryItemStatusUnknown';
    }
};

export const getDeliveryItemStatusColor = (status: DeliveryItemStatus) => {
    switch (status) {
        case DeliveryItemStatus.ready:
            return 'blue';
        case DeliveryItemStatus.damaged:
        case DeliveryItemStatus.refused:
        case DeliveryItemStatus.damagedDuringDelivery:
        case DeliveryItemStatus.lost:
        case DeliveryItemStatus.returned:
            return 'red';
        case DeliveryItemStatus.delivered:
            return 'green';
        default:
            return '';
    }
};

export enum DeliveryItemAnomalyType {
    damaged       = 'damaged',
    missing       = 'missing',
    labelError    = 'labelError',
    other         = 'other',
}

export const getDeliveryItemAnomalyType = (type?: DeliveryItemAnomalyType) => {
    switch (type) {
        case DeliveryItemAnomalyType.damaged: return 'deliveryItemAnomalyTypeDamaged';
        case DeliveryItemAnomalyType.missing: return 'deliveryItemAnomalyTypeMissing';
        case DeliveryItemAnomalyType.labelError: return 'deliveryItemAnomalyTypeLabelError';
        case DeliveryItemAnomalyType.other: return 'deliveryItemAnomalyTypeOther';
        default:
            return 'deliveryItemAnomalyTypeUnknown';
    }
};

export enum VehicleAnomalyType {
    damaged       = 'damaged',
    missing       = 'missing',
    other         = 'other',
}

export enum HousingType {
    house       = 'house',
    cityHouse   = 'cityHouse',
    subHouse    = 'subHouse',
    apartment   = 'apartment',
}

export enum ScheduledEventType {
    login       = 'login',
    start       = 'start',
    preparation = 'preparation',
    pause       = 'pause',
    end         = 'end',
    logout      = 'logout',
    unknown     = 'unknown',
}

export enum DiscountType {
    fidelity    = 'fidelity',
    offer       = 'offer',
    other       = 'other',
}

export enum PaymentMethodType {
    prepaid              = 'prepaid',
    paidByThirdParty     = 'paidByThirdParty',
    check                = 'check',
    cash                 = 'cash',
    creditCard           = 'creditCard',
    deferredCheck        = 'deferredCheck',
    credit               = 'credit',
    prepaidCard          = 'prepaidCard',
    draft                = 'draft',
    transfer             = 'transfer',
    restaurantCoupon     = 'restaurantCoupon',
    paypal               = 'paypal',
}

export enum SensorType {
    temperature = 'temperature',
    other       = 'other',
}

export enum SensorEmplacementType {
    freshtorage = 'freshtorage',
    freezer     = 'freezer',
    other       = 'other',
}

export type AnomalyType = DeliveryItemAnomalyType | VehicleAnomalyType;

// Models

export interface NeerbyConf {
    appUuid: string;
    apiKey: string;
}

export interface Tag {
    code?: string;
    color?: string;
    label: string;
}

export interface Provider {
    type: string;
    config?: { [key: string]: string };
}

export interface Organization {
    id?: string;
    name: string;
    reference?: string;
    mapKey?: string;
    apiKey?: string;
    neerby?: NeerbyConf;
    provider?: Provider;
    permissions?: { [feature: string]: PermissionRight };
    config?: {
        messages: {
            enabled?: string;
            provider?: string;
            apiKey?: string;
            sender?: string;
        };
    };

}

export interface Role {
    id?: string;
    name: string;
    removable?: true;
    permissions?: { [feature: string]: PermissionRight };
    organization?: Organization;
}

export interface Permission {
    id?: string;
    reference: string;
    name?: string;
    organization?: Organization;
}

export interface User {
    id?: string;
    reference: string;
    email?: string;
    firstname?: string;
    lastname?: string;
    permissions?: { [feature: string]: PermissionRight };
    scope: {
        organizations?: Organization[],
        agencies?: Agency[],
        deliveryMen?: DeliveryMan[],
    };
    role?: Role;
    organization?: Organization;
}

export interface Image {
    id?: string;
    reference: string;
    fileExists: boolean;
    filename: string;
    url: string;
    mimetype?: string;
    organization?: Organization;

}

export interface Anomaly {
    id?: string;
    type: AnomalyType;
    registeredAt: string;
    comment?: string;
    imageReference?: string;
    imageUrl?: string;
}

export interface DeliveryItemAnomaly extends Anomaly {
    type: DeliveryItemAnomalyType;
}

export interface Parcel {
    id?: string;
    reference: string;
    label?: string;
    number?: number;
    type: ParcelType;
    tracking?: string;
    trackingPartner?: string;
}

export interface ProductPrice {
    price: number;
    withoutTax: number;
}

export interface Product {
    id?: string;
    reference: string;
    name: number;
    label: number;
    barCodes?: string[];
    price?: ProductPrice;
    organization?: Organization;
    state?: {
        stock: number;
        ordered: number;
        prepared: number;
        anomalies: DeliveryItemAnomaly[];
        sold: {
            // [source: DeliveryItemSource]: number;
            [source: string]: number;
        },
    };
}

export interface Coordinates {
    id?: string;
    latitude: number;
    longitude: number;
    precision?: string;
    speedIndex?: string;
}

export interface Agency {
    id?: string;
    reference: string;
    name: string;
    address?: Address;
    coordinates?: Coordinates;
    organization?: Organization;
}

export interface Price {
    [key: string]: number;
    initialPrice: number;
    initialWithoutTax: number;
    price: number;
    withoutTax: number;
    adjusted: number;
    discounts: number;
    coupons: number;
    damaged: number;
    missing: number;
    refused: number;
    additional: number;
}

export interface Address {
    id?: string;
    sourceId?: string;
    civility?: string;
    firstname?: string;
    lastname?: string;
    type?: HousingType;
    number?: string;
    street: string;
    zipcode: string;
    city: string;
    country: string;
    sup1?: string;
    sup2?: string;
    directives?: string;
    floor?: string;
    elevator?: boolean;
    intercom?: boolean;
    digicode?: string;
    codeIris?: string;
    codeInsee?: string;
    distanceToParkingSpot?: number;
    raw: string;
    coordinates?: Coordinates;
}

export interface Discount {
    label?: string;
    reference: string;
    type: DiscountType;
    enabled: boolean;
    amount: number;
    valid?: {
        from?: Date;
        to?: Date;
    };
    usedAt?: Date;
}

export interface NeerbyTag {
    os: string;
    osVersion: string;
    appId: string;
    appVersion: string;
    sdkVersion: string;
    deviceUid: string;
    date: string;
    queuedDate: string;
    ingestionDate: string;
    location: Coordinates;
    battery: {
        state: string;
        level: string;
    };
    type: AllEvents;
    data?: {
        delivery?: string;
        sequence: string;
        parcel?: string;
        item?: string;
        failureReason?: string;
        deliveryMan?: string;
        deliveryTour?: string;
        comment?: string;
        id: string;
        imageReference?: string;
    };
}

export interface GeoJson {
    type: string;
    coordinates: Array<[ number, number ]>;
}

export interface Customer {
    id?: string;
    reference: string;
    civility?: string;
    firstname?: string;
    lastname?: string;
    emails: string[];
    phones: string[];
    addresses: Address[];
    discounts: Discount[];
    tags: Tag[];
    meta: { [key: string]: any };
    organization?: Organization;
    deliveries?: Delivery;
}

export interface DeliveryItem {
    id?: string;
    reference: string;
    label: string;
    barCodes?: number[];
    index?: number;
    type: DeliveryItemType;
    source: DeliveryItemSource;
    status: DeliveryItemStatus;
    isEditable: boolean;
    refusedReason?: string;
    anomaly?: DeliveryItemAnomaly;
    price?: ProductPrice;
    product?: Product['reference'];
    parcel?: Parcel['reference'];
}

export interface Delivery {
    id?: string;
    reference: string;
    date: string;
    startDate?: string;
    endDate?: string;
    type: DeliveryType;
    status: DeliveryStatus;
    failureReason?: DeliveryFailureReason;
    timeframe: {
        from?: string;
        to?: string;
    };
    address: Address;
    contact: {
        name?: string;
        email?: string;
        phone?: string;
    };
    skipPreparation: boolean;
    parcels: Parcel[];
    items: DeliveryItem[];
    discountReferences: string[];
    price?: Price;
    paymentMethods: string[];
    comment: {
        customer?: string;
        deliveryMan?: string;
    };
    preorderDate?: Date;
    payments: Payment[];
    signature: {
        registeredAt: string;
        imageReference?: string;
        imageUrl?: string;
    };
    meta: { [key: string]: string };
    tags: Tag[];
    attachedToDelivery?: Delivery['reference'];
    attachedDeliveries: Array<Delivery['reference']>;
    relatedDeliveryTours: Array<DeliveryTour['reference']>;
    organization?: Organization;
    deliveryTour?: DeliveryTour;
    agency?: Agency;
    partner?: Partner;
    customer?: Customer;
    neerbyTags?: NeerbyTag[];
    products?: Product[];
}

export interface DeliveryMan {
    id?: string;
    reference: string;
    firstname?: string;
    lastname?: string;
    organization?: Organization;
}

export interface ScheduledEvent {
    id?: string;
    sourceId?: string;
    type: ScheduledEventType;
    from: Date;
    to?: Date;
}

export interface DeliveryTour {
    id?: string;
    reference: string;
    label: string;
    date: string;
    startDate?: string;
    endDate?: string;
    status: DeliveryTourStatus;
    lastTag: {
        id?: string;
        sequence?: number;
        type?: string;
        receivedAt?: string;
        outOfSequence?: boolean;
    };
    live?: Coordinates & {
        lastUpdate: Date;
    };
    scheduledEvents: ScheduledEvent[];
    meta: { [key: string]: any };
    params: { [key: string]: any };
    organization?: Organization;
    agency?: Agency;
    vehicles?: Vehicle[];
    deliveries?: Delivery[];
    deliveryMen?: DeliveryMan[];
    customers?: Customer[];
    preparationTags?: NeerbyTag[];
}

export interface Partner {
    id?: string;
    reference: string;
    name: string;
    organization?: Organization;
}

export const getVehicleAnomalyType = (type?: VehicleAnomalyType) => {
    switch (type) {
        case VehicleAnomalyType.damaged: return 'vehicleAnomalyTypeDamaged';
        case VehicleAnomalyType.missing: return 'vehicleAnomalyTypeMissing';
        case VehicleAnomalyType.other: return 'vehicleAnomalyTypeOther';
        default:
            return 'vehicleAnomalyTypeUnknown';
    }
};

export interface VehicleAnomaly extends Anomaly {
    type: VehicleAnomalyType;
}

export interface Vehicle {
    id?: string;
    reference: string;
    licensePlate: string;
    organization?: Organization;
    agency?: Agency;
    meta: { [key: string]: string };
    anomalies?: VehicleAnomaly[];
    lastAnomaly?: VehicleAnomaly;
}

export interface PaymentMethod {
    id?: string;
    reference: string;
    name: string;
    meta: { [key: string]: any };
    organization?: Organization;
}

export interface Payment {
    id?: string;
    reference: string;
    method: string;
    amount: number;
    isEditable: boolean;
    meta: { [key: string]: any };
}

export interface Note {
    id?: string;
    reference: string;
    title: string;
    value: string;
    deliveryMan?: DeliveryMan;
    organization?: Organization;
}

// Analytics

export interface DeliveryManStats {
    totalDeliveryTour: number;
    totalDeliveries: number;
    lateDeliveries: number;
    criticalyLateDeliveries: number;
    gpsErrors: number;
    pdaErrors: number;
    sucessfullDeliveries: number;
}

export interface GlobalStats {
    totalDeliveryTour: number;
    totalDeliveries: number;
    lateDeliveries: number;
    criticalyLateDeliveries: number;
    gpsErrors: number;
    pdaErrors: number;
    sucessfullDeliveries: number;
    deliveryStatusCount: { [status: string]: number };
    counts: Array<{
        [key: string]: any;
        step: string;
        total: number;
        late: number;
        criticalyLate: number;
        gps: number;
        pda: number;
    }>;
}

// Sensors

export interface Sensor {
    reference: string;
    name: string;
    type: SensorType;
    subType: SensorEmplacementType;
}

export interface SensorValue {
    value: number | string;
    date: Date;
}

export type SensorData = Sensor & { values: SensorValue[] };

// Monitoring

export interface LoggedRequest {
    id?: string;
    uri?: string;
    method?: string;
    response?: { [key: string]: string };
    cached?: string;
    statusCode?: number;
    error?: string;
    time?: {
        // Cache
        cache: number;
        // Request.timingPhases
        wait: number;
        dns: number;
        tcp: number;
        firstByte: number;
        download: number;
        total: number;
    };
    options?: { [key: string]: string };
    meta?: { [key: string]: string };
}

export interface MonitoringSynthesis {
    requestOkCounter?: number;
    requestErrorCounter?: number;
    message?: string;
}
