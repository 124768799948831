import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Dropdown, Menu, Button, Empty } from 'antd';
import { ClickParam } from 'antd/lib/menu';
import {
    DeliveryToursListDateMode, getDateMode,
} from '../../store/reducers/deliveryTours';

import messages from './DeliveryTour.messages';
import ListMessages from '../../locale/List.messages';
import Header from '../../components/header/Header';
import Content from '../../components/Content';
import HeaderNav from '../../components/header/HeaderNav';
import { IconAngle } from '../../components/icons';
import RelativeDateWithTooltip from '../../components/RelativeDateWithTooltip';
import DeliveryTourListWrapper, { DeliveryTourListColumns } from './DeliveryTourListWrapper';

const getDateModeMessage = (dateMode: DeliveryToursListDateMode) => {
    switch (dateMode) {
        case DeliveryToursListDateMode.today: return <FormattedMessage {...messages.todaysDeliveryTours} />;
        case DeliveryToursListDateMode.yesterday: return <FormattedMessage {...messages.yesterdaysDeliveryTours} />;
        case DeliveryToursListDateMode.all: return <FormattedMessage {...messages.allDeliveryTours} />;
    }
};

interface DeliveryTourListProps extends InjectedIntlProps, RouteComponentProps {
    dateMode: DeliveryToursListDateMode;
}

class DeliveryTourList extends React.Component<DeliveryTourListProps> {
    public listWrapperRef = React.createRef<typeof DeliveryTourListWrapper>();

    public onClickSeeLastDeliveryTours = () => {
        if (this.listWrapperRef.current) {
            // @ts-ignore
            this.listWrapperRef.current.getWrappedInstance().fetchData({ dateMode: DeliveryToursListDateMode.all });
        }
    }

    public onChangeDate = (e: ClickParam) => {
        if (this.listWrapperRef.current) {
            // @ts-ignore
            this.listWrapperRef.current.getWrappedInstance().fetchData({
                dateMode: DeliveryToursListDateMode[e.key as DeliveryToursListDateMode],
            });
        }
    }

    public setColumns = (columns: DeliveryTourListColumns) => {
        const { dateMode } = this.props;

        if (dateMode === 'all') {
            const newColumns = [...columns];

            newColumns.splice(1, 0, {
                dataIndex: 'date',
                render: (d) => (
                    <RelativeDateWithTooltip
                        value={d}
                    />
                ),
                width: 116,
            });

            return newColumns;
        } else {
            return columns;
        }
    }

    public renderEmptyList = () => {
        const { dateMode } = this.props;
        return (
            <>
                <Empty
                    description={
                        dateMode !== 'all' ? (
                            <FormattedMessage {...ListMessages.noDataForThisDate} />
                        ) : undefined
                    }
                />
                {dateMode !== 'all' && (
                    <Button
                        onClick={this.onClickSeeLastDeliveryTours}
                        size="small"
                        style={{ margin: 24 }}
                        type="primary"
                    >
                        <FormattedMessage {...messages.seeLastDeliveryTours} />
                    </Button>
                )}
            </>
        );
    }

    public render() {
        const { dateMode, history, intl } = this.props;
        const headerLinks = {
            [location.pathname]: intl.formatMessage(ListMessages.contentHeaderNavItemList),
        };
        const dateSelectorMenu = (
            <Menu onClick={this.onChangeDate}>
                {Object.values(DeliveryToursListDateMode).map((mode: DeliveryToursListDateMode) => (
                    <Menu.Item
                        className={
                            dateMode === mode ?
                                'ant-dropdown-menu-item-selected' :
                                undefined
                        }
                        key={mode}
                    >
                        {getDateModeMessage(mode)}
                    </Menu.Item>
                ))}
            </Menu>
        );

        return (
            <>
                <Header>
                    <HeaderNav links={headerLinks} />
                </Header>
                <Content>
                    <DeliveryTourListWrapper
                        emptyStateRenderer={this.renderEmptyList}
                        fetchPayload={{ dateMode }}
                        history={history}
                        listTitle={(
                            <Dropdown overlay={dateSelectorMenu}>
                                <span>
                                    {getDateModeMessage(dateMode)} <IconAngle direction="bottom" />
                                </span>
                            </Dropdown>
                        )}
                        // @ts-ignore
                        ref={this.listWrapperRef}
                        setColumns={this.setColumns}
                    />
                </Content>
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
    dateMode: getDateMode(state),
});

export default injectIntl(connect(mapStateToProps)(DeliveryTourList));
