import { combineReducers, Reducer } from 'redux';
import * as reduxTypes from '../types/telecoms';

import { PaginatedListState, MainReducerState } from '.';
import constants from '../../utils/constants';

export interface TelecomsListState extends PaginatedListState<string> {}

export interface TelecomsState {
    readonly list: TelecomsListState;
}

const listInitialState: TelecomsListState = {
    count: 0,
    data: [],
    filteredCount: 0,
    loading: false,
    page: 0,
    pageSize: constants.PAGE_SIZE,
    totalCount: 0,
};

const list: Reducer<TelecomsListState> = (state = listInitialState, { type, data }) => {
    switch (type) {
        case reduxTypes.LIST:
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        case reduxTypes.LIST_SUCCESS: {
            return {
                ...state,
                data,
                loading: false,
            };
        }
        case reduxTypes.LIST_FAILED:
            return {
                ...state,
                loading: false,
                error: data,
            };

        default:
            return state;
    }
};

export default combineReducers<TelecomsState>({
    list,
});

export const getTelecoms = (state: MainReducerState) => state.telecoms.list;
