import * as React from 'react';
import { connect } from 'react-redux';
import ReactMapGL, { NavigationControl, FlyToInterpolator, ViewState } from 'react-map-gl';
import WebMercatorViewport from 'viewport-mercator-project';
import { getBounds } from 'geolib';
import debounce from 'lodash/debounce';

import { Coordinates } from '../../store/api/types';

import constants from '../../utils/constants';

import '../../assets/styles/Map.less';
import { MainReducerState } from '../../store/reducers';
import { getUi } from '../../store/reducers/ui';

const initialState = {
    viewport: {
        latitude: constants.COORDINATES.fr.latitude,
        longitude: constants.COORDINATES.fr.longitude,
        zoom: 5,
    },
    height: 400,
    width: 900,
};

export interface MapBasePropsChildrenParams {
    viewport: MapBaseState['viewport'];
    width: number;
    height: number;
}

interface MapBaseProps {
    children: ({
        viewport, width, height,
    }: MapBasePropsChildrenParams) => React.ReactNode;
    isSidebarCollapsed: boolean;
    locale: string;
    withNavigationControl?: boolean;
}

export type MapBaseState = typeof initialState;

class MapBase extends React.PureComponent<MapBaseProps, MapBaseState> {
    public state: MapBaseState = initialState;
    public isComponentMounted: boolean = false;
    public debouncedResize: () => void;

    constructor(props: MapBaseProps) {
        super(props);
        this.debouncedResize = debounce(this.resize, 500);
    }

    public componentDidMount() {
        this.isComponentMounted = true; // used because of debounce
        window.addEventListener('resize', this.debouncedResize, false);
        window.setTimeout(this.resize, 300);
    }

    public componentDidUpdate(prevProps: MapBaseProps) {
        const sider: HTMLDivElement | null = document.querySelector('#main-layout > .ant-layout-sider');
        const timeline: HTMLDivElement | null = document.querySelector('#delivery-tour-timeline');
        const siderWidth = sider!.offsetWidth;
        const timelineWidth = window.innerWidth < 768 ? 0 : timeline!.offsetWidth;

        const width =
            window.innerWidth - siderWidth - timelineWidth;

        if (this.state.width !== width) {
            debounce(this.resize, 100);
        }

        if (prevProps.isSidebarCollapsed !== this.props.isSidebarCollapsed) {
            window.setTimeout(() => {
                this.resize();
            }, 500);
        }
    }

    public componentWillUnmount() {
        this.isComponentMounted = false;
        window.removeEventListener('resize', this.debouncedResize, false);
    }

    public resize = () => {
        if (this.isComponentMounted) {
            const contentHeader: HTMLDivElement | null = document.querySelector('#content-header');
            const sider: HTMLDivElement | null = document.querySelector('#main-layout > .ant-layout-sider');
            const timeline: HTMLDivElement | null = document.querySelector('#delivery-tour-timeline');
            const siderWidth = sider!.offsetWidth;
            const timelineWidth = window.innerWidth < 768 ? 0 : timeline!.offsetWidth;

            const height =
                window.innerHeight -
                (contentHeader ? contentHeader.offsetHeight : constants.CONTENT_HEADER_HEIGHT);
            const width =
                window.innerWidth - siderWidth - timelineWidth;

            this.setState((prevState) => ({
                height,
                width,
            }));
        }
    }

    public onViewportChange = (viewport: ViewState) => {
        this.setState((prevState) => ({
            viewport: { ...prevState.viewport, ...viewport },
        }));
    }

    // public onMapLoad = (evt: any) => {
    //     const { locale } = this.props;

    //     // set locale on each map layer containing labels
    //     evt.target.getStyle().layers.filter(
    //         (layer: Layer) => /label/.test(layer.id),
    //     ).forEach((layer: Layer) => {
    //         evt.target.setLayoutProperty(layer.id, 'text-field', ['get', `name_${locale}`]);
    //     });
    // }

    public fitToBounds = (markers: Coordinates[]) => {
        const { width, height, viewport } = this.state;

        if (width === 0 || height === 0) {
            // prevent WebMercatorViewport from crashing if viewport values are wrong
            console.warn(`[fitToBounds] viewport error`);
            return;
        }

        const { minLng, minLat, maxLng, maxLat } = getBounds(markers);

        try {
            const { longitude, latitude, zoom } = new WebMercatorViewport(viewport)
                .fitBounds([[minLng, minLat], [maxLng, maxLat]], {
                    padding: 20,
                    offset: [0, -100],
                });
            const newViewport = {
                ...viewport,
                longitude,
                latitude,
                zoom,
                transitionDuration: 2000,
                transitionInterpolator: new FlyToInterpolator(),
            };

            this.setState({ viewport: newViewport });
        } catch (error) {
            console.warn(`[fitToBounds] WebMercatorViewport error`, error);
        }
    }

    public render() {
        const { children, withNavigationControl } = this.props;
        const { viewport, width, height } = this.state;

        return (
            <div className="map">
                <ReactMapGL
                    viewState={viewport}
                    className="map"
                    mapStyle={constants.MAPBOX_STYLE}
                    mapboxApiAccessToken={constants.MAPBOX_TOKEN}
                    // @ts-ignore
                    // onLoad={this.onMapLoad}
                    onViewportChange={this.onViewportChange}
                    width={width}
                    height={height}
                >
                    {withNavigationControl &&
                        <div className="map-navigation-control">
                            <NavigationControl onViewportChange={this.onViewportChange} />
                        </div>
                    }
                    {children({ viewport, width, height })}
                </ReactMapGL>
            </div>
        );
    }
}

const mapStateToProps = (state: MainReducerState) => ({
    isSidebarCollapsed: getUi(state).isSidebarCollapsed,
});

export default connect(
    mapStateToProps,
    undefined,
    undefined,
    { forwardRef: true },
)(MapBase);
