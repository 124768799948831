import api from './_client';

export interface LoginPayload {
    username: string;
    password: string;
}

export const login = (payload: LoginPayload) => {
    return api.post('/admin/auth/login', payload, { params: { fetchScope: true } });
};

export const logout = () => {
    return api.post('/admin/auth/logout');
};

export const getUserDetails = () => {
    return api.get('/admin/auth/me', { params: {fetchScope: true}});
};
