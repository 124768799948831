import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14">
        <path fill="#BFBFBF" stroke="#BFBFBF" strokeWidth=".5" d="M2.884 1C1.85 1 1 1.813 1 2.8v8.4c0 .987.85 1.8 1.884 1.8h14.232C18.15 13 19 12.187 19 11.2V2.8c0-.987-.85-1.8-1.884-1.8H2.884zm.28 1.2h13.67L10.19 8.338c-.065.06-.315.06-.38 0L3.165 2.2zm-.908.83L6.67 7.107 2.257 11.17V3.03zm15.488 0v8.14L13.33 7.105l4.414-4.075zM7.574 7.945L8.933 9.2c.6.555 1.532.555 2.132 0l1.36-1.256 4.187 3.856H3.387l4.186-3.856z" />{/* tslint:disable:max-line-length */}
    </svg>
);

const IconEmail: React.SFC<IconProps> = (props) => (
    <Icon component={svg} className="anticon icon-email" {...props} />
);

export default IconEmail;
