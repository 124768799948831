import React, { Component } from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';

import { DeliveryTour, DeliveryTourStatus, Delivery, DeliveryStatus } from '../../store/api/types';

import { IconAgencyMarker, IconEndMarker } from '../../components/icons';
import messages from './DeliveryTourSider.messages';

interface DeliveryTourOverviewProps {
    deliveryTour?: DeliveryTour;
}

class DeliveryTourOverview extends Component<DeliveryTourOverviewProps> {

    public renderDuration = (startDate: number, endDate: number) => {
        const ms = endDate - startDate;
        const s = ms / 1000;
        const min = s / 60;
        const h = min / 60;
        let hours;
        let minutes;
        let result = '';

        if (Math.floor(h) > 0) {
            hours = Math.floor(h);

            result += `${hours}h`;
            if (Math.floor(min) > 0) {
                const minutesFloor = Math.floor(min);

                minutes = minutesFloor - hours * 60;

                if (minutes) {
                    result += `${minutes}${result ? '' : 'min'}`;
                }
            }
        }

        return result;
    }

    public renderTimes = () => {
        const { deliveryTour } = this.props;

        if (deliveryTour && deliveryTour.deliveries) {
            const { deliveries } = deliveryTour;
            const lastDelivery = deliveries[deliveries.length - 1];
            const startDate =
                deliveries[0] &&
                deliveries[0].timeframe &&
                deliveries[0].timeframe.from ?
                    (new Date(deliveries[0].timeframe.from)).getTime() :
                    new Date().setHours(0, 0, 0, 0);

            const endDate =
                lastDelivery &&
                lastDelivery.timeframe &&
                lastDelivery.timeframe.to ?
                    (new Date(lastDelivery.timeframe.to)).getTime() :
                    new Date().setHours(0, 0, 0, 0);

            return (
                <div id="overview-times">
                    <span id="overview-duration">
                        ~ {this.renderDuration(startDate, endDate)}
                    </span>
                    <FormattedTime value={startDate} />
                    <FormattedMessage
                        {...messages.numberOfTasks}
                        values={{ count: deliveries.length }}
                    />
                    <FormattedTime value={endDate} />
                </div>
            );
        }

        return null;
    }

    public renderItem = (
        key: string,
        itemWidthInPercentage: number,
        barPosition: string,
        icon?: React.ReactNode,
        iconLast?: React.ReactNode,
    ) => {
        return (
            <div key={key} className="overview-item-track" style={{ width: `${itemWidthInPercentage}%` }}>
                <div className="overview-track" />
                <div className="overview-bar" style={{ right: barPosition }} />
                <div className={`overview-tick${barPosition !== '100%' ? ' active' : ''}`} />
                {iconLast && (
                    <>
                        <div className={`overview-tick-last${barPosition === '0' ? ' active' : ''}`} />
                        {iconLast}
                    </>
                )}
                {icon}
            </div>
        );
    }

    public render() {
        const { deliveryTour } = this.props;
        const tasks: React.ReactNode[] = [];
        let preparation = null;

        if (deliveryTour) {
            const { deliveries } = deliveryTour;
            const hasAgency = deliveryTour.agency && deliveryTour.agency.coordinates;
            let itemCount = hasAgency ? 1 : 0;

            if (deliveries && deliveries.length) {
                itemCount += deliveries.length;
            }

            const itemWidthInPercentage = 100 / itemCount;

            if (hasAgency) {
                let barPosition = '100%';

                switch (deliveryTour.status) {
                    case DeliveryTourStatus.pending:
                        barPosition = '50%';
                        break;

                    default:
                        barPosition = '0';
                        break;
                }
                preparation = this.renderItem('preparation', itemWidthInPercentage, barPosition, <IconAgencyMarker />);
            }

            (deliveries || []).forEach((delivery: Delivery, index: number) => {
                if (delivery.address) {
                    const isLast = index === deliveries!.length - 1;
                    let barPosition = '100%';

                    switch (delivery.status) {
                        case DeliveryStatus.started:
                            barPosition = '50%';
                            break;

                        case DeliveryStatus.ready:
                        case DeliveryStatus.pending:
                            barPosition = '100%';
                            break;

                        default:
                            barPosition = '0';
                            break;
                    }
                    tasks.push(this.renderItem(
                        `${delivery.reference}-${index}`,
                        itemWidthInPercentage,
                        barPosition,
                        null,
                        isLast ? <IconEndMarker /> : null,
                    ));
                }
            });
        }

        return (
            <div id="delivery-tour-overview">
                <div id="overview-items">
                    {preparation}
                    {tasks}
                </div>
                {this.renderTimes()}
            </div>
        );
    }
}

export default DeliveryTourOverview;
