import { defineMessages } from 'react-intl';

export default defineMessages<string>({
    deliveryTypeBasicDetailsDrawerTitle: {
        id: 'delivery_details.drawer_title.delivery.type.basic',
        defaultMessage: 'Details of delivery {reference}',
        description: 'Delivery details drawer title with type',
    },
    deliveryTypeThirdPartyDetailsDrawerTitle: {
        id: 'delivery_details.drawer_title.delivery.type.third_party',
        defaultMessage: 'Details of delivery {reference} for {thirdParty}',
        description: 'Delivery details drawer title with type',
    },
    deliveryTypePickUpDetailsDrawerTitle: {
        id: 'delivery_details.drawer_title.delivery.type.pick_up',
        defaultMessage: 'Details of pick up {reference}',
        description: 'Delivery details drawer title with type',
    },
    deliveryTypeVisitDetailsDrawerTitle: {
        id: 'delivery_details.drawer_title.delivery.type.visit',
        defaultMessage: 'Details of visit {reference}',
        description: 'Delivery details drawer title with type',
    },
    deliveryTypeUnknownDetailsDrawerTitle: {
        id: 'delivery_details.drawer_title.delivery.type.unknown',
        defaultMessage: 'Unknown',
        description: 'Unknown delivery type',
    },
    trackingNumber: {
        id: 'delivery.tracking_number',
        defaultMessage: 'Tracking number',
        description: 'Tracking number',
    },
    partnerTrackingNumber: {
        id: 'delivery.partner_tracking_number',
        defaultMessage: 'Partner tracking number',
        description: 'Partner Tracking number',
    },
    parcelContent: {
        id: 'delivery.parcel.content',
        defaultMessage: 'Content',
        description: 'Delivery parcel content list header',
    },
    outOfParcel: {
        id: 'delivery.item.out_of_parcel',
        defaultMessage: 'Out of parcel',
        description: 'Delivery parcel content list header',
    },
});
