import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { Dispatch, bindActionCreators } from 'redux';
import {
    Form, Input, Button, Drawer, Select, Alert,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';

import { create as createAction, update as updateAction } from '../../store/actions/users';
import { list as fetchRolesAction } from '../../store/actions/roles';
import { list as fetchOrganizationsAction } from '../../store/actions/organizations';
import { list as fetchAgenciesAction } from '../../store/actions/agencies';
import { User, Role, Organization, PermissionRight, Agency } from '../../store/api/types';
import { getCreates, getUserById, getUpdates } from '../../store/reducers/users';
import { RequestState, MainReducerState } from '../../store/reducers';
import { getRoles, getPagination as getRolePagination } from '../../store/reducers/roles';
import { getOrganizations, getPagination as getOrganizationPagination } from '../../store/reducers/organizations';
import { PaginationRequestState } from '../../store/reducers/_generics';

import messages from './UserFormDrawer.messages';
import FormMessages from '../../locale/Form.messages';
import EmptyResult from '../../components/EmptyResult';
import GenericMessages from '../../locale/Generic.messages';
import { Can } from '../../components/auth';
import { isUserAllowed } from '../../utils/permissions';
import { getUser, AuthUser } from '../../store/reducers/auth';
import { constants } from '../../utils';
import { getAgencies, AgenciesListState } from '../../store/reducers/agencies';

interface UserFormDrawerProps extends FormComponentProps, InjectedIntlProps {
    authUser: AuthUser;
    create: typeof createAction;
    creates: RequestState;
    drawerTitle: string | React.ReactNode;
    fetchAgencies: typeof fetchAgenciesAction;
    fetchOrganizations: typeof fetchOrganizationsAction;
    fetchRoles: typeof fetchRolesAction;
    onClose: () => void;
    onSuccess: () => void;
    agencies: AgenciesListState;
    organizations: Organization[];
    organizationPagination: PaginationRequestState;
    roles: Role[];
    rolePagination: PaginationRequestState;
    user?: User;
    userId?: User['id'];
    update: typeof updateAction;
    updates: RequestState;
}

interface UserFormDrawerState {
    visible: boolean;
}

export class UserDrawer extends React.Component<UserFormDrawerProps, UserFormDrawerState> {
    public state = { visible: false };

    public componentDidMount() {
        this.fetchRoles();

        if (isUserAllowed(this.props.authUser, 'su', PermissionRight.write)) {
            this.fetchOrganizations();
        }

        this.fetchAgencies();
    }

    public componentDidUpdate(prevProps: UserFormDrawerProps) {
        const { creates, form, onSuccess, updates, userId } = this.props;

        if (
            (!prevProps.creates.success && creates.success) ||
            (!prevProps.updates.success && updates.success)
        ) {
            onSuccess();
            this.onClose();
            form.resetFields();
        }

        if (!prevProps.userId && userId) {
            this.updateFieldsValue();
        }
    }

    public showDrawer = () => {
        this.setState({
            visible: true,
        });
    }

    public onClose = () => {
        const { form, onClose } = this.props;
        form.resetFields();
        this.setState({
            visible: false,
        }, onClose);
    }

    public onRoleChange = (id: Role['id']) => {
        this.props.form.setFieldsValue({ role: id });
    }

    public onOrganizationChange = (id: Organization['id']) => {
        this.props.form.setFieldsValue({ organization: id });
    }

    public onScopeOrganizationsChange = (id: Organization['id']) => {
        this.props.form.setFieldsValue({ 'scope.organization': id });
    }

    public onAgenciesChange = (ids: Array<Agency['id']>) => {
        this.props.form.setFieldsValue({ 'scope.agencies': ids });
    }

    public onSubmit = (e?: React.FormEvent) => {
        const { create, userId, form, update } = this.props;
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }

            if (userId !== undefined) {
                update(userId, val);
            } else {
                create(val);
            }
        });
    }

    public updateFieldsValue = () => {
        const { form, user } = this.props;

        if (user) {
            form.setFieldsValue({
                firstname: user.firstname,
            });
        }
    }

    public fetchRoles = async (search?: string) => {
        await this.props.fetchRoles({ search, throttling: 200 });
    }

    public fetchOrganizations = async (search?: string) => {
        await this.props.fetchOrganizations({ search, throttling: 200 });
    }

    public fetchAgencies = async (search?: string) => {
        await this.props.fetchAgencies({ search, throttling: 200 });
    }

    public validatePassword = (rule: any, value: any, callback: any) => {
        const { intl } = this.props;

        if (value && !constants.PASSWORD_REGEX.test(value)) {
            callback(intl.formatMessage(messages.passwordHelp));
        } else {
            callback();
        }
    }

    public render() {
        const {
            agencies, creates, drawerTitle, form, intl, organizations, organizationPagination, user,
            userId, roles, rolePagination, updates,
        } = this.props;
        const { visible } = this.state;
        const { getFieldDecorator } = form;
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 16 },
        };

        return (
            <Drawer
                title={drawerTitle}
                width={580}
                onClose={this.onClose}
                visible={visible}
            >
                <Form onSubmit={this.onSubmit} layout="horizontal">
                    <Can edit="su">
                        <Form.Item {...formItemLayout} label={<FormattedMessage {...messages.organizationLabel} />}>
                            {getFieldDecorator('organization', {
                                rules: [{
                                    required: !!isUserAllowed(this.props.authUser, 'su', PermissionRight.write),
                                    message: intl.formatMessage(FormMessages.requiredError),
                                }],
                                initialValue: user && user.organization ?
                                    user.organization.id :
                                    undefined,
                            })(
                                <Select
                                    placeholder="none"
                                    onSearch={this.fetchOrganizations}
                                    onChange={this.onOrganizationChange}
                                    loading={organizationPagination.loading}
                                    notFoundContent={<EmptyResult />}
                                    filterOption={false}
                                    showSearch={true}
                                >
                                    {organizations.map((organization: Organization) => (
                                        <Select.Option
                                            key={organization.id}
                                            value={organization.id}
                                        >
                                            {organization.name}
                                        </Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>
                    </Can>
                    <Form.Item {...formItemLayout} label={<FormattedMessage {...messages.firstNameLabel} />}>
                        {getFieldDecorator('firstname', {
                            rules: [{
                                required: true,
                                message: intl.formatMessage(FormMessages.requiredError),
                            }],
                            initialValue: user ? user.firstname : undefined,
                        })(
                            <Input
                                placeholder="Eugène"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label={<FormattedMessage {...messages.lastNameLabel} />}>
                        {getFieldDecorator('lastname', {
                            rules: [{
                                required: true,
                                message: intl.formatMessage(FormMessages.requiredError),
                            }],
                            initialValue: user ? user.lastname : undefined,
                        })(
                            <Input
                                placeholder="Quompasse"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label={<FormattedMessage {...messages.emailLabel} />}>
                        {getFieldDecorator('email', {
                            rules: [{
                                required: true,
                                type: 'email',
                                message: intl.formatMessage(FormMessages.requiredError),
                            }],
                            initialValue: user ? user.email : undefined,
                        })(
                            <Input
                                type="email"
                                placeholder={intl.formatMessage(messages.emailLabel)}
                            />,
                        )}
                    </Form.Item>
                    {userId === undefined && (
                        <Form.Item
                            {...formItemLayout}
                            help={<FormattedMessage {...messages.passwordHelp} />}
                            label={<FormattedMessage {...messages.passwordLabel} />}
                        >
                            {getFieldDecorator('password', {
                                rules: [
                                    {
                                        required: true,
                                        message: intl.formatMessage(FormMessages.requiredError),
                                    },
                                    {
                                        validator: this.validatePassword,
                                    },
                                ],
                                validateTrigger: 'onBlur',
                            })(
                                <Input.Password
                                    placeholder={intl.formatMessage(messages.passwordLabel)}
                                />,
                            )}
                        </Form.Item>
                    )}
                    <Form.Item {...formItemLayout} label={<FormattedMessage {...messages.roleLabel} />}>
                        {getFieldDecorator('role', {
                            rules: [{
                                required: true,
                                message: intl.formatMessage(FormMessages.requiredError),
                            }],
                            initialValue: user && user.role ?
                                user.role.id :
                                undefined,
                        })(
                            <Select
                                placeholder="none"
                                onSearch={this.fetchRoles}
                                onChange={this.onRoleChange}
                                loading={rolePagination.loading}
                                notFoundContent={<EmptyResult />}
                                filterOption={false}
                                showSearch={true}
                            >
                                {roles.map((role: Role) => (
                                    <Select.Option
                                        key={role.id}
                                        value={role.id}
                                    >
                                        {role.name}
                                    </Select.Option>
                                ))}
                            </Select>,
                        )}
                    </Form.Item>
                    <fieldset>
                        <legend><FormattedMessage {...messages.scope} /></legend>
                        <Form.Item
                            {...formItemLayout}
                            label={<FormattedMessage {...GenericMessages.agency} values={{ count: 0 }} />}
                        >
                            {getFieldDecorator('scope.agencies', {
                                initialValue: user && user.scope && user.scope.agencies ?
                                    user.scope.agencies :
                                    undefined,
                            })(
                                <Select
                                    placeholder={intl.formatMessage(GenericMessages.none)}
                                    mode="multiple"
                                    onSearch={this.fetchAgencies}
                                    onChange={this.onAgenciesChange}
                                    loading={agencies.loading}
                                    notFoundContent={<EmptyResult />}
                                    filterOption={false}
                                    showSearch={true}
                                >
                                    {agencies.data.map((agency: Agency) => (
                                        <Select.Option
                                            key={agency.id}
                                            value={agency.id}
                                        >
                                            {agency.name}
                                        </Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>
                        <Can edit="su">
                            <Form.Item
                                {...formItemLayout}
                                label={<FormattedMessage {...GenericMessages.organization} values={{ count: 0 }} />}
                            >
                                {getFieldDecorator('scope.organizations', {
                                    initialValue: user && user.scope && user.scope.organizations ?
                                        user.scope.organizations :
                                        undefined,
                                })(
                                    <Select
                                        placeholder={intl.formatMessage(GenericMessages.none)}
                                        mode="multiple"
                                        onSearch={this.fetchOrganizations}
                                        onChange={this.onScopeOrganizationsChange}
                                        loading={organizationPagination.loading}
                                        notFoundContent={<EmptyResult />}
                                        filterOption={false}
                                        showSearch={true}
                                    >
                                        {organizations.map((organization: Organization) => (
                                            <Select.Option
                                                key={organization.id}
                                                value={organization.id}
                                            >
                                                {organization.name}
                                            </Select.Option>
                                        ))}
                                    </Select>,
                                )}
                            </Form.Item>
                        </Can>
                    </fieldset>
                    {(creates && !creates.loading && creates.error) ||
                        (updates && !updates.loading && updates.error) &&
                        <Form.Item>
                            <Alert
                                message={<FormattedMessage {...GenericMessages.error} />}
                                type="error"
                            />
                        </Form.Item>
                        || undefined}
                    <div className="form-actions">
                        <Button onClick={this.onClose} type="ghost">
                            <FormattedMessage {...FormMessages.cancel} />
                        </Button>
                        <Button htmlType="submit" type="primary" loading={updates.loading || creates.loading}>
                            <FormattedMessage {...FormMessages.submit} />
                        </Button>
                    </div>
                </Form>
            </Drawer>
        );
    }
}

const UserFormDrawer = Form.create<UserFormDrawerProps>()(UserDrawer);

const mapStateToProps = (state: MainReducerState, { userId }: { userId?: User['id'] }) => ({
    authUser: getUser(state),
    creates: getCreates(state),
    agencies: getAgencies(state),
    organizations: getOrganizations(state),
    organizationPagination: getOrganizationPagination(state),
    rolePagination: getRolePagination(state),
    roles: getRoles(state),
    user: getUserById(state, userId),
    updates: getUpdates(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
    create: createAction,
    fetchAgencies: fetchAgenciesAction,
    fetchOrganizations: fetchOrganizationsAction,
    fetchRoles: fetchRolesAction,
    update: updateAction,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    undefined,
    { forwardRef: true },
)(injectIntl(UserFormDrawer, { withRef: true }));
