import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import Table, { ColumnProps } from 'antd/lib/table';

import * as CustomersActions from '../../store/actions/customers';
import { SearchQueryComponentState, SearchPaginationQuery } from '../../store/api';
import { Customer } from '../../store/api/types';
import { getCustomers, getPagination } from '../../store/reducers/customers';
import { PaginationRequestState } from '../../store/reducers/_generics';

import Header from '../../components/header/Header';
import Content from '../../components/Content';
import ListMessages from '../../locale/List.messages';
import HeaderNav from '../../components/header/HeaderNav';
import { ListItemTitle, List } from '../../components/list';
import MainMenuMessages from '../../components/MainMenu.messages';
import messages from './CustomersList.messages';
import { getFullName } from '../../utils';
import EmptyStateText from '../../components/typography/EmptyStateText';

import '../../assets/styles/CustomersList.less';
import { FullAddress } from '../../components/FullAdress';
import EmptyResult from '../../components/EmptyResult';
import { getRoute, RoutePathName } from '../../routes';
import { Languages } from '../../components/IntlProvider';
import { SkeletonRow, Bone } from '../../components/skeleton';

interface CustomersListProps extends InjectedIntlProps, RouteComponentProps {
    fetchCustomers: (query?: SearchPaginationQuery) => void;
    customers: Customer[];
    pagination: PaginationRequestState;
}

class CustomersList extends React.Component<CustomersListProps, SearchQueryComponentState> {
    public state: SearchQueryComponentState = {};
    private columns: Array<ColumnProps<Customer>> = [
        {
            dataIndex: 'id',
            render: (text, customer, index) => (
                <ListItemTitle
                    className="delivery-man-list-item-title"
                    index={(this.props.pagination.pageSize * this.props.pagination.page) + index + 1}
                    text={
                        getFullName(customer.firstname, customer.lastname).toLowerCase() ?
                            ` ${getFullName(customer.firstname, customer.lastname).toLowerCase()}` : (
                            <EmptyStateText>
                                <FormattedMessage {...messages.noName} />
                            </EmptyStateText>
                        )
                    }
                />
            ),
        }, {
            dataIndex: 'emails',
            render: (emails) => emails.length ? emails[0] : (
                <EmptyStateText>
                    <FormattedMessage {...messages.noEmail} />
                </EmptyStateText>
            ),
        }, {
            dataIndex: 'addresses',
            align: 'right',
            render: (addresses) => {
                const address = addresses[0] || {};

                return Object.keys(address).length ? (
                    <FullAddress
                        address={address}
                    />
                ) : (
                    <EmptyStateText>
                        <FormattedMessage {...messages.noAddress} />
                    </EmptyStateText>
                );
            },
        }, {
            dataIndex: 'phones',
            className: 'customers-phone',
            render: (phones) => phones.length ? (
                <span className="customers-phone">{phones[0]}</span>
            ) : (
                <EmptyStateText>
                    <FormattedMessage {...messages.noPhone} />
                </EmptyStateText>
            ),
        }, {
            dataIndex: 'reference',
        },
    ];

    public componentDidMount() {
        this.fetchData();
    }

    public onRowClick = (record: Customer) => {
        const { history, intl } = this.props;
        history.push(getRoute(intl.locale as Languages, RoutePathName.customer, { id: record.id! }));
    }

    public subTableRenderer = (customer: Customer) => {
        if (!customer.meta || !Object.keys(customer.meta).length) {
            return null;
        }

        const customerMetaColumns: Array<ColumnProps<Customer['meta']>> =
            Object.keys(customer.meta).map((meta) => ({
                title: meta,
                dataIndex: meta,
            }));

        return (
            <Table<Customer['meta']>
                columns={customerMetaColumns}
                dataSource={[customer.meta]}
                locale={{
                    emptyText: <EmptyResult />,
                }}
                pagination={false}
                rowKey={this.subTableRowKey}
            />
        );
    }

    public skeletonItemRenderer(index: number) {
        return (
            <SkeletonRow key={index}>
                <Bone style={{ flex: '0 1 auto' }} />
                <Bone style={{ flex: '0 1 auto' }} />
                <Bone style={{ flex: '0 0 25%' }} />
                <Bone style={{ flex: '0 0 25%' }} />
                <Bone style={{ flex: '0 0 25%' }} />
                <Bone style={{ flex: '0 0 10px' }} />
            </SkeletonRow>
        );
    }

    public render() {
        const { customers, intl, pagination } = this.props;
        const headerLinks = {
            [location.pathname]: intl.formatMessage(ListMessages.contentHeaderNavItemList),
        };

        return (
            <>
                <Header>
                    <HeaderNav links={headerLinks} />
                </Header>
                <Content>
                    <List<Customer>
                        columns={this.columns}
                        dataState={{
                            data: customers,
                            loading: pagination.loading,
                            ...pagination,
                        }}
                        listTitle={<FormattedMessage {...MainMenuMessages.customers} />}
                        onChangePage={this.onChangePage}
                        onRowClick={this.onRowClick}
                        onSearch={this.onSearch}
                        rowKey={this.rowKey}
                        subTableRenderer={this.subTableRenderer}
                        skeletonRenderer={this.skeletonItemRenderer}
                    />
                </Content>
            </>
        );
    }

    private rowKey = (record: Customer) => record.id!.toString();

    private subTableRowKey = (record: Customer['meta'], index: number) => `${index}`;

    private onChangePage = (page: number) => {
        const { search } = this.state;

        this.fetchData({
            search,
            page: page - 1,
        });
    }

    private onSearch = (search: string) => {
        this.setState({ search });
        this.fetchData({
            search,
            throttling: 200,
        });
    }

    private fetchData = (query?: SearchPaginationQuery) => {
        const { page, pageSize } = this.props.pagination;
        this.props.fetchCustomers({
            page,
            pageSize,
            ...query,
        });
    }
}

const mapStateToProps = (state: any) => ({
    customers: getCustomers(state),
    pagination: getPagination(state),
});

export default injectIntl(connect(
    mapStateToProps,
    { fetchCustomers: CustomersActions.list },
)(CustomersList));
