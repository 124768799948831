import { combineReducers, Reducer } from 'redux';

import * as reduxTypes from '../types/deliveries';
import * as deliveryToursTypes from '../types/deliveryTours';

import { MainReducerState, RequestState } from '.';
import { Delivery } from '../api/types';
import {
    paginateReducer, PaginationRequestState, entityReducer, EntityReducerState, requestReducer,
} from './_generics';
import { denormalize } from 'normalizr';
import { arrayOfDeliveries, delivery, Entities } from '../schema';

interface SendMessageState {
    [key: string]: RequestState;
}

export interface DeliveriesState {
    readonly entities: EntityReducerState<Delivery>;
    readonly pagination: PaginationRequestState;
    readonly sendMessage: SendMessageState;
}

const sendMessage: Reducer<SendMessageState> = (state = {}, { type, data, id }) => {
    switch (type) {
        case reduxTypes.SEND_MESSAGE:
            return {
                ...state,
                [id]: {
                    ...state[id],
                    loading: true,
                    error: undefined,
                    success: undefined,
                },
            };
        case reduxTypes.SEND_MESSAGE_SUCCESS: {
            return {
                ...state,
                [id]: {
                    ...state[id],
                    loading: false,
                    success: true,
                },
            };
        }
        case reduxTypes.SEND_MESSAGE_FAILED:
            return {
                ...state,
                [id]: {
                    ...state[id],
                    loading: false,
                    error: data && data.response && data.response.data ?
                        data.response.data :
                        true,
                    response: data,
                    success: false,
                },
            };

        default:
            return state;
    }
};

export default combineReducers<DeliveriesState>({
    entities: entityReducer([
        reduxTypes.LIST_SUCCESS,
        deliveryToursTypes.LIST_SUCCESS,
        deliveryToursTypes.DETAILS_SUCCESS,
    ], Entities.deliveries),
    pagination: paginateReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    }),
    sendMessage,
});

export const getIds = (state: MainReducerState) => state.deliveries.pagination.ids;

export const getDeliveryById = (state: MainReducerState, id: Delivery['id']) =>
    denormalize(id, delivery, { [Entities.deliveries]: state.deliveries.entities }) || undefined;

export const getDeliveries = (state: MainReducerState) =>
    denormalize(getIds(state), arrayOfDeliveries, { [Entities.deliveries]: state.deliveries.entities });

export const getPagination = (state: MainReducerState) => state.deliveries.pagination;

export const getSendMessageByDeliveryId = (state: MainReducerState, id: Delivery['id']) => id ?
    state.deliveries.sendMessage[id] :
    undefined;
