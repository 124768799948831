import * as React from 'react';

interface ListItemTitleProps extends React.HTMLAttributes<HTMLSpanElement> {
    index: number | string;
    text?: string | React.ReactNode;
}

const ListItemTitle: React.SFC<ListItemTitleProps> = ({ index, text, ...rest }) => (
    <span {...rest}>
        <span className="list-item-title-index">{index}</span>
        {text && (
            <span className="list-item-title">{text}</span>
        )}
    </span>
);

export default ListItemTitle;
