import { ActionCreator } from 'redux';

import { DeliveryMenListResponse } from '../api/deliveryMen';
import * as reduxTypes from '../types/deliveryMen';
import { DataAction, QueryListPayload, QueryListAction, IdAction } from '.';
import { DeliveryMan, DeliveryManStats } from '../api/types';

// LIST

export const list: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    ...payload,
    type: reduxTypes.LIST,
});

export const listSuccess: ActionCreator<DataAction> = (data: DeliveryMenListResponse) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAILED,
    data,
});

// DETAILS

export const details: ActionCreator<IdAction> = (id: DeliveryMan['id']) => ({
    type: reduxTypes.DETAILS,
    id,
});

export const detailsSuccess: ActionCreator<DataAction> = (data: DeliveryMan, id: DeliveryMan['id']) => ({
    type: reduxTypes.DETAILS_SUCCESS,
    data,
    id,
});

export const detailsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_FAILED,
    data,
});

// STATS

export const stats: ActionCreator<IdAction> = (id: DeliveryMan['id']) => ({
    type: reduxTypes.STATS,
    id,
});

export const statsSuccess: ActionCreator<DataAction> = (data: DeliveryManStats, id: DeliveryMan['id']) => ({
    type: reduxTypes.STATS_SUCCESS,
    data,
    id,
});

export const statsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.STATS_FAILED,
    data,
});
