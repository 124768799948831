import { Action } from 'redux';
import { delay } from 'redux-saga';
import { call, takeLatest, put } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import * as reduxActions from '../actions/organizations';
import * as reduxTypes from '../types/organizations';
import * as api from '../api/organizations';
import { SearchQuery } from '../api';
import { DataAction, IdAction } from '../actions';
import { arrayOfOrganizations, organization } from '../schema';
import { Organization } from '../api/types';

export function* listSaga(action: Action & SearchQuery) {
    try {
        yield delay(action.throttling || 0);
        const {items, ...rest} = yield call(api.list, action);
        const normalizedItems = normalize(items, arrayOfOrganizations);

        return yield put(reduxActions.listSuccess({
            ...normalizedItems,
            ...rest,
        }));
    } catch (error) {
        return yield put(reduxActions.listFailed(error));
    }
}

export function* detailsSaga(action: IdAction<Organization['id']>) {
    try {
        const response = yield call(api.details, action.id);
        const normalizedResponse = normalize(response, organization);

        return yield put(reduxActions.detailsSuccess(normalizedResponse, action.id));
    } catch (error) {
        return yield put(reduxActions.detailsFailed(error, action.id));
    }
}

export function* createSaga(action: DataAction<api.OrganizationUpdateData>) {
    try {
        const response = yield call(api.create, action.data);
        return yield put(reduxActions.createSuccess(response));
    } catch (error) {
        return yield put(reduxActions.createFailed(error));
    }
}

export function* updateSaga(action: DataAction<api.OrganizationUpdateData> & IdAction<Organization['id']>) {
    try {
        const response = yield call(api.update, action.id, action.data);
        return yield put(reduxActions.updateSuccess(response));
    } catch (error) {
        return yield put(reduxActions.updateFailed(error));
    }
}

export default function* organizationsSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.CREATE, createSaga);
    yield takeLatest(reduxTypes.DETAILS, detailsSaga);
    yield takeLatest(reduxTypes.UPDATE, updateSaga);
}
