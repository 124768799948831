import * as React from 'react';
import { RouteComponentProps, withRouter, Link, NavLink } from 'react-router-dom';

interface HeaderNavProps extends RouteComponentProps {
    links: {
        [route: string]: string | React.ReactNode;
    };
}

class HeaderNav extends React.Component<HeaderNavProps> {
    public render() {
        const { links, location } = this.props;

        return (
            <div id="content-header-nav">
                {Object.keys(links).map((route, index) => {
                    const isActive = route.match(location.pathname);

                    return (
                        <span
                            className={`content-header-nav-item${isActive ? ' active' : ''}`}
                            key={`${index}-${route}`}
                        >
                            {links[route] ? (
                                <NavLink exact to={route}>{links[route]}</NavLink>
                            ) : null}
                        </span>
                    );
                })}
            </div>
        );
    }
}

export default withRouter(HeaderNav);
