import { combineReducers, Reducer } from 'redux';

import * as reduxTypes from '../types/vehicles';
import { SensorData, Vehicle } from '../api/types';
import { MainReducerState } from '.';

export interface VehiclesState {
    readonly temperatureByVehicleId: TemperatureByVehicleId;
}

export interface VehicleTemperatureState {
    error: any;
    loading: boolean;
    data: SensorData[];
}

export interface TemperatureByVehicleId {
    [key: string]: VehicleTemperatureState;
}

const temperatureByVehicleId: Reducer<TemperatureByVehicleId> = (state = {}, {id, data, type}) => {
    switch (type) {
        case reduxTypes.TEMPERATURE:
            return {
                ...state,
                [id]: {
                    ...state[id],
                    error: undefined,
                    loading: true,
                },
            };
        case reduxTypes.TEMPERATURE_SUCCESS:
            return {
                ...state,
                [id]: {
                    ...state[id],
                    loading: false,
                    data,
                },
            };
        case reduxTypes.TEMPERATURE_FAILED:
            return {
                ...state,
                [id]: {
                    ...state[id],
                    loading: false,
                    error: data,
                },
            };
        default:
            return state;
    }
};

export default combineReducers<VehiclesState>({
    temperatureByVehicleId,
});

export const getTemperatureByVehicleId = (state: MainReducerState, id: Vehicle['id']) =>
    id ? state.vehicles.temperatureByVehicleId[id] : undefined;
