import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { Dispatch, bindActionCreators } from 'redux';
import {
    Form, Input, Button, Drawer, Select, Icon, Alert, Spin, Checkbox, Switch, Typography,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';

import { create as createAction, update as updateAction } from '../../store/actions/organizations';
import { list as fetchProvidersAction } from '../../store/actions/providers';
import { list as fetchTelecomsAction } from '../../store/actions/telecoms';
import { Organization, Provider } from '../../store/api/types';
import { getCreates, getOrganizationById, getReads, getUpdates } from '../../store/reducers/organizations';
import { RequestState, MainReducerState } from '../../store/reducers';
import { getProviders, ProvidersListState } from '../../store/reducers/providers';
import { getTelecoms, TelecomsListState } from '../../store/reducers/telecoms';

import messages from './OrganizationFormDrawer.messages';
import FormMessages from '../../locale/Form.messages';
import EmptyResult from '../../components/EmptyResult';
import GenericMessages from '../../locale/Generic.messages';

interface OrganizationFormDrawerProps extends FormComponentProps, InjectedIntlProps {
    create: typeof createAction;
    creates: RequestState;
    drawerTitle: string | React.ReactNode;
    fetchProviders: typeof fetchProvidersAction;
    fetchTelecoms: typeof fetchTelecomsAction;
    onClose: () => void;
    organization?: Organization;
    organizationId?: Organization['id'];
    providers: ProvidersListState;
    telecoms: TelecomsListState;
    reads: RequestState;
    update: typeof updateAction;
    updates: RequestState;
}

interface OrganizationFormDrawerState {
    visible: boolean;
}

export class OrganizationDrawer extends React.Component<OrganizationFormDrawerProps, OrganizationFormDrawerState> {
    public state = { visible: false };

    public componentDidMount() {
        this.fetchProviders();
        this.fetchTelecoms();
    }

    public componentDidUpdate(prevProps: OrganizationFormDrawerProps) {
        const { creates, form, reads, updates } = this.props;

        if (
            (!prevProps.creates.success && creates.success) ||
            (!prevProps.updates.success && updates.success)
        ) {
            this.onClose();
            form.resetFields();
        }

        if (!prevProps.reads.success && reads.success) {
            this.updateFieldsValue();
        }
    }

    public showDrawer = () => {
        this.setState({
            visible: true,
        });
    }

    public onClose = () => {
        const { form, onClose } = this.props;
        form.resetFields();
        this.setState({
            visible: false,
        }, onClose);
    }

    public onProviderChange = (provider: Provider['type']) => {
        this.props.form.setFieldsValue({ 'provider.type': provider });
    }

    public onTelecomChange = (telecom: string) => {
        this.props.form.setFieldsValue({ 'config.messages.provider': telecom });
    }

    public onSubmit = (e?: React.FormEvent) => {
        const { create, organizationId, form, update } = this.props;
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }
            const { configKeys, keys, values, ...rest } = val;
            const data = {
                ...rest,
            };

            if (configKeys && configKeys.length) {
                if (!data.provider) {
                    data.provider = {
                        config: {},
                    };
                } else if (!data.provider.config) {
                    data.provider.config = {};
                }

                configKeys.forEach((keyIndex: number) => {
                    if (keys[keyIndex] !== undefined) {
                        data.provider.config[keys[keyIndex]] = values[keyIndex];
                    }
                });
            }

            if (data.provider.config && !Object.keys(data.provider.config).length) {
                delete data.provider.config;
            }

            if (organizationId !== undefined) {
                update(organizationId, data);
            } else {
                create(data);
            }
        });
    }

    public removeField = (configKey: string) => {
        const { form } = this.props;
        const providerConfigKeys = form.getFieldValue('configKeys');

        form.setFieldsValue({
            configKeys: providerConfigKeys.filter((c: string) => c !== configKey),
        });
    }

    public addField = () => {
        const { form } = this.props;
        const providerConfigKeys = form.getFieldValue('configKeys');
        const nextKeys = providerConfigKeys.concat(providerConfigKeys.length);

        form.setFieldsValue({
            configKeys: nextKeys,
        });
    }

    public updateFieldsValue = () => {
        const { form, organization } = this.props;

        if (organization) {
            form.setFieldsValue({
                configKeys: organization.provider && organization.provider.config ?
                    Array.from(Array(Object.keys(organization.provider.config).length).keys()) :
                    [],
                keys: organization.provider && organization.provider.config ?
                    Object.keys(organization.provider.config) :
                    [],
                values: organization.provider && organization.provider.config ?
                    Object.values(organization.provider.config) :
                    [],
                name: organization.name,
                'neerby.appUuid': organization.neerby ?
                    organization.neerby.appUuid :
                    undefined,
                'neerby.apiKey': organization.neerby ?
                    organization.neerby.apiKey :
                    undefined,
                'provider.type': organization.provider ?
                    organization.provider.type :
                    undefined,
            });
        }
    }

    public fetchProviders = async (search?: string) => {
        await this.props.fetchProviders({ search, throttling: 200 });
    }

    public fetchTelecoms = async (search?: string) => {
        await this.props.fetchTelecoms({ search, throttling: 200 });
    }

    public render() {
        const {
            creates, drawerTitle, form, intl, organization, organizationId, providers, telecoms, reads,
            updates,
        } = this.props;
        const { visible } = this.state;
        const { getFieldDecorator, getFieldValue } = form;
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 16 },
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 16, offset: 6 },
            },
        };

        getFieldDecorator('configKeys', {
            initialValue: organization && organization.provider && organization.provider.config ?
                Array.from(Array(Object.keys(organization.provider.config).length).keys()) :
                [],
        });
        const providerConfigKeys = getFieldValue('configKeys');

        const providerConfigFields = providerConfigKeys.map((configKey: string, index: number) => (
            <React.Fragment key={`${configKey}`}>
                <Form.Item
                    required={false}
                    style={{ display: 'inline-block', width: 'calc(50% - 24px)', marginRight: 16 }}
                >
                    {getFieldDecorator(`keys[${configKey}]`, {
                        validateTrigger: ['onChange', 'onBlur'],
                        rules: [{
                            whitespace: true,
                        }],
                    })(
                        <Input placeholder="clé" />,
                    )}
                </Form.Item>
                <Form.Item
                    required={false}
                    style={{ display: 'inline-block', width: 'calc(50% - 24px)' }}
                >
                    {getFieldDecorator(`values[${configKey}]`, {
                        validateTrigger: ['onChange', 'onBlur'],
                        rules: [{
                            whitespace: true,
                        }],
                    })(
                        <Input placeholder="valeur" />,
                    )}
                </Form.Item>
                <Form.Item
                    style={{ display: 'inline-block', width: 16 }}
                >
                    <Icon
                        style={{ marginLeft: 8 }}
                        type="minus-circle-o"
                        onClick={this.removeField.bind(null, configKey)}
                    />
                </Form.Item>
            </React.Fragment>
        ));

        return (
            <Drawer
                title={drawerTitle}
                width={580}
                onClose={this.onClose}
                visible={visible}
            >
                <Form onSubmit={this.onSubmit} layout="horizontal">
                    <Spin spinning={organizationId !== undefined && reads.loading}>
                        <Form.Item {...formItemLayout} label={<FormattedMessage {...messages.nameField} />}>
                            {getFieldDecorator('name', {
                                rules: [{
                                    required: true,
                                    message: intl.formatMessage(FormMessages.requiredError),
                                }],
                                initialValue: organization ? organization.name : undefined,
                            })(
                                <Input
                                    placeholder="Ezeeworld"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item {...formItemLayout} label="API key">
                            <Typography.Paragraph style={{marginBottom: 0}} code copyable>
                                {organization && organization.apiKey}
                            </Typography.Paragraph>
                        </Form.Item>
                        <Form.Item {...formItemLayout} label="MAP key">
                            <Typography.Paragraph style={{marginBottom: 0}} code copyable>
                                {organization && organization.mapKey}
                            </Typography.Paragraph>
                        </Form.Item>
                        <fieldset>
                            <legend>Neerby</legend>
                            <Form.Item {...formItemLayout} label="Application uuid">
                                {getFieldDecorator('neerby.appUuid', {
                                    rules: [{
                                    required: true,
                                    message: intl.formatMessage(FormMessages.requiredError),
                                }],
                                    initialValue: organization && organization.neerby ?
                                        organization.neerby.appUuid :
                                        undefined,
                                })(
                                    <Input placeholder="uuid" />,
                                )}
                            </Form.Item>
                            <Form.Item {...formItemLayout} label="API key">
                                {getFieldDecorator('neerby.apiKey', {
                                    rules: [{
                                    required: true,
                                    message: intl.formatMessage(FormMessages.requiredError),
                                }],
                                    initialValue: organization && organization.neerby ?
                                        organization.neerby.apiKey :
                                        undefined,
                                })(
                                    <Input placeholder="key" />,
                                )}
                            </Form.Item>
                        </fieldset>
                        <fieldset>
                            <legend>Telecoms (SMS)</legend>
                            <Form.Item {...formItemLayout} label="Activé">
                                {getFieldDecorator('config.messages.enabled', {
                                    valuePropName: 'checked',
                                    initialValue: organization && organization.config ?
                                        !!organization.config.messages.enabled :
                                        false,
                                })(
                                    <Switch />,
                                )}
                            </Form.Item>
                            <Form.Item {...formItemLayout} label="Provider">
                                {getFieldDecorator('config.messages.provider', {
                                    rules: [{
                                    required: true,
                                    message: intl.formatMessage(FormMessages.requiredError),
                                }],
                                    initialValue: organization && organization.config && organization.config.messages ?
                                        organization.config.messages.provider :
                                        undefined,
                                })(
                                    <Select
                                        placeholder="none"
                                        onSearch={this.fetchTelecoms}
                                        onChange={this.onTelecomChange}
                                        loading={telecoms.loading}
                                        notFoundContent={<EmptyResult />}
                                        filterOption={false}
                                        allowClear={true}
                                        showSearch={true}
                                    >
                                        {telecoms.data.map((telecom) => (
                                            <Select.Option
                                                key={telecom}
                                                value={telecom}
                                            >
                                                {telecom}
                                            </Select.Option>
                                        ))}
                                    </Select>,
                                )}
                            </Form.Item>
                            <Form.Item {...formItemLayout} label="API key">
                                {getFieldDecorator('config.messages.apiKey', {
                                    initialValue: organization && organization.config ?
                                        organization.config.messages.apiKey :
                                        undefined,
                                })(
                                    <Input placeholder="key" />,
                                )}
                            </Form.Item>
                            <Form.Item {...formItemLayout} label="Expéditeur">
                                {getFieldDecorator('config.messages.sender', {
                                    initialValue: organization && organization.config ?
                                        organization.config.messages.sender :
                                        undefined,
                                })(
                                    <Input placeholder="ex Nom de l'organisation" />,
                                )}
                            </Form.Item>
                        </fieldset>
                        <fieldset>
                            <legend>Provider</legend>
                            <Form.Item {...formItemLayout} label="Provider">
                                {getFieldDecorator('provider.type', {
                                    rules: [{
                                    required: true,
                                    message: intl.formatMessage(FormMessages.requiredError),
                                }],
                                    initialValue: organization && organization.provider ?
                                        organization.provider.type :
                                        undefined,
                                })(
                                    <Select
                                        placeholder="none"
                                        onSearch={this.fetchProviders}
                                        onChange={this.onProviderChange}
                                        loading={providers.loading}
                                        notFoundContent={<EmptyResult />}
                                        filterOption={false}
                                        allowClear={true}
                                        showSearch={true}
                                    >
                                        {providers.data.map((provider) => (
                                            <Select.Option
                                                key={provider}
                                                value={provider}
                                            >
                                                {provider}
                                            </Select.Option>
                                        ))}
                                    </Select>,
                                )}
                            </Form.Item>
                            <Form.Item
                                {...formItemLayout}
                                wrapperCol={{ span: 17 }}
                                label="Config"
                                style={{ marginBottom: 0 }}
                            >
                                {providerConfigFields}
                            </Form.Item>
                            <Form.Item {...formItemLayoutWithOutLabel}>
                                <Button type="dashed" size="small" onClick={this.addField}>
                                    <Icon type="plus" /> <FormattedMessage {...FormMessages.addField} />
                                </Button>
                            </Form.Item>
                            {(creates && !creates.loading && creates.error) ||
                             (updates && !updates.loading && updates.error) &&
                                <Form.Item>
                                    <Alert
                                        message={<FormattedMessage {...GenericMessages.error} />}
                                        type="error"
                                    />
                                </Form.Item>
                            || undefined}
                        </fieldset>
                    </Spin>
                    <div className="form-actions">
                        <Button onClick={this.onClose} type="ghost">
                            <FormattedMessage {...FormMessages.cancel} />
                        </Button>
                        <Button htmlType="submit" type="primary" loading={updates.loading || creates.loading}>
                            <FormattedMessage {...FormMessages.submit} />
                        </Button>
                    </div>
                </Form>
            </Drawer>
        );
    }
}

const OrganizationFormDrawer = Form.create<OrganizationFormDrawerProps>()(OrganizationDrawer);

const mapStateToProps = (state: MainReducerState, { organizationId }: { organizationId?: Organization['id'] }) => ({
    creates: getCreates(state),
    organization: getOrganizationById(state, organizationId),
    providers: getProviders(state),
    telecoms: getTelecoms(state),
    reads: getReads(state),
    updates: getUpdates(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
    create: createAction,
    fetchProviders: fetchProvidersAction,
    fetchTelecoms: fetchTelecomsAction,
    update: updateAction,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    undefined,
    { forwardRef: true },
)(injectIntl(OrganizationFormDrawer, { withRef: true }));
