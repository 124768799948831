import { defineMessages } from 'react-intl';

export default defineMessages({
    noResult: {
        id: 'list.no_result',
        defaultMessage: 'No result',
        description: 'Generic empty list message',
    },
    paginationText: {
        id: 'list.pagination.text',
        defaultMessage: 'Results from {range0} to {range1} of {total}',
        description: 'List pagination message',
    },
    contentHeaderNavItemList: {
        id: 'content_header.nav.item.list',
        defaultMessage: 'List',
        description: 'Content header navigation item "List"',
    },
    noDataForThisDate: {
        id: 'list.empty.no_data_for_this_date',
        defaultMessage: 'No data for this date',
        description: 'Empty list message when a date is selected',
    },
});
