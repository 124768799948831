import { Action } from 'redux';
import { takeLatest, put } from 'redux-saga/effects';
import * as reduxActions from '../actions/ui';
import * as reduxTypes from '../types/ui';

export function* toggleSidebarSaga(action: Action & { collapsed: boolean }) {
    return reduxActions.toggleSidebar(action.collapsed);
}

export default function* uiSaga() {
    yield takeLatest(reduxTypes.TOGGLE_SIDEBAR, toggleSidebarSaga);
}
