import { combineReducers } from 'redux';

import * as reduxTypes from '../types/permissions';

import { MainReducerState } from '.';
import { Permission } from '../api/types';
import {
    paginateReducer, PaginationRequestState, entityReducer, EntityReducerState,
} from './_generics';
import { denormalize } from 'normalizr';
import { arrayOfPermissions, permission, Entities } from '../schema';

export interface PermissionsState {
    readonly entities: EntityReducerState<Permission>;
    readonly pagination: PaginationRequestState;
}

export default combineReducers<PermissionsState>({
    entities: entityReducer([
        reduxTypes.LIST_SUCCESS,
    ], Entities.permissions),
    pagination: paginateReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    }, { pageSize: 200 }),
});

export const getIds = (state: MainReducerState) => state.permissions.pagination.ids;
export const getPermissionById = (state: MainReducerState, id: Permission['id']) =>
    denormalize(id, permission, { [Entities.permissions]: state.permissions.entities }) || undefined;
export const getPermissions = (state: MainReducerState) =>
    denormalize(getIds(state), arrayOfPermissions, { [Entities.permissions]: state.permissions.entities });
export const getPagination = (state: MainReducerState) => state.permissions.pagination;
