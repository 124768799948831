import { ActionCreator } from 'redux';

import { MonitoringSynthesis } from '../api/types';
import { LoggedRequestResponse } from '../api/monitoring';
import * as reduxTypes from '../types/monitoring';
import { DataAction, QueryAction, QueryListPayload, QueryListAction } from '.';
import { MonitoringDateMode } from '../reducers/monitoring';
import { DateQuery } from '../api';
// LIST

export const list: ActionCreator<QueryListAction> = (payload: QueryListPayload & {
    dateMode: MonitoringDateMode,
}) => ({
    ...payload,
    type: reduxTypes.LIST,
});

export const listSuccess: ActionCreator<DataAction> = (data: LoggedRequestResponse) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAILED,
    data,
});

// SYNTHESIS

export const synthesis: ActionCreator<QueryAction> = (payload: any) => ({
    ...payload,
    type: reduxTypes.SYNTHESIS,
});

export const synthesisSuccess: ActionCreator<DataAction> = (data: MonitoringSynthesis, query: DateQuery) => ({
    type: reduxTypes.SYNTHESIS_SUCCESS,
    data,
    query,
});

export const synthesisFailed: ActionCreator<DataAction> = (data: any, query: DateQuery) => ({
    type: reduxTypes.SYNTHESIS_FAILED,
    data,
    query,
});
