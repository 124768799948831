import api, { PaginatedListResponse, urlWithQuery } from './_client';
import { QueryListPayload } from '../actions';
import { DeliveryTour, NeerbyTag, GeoJson } from './types';

export interface DeliveryToursListResponse extends PaginatedListResponse<DeliveryTour> {}

export const details = (id: DeliveryTour['id']) => {
    return api.get<DeliveryTour>(`/admin/deliveryTours/${id}`);
};

export const list = (payload: QueryListPayload) => {
    return api.get<DeliveryToursListResponse>(urlWithQuery('/admin/deliveryTours', payload));
};

export const events = (id: DeliveryTour['id']) => {
    return api.get<NeerbyTag[]>(`/admin/deliveryTours/${id}/events`);
};

export const matchedRoutes = (id: DeliveryTour['id']) => {
    return api.get<GeoJson[]>(`/admin/deliveryTours/${id}/routing`);
};

export const sendMessage = (id: DeliveryTour['id'], payload: { message: string }) => {
    return api.post(`/admin/deliveryTours/${id}/message`, payload);
};
