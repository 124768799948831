import api, { PaginatedListResponse, urlWithQuery } from './_client';
import { QueryListPayload } from '../actions';
import { Customer } from './types';

export interface CustomersListResponse extends PaginatedListResponse<Customer> {}

export const list = (payload: QueryListPayload) => {
    return api.get<CustomersListResponse>(urlWithQuery('/admin/customers', payload));
};

export const details = (id: Customer['id']) => {
    return api.get<Customer>(`/admin/customers/${id}`);
};
