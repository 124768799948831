import * as React from 'react';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { Button, Card } from 'antd';

import messages from './Roles.messages';
import { RoleTableForm } from '.';

interface RoleCreateProps extends InjectedIntlProps {
    onCreateSuccess: () => void;
}

interface RoleCreateState {
    isRoleTableVisible: boolean;
}

class RoleCreate extends React.Component<RoleCreateProps> {
    public state: RoleCreateState = {
        isRoleTableVisible: false,
    };

    public onCreateButtonClick = () => {
        this.setState({ isRoleTableVisible: true });
    }

    public hideTable = () => {
        this.setState({ isRoleTableVisible: false });
    }

    public onCreateSuccess = () => {
        this.hideTable();
        this.props.onCreateSuccess();
    }

    public render() {
        const { isRoleTableVisible } = this.state;

        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {!isRoleTableVisible && (
                    <Button
                        onClick={this.onCreateButtonClick}
                        type="primary"
                    >
                        <FormattedMessage {...messages.addRole} />
                    </Button>
                )}
                {isRoleTableVisible && (
                    <Card id="role-create">
                        <RoleTableForm
                            onCreateCancel={this.hideTable}
                            onCreateSuccess={this.onCreateSuccess}
                        />
                    </Card>
                )}
            </div>
        );
    }
}

export default injectIntl(RoleCreate);
