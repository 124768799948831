import { defineMessages } from 'react-intl';

export default defineMessages({
    unauthorizedTitle: {
        id: 'unauthorized.title',
        defaultMessage: '403 Unauthorized access',
        description: 'Unauthorized page title',
    },
    unauthorizedDescription: {
        id: 'unauthorized.description',
        defaultMessage: 'You do not have access to this page, please contact your administrator.',
        description: 'Unauthorized page description',
    },
});
