import * as React from 'react';
import { Icon } from 'antd';
import { FormattedMessage } from 'react-intl';

import '../assets/styles/EmptyResult.less';

import ListMessages from '../locale/List.messages';

interface EmptyResultProps extends React.HTMLProps<JSX.Element> {
    text?: string | React.ReactNode;
    icon?: string;
}

const EmptyResult: React.SFC<EmptyResultProps> = ({ text, icon }) => (
    <div className="empty-result">
        <Icon type={icon || 'frown'} />
        {text ?
            <p>{text}</p> :
            <FormattedMessage {...ListMessages.noResult} />
        }
    </div>
);

export default EmptyResult;
