import { ActionCreator, Action } from 'redux';

import * as reduxTypes from '../types/ui';

// TOGGLE_SIDEBAR

export const toggleSidebar: ActionCreator<Action & { collapsed: boolean }> = (collapsed: boolean) => ({
    type: reduxTypes.TOGGLE_SIDEBAR,
    collapsed,
});
