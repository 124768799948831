import * as React from 'react';
import { Card } from 'antd';

import Metric, { MetricProps } from './Metric';

interface MetricCardProps extends MetricProps {
    altValue?: string | number | React.ReactNode;
    altValueMessage?: string | number | React.ReactNode;
}

const MetricCard: React.SFC<MetricCardProps> = ({ altValue, altValueMessage, ...metricProps }) => (
    <Card className="metric-card">
        <Metric
            {...metricProps}
        />
        <div className="card-footer">
            <p className="metric-card-alt">
                {altValue !== undefined && (
                    <span className="metric-card-alt-value">
                        {altValue}
                        &nbsp;
                    </span>
                )}
                {altValueMessage !== undefined && altValueMessage}
            </p>
        </div>
    </Card>
);

export default MetricCard;
