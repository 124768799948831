import { Action } from 'redux';
import { delay } from 'redux-saga';
import { call, takeLatest, put } from 'redux-saga/effects';
import * as reduxActions from '../actions/agencies';
import * as reduxTypes from '../types/agencies';
import * as api from '../api/agencies';
import { SearchQuery } from '../api';

export function* listSaga(action: Action & SearchQuery) {
    try {
        yield delay(action.throttling || 0);
        const response = yield call(api.list, action);
        return yield put(reduxActions.listSuccess(response));
    } catch (error) {
        return yield put(reduxActions.listFailed(error));
    }
}

export default function* agenciesSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
}
