import * as React from 'react';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';

import ButtonLink from '../../components/ButtonLink';
import EmptyResult from '../../components/EmptyResult';
import Content from '../../components/Content';
import messages from './NotFound.messages';
import genericMessages from '../../locale/Generic.messages';
import { getRoute, RoutePathName } from '../../routes';
import { Languages } from '../../components/IntlProvider';

const NotFound: React.SFC<InjectedIntlProps> = ({ intl }) => (
    <>
        <Content className="content-center">
            <FormattedMessage
                tagName="h1"
                {...messages.notFoundTitle}
            />
            <EmptyResult
                text={(
                    <FormattedMessage {...messages.notFoundDescription} />
                )}
            />
            <ButtonLink to={getRoute(intl.locale as Languages, RoutePathName.home)} type="primary">
                <FormattedMessage {...genericMessages.goBackToHome} />
            </ButtonLink>
        </Content>
    </>
);

export default injectIntl(NotFound);
