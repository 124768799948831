import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Typography, Tag, Row, Col, Menu, Empty } from 'antd';
import { ClickParam } from 'antd/lib/menu';
import {
    MonitoringDateMode, getDateMode, getSynthesis, MonitoringSynthesisState,
} from '../../store/reducers/monitoring';
import MonitorinMessage from '../../components/Monitoring.messages';

import MetricCard from '../../components/MetricCard';
import messages from './Monitoring.messages';
import ListMessages from '../../locale/List.messages';
import Header from '../../components/header/Header';
import Content from '../../components/Content';
import HeaderNav from '../../components/header/HeaderNav';
import * as MonitoringActions from '../../store/actions/monitoring';
import LoggedRequestsList, { LoggedRequestsListColumns } from './LoggedRequestsList';

const getDateModeMessage = (dateMode: MonitoringDateMode) => {
    switch (dateMode) {
        case MonitoringDateMode.today: return <FormattedMessage {...messages.todaysDeliveryTours} />;
        case MonitoringDateMode.yesterday: return <FormattedMessage {...messages.yesterdaysDeliveryTours} />;
        case MonitoringDateMode.before_yesterday:
            return <FormattedMessage {...messages.beforeYesterdayDeliveryTours} />;
    }
};

interface MonitoringProps extends InjectedIntlProps, RouteComponentProps {
    dateMode: MonitoringDateMode;
    synthesis: MonitoringSynthesisState;
    fetchSynthesis: typeof MonitoringActions.synthesis;
}

class Monitoring extends React.Component<MonitoringProps> {
    public LoggedRequestsListRef = React.createRef<typeof LoggedRequestsList>();

    public onChangeDate = (e: ClickParam) => {
        if (this.LoggedRequestsListRef.current) {
            // @ts-ignore
            this.listWrapperRef.current.getWrappedInstance().fetchData({
                dateMode: MonitoringDateMode[e.key as MonitoringDateMode],
            });
        }
    }
    public componentDidMount() {
        this.fetchData();
    }

    public fetchData = () => {
        const { fetchSynthesis, dateMode } = this.props;
        fetchSynthesis({dateMode});
    }

    public renderEmptyList = () => {
        const { dateMode } = this.props;
        return (
            <>
                <Empty
                    description={<FormattedMessage {...ListMessages.noDataForThisDate} />}
                />
            </>
        );
    }

    public setColumns = (columns: LoggedRequestsListColumns) => {
        const { dateMode } = this.props;
        return columns;
    }

    public render() {
        const { synthesis, dateMode, history, intl } = this.props;
        const headerLinks = {
            [location.pathname]: intl.formatMessage(MonitorinMessage.diagnosticHeader),
        };

        const dateSelectorMenu = (
            <Menu onClick={this.onChangeDate}>
                {Object.values(MonitoringDateMode).map((mode: MonitoringDateMode) => (
                    <Menu.Item
                        className={
                            dateMode === mode ?
                                'ant-dropdown-menu-item-selected' :
                                undefined
                        }
                        key={mode}
                    >
                        {getDateModeMessage(mode)}
                    </Menu.Item>
                ))}
            </Menu>
        );

        let diagColor = 'yellow';
        let diagnosticMessage = (<></>);

        if (synthesis.data) {
            if (synthesis.data.message === 'api-ko') {
                diagColor = 'red';
                diagnosticMessage = (
                    <FormattedMessage {...MonitorinMessage.diagnosticApiKO} />
                );
            } else {
                diagColor = 'green';
                diagnosticMessage = (
                    <FormattedMessage {...MonitorinMessage.diagnosticApiOK} />
                );
            }
        } else {
            diagnosticMessage = (
                <FormattedMessage {...MonitorinMessage.diagnosticRunning} />
            );
        }

        return (
            <>
                <Header>
                    <HeaderNav links={headerLinks} />
                </Header>
                <Content>
                    <Typography.Title level={2}>
                        <FormattedMessage {...MonitorinMessage.title} />
                    </Typography.Title>
                    <Row gutter={32} type="flex" className="fill-height" style={{ marginBottom: 40 }}>
                        <Col xs={24} sm={12} lg={6}>
                            <MetricCard
                                label={
                                    <FormattedMessage
                                        {...MonitorinMessage.loggedRequestsInError}
                                    />
                                }
                                loading={synthesis.loading}
                                value={synthesis.data ? synthesis.data.requestErrorCounter : 0}
                            />
                        </Col>
                        <Col xs={24} sm={12} lg={6}>
                            <MetricCard
                                label={<FormattedMessage {...MonitorinMessage.loggedRequestsOk} />}
                                loading={synthesis.loading}
                                value={synthesis.data ? synthesis.data.requestOkCounter : 0}
                            />
                        </Col>
                    </Row>
                    <Row gutter={32} type="flex" align="middle" justify="center" style={{ marginBottom: 40 }}>
                        <Col>
                            <Tag color={diagColor}>{diagnosticMessage}</Tag>
                        </Col>
                    </Row>
                    <LoggedRequestsList
                        emptyStateRenderer={this.renderEmptyList}
                        fetchPayload={{ dateMode }}
                        history={history}
                        // @ts-ignore
                        ref={this.listWrapperRef}
                        setColumns={this.setColumns}
                    />
                </Content>
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
    dateMode: getDateMode(state),
    synthesis: getSynthesis(state),
});

export default injectIntl(connect(
    mapStateToProps,
    {
        fetchSynthesis: MonitoringActions.synthesis,
    },
)(Monitoring));
