import React from 'react';

import { Address } from '../store/api/types';

import { capitalizeWords } from '../utils';

interface FullAddressProps {
    address: Address;
    withLineBreak?: boolean;
}

export const FullAddress: React.SFC<FullAddressProps> = ({ address, withLineBreak }) => {
    return (
        <>
            {`${address.street ? `${capitalizeWords(address.street).trim()}` : ''}`}
            {withLineBreak ? <br /> : null}
            {`
                ${address.zipcode ? ` ${address.zipcode}` : ''}
                ${address.city ? ` ${capitalizeWords(address.city).trim()}` : ''}
            `}
        </>
    );
};
