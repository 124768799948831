import { defineMessages } from 'react-intl';

export default defineMessages({
    noName: {
        id: 'organizations.no_name',
        defaultMessage: 'No name',
        description: 'Placeholder when there is no name for an organization',
    },
    createOrganization: {
        id: 'organizations.create_button',
        defaultMessage: 'Add an organization',
        description: 'Add organization button',
    },
    updateOrganization: {
        id: 'organizations.update_button',
        defaultMessage: 'Update an organization',
        description: 'Update organization button',
    },
});
