import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';
import { FormComponentProps } from 'antd/lib/form/Form';
import {
    Form, Input, Button, Row, Col, Checkbox,
} from 'antd';

import '../../assets/styles/Login.less';

import * as Auth from '../../store/actions/auth';
import { LoginPayload } from '../../store/api/auth';
import { AuthUser, getUser } from '../../store/reducers/auth';

import { IconLogo, IconLogoText, IconEzeeworld, IconEmail, IconLock } from '../../components/icons';
import genericMessages from '../../locale/Generic.messages';
import messages from './Login.messages';
import { getRoute, RoutePathName } from '../../routes';
import { Languages } from '../../components/IntlProvider';

interface LoginProps extends RouteComponentProps, FormComponentProps, InjectedIntlProps {
    login: (payload: LoginPayload) => void;
    user: AuthUser;
}

class Login extends React.Component<LoginProps> {

    public renderFrom() {
        const { form, intl, user } = this.props;
        const { getFieldDecorator } = form;
        let error = user.error ? <FormattedMessage {...genericMessages.error} /> : null;

        if (user.error && user.error.status && user.error.status === 401) {
            error = <FormattedMessage {...messages.invalidCredentials} />;
        }

        return (
            <Form onSubmit={this.onLogin} className="login-form">
                <FormattedMessage {...messages.welcome} tagName="h1" />
                <FormattedMessage {...messages.description} tagName="p" />
                <Form.Item>
                    {getFieldDecorator('username', {
                        rules: [{
                            required: true,
                            type: 'email',
                            message: intl.formatMessage(messages.emailError),
                        }],
                        validateTrigger: 'onBlur',
                    })(
                        <Input
                            prefix={<IconEmail />}
                            placeholder={intl.formatMessage(messages.emailPlaceholder)}
                            type="email"
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: intl.formatMessage(messages.passwordError) }],
                    })(
                        <Input.Password
                            prefix={<IconLock />}
                            placeholder={intl.formatMessage(messages.passwordPlaceholder)}
                        />,
                    )}
                </Form.Item>
                <Form.Item className="login-remember">
                    {getFieldDecorator('remember', {
                        valuePropName: 'checked',
                        initialValue: true,
                    })(
                        <Checkbox>
                            <FormattedMessage {...messages.rememberMe} />
                        </Checkbox>,
                    )}
                    <a className="login-form-forgot" href="">
                        <FormattedMessage {...messages.forgotPassword} />
                    </a>
                    {error ? (
                        <div className="login-error has-error">
                            <span className="ant-form-explain">{error}</span>
                        </div>
                    ) : null}
                </Form.Item>
                <Button type="primary" htmlType="submit" size="large" loading={user.loading} block>
                    <FormattedMessage {...messages.loginButton} />
                </Button>
            </Form>
        );
    }

    public render() {
        const { intl, user } = this.props;

        return user.isConnected ? (
            <Redirect to={getRoute(intl.locale as Languages, RoutePathName.home)} />
        ) : (
            <Row id="login-layout">
                <Col xs={24} md={14} className="login-left">
                    <div id="logo-wrapper">
                        <div>
                            <div className="logo-badge">
                                <IconLogo />
                            </div>
                            <IconLogoText />
                        </div>
                    </div>
                    <a
                        href="https://www.ezeeworld.com"
                        rel="noreferrer noopener"
                        target="_blank"
                        title="Ezeeworld"
                    >
                        <IconEzeeworld />
                    </a>
                </Col>
                <Col xs={24} md={10} className="login-right">
                    {this.renderFrom()}
                    <a
                        href="https://www.ezeeworld.com"
                        id="link-ezeeworld"
                        rel="noreferrer noopener"
                        target="_blank"
                        title="Ezeeworld"
                    >
                        <IconEzeeworld />
                    </a>
                </Col>
            </Row>
        );
    }

    private onLogin = (e: React.FormEvent) => {
        const { form, login } = this.props;
        e.preventDefault();
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            login(values);
        });
    }

}

const mapStateToProps = (state: any) => ({
    user: getUser(state),
});

const LoginForm = Form.create()(injectIntl(Login));

export default connect(
    mapStateToProps,
    { login: Auth.login },
)(LoginForm);
