import * as reduxTypes from '../types/ui';

import { MainReducerState } from '.';
import { Reducer } from 'redux';

export interface UiState {
    readonly isSidebarCollapsed: boolean;
}

const uiStateInitialState: UiState = {
    isSidebarCollapsed: false,
};

const reducer: Reducer<UiState> = (state = uiStateInitialState, { type, collapsed }) => {
    switch (type) {
        case reduxTypes.TOGGLE_SIDEBAR:
            return {
                ...state,
                isSidebarCollapsed: collapsed !== undefined ? collapsed : !state.isSidebarCollapsed,
            };

        default:
            return state;
    }
};

export default reducer;

export const getUi = (state: MainReducerState) => state.ui;
