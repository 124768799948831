import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Tag, Icon, Button, Popconfirm } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import * as UsersActions from '../../store/actions/users';
import { SearchQueryComponentState, SearchPaginationQuery } from '../../store/api';
import { User, PermissionRight } from '../../store/api/types';
import { getUsers, getPagination, getDeletes } from '../../store/reducers/users';
import { getUser, AuthUser } from '../../store/reducers/auth';
import { RequestState } from '../../store/reducers';
import { PaginationRequestState } from '../../store/reducers/_generics';

import { getFullName } from '../../utils';
import { isUserAllowed } from '../../utils/permissions';

import ListMessages from '../../locale/List.messages';
import GenericMessages from '../../locale/Generic.messages';
import Header from '../../components/header/Header';
import Content from '../../components/Content';
import HeaderNav from '../../components/header/HeaderNav';
import { ListItemTitle, List } from '../../components/list';
import MainMenuMessages from '../../components/MainMenu.messages';
import messages from './UsersList.messages';

import { UserDrawer } from './UserFormDrawer';
import { UserFormDrawer } from '.';

interface UsersListProps extends InjectedIntlProps, RouteComponentProps {
    deletes: RequestState;
    deleteUser: typeof UsersActions.del;
    fetchUsers: typeof UsersActions.list;
    user: AuthUser;
    users: User[];
    usersPagination: PaginationRequestState;
}

interface ComponentUsersListState extends SearchQueryComponentState {
    userIdToEdit?: User['id'];
}

class UsersList extends React.Component<UsersListProps, ComponentUsersListState> {
    public state: ComponentUsersListState = {};

    private formDrawer?: any = undefined;

    private columns: Array<ColumnProps<User>> = [
        {
            dataIndex: 'id',
            render: (text, user, index) => (
                <ListItemTitle
                    index={(this.props.usersPagination.pageSize * this.props.usersPagination.page) + index + 1}
                    text={getFullName(user.firstname, user.lastname)}
                />
            ),
        }, {
            dataIndex: 'email',
        }, {
            dataIndex: 'organization.name',
        }, {
            dataIndex: 'role',
            render: (role) => role ? (
                <Tag>
                    {role.name}
                </Tag>
            ) : undefined,
        },
    ];

    public componentDidMount() {
        this.fetchData();
    }

    public componentDidUpdate(prevProps: UsersListProps) {
        const { deletes } = this.props;

        if (prevProps.deletes.loading && !deletes.loading && deletes.success) {
            this.fetchData();
        }
    }

    public onOpenFormDrawer = () => {
        if (this.formDrawer) {
            this.formDrawer.showDrawer();
        }
    }

    public onCloseFormDrawer = () => {
        this.setState({ userIdToEdit: undefined });
    }

    public onClickEdit = (user: User) => {
        this.setState({ userIdToEdit: user.id }, this.onOpenFormDrawer);
    }

    public onChangePage = (page: number) => {
        const { search } = this.state;

        this.fetchData({
            search,
            page: page - 1,
        });
    }

    public onSearch = (search: string) => {
        this.setState({ search });
        this.fetchData({
            search,
            throttling: 200,
        });
    }

    public onDeleteConfirm = (id: User['id']) => {
        this.props.deleteUser(id);
    }

    public fetchData = (query?: SearchPaginationQuery) => {
        const { page, pageSize } = this.props.usersPagination;
        this.props.fetchUsers({
            page,
            pageSize,
            ...query,
            fetchOrganization: true,
        });
    }

    public rowKey = (record: User) => record.id!.toString();

    public render() {
        const { intl, usersPagination, user, users } = this.props;
        const { userIdToEdit } = this.state;
        const headerLinks = {
            [location.pathname]: intl.formatMessage(ListMessages.contentHeaderNavItemList),
        };
        const columns = [
            ...this.columns,
        ];

        if (isUserAllowed(user, 'users', PermissionRight.write)) {
            columns.push(
                {
                    title: 'Action',
                    key: 'edit',
                    width: 32,
                    render: (usr) => (
                        <Button
                            onClick={this.onClickEdit.bind(this, usr)}
                            shape="circle"
                            size="small"
                            type="default"
                        >
                            <Icon type="ellipsis" />
                        </Button>
                    ),
                },
                {
                    title: 'Action',
                    key: 'delete',
                    width: 32,
                    render: (usr) => (
                        <Popconfirm
                            onConfirm={this.onDeleteConfirm.bind(null, usr.id)}
                            placement="topRight"
                            title={<FormattedMessage {...GenericMessages.deleteConfirm} />}
                        >
                            <Button
                                shape="circle"
                                size="small"
                                type="danger"
                            >
                                <Icon type="delete" />
                            </Button>
                        </Popconfirm>
                    ),
                },
            );
        }

        return (
            <>
                <Header>
                    <HeaderNav links={headerLinks} />
                    <Button
                        onClick={this.onOpenFormDrawer}
                        type="primary"
                    >
                        <FormattedMessage {...messages.createUser} />
                    </Button>
                </Header>
                <Content>
                    <List<User>
                        columns={columns}
                        dataState={{
                            data: users,
                            loading: usersPagination.loading,
                            ...usersPagination,
                        }}
                        listTitle={<FormattedMessage {...MainMenuMessages.users} />}
                        onChangePage={this.onChangePage}
                        onSearch={this.onSearch}
                        rowKey={this.rowKey}
                    />
                    <UserFormDrawer
                        drawerTitle={
                            <FormattedMessage
                                {...messages[
                                userIdToEdit === undefined ?
                                    'createUser' :
                                    'updateUser'
                                ]}
                            />
                        }
                        onSuccess={this.fetchData}
                        onClose={this.onCloseFormDrawer}
                        userId={userIdToEdit}
                        // tslint:disable-next-line
                        wrappedComponentRef={(c: UserDrawer) => this.formDrawer = c}
                    />
                </Content>
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
    deletes: getDeletes(state),
    user: getUser(state),
    users: getUsers(state),
    usersPagination: getPagination(state),
});

export default injectIntl(connect(
    mapStateToProps,
    {
        fetchUsers: UsersActions.list,
        deleteUser: UsersActions.del,
    },
)(UsersList));
