import { combineReducers } from 'redux';
import { denormalize } from 'normalizr';

import * as reduxTypes from '../types/users';
import { User } from '../api/types';
import { Entities, arrayOfUsers, user } from '../schema';
import { MainReducerState, RequestState } from '.';
import {
    requestReducer, entityReducer, paginateReducer, EntityReducerState, PaginationRequestState,
} from './_generics';

export interface UsersState {
    readonly entities: EntityReducerState<User>;
    readonly pagination: PaginationRequestState;
    readonly creates: RequestState;
    readonly reads: RequestState;
    readonly updates: RequestState;
    readonly deletes: RequestState;
}

export default combineReducers<UsersState>({
    entities: entityReducer([
        reduxTypes.LIST_SUCCESS,
        reduxTypes.DETAILS_SUCCESS,
    ], Entities.users),
    pagination: paginateReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    }),
    creates: requestReducer({
        START: reduxTypes.CREATE,
        SUCCESS: reduxTypes.CREATE_SUCCESS,
        FAILED: reduxTypes.CREATE_FAILED,
    }),
    reads: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAILED,
    }),
    updates: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAILED,
    }),
    deletes: requestReducer({
        START: reduxTypes.DELETE,
        SUCCESS: reduxTypes.DELETE_SUCCESS,
        FAILED: reduxTypes.DELETE_FAILED,
    }),
});

export const getIds = (state: MainReducerState) => state.users.pagination.ids;
export const getUserById = (state: MainReducerState, id: User['id']) =>
    denormalize(id, user, { [Entities.users]: state.users.entities }) || undefined;
export const getUsers = (state: MainReducerState) =>
    denormalize(getIds(state), arrayOfUsers, { [Entities.users]: state.users.entities });
export const getPagination = (state: MainReducerState) => state.users.pagination;
export const getCreates = (state: MainReducerState) => state.users.creates;
export const getReads = (state: MainReducerState) => state.users.reads;
export const getUpdates = (state: MainReducerState) => state.users.updates;
export const getDeletes = (state: MainReducerState) => state.users.deletes;
