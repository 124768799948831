import { ActionCreator } from 'redux';

import { AgenciesListResponse } from '../api/agencies';
import * as reduxTypes from '../types/agencies';
import { DataAction, QueryListPayload, QueryListAction } from '.';

// LIST

export const list: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    ...payload,
    type: reduxTypes.LIST,
});

export const listSuccess: ActionCreator<DataAction> = (data: AgenciesListResponse) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAILED,
    data,
});
