import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

interface IconAngleProps extends IconProps {
    direction?: 'left' | 'top' | 'right' | 'bottom';
}

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12">
        <path d="M5.080,11.955 C4.854,11.955 4.627,11.859 4.475,11.671 L0.164,6.463 C-0.071,6.180 -0.071,5.774 0.164,5.491 L4.475,0.284 C4.744,-0.050 5.236,-0.095 5.575,0.173 C5.909,0.442 5.961,0.927 5.686,1.257 L1.778,5.977 L5.686,10.698 C5.961,11.028 5.909,11.513 5.575,11.782 C5.428,11.898 5.254,11.955 5.080,11.955 Z" />{/* tslint:disable:max-line-length */}
    </svg>
);

const IconAngle: React.SFC<IconAngleProps> = ({direction = 'left', ...props}) => (
    <Icon
        component={svg}
        className={`icon-angle rotate-${direction}`}
        {...props}
    />
);

export default IconAngle;
