import { combineReducers, Reducer } from 'redux';

import * as reduxTypes from '../types/dashboard';

import { MainReducerState, RequestState } from '.';
import { GlobalStats } from '../api/types';
import { DateQuery } from '../api';

export type DashboardStatsState = RequestState & {
    byDate: {
        [date: string]: GlobalStats,
    };
    data?: GlobalStats;
};

export interface DashboardState {
    readonly stats: DashboardStatsState;
}

const getDateKey = (query: DateQuery) => {
    return `${query.fromDate || ''}~${query.toDate || ''}`;
};

const statsInitialState: DashboardStatsState = {
    loading: true,
    byDate: {},
};

const stats: Reducer<DashboardStatsState> = (state = statsInitialState, { type, data, query }) => {
    switch (type) {
        case reduxTypes.STATS:
            return {
                ...state,
                error: undefined,
                loading: true,
            };
        case reduxTypes.STATS_SUCCESS: {
            const dateKey = getDateKey(query);

            return {
                ...state,
                byDate: {
                    ...state.byDate,
                    [dateKey]: data,
                    timestamp: new Date(),
                },
                loading: false,
            };
        }
        case reduxTypes.STATS_FAILED:
            return {
                ...state,
                loading: false,
                error: data,
            };

        default:
            return state;
    }
};

export default combineReducers<DashboardState>({
    stats,
});

export const getDashboardStatsByDate = (state: MainReducerState, query: Partial<DateQuery>) => {
    const dateKey = getDateKey(query);
    const result: DashboardStatsState = {
        ...state.dashboard.stats,
    };

    delete result.byDate;

    result.data = state.dashboard.stats.byDate[dateKey];

    return result;
};
