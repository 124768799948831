import fr_FR from 'antd/lib/locale-provider/fr_FR';

export default {
    ...fr_FR,
    Table: {
        ...fr_FR.Table,
        selectAll: 'Sélectionner la page courante',
        selectInvert: 'Inverser la page courante',
    },
    Upload: {
        uploading: 'Envoi...',
        removeFile: 'Supprimer',
        uploadError: 'Erreur',
        previewFile: 'Prévisualiser',
    },
};
