import { defineMessages } from 'react-intl';

export default defineMessages({
    vehicleAnomalyTypeDamaged: {
        id: 'vehicle.anomaly.type.damaged',
        defaultMessage: 'Damaged',
        description: 'type of a vehicle anomaly',
    },
    vehicleAnomalyTypeMissing: {
        id: 'vehicle.anomaly.type.missing',
        defaultMessage: 'Missing',
        description: 'type of a vehicle anomaly',
    },
    vehicleAnomalyTypeOther: {
        id: 'vehicle.anomaly.type.other',
        defaultMessage: 'Other',
        description: 'type of a vehicle anomaly',
    },
    vehicleAnomalyTypeUnknown: {
        id: 'vehicle.anomaly.type.unknown',
        defaultMessage: 'Unknown',
        description: 'type of a vehicle anomaly',
    },
    temperaturesOverTime: {
        id: 'vehicle.temperatures_over_time',
        defaultMessage: 'Temperatures over time',
        description: 'Temperature chart card header',
    },
});
