import { Action } from 'redux';
import { delay } from 'redux-saga';
import { call, takeEvery, takeLatest, put, all } from 'redux-saga/effects';
import { DateTime } from 'luxon';
import { normalize } from 'normalizr';

import * as reduxActions from '../actions/monitoring';
import * as reduxTypes from '../types/monitoring';
import * as api from '../api/monitoring';
import { SearchPaginationQuery, DateQuery } from '../api';
import { MonitoringDateMode } from '../reducers/monitoring';
import { arrayOfLoggedRequests } from '../schema';

export function* synthesisSaga(action: Action & DateQuery & {dateMode: MonitoringDateMode}) {
    try {
        const payload = {
            ...action,
        };
        switch (action.dateMode) {
            case MonitoringDateMode.today:
                payload.fromDate = DateTime.local().startOf('day').toISO();
                payload.toDate = DateTime.local().endOf('day').toISO();
                break;

            case MonitoringDateMode.yesterday:
                payload.fromDate = DateTime.local().minus({ days: 1 }).startOf('day').toISO();
                payload.toDate = DateTime.local().minus({ days: 1 }).endOf('day').toISO();
                break;

            case MonitoringDateMode.before_yesterday:
                payload.fromDate = DateTime.local().minus({ days: 2 }).startOf('day').toISO();
                payload.toDate = DateTime.local().minus({ days: 2 }).endOf('day').toISO();
                break;
        }

        const response = yield call(api.synthesis, payload);

        return yield put(reduxActions.synthesisSuccess(response, action));
    } catch (error) {
        return yield put(reduxActions.synthesisFailed(error, action));
    }
}

export function* listSaga(action: Action & SearchPaginationQuery & {
    dateMode: MonitoringDateMode,
}) {
    try {

        yield delay(action.throttling || 0);
        const payload = {
            ...action,
        };

        switch (action.dateMode) {
            case MonitoringDateMode.today:
                payload.fromDate = DateTime.local().startOf('day').toISO();
                payload.toDate = DateTime.local().endOf('day').toISO();
                break;

            case MonitoringDateMode.yesterday:
                payload.fromDate = DateTime.local().minus({ days: 1 }).startOf('day').toISO();
                payload.toDate = DateTime.local().minus({ days: 1 }).endOf('day').toISO();
                break;

            case MonitoringDateMode.before_yesterday:
                payload.fromDate = DateTime.local().minus({ days: 2 }).startOf('day').toISO();
                payload.toDate = DateTime.local().minus({ days: 2 }).endOf('day').toISO();
                break;

            default:
                payload.sort = 'date';
                payload.sortOrder = 'desc';
                break;
        }

        payload.sort = 'statusCode';
        payload.sortOrder = 'desc';

        const { items, ...rest } = yield call(api.list, payload);
        const normalizedItems = normalize(items, arrayOfLoggedRequests);

        return yield put(reduxActions.listSuccess({
            ...normalizedItems,
            ...rest,
        }));
    } catch (error) {
        return yield put(reduxActions.listFailed(error));
    }
}

export default function* monitoringSaga() {
    yield takeLatest(reduxTypes.SYNTHESIS, synthesisSaga);
    yield takeLatest(reduxTypes.LIST, listSaga);
}
