import * as React from 'react';
import { Tag } from 'antd';
import { TagProps } from 'antd/lib/tag';

import '../assets/styles/DarkTag.less';

const DarkTag: React.SFC<TagProps> = ({ children, color }) => (
    <Tag className={`dark${color ? `-${color}` : ''}`}>
        {children}
    </Tag>
);

export default DarkTag;
