import * as React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import './assets/styles/App.less';
import './assets/styles/List.less';
import './assets/styles/Typography.less';
import './assets/styles/Form.less';

import { ProtectedRoute, Can } from './components/auth';
import MainLayout from './components/MainLayout';
import NotFound from './pages/notFound';
import Unauthorized from './pages/unauthorized';

import Login from './pages/login';
import { Dashboard } from './pages/dashboard';
import { DeliveryTourDetails, DeliveryTourList } from './pages/deliveryTours';
import { UsersList } from './pages/users';
import { AgenciesList } from './pages/agencies';
import { DeliveryMenList, DeliveryMenDetails } from './pages/deliveryMen';
import { CustomersList } from './pages/customers';
import { OrganizationsList } from './pages/organizations';
import { Roles } from './pages/roles';
import { getRawRoute, RoutePathName } from './routes';
import { Languages } from './components/IntlProvider';
import CustomerDetails from './pages/customers/CustomerDetails';
import Monitoring from './pages/monitoring/Monitoring';

const App: React.SFC<InjectedIntlProps> = (props) => {
    const locale: Languages = props.intl.locale as Languages;
    return (
        <Router>
            <Switch>
                <Route exact path={getRawRoute(locale, RoutePathName.login)} component={Login} />
                <ProtectedRoute loginPath={getRawRoute(locale, RoutePathName.login)}>
                    <MainLayout>
                        <Switch>
                            <Route
                                exact
                                path={getRawRoute(locale, RoutePathName.unauthorized)}
                                component={Unauthorized}
                            />

                            <Route
                                exact
                                path={getRawRoute(locale, RoutePathName.notFound)}
                                component={NotFound}
                            />

                            {/* SUPERVISION */}

                            <Redirect
                                exact
                                from={getRawRoute(locale, RoutePathName.home)}
                                to={getRawRoute(locale, RoutePathName.dashboard)}
                            />

                            <Can
                                visit="dashboard"
                                exact
                                path={getRawRoute(locale, RoutePathName.dashboard)}
                                component={Dashboard}
                            />

                            <Can
                                visit="deliveryTours"
                                exact
                                path={getRawRoute(locale, RoutePathName.deliveryTourList)}
                                component={DeliveryTourList}
                            />
                            <Can
                                visit="deliveryTours"
                                exact
                                path={getRawRoute(locale, RoutePathName.deliveryTour)}
                                component={DeliveryTourDetails}
                            />

                            <Can
                                visit="customers"
                                exact
                                path={getRawRoute(locale, RoutePathName.customersList)}
                                component={CustomersList}
                            />
                            <Can
                                visit="customers"
                                exact
                                path={getRawRoute(locale, RoutePathName.customer)}
                                component={CustomerDetails}
                            />

                            <Can
                                visit="agencies"
                                exact
                                path={getRawRoute(locale, RoutePathName.agenciesList)}
                                component={AgenciesList}
                            />

                            <Can
                                visit="deliveryMen"
                                exact
                                path={getRawRoute(locale, RoutePathName.deliveryMenList)}
                                component={DeliveryMenList}
                            />
                            <Can
                                visit="deliveryMen"
                                exact
                                path={getRawRoute(locale, RoutePathName.deliveryMan)}
                                component={DeliveryMenDetails}
                            />
                            <Can
                                visit="monitoring"
                                exact
                                path={getRawRoute(locale, RoutePathName.monitoring)}
                                component={Monitoring}
                            />

                            {/* ADMINISTRATION */}

                            <Can
                                visit="users"
                                exact
                                path={getRawRoute(locale, RoutePathName.usersList)}
                                component={UsersList}
                            />

                            <Can
                                visit="organizations"
                                exact
                                path={getRawRoute(locale, RoutePathName.organizationsList)}
                                component={OrganizationsList}
                            />

                            <Can
                                visit="roles"
                                exact
                                path={getRawRoute(locale, RoutePathName.roles)}
                                component={Roles}
                            />

                            <Route /* tslint:disable-next-line jsx-no-lambda */
                                render={() => <Redirect to={getRawRoute(locale, RoutePathName.notFound)} />}
                            />
                        </Switch>
                    </MainLayout>
                </ProtectedRoute>
            </Switch>
        </Router>
    );
};

export default injectIntl(App);
