import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Popover, Tabs, Form, Input, Button, Icon } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';

import { Delivery, DeliveryTour, Customer } from '../store/api/types';
import * as DeliveryToursActions from '../store/actions/deliveryTours';
import * as DeliveriesActions from '../store/actions/deliveries';

import { IconSend } from './icons';
import GenericMessages from '../locale/Generic.messages';
import FormMessages from '../locale/Form.messages';
import messages from './SendMessagePopover.messages';

import '../assets/styles/SendMessagePopover.less';
import { getSendMessageByDeliveryId } from '../store/reducers/deliveries';
import { getSendMessage as getDeliveryTourSendMessage } from '../store/reducers/deliveryTours';
import { MainReducerState, RequestState } from '../store/reducers';

interface SendMessagePopoverProps extends FormComponentProps, InjectedIntlProps {
    customerId?: Customer['id'];
    deliveryTourId?: DeliveryTour['id'];
    deliveryTourSendMessage: RequestState;
    deliverySendMessage?: RequestState;
    deliveryId?: Delivery['id'];
    recipient: string;
    sendDeliveryTourMessage: typeof DeliveryToursActions.sendMessage;
    sendDeliveryMessage: typeof DeliveriesActions.sendMessage;
}

interface SendMessagePopoverState {
    success: boolean;
}

class SendMessagePopover extends React.Component<SendMessagePopoverProps, SendMessagePopoverState> {
    public state: SendMessagePopoverState = {
        success: false,
    };

    private successTimeout: number = 0;
    private isComponentMounted: boolean = false;

    public componentDidMount() {
        this.isComponentMounted = true;
    }

    public componentDidUpdate(prevProps: SendMessagePopoverProps) {
        const {
            deliveryId, deliveryTourId, deliverySendMessage, deliveryTourSendMessage, form,
        } = this.props;

        if (
            (
                deliveryTourId &&
                prevProps.deliveryTourSendMessage.loading &&
                !deliveryTourSendMessage.loading &&
                deliveryTourSendMessage.success
            ) ||
            (
                deliveryId &&
                prevProps.deliverySendMessage &&
                prevProps.deliverySendMessage.loading &&
                deliverySendMessage &&
                !deliverySendMessage.loading &&
                deliverySendMessage.success
            )
        ) {
            form.resetFields();
            this.setState({ success: true }, () => {
                this.successTimeout = window.setTimeout(() => {
                    if (this.isComponentMounted) {
                        this.setState({ success: false });
                    }
                }, 4000);
            });
        }
    }

    public componentWillUnmount() {
        this.isComponentMounted = false;
    }

    public onSubmit = (e?: React.FormEvent) => {
        const {
            form, deliveryId, deliveryTourId, sendDeliveryMessage, sendDeliveryTourMessage,
        } = this.props;
        if (e) {
            e.preventDefault();
        }
        form.validateFieldsAndScroll(async (err, val) => {
            if (err) {
                return;
            }

            if (deliveryTourId) {
                sendDeliveryTourMessage(deliveryTourId, val);
            } else if (deliveryId) {
                sendDeliveryMessage(deliveryId, val);
            }
        });
    }

    public renderContent = () => {
        const {
            deliveryId, deliveryTourId, deliverySendMessage, deliveryTourSendMessage, form, intl, recipient,
        } = this.props;
        const { success } = this.state;
        let loading = false;

        if (deliveryId) {
            loading = !!deliverySendMessage && deliverySendMessage.loading;
        }
        if (deliveryTourId) {
            loading = deliveryTourSendMessage.loading;
        }

        return (
            <Tabs>
                <Tabs.TabPane
                    key="1"
                    tab={<FormattedMessage {...GenericMessages.textMessage} />}
                >
                    <p className="send-message-popover-recipient">
                        <FormattedMessage {...messages.toRecipient} values={{ recipient }} />
                    </p>
                    <Form onSubmit={this.onSubmit}>
                        <Form.Item>
                            {form.getFieldDecorator('message', {
                                rules: [{
                                    required: true,
                                    message: intl.formatMessage(FormMessages.requiredError),
                                }],
                            })(
                                <Input.TextArea
                                    autosize={{ minRows: 3, maxRows: 3 }}
                                    placeholder={intl.formatMessage(GenericMessages.message)}
                                />,
                            )}
                        </Form.Item>
                        <Button
                            htmlType="submit"
                            icon={success ? 'check' : undefined}
                            loading={loading}
                            type="primary"
                            block
                        >
                            {success ?
                                <FormattedMessage {...messages.messageSent} /> :
                                <IconSend />
                            }
                        </Button>
                    </Form>
                </Tabs.TabPane>
            </Tabs>
        );
    }

    public render() {
        const { children } = this.props;

        return (
            <Popover
                content={this.renderContent()}
                overlayClassName="send-message-popover"
                trigger="click"
            >
                {children}
            </Popover>
        );
    }
}

const SendMessagePopoverForm = Form.create<SendMessagePopoverProps>()(SendMessagePopover);

const mapStateToProps = (state: MainReducerState, { deliveryId }: { deliveryId?: Delivery['id'] }) => ({
    deliverySendMessage: getSendMessageByDeliveryId(state, deliveryId),
    deliveryTourSendMessage: getDeliveryTourSendMessage(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
    sendDeliveryMessage: DeliveriesActions.sendMessage,
    sendDeliveryTourMessage: DeliveryToursActions.sendMessage,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(SendMessagePopoverForm));
