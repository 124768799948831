import { call, takeLatest, put } from 'redux-saga/effects';

import * as reduxActions from '../actions/vehicles';
import * as reduxTypes from '../types/vehicles';
import * as api from '../api/vehicles';
import { IdAction, QueryListAction } from '../actions';
import { Vehicle } from '../api/types';

export function* temperatureSaga(action: QueryListAction & IdAction<Vehicle['id']>) {
    const {id, type, ...query} = action;
    try {
        const response = yield call(api.temperature, id, query);
        return yield put(reduxActions.temperatureSuccess(id, response));
    } catch (error) {
        return yield put(reduxActions.temperatureFailed(id, error));
    }
}

export default function* usersSaga() {
    yield takeLatest(reduxTypes.TEMPERATURE, temperatureSaga);
}
