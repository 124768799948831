export default {
    "agency": {
        "x": 0,
        "y": 588,
        "width": 36,
        "height": 48
    },
    "bike": {
        "x": 650,
        "y": 1174,
        "width": 38,
        "height": 76
    },
    "cluster-1": {
        "x": 520,
        "y": 914,
        "width": 64,
        "height": 64
    },
    "cluster-2": {
        "x": 650,
        "y": 914,
        "width": 64,
        "height": 64
    },
    "cluster-3": {
        "x": 171,
        "y": 784,
        "width": 64,
        "height": 64
    },
    "cluster-4": {
        "x": 821,
        "y": 784,
        "width": 64,
        "height": 64
    },
    "cluster-5": {
        "x": 455,
        "y": 1109,
        "width": 64,
        "height": 64
    },
    "cluster-6": {
        "x": 585,
        "y": 1174,
        "width": 64,
        "height": 64
    },
    "cluster-7": {
        "x": 715,
        "y": 979,
        "width": 64,
        "height": 64
    },
    "cluster-8": {
        "x": 195,
        "y": 1044,
        "width": 64,
        "height": 64
    },
    "cluster-9": {
        "x": 325,
        "y": 1044,
        "width": 64,
        "height": 64
    },
    "cluster-10": {
        "x": 195,
        "y": 914,
        "width": 64,
        "height": 64
    },
    "cluster-11": {
        "x": 325,
        "y": 914,
        "width": 64,
        "height": 64
    },
    "cluster-12": {
        "x": 260,
        "y": 914,
        "width": 64,
        "height": 64
    },
    "cluster-13": {
        "x": 130,
        "y": 914,
        "width": 64,
        "height": 64
    },
    "cluster-14": {
        "x": 0,
        "y": 914,
        "width": 64,
        "height": 64
    },
    "cluster-15": {
        "x": 650,
        "y": 849,
        "width": 64,
        "height": 64
    },
    "cluster-16": {
        "x": 780,
        "y": 849,
        "width": 64,
        "height": 64
    },
    "cluster-17": {
        "x": 910,
        "y": 849,
        "width": 64,
        "height": 64
    },
    "cluster-18": {
        "x": 845,
        "y": 849,
        "width": 64,
        "height": 64
    },
    "cluster-19": {
        "x": 390,
        "y": 914,
        "width": 64,
        "height": 64
    },
    "cluster-20": {
        "x": 845,
        "y": 914,
        "width": 64,
        "height": 64
    },
    "cluster-21": {
        "x": 0,
        "y": 979,
        "width": 64,
        "height": 64
    },
    "cluster-22": {
        "x": 195,
        "y": 979,
        "width": 64,
        "height": 64
    },
    "cluster-23": {
        "x": 65,
        "y": 979,
        "width": 64,
        "height": 64
    },
    "cluster-24": {
        "x": 130,
        "y": 979,
        "width": 64,
        "height": 64
    },
    "cluster-25": {
        "x": 910,
        "y": 914,
        "width": 64,
        "height": 64
    },
    "cluster-26": {
        "x": 780,
        "y": 914,
        "width": 64,
        "height": 64
    },
    "cluster-27": {
        "x": 455,
        "y": 914,
        "width": 64,
        "height": 64
    },
    "cluster-28": {
        "x": 585,
        "y": 914,
        "width": 64,
        "height": 64
    },
    "cluster-29": {
        "x": 715,
        "y": 914,
        "width": 64,
        "height": 64
    },
    "cluster-30": {
        "x": 585,
        "y": 849,
        "width": 64,
        "height": 64
    },
    "cluster-31": {
        "x": 886,
        "y": 784,
        "width": 64,
        "height": 64
    },
    "cluster-32": {
        "x": 431,
        "y": 784,
        "width": 64,
        "height": 64
    },
    "cluster-33": {
        "x": 561,
        "y": 784,
        "width": 64,
        "height": 64
    },
    "cluster-34": {
        "x": 691,
        "y": 784,
        "width": 64,
        "height": 64
    },
    "cluster-35": {
        "x": 626,
        "y": 784,
        "width": 64,
        "height": 64
    },
    "cluster-36": {
        "x": 366,
        "y": 784,
        "width": 64,
        "height": 64
    },
    "cluster-37": {
        "x": 301,
        "y": 784,
        "width": 64,
        "height": 64
    },
    "cluster-38": {
        "x": 41,
        "y": 784,
        "width": 64,
        "height": 64
    },
    "cluster-39": {
        "x": 106,
        "y": 784,
        "width": 64,
        "height": 64
    },
    "cluster-40": {
        "x": 236,
        "y": 784,
        "width": 64,
        "height": 64
    },
    "cluster-41": {
        "x": 496,
        "y": 784,
        "width": 64,
        "height": 64
    },
    "cluster-42": {
        "x": 756,
        "y": 784,
        "width": 64,
        "height": 64
    },
    "cluster-43": {
        "x": 195,
        "y": 849,
        "width": 64,
        "height": 64
    },
    "cluster-44": {
        "x": 325,
        "y": 849,
        "width": 64,
        "height": 64
    },
    "cluster-45": {
        "x": 520,
        "y": 849,
        "width": 64,
        "height": 64
    },
    "cluster-46": {
        "x": 390,
        "y": 849,
        "width": 64,
        "height": 64
    },
    "cluster-47": {
        "x": 455,
        "y": 849,
        "width": 64,
        "height": 64
    },
    "cluster-48": {
        "x": 260,
        "y": 849,
        "width": 64,
        "height": 64
    },
    "cluster-49": {
        "x": 130,
        "y": 849,
        "width": 64,
        "height": 64
    },
    "cluster-50": {
        "x": 951,
        "y": 784,
        "width": 64,
        "height": 64
    },
    "cluster-51": {
        "x": 65,
        "y": 849,
        "width": 64,
        "height": 64
    },
    "cluster-52": {
        "x": 0,
        "y": 849,
        "width": 64,
        "height": 64
    },
    "cluster-53": {
        "x": 260,
        "y": 979,
        "width": 64,
        "height": 64
    },
    "cluster-54": {
        "x": 715,
        "y": 849,
        "width": 64,
        "height": 64
    },
    "cluster-55": {
        "x": 390,
        "y": 1109,
        "width": 64,
        "height": 64
    },
    "cluster-56": {
        "x": 520,
        "y": 1109,
        "width": 64,
        "height": 64
    },
    "cluster-57": {
        "x": 715,
        "y": 1109,
        "width": 64,
        "height": 64
    },
    "cluster-58": {
        "x": 585,
        "y": 1109,
        "width": 64,
        "height": 64
    },
    "cluster-59": {
        "x": 650,
        "y": 1109,
        "width": 64,
        "height": 64
    },
    "cluster-60": {
        "x": 325,
        "y": 1109,
        "width": 64,
        "height": 64
    },
    "cluster-61": {
        "x": 0,
        "y": 1109,
        "width": 64,
        "height": 64
    },
    "cluster-62": {
        "x": 130,
        "y": 1109,
        "width": 64,
        "height": 64
    },
    "cluster-63": {
        "x": 260,
        "y": 1109,
        "width": 64,
        "height": 64
    },
    "cluster-64": {
        "x": 780,
        "y": 1109,
        "width": 64,
        "height": 64
    },
    "cluster-65": {
        "x": 65,
        "y": 1109,
        "width": 64,
        "height": 64
    },
    "cluster-66": {
        "x": 845,
        "y": 1109,
        "width": 64,
        "height": 64
    },
    "cluster-67": {
        "x": 260,
        "y": 1174,
        "width": 64,
        "height": 64
    },
    "cluster-68": {
        "x": 325,
        "y": 1174,
        "width": 64,
        "height": 64
    },
    "cluster-69": {
        "x": 455,
        "y": 1174,
        "width": 64,
        "height": 64
    },
    "cluster-70": {
        "x": 520,
        "y": 1174,
        "width": 64,
        "height": 64
    },
    "cluster-71": {
        "x": 390,
        "y": 1174,
        "width": 64,
        "height": 64
    },
    "cluster-72": {
        "x": 910,
        "y": 1109,
        "width": 64,
        "height": 64
    },
    "cluster-73": {
        "x": 195,
        "y": 1174,
        "width": 64,
        "height": 64
    },
    "cluster-74": {
        "x": 0,
        "y": 1174,
        "width": 64,
        "height": 64
    },
    "cluster-75": {
        "x": 130,
        "y": 1174,
        "width": 64,
        "height": 64
    },
    "cluster-76": {
        "x": 65,
        "y": 1174,
        "width": 64,
        "height": 64
    },
    "cluster-77": {
        "x": 195,
        "y": 1109,
        "width": 64,
        "height": 64
    },
    "cluster-78": {
        "x": 390,
        "y": 979,
        "width": 64,
        "height": 64
    },
    "cluster-79": {
        "x": 910,
        "y": 1044,
        "width": 64,
        "height": 64
    },
    "cluster-80": {
        "x": 845,
        "y": 979,
        "width": 64,
        "height": 64
    },
    "cluster-81": {
        "x": 0,
        "y": 1044,
        "width": 64,
        "height": 64
    },
    "cluster-82": {
        "x": 910,
        "y": 979,
        "width": 64,
        "height": 64
    },
    "cluster-83": {
        "x": 780,
        "y": 979,
        "width": 64,
        "height": 64
    },
    "cluster-84": {
        "x": 650,
        "y": 979,
        "width": 64,
        "height": 64
    },
    "cluster-85": {
        "x": 325,
        "y": 979,
        "width": 64,
        "height": 64
    },
    "cluster-86": {
        "x": 455,
        "y": 979,
        "width": 64,
        "height": 64
    },
    "cluster-87": {
        "x": 585,
        "y": 979,
        "width": 64,
        "height": 64
    },
    "cluster-88": {
        "x": 520,
        "y": 979,
        "width": 64,
        "height": 64
    },
    "cluster-89": {
        "x": 65,
        "y": 1044,
        "width": 64,
        "height": 64
    },
    "cluster-90": {
        "x": 520,
        "y": 1044,
        "width": 64,
        "height": 64
    },
    "cluster-91": {
        "x": 650,
        "y": 1044,
        "width": 64,
        "height": 64
    },
    "cluster-92": {
        "x": 845,
        "y": 1044,
        "width": 64,
        "height": 64
    },
    "cluster-93": {
        "x": 715,
        "y": 1044,
        "width": 64,
        "height": 64
    },
    "cluster-94": {
        "x": 780,
        "y": 1044,
        "width": 64,
        "height": 64
    },
    "cluster-95": {
        "x": 585,
        "y": 1044,
        "width": 64,
        "height": 64
    },
    "cluster-96": {
        "x": 455,
        "y": 1044,
        "width": 64,
        "height": 64
    },
    "cluster-97": {
        "x": 130,
        "y": 1044,
        "width": 64,
        "height": 64
    },
    "cluster-98": {
        "x": 260,
        "y": 1044,
        "width": 64,
        "height": 64
    },
    "cluster-99": {
        "x": 390,
        "y": 1044,
        "width": 64,
        "height": 64
    },
    "cluster-100": {
        "x": 65,
        "y": 914,
        "width": 64,
        "height": 64
    },
    "pin-default-1": {
        "x": 697,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-2": {
        "x": 41,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-default-3": {
        "x": 611,
        "y": 588,
        "width": 40,
        "height": 48
    },
    "pin-default-4": {
        "x": 656,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-default-5": {
        "x": 902,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-default-6": {
        "x": 820,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-default-7": {
        "x": 984,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-default-8": {
        "x": 738,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-default-9": {
        "x": 0,
        "y": 784,
        "width": 40,
        "height": 48
    },
    "pin-default-10": {
        "x": 984,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-11": {
        "x": 78,
        "y": 588,
        "width": 40,
        "height": 48
    },
    "pin-default-12": {
        "x": 119,
        "y": 588,
        "width": 40,
        "height": 48
    },
    "pin-default-13": {
        "x": 201,
        "y": 588,
        "width": 40,
        "height": 48
    },
    "pin-default-14": {
        "x": 242,
        "y": 588,
        "width": 40,
        "height": 48
    },
    "pin-default-15": {
        "x": 160,
        "y": 588,
        "width": 40,
        "height": 48
    },
    "pin-default-16": {
        "x": 943,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-17": {
        "x": 902,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-18": {
        "x": 574,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-19": {
        "x": 615,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-20": {
        "x": 738,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-21": {
        "x": 820,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-22": {
        "x": 861,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-23": {
        "x": 779,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-24": {
        "x": 283,
        "y": 588,
        "width": 40,
        "height": 48
    },
    "pin-default-25": {
        "x": 406,
        "y": 588,
        "width": 40,
        "height": 48
    },
    "pin-default-26": {
        "x": 775,
        "y": 588,
        "width": 40,
        "height": 48
    },
    "pin-default-27": {
        "x": 816,
        "y": 588,
        "width": 40,
        "height": 48
    },
    "pin-default-28": {
        "x": 734,
        "y": 588,
        "width": 40,
        "height": 48
    },
    "pin-default-29": {
        "x": 857,
        "y": 588,
        "width": 40,
        "height": 48
    },
    "pin-default-30": {
        "x": 980,
        "y": 588,
        "width": 40,
        "height": 48
    },
    "pin-default-31": {
        "x": 0,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-default-32": {
        "x": 939,
        "y": 588,
        "width": 40,
        "height": 48
    },
    "pin-default-33": {
        "x": 693,
        "y": 588,
        "width": 40,
        "height": 48
    },
    "pin-default-34": {
        "x": 652,
        "y": 588,
        "width": 40,
        "height": 48
    },
    "pin-default-35": {
        "x": 324,
        "y": 588,
        "width": 40,
        "height": 48
    },
    "pin-default-36": {
        "x": 365,
        "y": 588,
        "width": 40,
        "height": 48
    },
    "pin-default-37": {
        "x": 447,
        "y": 588,
        "width": 40,
        "height": 48
    },
    "pin-default-38": {
        "x": 488,
        "y": 588,
        "width": 40,
        "height": 48
    },
    "pin-default-39": {
        "x": 570,
        "y": 588,
        "width": 40,
        "height": 48
    },
    "pin-default-40": {
        "x": 529,
        "y": 588,
        "width": 40,
        "height": 48
    },
    "pin-default-41": {
        "x": 656,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-42": {
        "x": 533,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-43": {
        "x": 492,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-default-44": {
        "x": 533,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-default-45": {
        "x": 451,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-default-46": {
        "x": 574,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-default-47": {
        "x": 615,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-default-48": {
        "x": 697,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-default-49": {
        "x": 738,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-default-50": {
        "x": 410,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-default-51": {
        "x": 369,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-default-52": {
        "x": 41,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-default-53": {
        "x": 82,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-default-54": {
        "x": 164,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-default-55": {
        "x": 205,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-default-56": {
        "x": 287,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-default-57": {
        "x": 328,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-default-58": {
        "x": 246,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-default-59": {
        "x": 779,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-default-60": {
        "x": 246,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-61": {
        "x": 287,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-62": {
        "x": 205,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-63": {
        "x": 328,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-64": {
        "x": 369,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-65": {
        "x": 451,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-66": {
        "x": 492,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-67": {
        "x": 410,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-68": {
        "x": 164,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-69": {
        "x": 123,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-70": {
        "x": 861,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-default-71": {
        "x": 943,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-default-72": {
        "x": 984,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-default-73": {
        "x": 41,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-74": {
        "x": 82,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-75": {
        "x": 0,
        "y": 539,
        "width": 40,
        "height": 48
    },
    "pin-default-76": {
        "x": 123,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-default-77": {
        "x": 533,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-default-78": {
        "x": 0,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-default-79": {
        "x": 41,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-default-80": {
        "x": 82,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-default-81": {
        "x": 123,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-default-82": {
        "x": 205,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-default-83": {
        "x": 246,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-default-84": {
        "x": 164,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-default-85": {
        "x": 943,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-default-86": {
        "x": 902,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-default-87": {
        "x": 574,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-default-88": {
        "x": 615,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-default-89": {
        "x": 697,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-default-90": {
        "x": 820,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-default-91": {
        "x": 861,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-default-92": {
        "x": 779,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-default-93": {
        "x": 656,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-default-94": {
        "x": 656,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-default-95": {
        "x": 779,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-default-96": {
        "x": 820,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-default-97": {
        "x": 738,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-default-98": {
        "x": 697,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-default-99": {
        "x": 943,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-default-100": {
        "x": 37,
        "y": 588,
        "width": 40,
        "height": 48
    },
    "pin-danger-1": {
        "x": 287,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-danger-2": {
        "x": 82,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-danger-3": {
        "x": 205,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-danger-4": {
        "x": 984,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-danger-5": {
        "x": 123,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-danger-6": {
        "x": 41,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-danger-7": {
        "x": 902,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-danger-8": {
        "x": 656,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-danger-9": {
        "x": 902,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-danger-10": {
        "x": 902,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-danger-11": {
        "x": 861,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-danger-12": {
        "x": 410,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-danger-13": {
        "x": 328,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-danger-14": {
        "x": 369,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-danger-15": {
        "x": 451,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-danger-16": {
        "x": 492,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-danger-17": {
        "x": 574,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-danger-18": {
        "x": 615,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-danger-19": {
        "x": 533,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-danger-20": {
        "x": 164,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-danger-21": {
        "x": 451,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-danger-22": {
        "x": 492,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-danger-23": {
        "x": 574,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-danger-24": {
        "x": 615,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-danger-25": {
        "x": 697,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-danger-26": {
        "x": 738,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-danger-27": {
        "x": 656,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-danger-28": {
        "x": 533,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-danger-29": {
        "x": 410,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-danger-30": {
        "x": 123,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-danger-31": {
        "x": 205,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-danger-32": {
        "x": 246,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-danger-33": {
        "x": 328,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-danger-34": {
        "x": 369,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-danger-35": {
        "x": 287,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-danger-36": {
        "x": 779,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-danger-37": {
        "x": 902,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-danger-38": {
        "x": 246,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-danger-39": {
        "x": 287,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-danger-40": {
        "x": 328,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-danger-41": {
        "x": 369,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-danger-42": {
        "x": 451,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-danger-43": {
        "x": 492,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-danger-44": {
        "x": 410,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-danger-45": {
        "x": 164,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-danger-46": {
        "x": 123,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-danger-47": {
        "x": 820,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-danger-48": {
        "x": 861,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-danger-49": {
        "x": 943,
        "y": 637,
        "width": 40,
        "height": 48
    },
    "pin-danger-50": {
        "x": 41,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-danger-51": {
        "x": 82,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-danger-52": {
        "x": 0,
        "y": 686,
        "width": 40,
        "height": 48
    },
    "pin-danger-53": {
        "x": 898,
        "y": 588,
        "width": 40,
        "height": 48
    },
    "pin-danger-54": {
        "x": 41,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-danger-55": {
        "x": 0,
        "y": 490,
        "width": 40,
        "height": 48
    },
    "pin-danger-56": {
        "x": 287,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-danger-57": {
        "x": 246,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-danger-58": {
        "x": 328,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-danger-59": {
        "x": 205,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-danger-60": {
        "x": 82,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-danger-61": {
        "x": 369,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-danger-62": {
        "x": 492,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-danger-63": {
        "x": 615,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-danger-64": {
        "x": 574,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-danger-65": {
        "x": 656,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-danger-66": {
        "x": 533,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-danger-67": {
        "x": 451,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-danger-68": {
        "x": 410,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-danger-69": {
        "x": 164,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-danger-70": {
        "x": 656,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-danger-71": {
        "x": 615,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-danger-72": {
        "x": 697,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-danger-73": {
        "x": 574,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-danger-74": {
        "x": 492,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-danger-75": {
        "x": 738,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-danger-76": {
        "x": 861,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-danger-77": {
        "x": 984,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-danger-78": {
        "x": 943,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-danger-79": {
        "x": 0,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-danger-80": {
        "x": 820,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-danger-81": {
        "x": 779,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-danger-82": {
        "x": 697,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-danger-83": {
        "x": 820,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-danger-84": {
        "x": 574,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-danger-85": {
        "x": 533,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-danger-86": {
        "x": 615,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-danger-87": {
        "x": 492,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-danger-88": {
        "x": 410,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-danger-89": {
        "x": 369,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-danger-90": {
        "x": 779,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-danger-91": {
        "x": 902,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-danger-92": {
        "x": 861,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-danger-93": {
        "x": 943,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-danger-94": {
        "x": 820,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-danger-95": {
        "x": 738,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-danger-96": {
        "x": 697,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-danger-97": {
        "x": 451,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-danger-98": {
        "x": 328,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-danger-99": {
        "x": 943,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-danger-100": {
        "x": 984,
        "y": 735,
        "width": 40,
        "height": 48
    },
    "pin-success-1": {
        "x": 41,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-success-2": {
        "x": 123,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-3": {
        "x": 82,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-success-4": {
        "x": 287,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-5": {
        "x": 410,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-success-6": {
        "x": 492,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-7": {
        "x": 287,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-success-8": {
        "x": 492,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-success-9": {
        "x": 0,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-success-10": {
        "x": 861,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-success-11": {
        "x": 779,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-success-12": {
        "x": 738,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-success-13": {
        "x": 0,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-success-14": {
        "x": 123,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-success-15": {
        "x": 246,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-success-16": {
        "x": 205,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-success-17": {
        "x": 287,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-success-18": {
        "x": 164,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-success-19": {
        "x": 82,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-success-20": {
        "x": 533,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-success-21": {
        "x": 451,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-success-22": {
        "x": 820,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-success-23": {
        "x": 779,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-success-24": {
        "x": 861,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-success-25": {
        "x": 738,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-success-26": {
        "x": 656,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-success-27": {
        "x": 615,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-success-28": {
        "x": 902,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-success-29": {
        "x": 0,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-30": {
        "x": 82,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-31": {
        "x": 164,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-32": {
        "x": 41,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-33": {
        "x": 984,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-success-34": {
        "x": 943,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-success-35": {
        "x": 697,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-success-36": {
        "x": 574,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-success-37": {
        "x": 164,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-success-38": {
        "x": 123,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-success-39": {
        "x": 205,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-success-40": {
        "x": 0,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-success-41": {
        "x": 246,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-success-42": {
        "x": 369,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-success-43": {
        "x": 492,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-success-44": {
        "x": 451,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-success-45": {
        "x": 533,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-success-46": {
        "x": 410,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-success-47": {
        "x": 328,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-success-48": {
        "x": 287,
        "y": 0,
        "width": 40,
        "height": 48
    },
    "pin-success-49": {
        "x": 205,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-50": {
        "x": 41,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-success-51": {
        "x": 0,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-success-52": {
        "x": 82,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-success-53": {
        "x": 984,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-54": {
        "x": 902,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-55": {
        "x": 861,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-56": {
        "x": 123,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-success-57": {
        "x": 246,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-success-58": {
        "x": 369,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-success-59": {
        "x": 328,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-success-60": {
        "x": 287,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-success-61": {
        "x": 205,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-success-62": {
        "x": 164,
        "y": 98,
        "width": 40,
        "height": 48
    },
    "pin-success-63": {
        "x": 943,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-64": {
        "x": 820,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-65": {
        "x": 410,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-66": {
        "x": 369,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-67": {
        "x": 451,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-68": {
        "x": 328,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-69": {
        "x": 246,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-70": {
        "x": 615,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-71": {
        "x": 738,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-72": {
        "x": 697,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-73": {
        "x": 779,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-74": {
        "x": 656,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-75": {
        "x": 574,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-76": {
        "x": 533,
        "y": 49,
        "width": 40,
        "height": 48
    },
    "pin-success-77": {
        "x": 984,
        "y": 196,
        "width": 40,
        "height": 48
    },
    "pin-success-78": {
        "x": 41,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-success-79": {
        "x": 328,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-success-80": {
        "x": 369,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-success-81": {
        "x": 246,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-success-82": {
        "x": 164,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-success-83": {
        "x": 123,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-success-84": {
        "x": 410,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-success-85": {
        "x": 533,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-success-86": {
        "x": 656,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-success-87": {
        "x": 615,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-success-88": {
        "x": 697,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-success-89": {
        "x": 574,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-success-90": {
        "x": 451,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-success-91": {
        "x": 205,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-success-92": {
        "x": 82,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-success-93": {
        "x": 697,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "pin-success-94": {
        "x": 656,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "pin-success-95": {
        "x": 738,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "pin-success-96": {
        "x": 615,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "pin-success-97": {
        "x": 533,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "pin-success-98": {
        "x": 779,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "pin-success-99": {
        "x": 902,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "pin-success-100": {
        "x": 984,
        "y": 147,
        "width": 40,
        "height": 48
    },
    "pin-warning-1": {
        "x": 451,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-2": {
        "x": 369,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-3": {
        "x": 328,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-4": {
        "x": 615,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-warning-5": {
        "x": 164,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-warning-6": {
        "x": 328,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-warning-7": {
        "x": 287,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "pin-warning-8": {
        "x": 492,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-9": {
        "x": 574,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-10": {
        "x": 41,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-warning-11": {
        "x": 943,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "pin-warning-12": {
        "x": 861,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "pin-warning-13": {
        "x": 820,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "pin-warning-14": {
        "x": 738,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-warning-15": {
        "x": 861,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-warning-16": {
        "x": 615,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-17": {
        "x": 574,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-18": {
        "x": 656,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-19": {
        "x": 533,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-20": {
        "x": 410,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-21": {
        "x": 697,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-22": {
        "x": 820,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-23": {
        "x": 943,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-24": {
        "x": 902,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-25": {
        "x": 984,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-26": {
        "x": 861,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-27": {
        "x": 779,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-28": {
        "x": 738,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-29": {
        "x": 492,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-30": {
        "x": 984,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-warning-31": {
        "x": 943,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-warning-32": {
        "x": 0,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-33": {
        "x": 902,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-warning-34": {
        "x": 820,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-warning-35": {
        "x": 779,
        "y": 392,
        "width": 40,
        "height": 48
    },
    "pin-warning-36": {
        "x": 41,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-37": {
        "x": 164,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-38": {
        "x": 287,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-39": {
        "x": 246,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-40": {
        "x": 205,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-41": {
        "x": 123,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-42": {
        "x": 82,
        "y": 441,
        "width": 40,
        "height": 48
    },
    "pin-warning-43": {
        "x": 574,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "pin-warning-44": {
        "x": 492,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "pin-warning-45": {
        "x": 820,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-warning-46": {
        "x": 779,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-warning-47": {
        "x": 861,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-warning-48": {
        "x": 738,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-warning-49": {
        "x": 656,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-warning-50": {
        "x": 902,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-warning-51": {
        "x": 0,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-52": {
        "x": 123,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-53": {
        "x": 82,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-54": {
        "x": 164,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-55": {
        "x": 41,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-56": {
        "x": 984,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-warning-57": {
        "x": 943,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-warning-58": {
        "x": 697,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-warning-59": {
        "x": 574,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-warning-60": {
        "x": 123,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-warning-61": {
        "x": 205,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-warning-62": {
        "x": 82,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-warning-63": {
        "x": 0,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-warning-64": {
        "x": 246,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-warning-65": {
        "x": 369,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-warning-66": {
        "x": 492,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-warning-67": {
        "x": 451,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-warning-68": {
        "x": 533,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-warning-69": {
        "x": 410,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-warning-70": {
        "x": 287,
        "y": 245,
        "width": 40,
        "height": 48
    },
    "pin-warning-71": {
        "x": 205,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-72": {
        "x": 328,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-73": {
        "x": 82,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "pin-warning-74": {
        "x": 41,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "pin-warning-75": {
        "x": 123,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "pin-warning-76": {
        "x": 0,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "pin-warning-77": {
        "x": 943,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-78": {
        "x": 902,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-79": {
        "x": 164,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "pin-warning-80": {
        "x": 410,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "pin-warning-81": {
        "x": 369,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "pin-warning-82": {
        "x": 451,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "pin-warning-83": {
        "x": 328,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "pin-warning-84": {
        "x": 246,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "pin-warning-85": {
        "x": 205,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "pin-warning-86": {
        "x": 984,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-87": {
        "x": 861,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-88": {
        "x": 451,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-89": {
        "x": 410,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-90": {
        "x": 369,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-91": {
        "x": 287,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-92": {
        "x": 246,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-93": {
        "x": 533,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-94": {
        "x": 656,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-95": {
        "x": 779,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-96": {
        "x": 738,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-97": {
        "x": 820,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-98": {
        "x": 697,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-99": {
        "x": 615,
        "y": 294,
        "width": 40,
        "height": 48
    },
    "pin-warning-100": {
        "x": 984,
        "y": 343,
        "width": 40,
        "height": 48
    },
    "scooter": {
        "x": 689,
        "y": 1174,
        "width": 50,
        "height": 86
    },
    "truck": {
        "x": 740,
        "y": 1172,
        "width": 176,
        "height": 108
    }
}
