import { call, takeLatest, put } from 'redux-saga/effects';

import * as reduxActions from '../actions/dashboard';
import * as reduxTypes from '../types/dashboard';
import * as api from '../api/dashboard';
import { QueryAction } from '../actions';

export function* statsSaga(action: QueryAction) {
    try {
        const response = yield call(api.stats, action.query);
        return yield put(reduxActions.statsSuccess(response, action.query));
    } catch (error) {
        return yield put(reduxActions.statsFailed(error, action.query));
    }
}

export default function* dashboardSaga() {
    yield takeLatest(reduxTypes.STATS, statsSaga);
}
