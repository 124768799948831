import axios, { AxiosResponse } from 'axios';
import * as qs from 'query-string';
import { RequestError, SearchPaginationQuery } from './';
import { message } from 'antd';

import { intlModule } from '../../components/IntlProvider';
import GenericMessages from '../../locale/Generic.messages';

const paginationHeaders: { [apiHeader: string]: string } = {
    count: 'count',
    filteredcount: 'filteredCount',
    totalcount: 'totalCount',
    page: 'page',
    pagesize: 'pageSize',
};

const getPaginationFromHeaders = (headers: AxiosResponse['headers']) => {
    const pagination: { [storePaginationKey: string]: number } = {};

    Object.keys(paginationHeaders).forEach((apiHeader: string) => {
        if (headers[apiHeader]) {
            pagination[paginationHeaders[apiHeader]] = parseInt(headers[apiHeader], 10);
        }
    });

    return pagination;
};

const client = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    withCredentials: true,
});

client.interceptors.response.use(
    (response) => {
        if (response.headers['content-type'] && response.headers['content-type'].startsWith('application/json')) {

            if (response.headers.page) {
                return {
                    items: response.data,
                    ...getPaginationFromHeaders(response.headers),
                };
            }

            return response.data;
        }
        return response;
    },
    (error) => {
        const formattedError: RequestError = {
            status: 0,
            message: '',
        };
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
            formattedError.status = error.response.status;
            formattedError.data = error.response.data;
            formattedError.headers = error.response.headers;

            if (error.response.status === 401) {
                const e = document.createEvent('CustomEvent');
                e.initCustomEvent('unauthorized.error', true, false, 401);
                window.dispatchEvent(e);
            }
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest
            // console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            // console.log('Error', error.message);
        }

        if (formattedError.status === 500 || formattedError.status === 0 && intlModule) {
            message.error(intlModule.intl.formatMessage(GenericMessages.error));
        }

        throw formattedError;
    },
);

export type ListResponse<T> = T[];

export type UpdateData<T> = Pick<T, Exclude<keyof T, keyof { id: any, creationDate: any }>>;

export interface PaginatedListResponse<T> {
    count: number;
    filteredCount: number;
    items: ListResponse<T>;
    page: number;
    pageSize: number;
    totalCount: number;
}

export const urlWithQuery = (url: string, payload: SearchPaginationQuery, queryParams: any = {}) => {
    const query = {
        ...queryParams,
    };

    if (payload.page !== undefined) {
        query.page = payload.page;
    }

    if (payload.pageSize !== undefined) {
        query.pageSize = payload.pageSize;
    }

    if (payload.search !== undefined) {
        query.search = payload.search;
    }

    if (payload.fromDate !== undefined) {
        query.fromDate = payload.fromDate;
    }

    if (payload.toDate !== undefined) {
        query.toDate = payload.toDate;
    }

    if (payload.step !== undefined) {
        query.step = payload.step;
    }

    if (payload.sort !== undefined) {
        query.sort = payload.sort;
    }

    if (payload.sortOrder !== undefined) {
        query.sortOrder = payload.sortOrder;
    }

    if (payload.customer !== undefined) {
        query.customer = payload.customer;
    }

    if (payload.deliveryMan !== undefined) {
        query.deliveryMan = payload.deliveryMan;
    }

    if (payload.agency !== undefined) {
        query.agency = payload.agency;
    }

    const queryString = qs.stringify(query);
    return queryString ? `${url}?${queryString}` : url;
};

export default client;
