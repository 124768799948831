import { ActionCreator } from 'redux';

import * as reduxTypes from '../types/dashboard';
import { DataAction, QueryAction } from '.';
import { GlobalStats } from '../api/types';
import { DateQuery } from '../api';

// STATS

export const stats: ActionCreator<QueryAction> = (query: DateQuery) => ({
    type: reduxTypes.STATS,
    query,
});

export const statsSuccess: ActionCreator<DataAction> = (data: GlobalStats, query: DateQuery) => ({
    type: reduxTypes.STATS_SUCCESS,
    data,
    query,
});

export const statsFailed: ActionCreator<DataAction> = (data: any, query: DateQuery) => ({
    type: reduxTypes.STATS_FAILED,
    data,
    query,
});
