import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Card, Avatar, Row, Col, Skeleton, Tabs, Icon } from 'antd';

import * as CustomersActions from '../../store/actions/customers';
import * as DeliveriesActions from '../../store/actions/deliveries';
import { Customer } from '../../store/api/types';
import { getCustomerById, getReads } from '../../store/reducers/customers';
import { MainReducerState, RequestState } from '../../store/reducers';

import Header from '../../components/header/Header';
import Content from '../../components/Content';
import HeaderNav from '../../components/header/HeaderNav';
import listMessages from '../../locale/List.messages';
import GenericMessages from '../../locale/Generic.messages';

import '../../assets/styles/CustomerDetails.less';
import { getRoute, RoutePathName } from '../../routes';
import { getFullName, capitalizeWords } from '../../utils';
import avatar from '../../assets/images/avatar.jpg';
import avatar2x from '../../assets/images/avatar@2x.jpg';
import avatar3x from '../../assets/images/avatar@3x.jpg';
import { Languages } from '../../components/IntlProvider';
import DeliveriesListWrapper from '../deliveries/DeliveriesListWrapper';
import { FullAddress } from '../../components/FullAdress';
import EmptyStateText from '../../components/typography/EmptyStateText';
import CustomersListMessages from './CustomersList.messages';

interface MatchParams {
    id: Customer['id'];
}

interface CustomerDetailsProps extends InjectedIntlProps, RouteComponentProps<MatchParams> {
    customer: Customer;
    fetchCustomer: typeof CustomersActions.details;
    fetchDeliveries: typeof DeliveriesActions.list;
    reads: RequestState;
}

class CustomerDetails extends React.Component<CustomerDetailsProps> {

    public componentDidMount() {
        const { customer, fetchCustomer, fetchDeliveries, match } = this.props;
        fetchDeliveries({ customer: match.params.id });

        if (customer === undefined) {
            fetchCustomer(match.params.id);
        }
    }

    public render() {
        const { customer, intl, location, match, reads } = this.props;
        const fullName = customer ?
            getFullName(
                customer.firstname,
                customer.lastname,
            ).toLowerCase() :
            '';
        const headerLinks = {
            [getRoute(intl.locale as Languages, RoutePathName.customersList)]:
                intl.formatMessage(listMessages.contentHeaderNavItemList),
            [location.pathname]: capitalizeWords(fullName),
        };

        return (
            <>
                <Header>
                    <HeaderNav links={headerLinks} />
                </Header>
                <Content>
                    <Row gutter={32} type="flex" className="fill-height" style={{ marginBottom: 40 }}>
                        <Col xs={24} sm={12} lg={9}>
                            <Card className="profile-card">
                                <Skeleton loading={!customer || reads.loading} avatar active>
                                    {customer && (
                                        <>
                                            <Card.Meta
                                                avatar={
                                                    <Avatar
                                                        size="large"
                                                        src={avatar}
                                                        srcSet={`${avatar} 1x, ${avatar2x} 2x, ${avatar3x} 3x`}
                                                    />
                                                }
                                                title={fullName}
                                                description={
                                                    <p className="profile-reference">
                                                        {customer.reference}
                                                    </p>
                                                }
                                            />
                                            <Row type="flex" justify="space-around" align="middle">
                                                <Col xs={24} lg={8}>
                                                    <div className="customer-profile-infos">
                                                        <Icon type="mail" />
                                                        <ul>
                                                            {customer.emails.length ? customer.emails.map((email) => (
                                                                <li key={email}>{email}</li>
                                                            )) : (
                                                                <EmptyStateText>
                                                                    <FormattedMessage
                                                                        {...CustomersListMessages.noEmail}
                                                                    />
                                                                </EmptyStateText>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </Col>
                                                <Col xs={24} lg={8}>
                                                    <div className="customer-profile-infos">
                                                        <Icon type="phone" />
                                                        <ul>
                                                            {customer.phones.length ? customer.phones.map((phone) => (
                                                                <li key={phone}>{phone}</li>
                                                            )) : (
                                                                <EmptyStateText>
                                                                    <FormattedMessage
                                                                        {...CustomersListMessages.noPhone}
                                                                    />
                                                                </EmptyStateText>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </Skeleton>
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} lg={5}>
                            <Card>
                                <Skeleton loading={reads.loading} active>
                                    {customer && (
                                        <Tabs defaultActiveKey="1">
                                            {customer.addresses.map((address, index) => (
                                                <Tabs.TabPane
                                                    tab={
                                                        <FormattedMessage
                                                            {...GenericMessages.address}
                                                            values={{ index: index + 1 }}
                                                        />
                                                    }
                                                    key={`${index + 1}`}
                                                >
                                                    <p className="delivery-recipient-address">
                                                        <FullAddress
                                                            address={address}
                                                            withLineBreak
                                                        />
                                                    </p>
                                                </Tabs.TabPane>
                                            ))}
                                        </Tabs>
                                    )}
                                </Skeleton>
                            </Card>
                        </Col>
                    </Row>
                    <DeliveriesListWrapper
                        fetchPayload={{ customer: match.params.id }}
                        listTitle={<FormattedMessage {...GenericMessages.tasks} />}
                    />
                </Content>
            </>
        );
    }
}

const mapStateToProps = (state: MainReducerState, { match }: CustomerDetailsProps) => ({
    customer: getCustomerById(state, match.params.id),
    reads: getReads(state),
});

export default injectIntl(connect(
    mapStateToProps,
    {
        fetchCustomer: CustomersActions.details,
        fetchDeliveries: DeliveriesActions.list,
    },
)(CustomerDetails));
