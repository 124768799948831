import api, { PaginatedListResponse, urlWithQuery, UpdateData } from './_client';
import { QueryListPayload } from '../actions';
import { User } from './types';

export interface UsersListResponse extends PaginatedListResponse<User> {}
export type UserUpdateData = UpdateData<User>;

export const list = (payload: QueryListPayload & { fetchOrganization?: boolean }) => {
    return api.get<UsersListResponse>(urlWithQuery('/admin/users', payload, {
        fetchOrganization: payload.fetchOrganization,
    }));
};

export const details = (id: User['id']) => {
    return api.get<User>(`/admin/users/${id}`);
};

export const create = (body: UserUpdateData) => {
    return api.post<User>('/admin/users', body);
};

export const update = (id: User['id'], body: UserUpdateData) => {
    return api.post<User>(`/admin/users/${id}`, body);
};

export const del = (id: User['id']) => {
    return api.delete(`/admin/users/${id}`);
};
