import { Layout } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, matchPath, withRouter } from 'react-router-dom';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';
import { Dispatch, bindActionCreators } from 'redux';

import { getUi } from '../store/reducers/ui';
import { MainReducerState } from '../store/reducers';
import * as uiActions from '../store/actions/ui';

import IconLogo from './icons/IconLogo';
import MainMenu from './MainMenu';
import UserMenu from './UserMenu';
import { getRoute, RoutePathName, getRawRoute } from '../routes';
import { IconAngle, IconLogoText } from './icons';
import GenericMessages from '../locale/Generic.messages';
import { Languages } from './IntlProvider';
import ScrollBars from './ScrollBars';

import '../assets/styles/Sidebar.less';

const {
    Sider,
} = Layout;

interface SidebarProps extends RouteComponentProps, InjectedIntlProps {
    isCollapsed: boolean;
    setCollapsed: typeof uiActions.toggleSidebar;
}

interface SidebarState {
    collapsed: boolean;
    openKeys: string[];
}

class Sidebar extends React.Component<SidebarProps, SidebarState> {
    public state: SidebarState = {
        collapsed: false,
        openKeys: [],
    };

    public componentDidUpdate(prevProps: SidebarProps) {
        const { isCollapsed, setCollapsed } = this.props;

        if (this.props.location !== prevProps.location) {
            const match = matchPath(location.pathname, {
                path: getRawRoute(this.props.intl.locale as Languages, RoutePathName.deliveryTour),
                exact: true,
                strict: false,
            });

            if (match) {
                if (!isCollapsed) {
                    setCollapsed(true);
                }
            } else if (isCollapsed && window.innerWidth > 992) {
                setCollapsed(false);
            }
        }
    }

    public componentWillUnmount() {
        if (this.props.isCollapsed) {
            this.props.setCollapsed(false);
        }
    }

    public onCollapse = (collapsed: boolean) => {
        this.props.setCollapsed(collapsed);
    }

    public render() {
        return (
            <Sider
                breakpoint="lg"
                collapsed={this.props.isCollapsed}
                onCollapse={this.onCollapse}
                trigger={<IconAngle />}
                width="16.7777%"
                collapsible
                reverseArrow
            >
                <div id="logo-wrapper">
                    <Link to={getRoute(this.props.intl.locale as Languages, RoutePathName.home)}>
                        <div className="logo-badge">
                            <IconLogo />
                        </div>
                        <IconLogoText />
                    </Link>
                </div>
                <UserMenu />
                <div id="menu-wrapper">
                    <ScrollBars>
                        <MainMenu />
                    </ScrollBars>
                </div>
                <span id="copyright">
                    <FormattedMessage {...GenericMessages.madeBy} />&nbsp;
                    <a href="https://www.ezeeworld.com" target="_blank" rel="noopener noreferrer">ezeeworld</a>
                </span>
            </Sider>
        );
    }
}

const mapStateToProps = (state: MainReducerState) => ({
    isCollapsed: getUi(state).isSidebarCollapsed,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
    setCollapsed: uiActions.toggleSidebar,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(Sidebar)));
