import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl, InjectedIntlProps, FormattedMessage, InjectedIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';

import * as OrganizationsActions from '../../store/actions/organizations';
import { SearchQueryComponentState, SearchPaginationQuery } from '../../store/api';
import { Organization } from '../../store/api/types';
import { getOrganizations, getPagination } from '../../store/reducers/organizations';

import Header from '../../components/header/Header';
import Content from '../../components/Content';
import ListMessages from '../../locale/List.messages';
import HeaderNav from '../../components/header/HeaderNav';
import { ListItemTitle, List } from '../../components/list';
import MainMenuMessages from '../../components/MainMenu.messages';
import messages from './OrganizationsList.messages';
import EmptyStateText from '../../components/typography/EmptyStateText';
import OrganizationFormDrawer, { OrganizationDrawer } from './OrganizationFormDrawer';
import { Button, Icon } from 'antd';
import { PaginationRequestState } from '../../store/reducers/_generics';

interface OrganizationsListProps extends InjectedIntlProps, RouteComponentProps {
    fetchOrganization: typeof OrganizationsActions.details;
    fetchOrganizations: typeof OrganizationsActions.list;
    organizations: Organization[];
    pagination: PaginationRequestState;
}

interface OrganizationsListInternalState extends SearchQueryComponentState {
    organizationIdToEdit?: Organization['id'];
}

class OrganizationsList extends React.Component<OrganizationsListProps, OrganizationsListInternalState> {
    public state: OrganizationsListInternalState = {};

    private formDrawer?: any = undefined;

    private columns: Array<ColumnProps<Organization>> = [
        {
            dataIndex: 'name',
            render: (name, organization, index) => (
                <ListItemTitle
                    index={(this.props.pagination.pageSize * this.props.pagination.page) + index + 1}
                    text={
                        name || (
                            <EmptyStateText>
                                <FormattedMessage {...messages.noName} />
                            </EmptyStateText>
                        )
                    }
                />
            ),
        }, {
            dataIndex: 'id',
        }, {
            title: 'Action',
            key: 'action',
            width: 32,
            render: (organization) => (
                <Button
                    onClick={this.onClickEdit.bind(this, organization)}
                    shape="circle"
                    size="small"
                    type="default"
                >
                    <Icon type="ellipsis" />
                </Button>
            ),
        },
    ];

    public componentDidMount() {
        this.fetchData();
    }

    public onClickEdit = (organization: Organization) => {
        this.props.fetchOrganization(organization.id);
        this.setState({ organizationIdToEdit: organization.id }, this.onOpenFormDrawer);
    }

    public onOpenFormDrawer = () => {
        if (this.formDrawer) {
            this.formDrawer.showDrawer();
        }
    }

    public onCloseFormDrawer = () => {
        this.setState({ organizationIdToEdit: undefined });
    }

    public render() {
        const { organizations, pagination, intl } = this.props;
        const { organizationIdToEdit } = this.state;
        const headerLinks = {
            [location.pathname]: intl.formatMessage(ListMessages.contentHeaderNavItemList),
        };

        return (
            <>
                <Header>
                    <HeaderNav links={headerLinks} />
                    <Button
                        onClick={this.onOpenFormDrawer}
                        type="primary"
                    >
                        <FormattedMessage {...messages.createOrganization} />
                    </Button>
                </Header>
                <Content>
                    <List<Organization>
                        columns={this.columns}
                        dataState={{
                            data: organizations,
                            loading: pagination.loading,
                            ...pagination,
                        }}
                        listTitle={<FormattedMessage {...MainMenuMessages.organizations} />}
                        onChangePage={this.onChangePage}
                        onSearch={this.onSearch}
                        rowKey={this.rowKey}
                    />
                    <OrganizationFormDrawer
                        drawerTitle={
                            <FormattedMessage
                                {...messages[
                                    organizationIdToEdit === undefined ?
                                        'createOrganization' :
                                        'updateOrganization'
                                ]}
                            />
                        }
                        onClose={this.onCloseFormDrawer}
                        organizationId={organizationIdToEdit}
                        // tslint:disable-next-line
                        wrappedComponentRef={(c: OrganizationDrawer) => this.formDrawer = c}
                    />
                </Content>
            </>
        );
    }

    private rowKey = (record: Organization) => record.id!.toString();

    private onChangePage = (page: number) => {
        const { search } = this.state;

        this.fetchData({
            search,
            page: page - 1,
        });
    }

    private onSearch = (search: string) => {
        this.setState({ search });
        this.fetchData({
            search,
            throttling: 200,
        });
    }

    private fetchData = (query?: SearchPaginationQuery) => {
        const { page, pageSize } = this.props.pagination;
        this.props.fetchOrganizations({
            page,
            pageSize,
            ...query,
        });
    }
}

const mapStateToProps = (state: any) => ({
    organizations: getOrganizations(state),
    pagination: getPagination(state),
});

export default injectIntl(connect(
    mapStateToProps,
    {
        fetchOrganizations: OrganizationsActions.list,
        fetchOrganization: OrganizationsActions.details,
    },
)(OrganizationsList));
