import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <path fill="#FFF" d="M13.45 11.425a6.57 6.57 0 0 0-1.5-2.102 7.03 7.03 0 0 0-2.224-1.417c-.008-.004-.015-.005-.023-.01 1.16-.79 1.914-2.08 1.914-3.534C11.617 1.952 9.55 0 7 0 4.45 0 2.383 1.952 2.383 4.362c0 1.454.754 2.744 1.914 3.537A6.968 6.968 0 0 0 2.05 9.325a6.6 6.6 0 0 0-1.5 2.1 6.23 6.23 0 0 0-.55 2.43c0 .038.014.074.042.102A.153.153 0 0 0 .15 14h1.116a.145.145 0 0 0 .15-.137c.036-1.358.613-2.63 1.633-3.593C4.104 9.272 5.506 8.724 7 8.724c1.493 0 2.895.548 3.95 1.546 1.02.963 1.598 2.235 1.635 3.593.002.077.067.137.15.137h1.116c.04 0 .08-.015.108-.042a.137.137 0 0 0 .042-.102 6.247 6.247 0 0 0-.55-2.43zM7 7.387c-.855 0-1.66-.315-2.264-.886a2.924 2.924 0 0 1-.938-2.138c0-.807.333-1.567.938-2.14A3.278 3.278 0 0 1 7 1.338c.855 0 1.66.315 2.264.886.605.572.938 1.332.938 2.14 0 .806-.333 1.566-.938 2.138A3.278 3.278 0 0 1 7 7.388z" />{/* tslint:disable:max-line-length */}
    </svg>
);

const IconCustomer: React.SFC<IconProps> = (props) => (
    <Icon component={svg} className="anticon icon-customer" {...props} />
);

export default IconCustomer;
