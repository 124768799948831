import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="27" viewBox="0 0 18 27">
        <path
            fill="#B39CFF"
            fillRule="evenodd"
            d="M0 1.286c4-2 10-1.667 18 1-3 7 0 11 0 13-7-1-14-1.5-16 0v11H0v-25z"
        />
    </svg>
);

const IconEndMarker: React.SFC<IconProps> = (props) => (
    <Icon component={svg} className="icon-end-marker" {...props} />
);

export default IconEndMarker;
