import React, { PureComponent } from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { Alert, Button, Tooltip, Icon, Collapse } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';

import {
    Delivery, Agency, getDeliveryStatus, getDeliveryStatusColor, DeliveryStatus, DeliveryTour,
    DeliveryTourStatus,
    getDeliveryType,
    DeliveryType,
    NeerbyTag,
    getItemEventMessage,
    getVehicleEventMessage,
    getDeliveryEventMessage,
    getDeliveryTourEventMessage,
    getDeliveryManEventMessage,
    getTransferEventMessage,
    DeliveryEvent,
} from '../../store/api/types';

import messages from './DeliveryTourSider.messages';
import { IconMapDelivery, IconAgencyMarker, IconTimelineTag } from '../../components/icons';
import SendMessagePopover from '../../components/SendMessagePopover';
import { IconMapDeliveryStatuses } from '../../components/icons/IconMapDelivery';
import EmptyStateText from '../../components/typography/EmptyStateText';
import DarkTag from '../../components/DarkTag';
import { FullAddress } from '../../components/FullAdress';
import GenericMessages from '../../locale/Generic.messages';
import { Can } from '../../components/auth';
import Img from '../../components/Img';

const deliveryColorMap: { [color: string]: IconMapDeliveryStatuses } = {
    red: 'error',
    orange: 'warning',
    green: 'success',
    primary: 'default',
};

interface DeliveryTourTimelineItemProps {
    agency?: Agency;
    delivery?: Delivery;
    deliveryTourStatus?: DeliveryTour['status'];
    index?: number;
    isLast?: boolean;
    onClickDetails?: (delivery: Delivery) => void;
    preparationTags?: DeliveryTour['preparationTags'];
}

interface DeliveryTourTimelineItemState {
    itemOpenTags?: string;
}

class DeliveryTourTimelineItem extends PureComponent<DeliveryTourTimelineItemProps, DeliveryTourTimelineItemState> {
    public state: DeliveryTourTimelineItemState = {};

    public onClickDetails = (delivery: Delivery) => {
        this.props.onClickDetails!(delivery);
    }

    public onChangeTagsCollapse = (id: Delivery['id'] | 'preparation') => {
        this.setState(({ itemOpenTags }) => ({
            itemOpenTags: itemOpenTags === id ? undefined : id,
        }));
    }

    public renderAgency = () => {
        const { agency, deliveryTourStatus, preparationTags } = this.props;
        let timelineBarBottomPosition = '100%';

        switch (deliveryTourStatus) {
            case DeliveryTourStatus.pending:
                timelineBarBottomPosition = '50%';
                break;

            default:
                timelineBarBottomPosition = '-120px';
                break;
        }

        return (
            <>
                <div className="timeline-item-time-icon">
                    <div className="timeline-track" />
                    <div className="timeline-bar" style={{ bottom: timelineBarBottomPosition }} />
                    <IconAgencyMarker />
                </div>
                <div className="timeline-item-content">
                    <div className="timeline-item-header">
                        <h5>
                            {agency!.name}
                        </h5>
                    </div>
                    <p className="timeline-item-type">
                        <small>{agency!.reference}</small>
                    </p>
                    <div className="timeline-item-footer">
                        {this.renderNeerbyTags(preparationTags, 'preparation')}
                    </div>
                </div>
            </>
        );
    }

    public renderTag = (tag: NeerbyTag, index: number) => {
        const type = tag.type.split('.')[0];
        let tagTitle = null;
        const content: React.ReactNode[] = [];

        switch (type) {
            case 'item':
                tagTitle = (
                    <FormattedMessage
                        {...messages[getItemEventMessage(tag.type)]}
                        values={{ item: tag.data!.item }}
                    />
                );
                break;

            case 'vehicle':
                tagTitle = <FormattedMessage {...messages[getVehicleEventMessage(tag.type)]} />;
                break;

            case 'delivery':
                tagTitle = <FormattedMessage {...messages[getDeliveryEventMessage(tag.type)]} />;

                if (tag.type === DeliveryEvent.customerComment || tag.type === DeliveryEvent.deliveryManComment) {
                    content.push((
                        <p className="tag-value" key={`comment-${index}`}>{tag.data!.comment}</p>
                    ));
                }

                if (tag.type === DeliveryEvent.signature && tag.data!.imageReference) {
                    content.push((
                        <Img key={`img-${index}`} alt="signature" src={`/api/images/${tag.data!.imageReference}`} />
                    ));
                }

                if (tag.type === DeliveryEvent.failed && tag.data!.failureReason) {
                    content.push((
                        <p className="tag-value" key={`failure-${index}`}>{tag.data!.failureReason}</p>
                    ));
                }

                break;

            case 'deliveryTour':
                tagTitle = <FormattedMessage {...messages[getDeliveryTourEventMessage(tag.type)]} />;
                break;

            case 'deliveryMan':
                tagTitle = <FormattedMessage {...messages[getDeliveryManEventMessage(tag.type)]} />;
                break;

            case 'transfer':
                tagTitle = <FormattedMessage {...messages[getTransferEventMessage(tag.type)]} />;
                break;

            default:
                tagTitle = null;
                break;
        }

        return (
            <div className="timeline-tag" key={`${index}-${tag.date}`}>
                <div className="timeline-tag-time-icon">
                    <span className="timeline-item-time">
                        <FormattedTime value={tag.date} />
                    </span>
                    <IconTimelineTag />
                </div>
                <div className="timeline-tag-content">
                    {tagTitle}
                    {content}
                </div>
            </div>
        );
    }

    public renderTagsCollapseIcon: ((panelProps: any) => React.ReactNode) | undefined = ({ isActive }: any) =>
        <Icon type={isActive ? 'minus-circle' : 'plus-circle'} />

    public renderNeerbyTags = (neerbyTags: NeerbyTag[] | undefined, id: Delivery['id'] | 'preparation') => {

        return neerbyTags && !!neerbyTags.length ? (
            <Collapse
                activeKey={this.state.itemOpenTags ? [this.state.itemOpenTags] : []}
                className={this.state.itemOpenTags === id ? 'open' : ''}
                expandIcon={this.renderTagsCollapseIcon}
                onChange={this.onChangeTagsCollapse.bind(null, id)}
            >
                <Collapse.Panel
                    header={(
                        <span className="event-button">
                            <FormattedMessage
                                {...messages.numberOfEvents}
                                values={{ count: neerbyTags.length }}
                            />
                        </span>
                    )}
                    key={id!}
                    style={{
                        background: 'transparent',
                        border: 0,
                    }}
                >
                    {neerbyTags.map(this.renderTag)}
                </Collapse.Panel>
            </Collapse>
        ) : null;
    }

    public renderDelivery = () => {
        const { delivery, index, isLast, onClickDetails } = this.props;

        if (!delivery) {
            return null;
        }

        const deliveryStatus = getDeliveryStatus(delivery.status);
        const statusTag = (
            <DarkTag className="delivery-status" color={getDeliveryStatusColor(delivery.status)}>
                <FormattedMessage {...messages[deliveryStatus]} />
            </DarkTag>
        );

        let status = statusTag;

        if (delivery.status === DeliveryStatus.failed && delivery.failureReason) {
            status = (
                <Tooltip title={delivery.failureReason}>
                    {statusTag}
                </Tooltip>
            );
        }

        let timelineBarBottomPosition = '100%';

        switch (delivery.status) {
            case DeliveryStatus.started:
                timelineBarBottomPosition = '50%';
                break;

            case DeliveryStatus.ready:
            case DeliveryStatus.pending:
                timelineBarBottomPosition = '100%';
                break;

            default:
                timelineBarBottomPosition = isLast ? '0' : '-100px';
                break;
        }

        return (
            <>
                <div className="timeline-item-time-icon">
                    <span className="timeline-item-time">
                        <FormattedTime
                            value={delivery.endDate || delivery.timeframe.from || new Date().setHours(0, 0, 0 , 0)}
                        />
                    </span>
                    <div className="timeline-track" />
                    <div className="timeline-bar" style={{ bottom: timelineBarBottomPosition }} />
                    <IconMapDelivery
                        status={deliveryColorMap[getDeliveryStatusColor(delivery.status)]}
                        value={index! + 1}
                    />
                </div>
                <div className="timeline-item-content">
                    <div className="timeline-item-header">
                        <h5>
                            {delivery.contact.name ? delivery.contact.name.toLowerCase() : (
                                <EmptyStateText>
                                    <FormattedMessage {...messages.noDeliveryRecipientName} />
                                </EmptyStateText>
                            )}
                        </h5>
                        {status}
                    </div>
                    <p className="timeline-item-type">
                        <small>
                            <FormattedMessage
                                {...messages[getDeliveryType(delivery.type)]}
                                values={
                                    delivery.type === DeliveryType.thirdParty ?
                                        { thirdParty: `${delivery.partner ? delivery.partner.name : ''}` } :
                                        undefined
                                }
                            />
                            {
                                (
                                    delivery.type === DeliveryType.basic ||
                                    delivery.type === DeliveryType.thirdParty
                                ) && (
                                    <>
                                        {' — '}
                                        <FormattedMessage
                                            {...messages.numberOfParcels}
                                            values={{ count: delivery.parcels.length }}
                                        />
                                    </>
                                )
                            }
                        </small>
                    </p>
                    <p className="delivery-recipient-address">
                        <FullAddress
                            address={delivery.address}
                            withLineBreak
                        />
                    </p>
                    {
                        !delivery.address.coordinates ||
                            !delivery.address.coordinates.latitude ||
                            !delivery.address.coordinates.longitude && (
                                <Alert
                                    message={<FormattedMessage {...messages.deliveryNoCoordinates} />}
                                    type="error"
                                    showIcon
                                />
                            )
                    }
                    <div className="timeline-item-footer">
                        {this.renderNeerbyTags(delivery.neerbyTags, delivery.id)}
                        <ButtonGroup>
                            <Can edit="customerMessages">
                                <SendMessagePopover
                                    recipient={`${
                                        delivery.contact.name || ''
                                    }${
                                        delivery.contact.phone ?
                                            ` (${delivery.contact.phone})` :
                                            ''
                                    }`}
                                    deliveryId={delivery.id}
                                >
                                    <Button
                                        className="delivery-message-button"
                                        icon="message"
                                        size="small"
                                        type="primary"
                                    />
                                </SendMessagePopover>
                            </Can>
                            <Button
                                className="delivery-details-button"
                                onClick={onClickDetails && this.onClickDetails.bind(null, delivery)}
                                size="small"
                                type="primary"
                            >
                                <FormattedMessage {...GenericMessages.details} />
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
            </>
        );
    }

    public render() {
        const { agency, delivery } = this.props;
        const isAgency = !!agency;

        return (
            <div
                className={`timeline-item timeline-item-${isAgency ? 'agency' : 'delivery'}`}
                id={`timeline-item-${isAgency ? agency!.id : delivery!.id}`}
            >
                {isAgency ? this.renderAgency() : this.renderDelivery()}
            </div>
        );
    }
}

export default DeliveryTourTimelineItem;
