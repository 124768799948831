import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import Table, { ColumnProps } from 'antd/lib/table';
import { Tag, Divider } from 'antd';

import * as MonitoringActions from '../../store/actions/monitoring';
import { SearchQueryComponentState, SearchPaginationQuery } from '../../store/api';
import { LoggedRequest } from '../../store/api/types';

import {
    getLoggedRequests, getPagination,
} from '../../store/reducers/monitoring';
import EmptyStateText from '../../components/typography/EmptyStateText';
import messages from './LoggedRequestsList.messages';
import EmptyResult from '../../components/EmptyResult';

import { ListItemTitle, List } from '../../components/list';
import { ListProps } from '../../components/list/List';
import { PaginationRequestState } from '../../store/reducers/_generics';
import { Bone, SkeletonRow } from '../../components/skeleton';
import { getUser, AuthUser } from '../../store/reducers/auth';

export type LoggedRequestsListColumns = Array<ColumnProps<LoggedRequest>>;

interface LoggedRequestsListProps extends InjectedIntlProps,
                                            Partial<RouteComponentProps>,
                                            Partial<ListProps<LoggedRequest>> {
    loggedRequests: LoggedRequest[];
    fetchLoggedRequests: typeof MonitoringActions.list;
    fetchPayload?: {};
    pagination: PaginationRequestState;
    setColumns?: (columns: LoggedRequestsListColumns) => LoggedRequestsListColumns;
    user: AuthUser;
}

class LoggedRequestsList extends React.Component<LoggedRequestsListProps, SearchQueryComponentState> {
    public state: SearchQueryComponentState = {};
    private columns: Array<ColumnProps<LoggedRequest>> = [
        {
            dataIndex: 'uri',
            render: (uri, loggedRequest, index) => (
                <ListItemTitle
                    index={(this.props.pagination.pageSize * this.props.pagination.page) + index + 1}
                    text={
                        uri || (
                            <EmptyStateText>
                                <FormattedMessage {...messages.noName} />
                            </EmptyStateText>
                        )
                    }
                />
            ),
        },
        {
            dataIndex: 'time',
            align: 'right',
            render: (time, loggedRequest, index) => {
                if (time.total) {
                    return (
                        <>
                        {time.total}s
                        </>
                    );
                }
                return (
                <EmptyStateText>
                    <FormattedMessage {...messages.noTime} />
                </EmptyStateText>
               );
            },
        },
        {
            dataIndex: 'statusCode',
            render: (statusCode) => {
                const tag = statusCode < 400 ? (<Tag color="green">{statusCode}</Tag>) :
                                                (<Tag color="red">{statusCode}</Tag>);
                if (statusCode) {
                    return tag;
                }
                return (
                    <EmptyStateText>
                        <FormattedMessage {...messages.noStatus} />
                    </EmptyStateText>
                );
            },
        },
    ];

    public componentDidMount() {
        this.fetchData();
    }

    public fetchData = (query?: SearchPaginationQuery) => {
        const { fetchLoggedRequests, fetchPayload, pagination } = this.props;
        const { page, pageSize } = pagination;

        fetchLoggedRequests({
            page,
            pageSize,
            ...fetchPayload,
            ...query,
        });
    }

    public rowKey = (record: LoggedRequest) => record.id!.toString();

    public onChangePage = (page: number) => {
        const { search } = this.state;

        this.fetchData({
            search,
            page: page - 1,
        });
    }

    public onSearch = (search: string) => {
        this.setState({ search });
        this.fetchData({
            search,
            throttling: 200,
        });
    }

    public skeletonItemRenderer(index: number) {
        return (
            <SkeletonRow key={index}>
                <Bone style={{ flex: '0 1 auto' }} />
                <Bone style={{ flex: '0 0 116px' }} />
                <Bone style={{ flex: '0 1 auto' }} />
                <Bone style={{ flex: '0 1 auto' }} />
                <Bone style={{ flex: '0 0 50%' }} />
                <Bone style={{ flex: '0 0 10px' }} />
            </SkeletonRow>
        );
    }

    public subTableRenderer = (loggedRequest: LoggedRequest) => {
        if (!loggedRequest.meta || !Object.keys(loggedRequest.meta).length) {
            return null;
        }

        const loggegRequestMetaColumns: Array<ColumnProps<LoggedRequest['meta']>> =
            Object.keys(loggedRequest.meta).map((meta) => ({
                title: meta,
                dataIndex: meta,
            }));
        const response = loggedRequest.response ? JSON.stringify(loggedRequest.response, null, 2) : '';
        return (
            <div>
                <Table<LoggedRequest['meta']>
                    columns={loggegRequestMetaColumns}
                    dataSource={[loggedRequest.meta]}
                    locale={{
                        emptyText: <EmptyResult />,
                    }}
                    pagination={false}
                    rowKey={this.subTableRowKey}
                />
                <Divider dashed/>
                <p>Request Response :</p>
                <pre>{response}</pre>
            </div>
        );
    }

    public render() {
        const { loggedRequests, emptyStateRenderer, listTitle, pagination, setColumns } = this.props;

        return (
            <List<LoggedRequest>
                columns={this.columns}
                dataState={{
                    data: loggedRequests,
                    loading: pagination.loading,
                    ...pagination,
                }}
                emptyStateRenderer={emptyStateRenderer}
                listTitle={<FormattedMessage {...messages.listTitle} />}
                onChangePage={this.onChangePage}
                onSearch={this.onSearch}
                rowKey={this.rowKey}
                subTableRenderer={this.subTableRenderer}
                skeletonRenderer={this.skeletonItemRenderer}
            />
        );
    }
    private subTableRowKey = (record: LoggedRequest['meta'], index: number) => `${index}`;
}

const mapStateToProps = (state: any) => ({
    loggedRequests: getLoggedRequests(state),
    pagination: getPagination(state),
    user: getUser(state),
});

export default injectIntl(connect(
    mapStateToProps,
    { fetchLoggedRequests: MonitoringActions.list },
    undefined,
    { forwardRef: true },
)(LoggedRequestsList), { withRef: true });
