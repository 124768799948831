import * as React from 'react';
import moment from 'moment';
import { Card, Spin } from 'antd';
import { lime, geekblue, cyan } from '@ant-design/colors';

import { DashboardStatsState } from '../../store/reducers/dashboard';
import { DateStep } from '../../store/api';
import { preciseRound } from '../../utils/math';

import '../../assets/styles/QualityAndIncidentsChart.less';

import { MultiSeriesChart, ChartTooltip } from '../../components/charts';
import { FormattedMessage, injectIntl, InjectedIntlProps, FormattedNumber } from 'react-intl';
import GenericMessages from '../../locale/Generic.messages';
import { DescriptiveList } from '../../components/list';
import DashboardMessages from './Dashboard.messages';
import Metric from '../../components/Metric';

interface QualityAndIncidentsChartProps extends InjectedIntlProps {
    filters: {
        fromDate: number;
        toDate: number;
        step: DateStep;
    };
    stats: DashboardStatsState;
}

interface Datum {
    percentage: number;
    total: number;
    count: number;
    x: number;
    y: number;
}

type LineSeries = Array<{
    color: string;
    curve: string;
    data: any;
    id: string;
    name: React.ReactChild;
}>;

class QualityAndIncidentsChart extends React.Component<QualityAndIncidentsChartProps> {

    public getDefaultData = (timeSpan: number): Datum[] => {
        const { filters } = this.props;

        return Array.from(Array(timeSpan).keys(), (k, index) => ({
            percentage: 0,
            total: 0,
            count: 0,
            x: moment(filters.fromDate).add(index, DateStep[filters.step]).valueOf(),
            y: 0,
        }));
    }

    public getComputedData = () => {
        const { filters, stats } = this.props;

        if (stats.loading || !stats.data) {
            return {
                chart: {
                    lineSeries: [],
                },
                stats: {
                    criticalyLate: 0,
                    gps: 0,
                    late: 0,
                },
            };
        }

        const timeSpan = Math.ceil(moment(filters.toDate).diff(moment(filters.fromDate), DateStep[filters.step], true));
        const computedData: {
            chart: {
                lineSeries: LineSeries;
            },
            stats: {
                [key: string]: number;
                criticalyLate: number;
                gps: number;
                late: number;
            },
        } = {
            chart: {
                lineSeries: [
                    {
                        color: cyan[5],
                        curve: 'curveMonotoneX',
                        data: this.getDefaultData(timeSpan),
                        id: 'late',
                        name: (
                            <FormattedMessage {...GenericMessages.late} />
                        ),
                    },
                    {
                        color: geekblue[5],
                        curve: 'curveMonotoneX',
                        data: this.getDefaultData(timeSpan),
                        id: 'gps',
                        name: (
                            <FormattedMessage {...GenericMessages.gps} />
                        ),
                    },
                    {
                        color: lime[5],
                        curve: 'curveMonotoneX',
                        data: this.getDefaultData(timeSpan),
                        id: 'criticalyLate',
                        name: (
                            <FormattedMessage {...GenericMessages.overTwoHoursLate} />
                        ),
                    },
                ],
            },
            stats: {
                items: 0,
                criticalyLate: 0,
                gps: 0,
                late: 0,
            },
        };

        stats.data.counts.forEach((datum) => {
            ['late', 'gps', 'criticalyLate'].forEach((dataType) => {
                const currentData = computedData.chart.lineSeries.filter((s) => s.id === dataType)[0].data;
                const matchingDatumIndex = currentData.findIndex((d: Datum) =>
                    moment(d.x).isSame(datum.step, DateStep[filters.step]),
                );

                computedData.stats.items += 1;

                if (matchingDatumIndex !== -1) {
                    computedData.stats[dataType] += datum[dataType];
                    currentData[matchingDatumIndex].percentage = preciseRound(datum[dataType] / datum.total, 2);
                    currentData[matchingDatumIndex].count = datum[dataType];
                    currentData[matchingDatumIndex].y = 100 * currentData[matchingDatumIndex].percentage;
                    currentData[matchingDatumIndex].total = datum.total;
                }
            });
        });

        return computedData;
    }

    public formatXAxisTickLabels = (tick: any) => {
        const { filters, intl } = this.props;

        return filters.step === DateStep.hour ?
            intl.formatTime(tick) :
            intl.formatDate(tick);
    }

    public formatYAxisTickLabels = (tick: any) => this.props.intl.formatNumber(tick / 100, {style: 'percent'});

    public renderTooltip = (lineSeries: LineSeries, data: Datum[]) => {
        const { filters, intl } = this.props;

        if (data.length) {
            const currentDate = moment(data[0].x).toDate();
            const title = filters.step === DateStep.hour ?
                this.props.intl.formatTime(currentDate) :
                this.props.intl.formatDate(currentDate);

            const descriptiveListData = data.map((datum, index) => ({
                term: (
                    <span style={{ color: lineSeries[index].color }}>
                        {lineSeries[index].name}
                    </span>
                ),
                description: (
                    <span
                        style={{
                            color: lineSeries[index].color,
                            display: 'block',
                            textAlign: 'right',
                            width: '100%',
                        }}
                    >
                        {`${intl.formatNumber(datum.percentage, { style: 'percent' })} `}
                        <small>({`${datum.count}/${datum.total}`})</small>
                    </span>
                ),
            }));

            return (
                <ChartTooltip
                    title={title}
                    style={{ minWidth: 250 }}
                >
                    <DescriptiveList
                        data={descriptiveListData}
                        style={{ marginBottom: 0, width: '100%' }}
                    />
                </ChartTooltip>
            );
        } else {
            return null;
        }
    }

    public render() {
        const { filters, stats } = this.props;
        const computedData = this.getComputedData();

        return (
            <Card
                title={(
                    <FormattedMessage {...DashboardMessages.incidentsOverTime} />
                )}
            >
                <div
                    id="quality-content-wrapper"
                >
                    <div>
                        {/* <Spin spinning={!stats || (stats && stats.loading)}> */}
                            <MultiSeriesChart
                                data={computedData.chart}
                                noDataText={(
                                    <FormattedMessage {...GenericMessages.noData} />
                                )}
                                tooltipFormatter={this.renderTooltip.bind(null, computedData.chart.lineSeries)}
                                xAxisType="time-utc"
                                xAxisTickLabelFormatter={this.formatXAxisTickLabels}
                                xAxisDomain={[
                                    moment(filters.fromDate).valueOf(),
                                    moment(filters.toDate).valueOf(),
                                ]}
                                yAxisDomain={[0, 100]}
                                yAxisTickLabelFormatter={this.formatYAxisTickLabels}
                            />
                        {/* </Spin> */}
                    </div>
                    <div>
                        <Metric
                            color={cyan[5]}
                            label={(
                                <FormattedMessage {...GenericMessages.late} />
                            )}
                            loading={stats.loading}
                            suffix="%"
                            value={computedData.stats.items !== undefined && computedData.stats.items !== 0 ? (
                                <FormattedNumber
                                    value={preciseRound(computedData.stats.late / computedData.stats.items, 2)}
                                />
                            ) : undefined}
                        />
                        <Metric
                            color={geekblue[5]}
                            label={(
                                <FormattedMessage {...GenericMessages.gps} />
                            )}
                            loading={stats.loading}
                            suffix="%"
                            value={computedData.stats.items !== undefined && computedData.stats.items !== 0 ? (
                                <FormattedNumber
                                    value={preciseRound(computedData.stats.gps / computedData.stats.items, 2)}
                                />
                            ) : undefined}
                        />
                        <Metric
                            color={lime[5]}
                            label={(
                                <FormattedMessage {...GenericMessages.overTwoHoursLate} />
                            )}
                            loading={stats.loading}
                            suffix="%"
                            value={computedData.stats.items !== undefined && computedData.stats.items !== 0 ? (
                                <FormattedNumber
                                    value={preciseRound(computedData.stats.criticalyLate / computedData.stats.items, 2)}
                                />
                            ) : undefined}
                        />
                    </div>
                </div>
            </Card>
        );
    }
}

export default injectIntl(QualityAndIncidentsChart);
