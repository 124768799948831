import * as React from 'react';

import '../assets/styles/Metric.less';
import { Spin } from 'antd';
import { FormattedNumber } from 'react-intl';

export interface MetricProps {
    color?: string;
    label?: React.ReactNode | string;
    loading?: boolean;
    suffix?: string;
    value?: React.ReactNode | string | number;
}

const Metric: React.SFC<MetricProps> = ({ color, label, loading, suffix, value }) => (
    <div className={`metric${value === undefined ? ' metric-disabled' : ''}`}>
        <Spin spinning={loading}>
            <>
                {label && <p className="metric-label">{label}</p>}
                <p className="metric-value" style={color ? { color } : undefined}>
                    {value !== undefined ? (
                        <>
                            {typeof value === 'number' ? <FormattedNumber value={value} /> : value}
                            {suffix !== undefined && (
                                <span className="metric-suffix">{suffix}</span>
                            )}
                        </>
                    ) : '--'}
                </p>
            </>
        </Spin>
    </div>
);

export default Metric;
