import { combineReducers } from 'redux';

import * as reduxTypes from '../types/roles';

import { MainReducerState, RequestState } from '.';
import { Role } from '../api/types';
import {
    requestReducer, paginateReducer, PaginationRequestState, entityReducer, EntityReducerState,
} from './_generics';
import { denormalize } from 'normalizr';
import { arrayOfRoles, role, Entities } from '../schema';

export interface RolesState {
    readonly creates: RequestState;
    readonly deletes: RequestState;
    readonly entities: EntityReducerState<Role>;
    readonly pagination: PaginationRequestState;
    readonly updates: RequestState;
}

export default combineReducers<RolesState>({
    creates: requestReducer({
        START: reduxTypes.CREATE,
        SUCCESS: reduxTypes.CREATE_SUCCESS,
        FAILED: reduxTypes.CREATE_FAILED,
    }),
    entities: entityReducer([
        reduxTypes.LIST_SUCCESS,
    ], Entities.roles),
    pagination: paginateReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    }, { pageSize: 200 }),
    updates: requestReducer({
        START: reduxTypes.UPDATE,
        SUCCESS: reduxTypes.UPDATE_SUCCESS,
        FAILED: reduxTypes.UPDATE_FAILED,
    }),
    deletes: requestReducer({
        START: reduxTypes.DELETE,
        SUCCESS: reduxTypes.DELETE_SUCCESS,
        FAILED: reduxTypes.DELETE_FAILED,
    }),
});

export const getIds = (state: MainReducerState) => state.roles.pagination.ids;
export const getRoleById = (state: MainReducerState, id: Role['id']) =>
    denormalize(id, role, { [Entities.roles]: state.roles.entities }) || undefined;
export const getRoles = (state: MainReducerState) =>
    denormalize(getIds(state), arrayOfRoles, { [Entities.roles]: state.roles.entities });
export const getPagination = (state: MainReducerState) => state.roles.pagination;
export const getCreates = (state: MainReducerState) => state.roles.creates;
export const getDeletes = (state: MainReducerState) => state.roles.deletes;
export const getUpdates = (state: MainReducerState) => state.roles.updates;
