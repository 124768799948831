import { combineReducers } from 'redux';

import * as reduxTypes from '../types/customers';

import { MainReducerState, RequestState } from '.';
import { Customer } from '../api/types';
import {
    paginateReducer, PaginationRequestState, entityReducer, EntityReducerState, requestReducer,
} from './_generics';
import { denormalize } from 'normalizr';
import { arrayOfCustomers, customer, Entities } from '../schema';

export interface CustomersState {
    readonly entities: EntityReducerState<Customer>;
    readonly pagination: PaginationRequestState;
    readonly reads: RequestState;
}

export default combineReducers<CustomersState>({
    entities: entityReducer([
        reduxTypes.LIST_SUCCESS,
        reduxTypes.DETAILS_SUCCESS,
    ], Entities.customers),
    pagination: paginateReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    }),
    reads: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAILED,
    }),
});

export const getIds = (state: MainReducerState) => state.customers.pagination.ids;

export const getCustomerById = (state: MainReducerState, id: Customer['id']) =>
    denormalize(id, customer, { [Entities.customers]: state.customers.entities }) || undefined;

export const getCustomers = (state: MainReducerState) =>
    denormalize(getIds(state), arrayOfCustomers, { [Entities.customers]: state.customers.entities });

export const getPagination = (state: MainReducerState) => state.customers.pagination;

export const getReads = (state: MainReducerState) => state.customers.reads;
