import { combineReducers, Reducer } from 'redux';
import { denormalize } from 'normalizr';

import * as reduxTypes from '../types/deliveryTours';

import { MainReducerState, RequestState } from '.';
import { DeliveryTour, NeerbyTag, GeoJson } from '../api/types';

import {
    EntityReducerState, PaginationRequestState, entityReducer, paginateReducer, requestReducer,
} from './_generics';
import { arrayOfDeliveryTours, deliveryTour, Entities } from '../schema';

export enum DeliveryToursListDateMode {
    today = 'today',
    yesterday = 'yesterday',
    all = 'all',
}

export interface DeliveryTourNeerbyTagsState {
    readonly [id: string]: NeerbyTag[];
}

export interface DeliveryTourMatchedRouteState {
    readonly [id: string]: GeoJson[];
}

export interface DeliveryToursState {
    readonly dateMode: DeliveryToursListDateMode;
    readonly entities: EntityReducerState<DeliveryTour>;
    readonly neerbyTags: DeliveryTourNeerbyTagsState;
    readonly matchedRoutes: DeliveryTourMatchedRouteState;
    readonly pagination: PaginationRequestState;
    readonly reads: RequestState;
    readonly sendMessage: RequestState;
}

const dateMode: Reducer<DeliveryToursListDateMode> = (state = DeliveryToursListDateMode.today, action) => {
    switch (action.type) {
        case reduxTypes.LIST:
            return action.dateMode || DeliveryToursListDateMode.today;
        default:
            return state;
    }
};

const neerbyTagsInitialState: DeliveryTourNeerbyTagsState = {};

const neerbyTags: Reducer<DeliveryTourNeerbyTagsState> = (state = neerbyTagsInitialState, action) => {
    switch (action.type) {
        case reduxTypes.EVENTS_SUCCESS:
            return {
                ...state,
                [action.id]: action.data,
            };
        default:
            return state;
    }
};

const matchedRouteInitialState: DeliveryTourMatchedRouteState = {};

const matchedRoutes: Reducer<DeliveryTourMatchedRouteState> = (state = matchedRouteInitialState, action) => {
    switch (action.type) {
        case reduxTypes.MATCHED_ROUTES_SUCCESS:
            return {
                ...state,
                [action.id]: action.data,
            };
        default:
            return state;
    }
};

export default combineReducers<DeliveryToursState>({
    dateMode,
    entities: entityReducer([
        reduxTypes.LIST_SUCCESS,
        reduxTypes.DETAILS_SUCCESS,
    ], Entities.deliveryTours),
    neerbyTags,
    matchedRoutes,
    pagination: paginateReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    }),
    reads: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAILED,
    }),
    sendMessage: requestReducer({
        START: reduxTypes.SEND_MESSAGE,
        SUCCESS: reduxTypes.SEND_MESSAGE_SUCCESS,
        FAILED: reduxTypes.SEND_MESSAGE_FAILED,
    }),
});

export const getIds = (state: MainReducerState) => state.deliveryTours.pagination.ids;

export const getDeliveryTourById = (state: MainReducerState, id: DeliveryTour['id']) =>
    denormalize(id, deliveryTour, {
        [Entities.deliveryTours]: state.deliveryTours.entities,
        [Entities.deliveries]: state.deliveries.entities,
        [Entities.deliveryMen]: state.deliveryMen.entities,
    }) || undefined;

export const getDeliveryTours = (state: MainReducerState) =>
    denormalize(getIds(state), arrayOfDeliveryTours, {
        [Entities.deliveryTours]: state.deliveryTours.entities,
        [Entities.deliveries]: state.deliveries.entities,
        [Entities.deliveryMen]: state.deliveryMen.entities,
    });

export const getPagination = (state: MainReducerState) => state.deliveryTours.pagination;

export const getReads = (state: MainReducerState) => state.deliveryTours.reads;

export const getSendMessage = (state: MainReducerState) => state.deliveryTours.sendMessage;

export const getDateMode = (state: MainReducerState) => state.deliveryTours.dateMode;

export const getNeerbyTagsByDeliveryTourId = (state: MainReducerState, id: DeliveryTour['id']) =>
    state.deliveryTours.neerbyTags[id!] || [];

export const getMatchedRoutesByDeliveryTourId = (state: MainReducerState, id: DeliveryTour['id']) =>
    state.deliveryTours.matchedRoutes[id!] || [];
