import * as React from 'react';
import { PaginatedListState } from '../../store/reducers';
import { Tag } from 'antd';

interface ListTitleProps<T> {
    count?: PaginatedListState<T>['count'];
    filteredCount?: PaginatedListState<T>['filteredCount'];
    totalCount?: PaginatedListState<T>['totalCount'];
    title: string | React.ReactNode;
}

class ListTitle<T> extends React.PureComponent<ListTitleProps<T>> {
    public render() {
        const { count, filteredCount, title, totalCount, ...rest } = this.props;

        const total = filteredCount !== totalCount ? filteredCount : totalCount;
        const currentNumber = count === total ? '' : `${count}/`;

        return (
            <h1 className="list-title" {...rest}>
                {title}
                <Tag color={'#3022b3'/* @primary-8 */}>
                    {`${currentNumber}${total}`}
                </Tag>
            </h1>
        );
    }
}

export default ListTitle;
