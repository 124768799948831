import {combineReducers, Reducer} from 'redux';
import * as authTypes from '../types/auth';
import { MainReducerState, RequestState } from './';
import { User } from '../api/types';

export interface AuthUser extends Partial<User> {
    error?: RequestState['error'];
    hasCheckedLoginStatus: boolean;
    isConnected: boolean;
    loading: boolean;
}

export interface AuthState {
    readonly user: AuthUser;
}

const userInitialState: AuthUser = {
    hasCheckedLoginStatus: false,
    isConnected: false,
    loading: false,
};

const user: Reducer<AuthUser> = (state = userInitialState, action) => {
    switch (action.type) {
        case authTypes.LOGIN:
            return {
                ...state,
                error: undefined,
                loading: true,
            };

        case authTypes.LOGIN_SUCCESS:
            return {
                ...state,
                ...action.data.user,
                hasCheckedLoginStatus: true,
                isConnected: true,
                loading: false,
            };

        case authTypes.LOGIN_FAILED:
            return {
                error: action.data,
                hasCheckedLoginStatus: true,
                isConnected: false,
                loading: false,
            };

        case authTypes.LOGOUT_SUCCESS:
            return {
                hasCheckedLoginStatus: true,
                isConnected: false,
            };

        case authTypes.LOGOUT_FAILED:
            return {
                ...state,
                isConnected: true,
            };

        case authTypes.CHECK_LOGIN_STATUS:
            return {
                ...state,
                hasCheckedLoginStatus: false,
                isConnected: false,
            };

        case authTypes.CHECK_LOGIN_STATUS_SUCCESS:
            return {
                ...state,
                ...action.data,
                hasCheckedLoginStatus: true,
                isConnected: true,
            };

        case authTypes.CHECK_LOGIN_STATUS_FAILED:
            return {
                ...state,
                hasCheckedLoginStatus: true,
                isConnected: false,
                error: undefined,
            };

        default:
            return state;
    }
};

export default combineReducers<AuthState>({
    user,
});

export const getUser = (state: MainReducerState) => state.auth.user;
