import { combineReducers, Reducer } from 'redux';
import agencies, { AgenciesState } from './agencies';
import auth, { AuthState } from './auth';
import customers, { CustomersState } from './customers';
import dashboard, { DashboardState } from './dashboard';
import deliveries, { DeliveriesState } from './deliveries';
import deliveryMen, { DeliveryMenState } from './deliveryMen';
import deliveryTours, { DeliveryToursState } from './deliveryTours';
import organizations, { OrganizationsState } from './organizations';
import permissions, { PermissionsState } from './permissions';
import providers, { ProvidersState } from './providers';
import telecoms, { TelecomsState } from './telecoms';
import roles, { RolesState } from './roles';
import ui, { UiState } from './ui';
import users, { UsersState } from './users';
import vehicles, { VehiclesState } from './vehicles';
import monitoring, { MonitoringState } from './monitoring';

export interface RequestState {
    readonly loading: boolean;
    readonly error?: any;
    readonly response?: any;
    readonly success?: boolean;
}

export interface DataStateHash<T> {
    readonly [id: number]: T;
}

export interface ListState<T> extends RequestState {
    readonly data: T[];
}

export interface PaginationState {
    readonly count: number;
    readonly filteredCount: number;
    readonly ids?: string[];
    readonly page: number;
    readonly pageSize: number;
    readonly totalCount: number;
    readonly fromDate?: string;
    readonly toDate?: string;
    readonly sort?: string;
    readonly sortOrder?: string;
}

export type PaginatedListState<T> = ListState<T> & PaginationState;

export interface DetailsStateById<T> {
    readonly error?: RequestState['error'];
    readonly data?: T;
    readonly loading: RequestState['loading'];
}

export interface DetailsState<T> {
    readonly [id: string]: DetailsStateById<T>;
}

export interface MainReducerState {
    readonly agencies: AgenciesState;
    readonly auth: AuthState;
    readonly customers: CustomersState;
    readonly dashboard: DashboardState;
    readonly deliveries: DeliveriesState;
    readonly deliveryMen: DeliveryMenState;
    readonly deliveryTours: DeliveryToursState;
    readonly organizations: OrganizationsState;
    readonly permissions: PermissionsState;
    readonly providers: ProvidersState;
    readonly telecoms: TelecomsState;
    readonly roles: RolesState;
    readonly ui: UiState;
    readonly users: UsersState;
    readonly vehicles: VehiclesState;
    readonly monitoring: MonitoringState;
}

const mainReducer = combineReducers<MainReducerState>({
    agencies,
    auth,
    customers,
    dashboard,
    deliveries,
    deliveryMen,
    deliveryTours,
    organizations,
    permissions,
    providers,
    telecoms,
    roles,
    ui,
    users,
    vehicles,
    monitoring,
});

export default mainReducer;
