export { default as constants } from './constants';

export const getFullName = (firstname?: string, lastname?: string) => {
    if (!firstname && !lastname) {
        return '';
    }

    return `${firstname || ''}${lastname ? ` ${lastname}` : ''}`.trim();
};

export const capitalize = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }

    const lowerCased = str.toLowerCase();

    return `${lowerCased.charAt(0).toUpperCase()}${lowerCased.slice(1)}`;
};

export const capitalizeWords = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }

    return str.split(' ').map(capitalize).join(' ');
};

export const hasOwnProperty = (obj: object, value: string, strict = true) => {
    let result = false;

    if (Array.isArray(value)) {
        result = strict ?
            !value.some((property) => !Object.prototype.hasOwnProperty.call(obj, property)) :
            value.some((property) => Object.prototype.hasOwnProperty.call(obj, property));
    } else {
        result = Object.prototype.hasOwnProperty.call(obj, value);
    }

    return result;
};

export function classNames(...args: Array<string | undefined | boolean>) {
    return [...arguments].filter(Boolean).join(' ');
}
