import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <path fill="#FFF" fillRule="evenodd" d="M12.74 4.825a1.905 1.905 0 0 1-1.913 1.895 1.917 1.917 0 0 1-1.666-.96.28.28 0 0 0-.242-.143h-.01c-.1 0-.193.055-.244.142A1.912 1.912 0 0 1 7 6.72a1.914 1.914 0 0 1-1.662-.957.29.29 0 0 0-.5 0 1.92 1.92 0 0 1-1.665.957A1.905 1.905 0 0 1 1.26 4.825V3.23c0-.006.004-.01.01-.01h11.46c.007 0 .01.003.01.01v1.595zm-.525 7.917H9.24V9.36a.56.56 0 0 0-.56-.56H5.32a.56.56 0 0 0-.56.56v3.382H1.785v-5.08c.05.026.103.05.158.07.39.166.805.265 1.232.265.427 0 .84-.1 1.232-.264a3.2 3.2 0 0 0 .677-.386.01.01 0 0 1 .01 0c.21.156.437.286.678.386.39.165.805.264 1.232.264.427 0 .84-.1 1.232-.264a3.2 3.2 0 0 0 .677-.386.01.01 0 0 1 .01 0c.21.156.437.286.678.386.39.165.806.264 1.233.264.427 0 .84-.1 1.232-.264.052-.023.105-.045.157-.07v5.08h-.007zm-6.335 0h2.24V9.92H5.88v2.822zM1.785 1.96h10.43v-.7H1.785v.7zm11.69-1.4a.56.56 0 0 0-.56-.56H1.085a.56.56 0 0 0-.56.56v1.402A.56.56 0 0 0 0 2.52v2.305a3.098 3.098 0 0 0 .525 1.737v6.878c0 .308.25.56.56.56h11.83c.31 0 .56-.25.56-.56V6.563A3.128 3.128 0 0 0 14 4.825V2.52a.56.56 0 0 0-.525-.558V.56z" />{/* tslint:disable:max-line-length */}
    </svg>
);

const IconAgencies: React.SFC<IconProps> = (props) => (
    <Icon component={svg} className="anticon icon-agencies" {...props} />
);

export default IconAgencies;
