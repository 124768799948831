import { defineMessages } from 'react-intl';

export default defineMessages({
    noName: {
        id: 'loggedrequest.no_name',
        defaultMessage: 'No name',
        description: 'Placeholder when there is no name',
    },
    noTime: {
        id: 'loggedrequest.no_time',
        defaultMessage: 'No Time Value',
        description: 'Placeholder when there is no time',
    },
    noStatus: {
        id: 'loggedrequest.no_status',
        defaultMessage: 'No Status Value',
        description: 'Placeholder when there is no status',
    },
    listTitle: {
        id: 'loggedrequest.list_title',
        defaultMessage: 'API calls',
        description: 'List title',
    },
});
