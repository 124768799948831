import { Action } from 'redux';
import { delay } from 'redux-saga';
import { call, takeLatest, put } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import * as reduxActions from '../actions/permissions';
import * as reduxTypes from '../types/permissions';
import * as api from '../api/permissions';
import { SearchQuery } from '../api';
import { arrayOfPermissions } from '../schema';

export function* listSaga(action: Action & SearchQuery) {
    try {
        yield delay(action.throttling || 0);
        const {items, ...rest} = yield call(api.list, action);
        const normalizedItems = normalize(items, arrayOfPermissions);

        return yield put(reduxActions.listSuccess({
            ...normalizedItems,
            ...rest,
        }));
    } catch (error) {
        return yield put(reduxActions.listFailed(error));
    }
}

export default function* rolesSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
}
