import { ActionCreator } from 'redux';

import { RolesListResponse, RoleUpdateData } from '../api/roles';
import * as reduxTypes from '../types/roles';
import { DataAction, QueryListPayload, QueryListAction, IdAction } from '.';
import { Role } from '../api/types';

// LIST

export const list: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    ...payload,
    type: reduxTypes.LIST,
});

export const listSuccess: ActionCreator<DataAction> = (data: RolesListResponse) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAILED,
    data,
});

// CREATE

export const create: ActionCreator<DataAction> = (data: RoleUpdateData) => ({
    type: reduxTypes.CREATE,
    data,
});

export const createSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_SUCCESS,
    data,
});

export const createFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_FAILED,
    data,
});

// DELETE

export const del: ActionCreator<IdAction> = (id: Role['id']) => ({
    type: reduxTypes.DELETE,
    id,
});

export const delSuccess: ActionCreator<DataAction> = (data: Role, id: Role['id']) => ({
    type: reduxTypes.DELETE_SUCCESS,
    data,
    id,
});

export const delFailed: ActionCreator<DataAction> = (data: any, id: Role['id']) => ({
    type: reduxTypes.DELETE_FAILED,
    data,
    id,
});

// UPDATE

export const update: ActionCreator<IdAction & DataAction> = (id: Role['id'], data: RoleUpdateData) => ({
    type: reduxTypes.UPDATE,
    data,
    id,
});

export const updateSuccess: ActionCreator<IdAction & DataAction> = (data: Role, id: Role['id']) => ({
    type: reduxTypes.UPDATE_SUCCESS,
    data,
    id,
});

export const updateFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_FAILED,
    data,
});
