import api, { PaginatedListResponse, urlWithQuery, UpdateData } from './_client';
import { QueryListPayload } from '../actions';
import { Organization } from './types';

export interface OrganizationsListResponse extends PaginatedListResponse<Organization> {}
export type OrganizationUpdateData = UpdateData<Organization>;

export const details = (id: Organization['id']) => {
    return api.get<Organization>(`/admin/organizations/${id}`);
};

export const list = (payload: QueryListPayload) => {
    return api.get<OrganizationsListResponse>(urlWithQuery('/admin/organizations', payload));
};

export const create = (body: OrganizationUpdateData) => {
    return api.post<Organization>('/admin/organizations', body);
};

export const update = (id: Organization['id'], body: OrganizationUpdateData) => {
    return api.post<Organization>(`/admin/organizations/${id}`, body);
};
