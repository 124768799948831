import { defineMessages } from 'react-intl';

export default defineMessages({
    todaysDeliveryTours: {
        id: 'logged_requests_list.list_title.today',
        defaultMessage: 'Today\'s API Calls',
        description: 'List title',
    },
    yesterdaysDeliveryTours: {
        id: 'logged_requests_list.list_title.yesterday',
        defaultMessage: 'Yesterday\'s API Calls',
        description: 'List title',
    },
    beforeYesterdayDeliveryTours: {
        id: 'logged_requests_list.list_title.before_yesterday',
        defaultMessage: 'Before Yesterday\'s API Calls',
        description: 'List title',
    },
});
