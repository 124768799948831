import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import throttle from 'lodash/throttle';
import { Layout } from 'antd';

import '../../assets/styles/Header.less';

interface HeaderProps extends RouteComponentProps {
    fixed?: boolean;
}

interface HeaderState {
    isFixed?: boolean;
}

class Header extends React.Component<HeaderProps, HeaderState> {
    private isComponentMounted: boolean = false;

    constructor(props: HeaderProps) {
        super(props);
        this.state = {
            isFixed: false,
        };

        if (props.fixed) {
            const win = window;
            if (win.addEventListener) {
                win.addEventListener('scroll', throttle(this.checkScroll, 100), false);
            } else {
                win.onscroll = this.checkScroll;
            }
        }
    }

    public componentDidMount() {
        this.isComponentMounted = true;
    }

    public componentWillUnmount() {
        const win = window;

        if (win.removeEventListener) {
            win.removeEventListener('scroll', throttle(this.checkScroll, 100), false);
        } else {
            win.onscroll = null;
        }

        this.isComponentMounted = false;
    }

    public checkScroll = () => {
        if (this.isComponentMounted) {
            const scrollTop = (window.pageYOffset !== undefined) ?
                window.pageYOffset :
                (document.documentElement || document.body.parentNode || document.body).scrollTop;

            this.setState({
                isFixed: scrollTop > 0,
            });
        }
    }

    public render() {
        const { children } = this.props;
        const { isFixed } = this.state;

        return (
            <Layout.Header id="content-header" className={isFixed ? 'content-header-fixed' : ''}>
                {children}
            </Layout.Header>
        );
    }
}

export default withRouter(Header);
