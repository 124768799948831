import { RequestState, PaginationState } from '.';
import { Reducer } from 'redux';
import merge from 'lodash/merge';

import { constants } from '../../utils';

export interface RequestReducerTypes {
    readonly START: string;
    readonly SUCCESS: string;
    readonly FAILED: string;
}

const requestReducerInitialState: RequestState = {
    loading: false,
};

export const requestReducer = (reduxTypes: RequestReducerTypes) => {
    const reducer: Reducer<RequestState> = (state = requestReducerInitialState, { data, type }) => {
        switch (type) {
            case reduxTypes.START:
                return {
                    ...state,
                    loading: true,
                    error: undefined,
                    success: undefined,
                };

            case reduxTypes.SUCCESS:
                return {
                    ...state,
                    loading: false,
                    success: true,
                };

            case reduxTypes.FAILED:
                return {
                    ...state,
                    loading: false,
                    error: data && data.response && data.response.data ?
                        data.response.data :
                        true,
                    response: data,
                    success: false,
                };

            default:
                return state;
        }
    };
    return reducer;
};

export type PaginationRequestState = PaginationState & RequestState;

const paginateReducerInitialState: PaginationRequestState = {
    count: 0,
    filteredCount: 0,
    ids: [],
    loading: false,
    page: 0,
    pageSize: constants.PAGE_SIZE,
    totalCount: 0,
};

export const paginateReducer = (reduxTypes: RequestReducerTypes, initialState = {}) => {
    const reducer: Reducer<PaginationRequestState> = (
        state = {
            ...paginateReducerInitialState,
            ...initialState,
        },
        { data, type },
    ) => {
        switch (type) {
            case reduxTypes.START:
                return {
                    ...state,
                    loading: true,
                    error: undefined,
                    success: undefined,
                };
            case reduxTypes.SUCCESS:
                return {
                    ...state,
                    filteredCount: data.filteredCount,
                    loading: false,
                    ids: data.result,
                    count: data.count,
                    page: data.page,
                    pageSize: data.pageSize,
                    success: true,
                    totalCount: data.totalCount,
                };
            case reduxTypes.FAILED:
                return {
                    ...state,
                    error: data && data.response && data.response.data ?
                        data.response.data :
                        true,
                    loading: false,
                    success: false,
                };
            default:
                return state;
        }
    };

    return reducer;
};

export interface EntityReducerState<T> {
    [id: string]: T;
}

type EntityReducerType = <T>(
    successTypes: Array<RequestReducerTypes['SUCCESS']>,
    entity: string,
) => Reducer<EntityReducerState<T>>;

export const entityReducer: EntityReducerType = (successTypes, entity) =>
    (state = {}, { type, data }) => {
        if (successTypes.indexOf(type) !== -1) {
            const { entities } = data;

            return merge({}, state, entities[entity]);
        } else {
            return state;
        }
    };
