import React, { PureComponent, CSSProperties } from 'react';

class EmptyStateText extends PureComponent<{
    style?: CSSProperties;
}> {
    public render() {
        const { children, ...rest } = this.props;
        return (
            <span className="disabled-text" {...rest}>
                {this.props.children}
            </span>
        );
    }
}

export default EmptyStateText;
