import { defineMessages } from 'react-intl';

export default defineMessages({
    firstNameLabel: {
        id: 'users.form.field.firstname',
        defaultMessage: 'First name',
        description: 'field label',
    },
    lastNameLabel: {
        id: 'users.form.field.lastname',
        defaultMessage: 'Last name',
        description: 'field label',
    },
    roleLabel: {
        id: 'users.form.field.role',
        defaultMessage: 'Role',
        description: 'field label',
    },
    emailLabel: {
        id: 'users.form.field.email',
        defaultMessage: 'Email',
        description: 'field label',
    },
    organizationLabel: {
        id: 'users.form.field.organization',
        defaultMessage: 'Organization',
        description: 'field label',
    },
    passwordLabel: {
        id: 'users.form.field.password',
        defaultMessage: 'Password',
        description: 'field label',
    },
    passwordHelp: {
        id: 'users.form.field.password.help',
// tslint:disable-next-line: max-line-length
        defaultMessage: 'Must be at least 10 characters long, contain a capital letter, a number and a special character.',
        description: 'field label',
    },
    scope: {
        id: 'users.form.scope',
        defaultMessage: 'Scope',
        description: 'fieldset title',
    },
});
