import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <path fill="#FFF" fillRule="evenodd" d="M11.893 10.43c.124-.3-.008-.557-.394-.74-.73-.343-1.443-.73-2.202-1.002-.61-.22-1.277-.31-1.926-.42-.154-.027-.38.058-.5.166-.726.655-1.43 1.334-2.147 2-.187.174-.217.35-.112.577.347.75.678 1.508 1.023 2.258.196.427.538.697 1.037.71a41.83 41.83 0 0 0 2.407.008c.553-.017.837-.41.743-.933-.133-.737-.267-1.474-.396-2.212-.028-.157-.034-.32-.056-.542.617.143 1.17.273 1.726.4.4.093.69-.006.797-.27zM8.723.756V3.04c-.002.495-.1.593-.61.595-.74.004-1.482.004-2.224 0-.51-.002-.61-.1-.61-.594V.76h-2.1C2.85.76 2.52.736 2.2.77c-.205.023-.473.09-.57.227-.072.1.046.38.158.525 1.42 1.845 2.857 3.68 4.29 5.518.365.47.36.518-.07.927-.587.556-1.172 1.112-1.76 1.667-.323.304-.445.313-.806.058-.734-.52-1.466-1.044-2.2-1.563-.105-.074-.223-.13-.398-.23-.017.184-.036.295-.036.407-.002 1.07-.003 2.142 0 3.213 0 .64.328.962 1.014.978.578.013 1.156.003 1.734.004.274 0 .527.054.53.373 0 .314-.24.38-.522.377-.59-.003-1.18.006-1.772-.002-1.076-.015-1.788-.703-1.79-1.72 0-1.094.006-2.19-.002-3.284-.003-.41.103-.764.514-.957.405-.19.768-.08 1.116.173.706.515 1.425 1.013 2.124 1.507l1.613-1.573c-.036-.048-.117-.165-.204-.277-1.346-1.735-2.695-3.468-4.04-5.204C.638 1.29.695.59 1.293.226c.218-.134.515-.212.776-.213C5.45 0 8.83-.007 12.21.01 13.343.02 14 .71 14 1.795v9.677c0 1.105-.704 1.774-1.877 1.78-.25.003-.514.042-.75-.016-.155-.038-.348-.2-.386-.34-.063-.23.128-.37.377-.383.313-.014.63 0 .94-.02.568-.04.89-.368.89-.91.003-3.297.003-6.594 0-9.89 0-.56-.322-.914-.914-.926C11.114.74 9.947.757 8.723.757" />{/* tslint:disable:max-line-length */}
    </svg>
);

const IconDeliveryMen: React.SFC<IconProps> = (props) => (
    <Icon component={svg} className="anticon icon-delivery-men" {...props} />
);

export default IconDeliveryMen;
