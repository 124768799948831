import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl, InjectedIntlProps, FormattedDate, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Button } from 'antd';

import * as DeliveryToursActions from '../../store/actions/deliveryTours';
import { DeliveryTour, NeerbyTag, GeoJson, Delivery } from '../../store/api/types';
import {
    getDeliveryTourById, getNeerbyTagsByDeliveryTourId, getMatchedRoutesByDeliveryTourId, getReads,
} from '../../store/reducers/deliveryTours';
import { MainReducerState, RequestState } from '../../store/reducers';

import Header from '../../components/header/Header';
import Content from '../../components/Content';
import HeaderNav from '../../components/header/HeaderNav';
import listMessages from '../../locale/List.messages';
import { DeliveryTourMap, DeliveryTourSider } from '.';
import { getRoute, RoutePathName } from '../../routes';
import DeliveryTourMessages from './DeliveryTour.messages';
import { Languages } from '../../components/IntlProvider';
import SendMessagePopover from '../../components/SendMessagePopover';
import { Can } from '../../components/auth';

import '../../assets/styles/DeliveryTourDetails.less';
import DeliveryTourDeliveryManDetailsDrawer from './DeliveryTourDeliveryManDetailsDrawer';
import { IconDeliveryTour } from '../../components/icons';

interface MatchParams {
    id: DeliveryTour['id'];
}

interface DeliveryTourDetailsProps extends InjectedIntlProps, RouteComponentProps<MatchParams> {
    deliveryTour: DeliveryTour;
    fetchDeliveryTour: (id: DeliveryTour['id']) => void;
    neerbyTags: NeerbyTag[];
    matchedRoutes: GeoJson[];
    reads: RequestState;
}

interface DeliveryTourDetailsState {
    showDeliveryManDetailsDrawer: boolean;
}

class DeliveryTourDetails extends React.Component<DeliveryTourDetailsProps, DeliveryTourDetailsState> {
    public refreshInterval: number = 0;
    public deliveryTourSiderRef = React.createRef<typeof DeliveryTourSider>();

    public state: DeliveryTourDetailsState = {
        showDeliveryManDetailsDrawer: false,
    };

    public componentDidMount() {
        const { fetchDeliveryTour, match } = this.props;

        fetchDeliveryTour(match.params.id);
        this.refreshInterval = window.setInterval(() => {
            fetchDeliveryTour(match.params.id);
        }, 30000);
        window.scrollTo(0, 0);
        document.querySelector('body')!.classList.add('no-scroll');
    }

    public componentWillUnmount() {
        document.querySelector('body')!.classList.remove('no-scroll');
        window.clearInterval(this.refreshInterval);
    }

    public onTaskClick = (task: Delivery) => {
        if (this.deliveryTourSiderRef.current) {
            // @ts-ignore
            this.deliveryTourSiderRef.current.getWrappedInstance().onTaskClick(task);
        }
    }

    public onClickDeliveryManDetailsButton = () => {
        this.setState({ showDeliveryManDetailsDrawer: true });
    }

    public onCloseDeliveryManDetailsDrawer = () => {
        this.setState({ showDeliveryManDetailsDrawer: false });
    }

    public renderHeaderInfos = (deliveryTour: DeliveryTourDetailsProps['deliveryTour']) => {
        if (deliveryTour) {
            return (
                <div id="delivery-tour-details-header">
                    <span className="delivery-tour-date">
                        <FormattedDate value={deliveryTour.date} />
                    </span>
                    <Can edit="customerMessages">
                        <SendMessagePopover
                            recipient={this.props.intl.formatMessage(DeliveryTourMessages.everyNotDeliveredCustomers)}
                            deliveryTourId={deliveryTour.id}
                        >
                            <Button
                                icon="message"
                                shape="circle"
                                size="small"
                            />
                        </SendMessagePopover>
                    </Can>
                    <Button
                        type="primary"
                        size="small"
                        onClick={this.onClickDeliveryManDetailsButton}
                    >
                        <IconDeliveryTour />
                        <FormattedMessage {...DeliveryTourMessages.deliveryManInformations} />
                    </Button>
                </div>
            );
        } else {
            return null;
        }
    }

    public render() {
        const { showDeliveryManDetailsDrawer } = this.state;
        const { deliveryTour, neerbyTags, matchedRoutes, intl, location, reads } = this.props;
        const headerLinks = {
            [getRoute(intl.locale as Languages, RoutePathName.deliveryTourList)]:
                intl.formatMessage(listMessages.contentHeaderNavItemList),
            [location.pathname]: deliveryTour ? `N°${deliveryTour.label}` : '',
        };

        return (
            <>
                <Header>
                    <HeaderNav links={headerLinks} />
                    {this.renderHeaderInfos(deliveryTour)}
                </Header>
                <Content id="delivery-tour-details">
                    <DeliveryTourMap
                        deliveryTour={deliveryTour}
                        neerbyTags={neerbyTags}
                        matchedRoutes={matchedRoutes}
                        onTaskClick={this.onTaskClick}
                        loading={reads.loading}
                    />
                    <DeliveryTourSider
                        deliveryTour={deliveryTour}
                        loading={reads.loading}
                        // @ts-ignore
                        ref={this.deliveryTourSiderRef}
                    />
                    <DeliveryTourDeliveryManDetailsDrawer
                        deliveryTour={deliveryTour}
                        onClose={this.onCloseDeliveryManDetailsDrawer}
                        visible={showDeliveryManDetailsDrawer}
                    />
                </Content>
            </>
        );
    }
}

const mapStateToProps = (state: MainReducerState, { match }: DeliveryTourDetailsProps) => ({
    neerbyTags: getNeerbyTagsByDeliveryTourId(state, match.params.id),
    matchedRoutes: getMatchedRoutesByDeliveryTourId(state, match.params.id),
    deliveryTour: getDeliveryTourById(state, match.params.id),
    reads: getReads(state),
});

export default injectIntl(connect(
    mapStateToProps,
    { fetchDeliveryTour: DeliveryToursActions.details },
)(DeliveryTourDetails));
