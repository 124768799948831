import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Tag, Icon } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import * as AgenciesActions from '../../store/actions/agencies';
import { SearchQueryComponentState, SearchPaginationQuery } from '../../store/api';
import { Agency } from '../../store/api/types';
import { getAgencies, AgenciesListState } from '../../store/reducers/agencies';

import Header from '../../components/header/Header';
import Content from '../../components/Content';
import ListMessages from '../../locale/List.messages';
import HeaderNav from '../../components/header/HeaderNav';
import { ListItemTitle, List } from '../../components/list';
import MainMenuMessages from '../../components/MainMenu.messages';

interface AgenciesListProps extends InjectedIntlProps, RouteComponentProps {
    fetchAgencies: (query?: SearchPaginationQuery) => void;
    agencies: AgenciesListState;
}

class AgenciesList extends React.Component<AgenciesListProps, SearchQueryComponentState> {
    public state: SearchQueryComponentState = {};
    private columns: Array<ColumnProps<Agency>> = [
        {
            dataIndex: 'id',
            render: (text, agency, index) => (
                <ListItemTitle
                    index={(this.props.agencies.pageSize * this.props.agencies.page) + index + 1}
                    text={agency.name}
                />
            ),
        }, {
            dataIndex: 'reference',
        },
    ];

    public componentDidMount() {
        this.fetchData();
    }

    public render() {
        const { agencies, intl } = this.props;
        const headerLinks = {
            [location.pathname]: intl.formatMessage(ListMessages.contentHeaderNavItemList),
        };

        return (
            <>
                <Header>
                    <HeaderNav links={headerLinks} />
                </Header>
                <Content>
                    <List<Agency>
                        columns={this.columns}
                        dataState={agencies}
                        listTitle={<FormattedMessage {...MainMenuMessages.agencies} />}
                        onChangePage={this.onChangePage}
                        onSearch={this.onSearch}
                        rowKey={this.rowKey}
                    />
                </Content>
            </>
        );
    }

    private rowKey = (record: Agency) => record.id!.toString();

    private onChangePage = (page: number) => {
        const { search } = this.state;

        this.fetchData({
            search,
            page: page - 1,
        });
    }

    private onSearch = (search: string) => {
        console.warn(search);

        this.setState({ search });
        this.fetchData({
            search,
            throttling: 200,
        });
    }

    private fetchData = (query?: SearchPaginationQuery) => {
        const { page, pageSize } = this.props.agencies;
        this.props.fetchAgencies({
            page,
            pageSize,
            ...query,
        });
    }
}

const mapStateToProps = (state: any) => ({
    agencies: getAgencies(state),
});

export default injectIntl(connect(
    mapStateToProps,
    { fetchAgencies: AgenciesActions.list },
)(AgenciesList));
