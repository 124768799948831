import React from 'react';
import { addLocaleData, IntlProvider as ReactIntlProvider, Messages, InjectedIntl } from 'react-intl';
import { LocaleProvider, Spin } from 'antd';
import moment from 'moment';

import antLocale from '../locale/i18n/fr_FR';
import { hasOwnProperty } from '../utils';

export enum Languages {
    fr = 'fr',
    en = 'en',
}

export let intlModule: { intl: InjectedIntl; };

interface IntlProviderProps {
    children?: React.ReactNode;
}

interface IntlProviderState {
    locale: Languages;
    messages: Messages | null;
}

/**
 * Intl wrapper around the app
 */
export default class IntlProvider extends React.Component<IntlProviderProps, IntlProviderState> {
    constructor(props: IntlProviderProps) {
        super(props);
        this.state = {
            locale: Languages.en,
            messages: null,
        };
        this.setLang();
    }

    public render() {
        const { children } = this.props;
        const { locale, messages } = this.state;

        if (messages === null) {
            return (
                <div id="initial-loader">
                    <Spin />
                </div>
            );
        }

        return (
            <LocaleProvider locale={antLocale}>
                <ReactIntlProvider
                    locale={locale}
                    messages={messages}
                >
                    {children}
                </ReactIntlProvider>
            </LocaleProvider>
        );
    }

    private async setLang() {
        const locale = navigator.language;
        let languageCode = locale.split('-')[0] as Languages;
        const reactIntlLocaleData: { [id: string]: any } = {
            fr: () => import('react-intl/locale-data/fr'),
            en: () => import('react-intl/locale-data/en'),
        };
        const l10nFiles: { [id: string]: any } = {
            fr: () => import('../locale/l10n/fr.json'),
            en: () => import('../locale/l10n/en.json'),
        };
        const momentLocales: { [id: string]: any } = {
            // @ts-ignore
            fr: () => import('moment/locale/fr'),
        };

        if (!hasOwnProperty(l10nFiles, languageCode)) {
            languageCode = Languages.en;
        }

        if (hasOwnProperty(momentLocales, languageCode)) {
            await momentLocales[languageCode]();
            moment.locale(locale);
        }

        const intlData = await reactIntlLocaleData[languageCode]();
        addLocaleData(intlData.default);

        const messages = await l10nFiles[languageCode]();

        this.setState({
            locale: Languages[languageCode],
            messages: messages.default,
        });

        intlModule = new ReactIntlProvider({ locale: Languages[languageCode], messages }).getChildContext();

        document.getElementsByTagName('html')[0].setAttribute('lang', locale);
    }
}
