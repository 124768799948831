import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12">
        <path fill="#FFF" fillRule="evenodd" d="M1.166 1.2H8.75v7.82H5.63c-.02-.048-.05-.093-.075-.142l-.036-.065c-.117-.19-.26-.36-.427-.506l-.053-.04a1.992 1.992 0 0 0-.213-.146l-.092-.05a2.075 2.075 0 0 0-.197-.096c-.037-.013-.07-.04-.107-.04a1.887 1.887 0 0 0-.212-.06l-.1-.023c-.217-.04-.44-.04-.657 0l-.102.023a1.85 1.85 0 0 0-.32.103c-.067.026-.13.057-.192.092l-.098.055a1.71 1.71 0 0 0-.192.133l-.07.055c-.082.068-.16.14-.23.22a.374.374 0 0 1-.026.035c-.058.07-.113.143-.163.22-.02.03-.028.054-.05.096-.025.042-.05.08-.07.123L1.167 9V1.2zm2.625 9.603a.89.89 0 0 1-.87-.91c0-.06.007-.122.02-.183A.883.883 0 0 1 3.8 9a.89.89 0 0 1 .873.908.89.89 0 0 1-.882.897v-.002zm7.586 0a.89.89 0 0 1-.875-.896c0-.06.007-.123.018-.184a.887.887 0 0 1 .686-.705.876.876 0 0 1 1.03.705c.01.06.016.12.017.182a.89.89 0 0 1-.874.9v-.002zm1.466-2.37c-.025-.025-.03-.02-.08-.07a2.058 2.058 0 0 0-.294-.23 1.81 1.81 0 0 0-.198-.113 1.567 1.567 0 0 0-.377-.145c-.043-.01-.08-.032-.123-.032a1.87 1.87 0 0 0-.773 0c-.043.008-.08.022-.12.032a1.86 1.86 0 0 0-.244.08c-.047.02-.053.028-.132.063-.078.035-.146.072-.212.114l-.123.087c-.062.047-.12.098-.177.153-.023.02-.05.037-.072.06V4.802h1.428l1.49 2.283.008 1.35zM0 .6v9.003c0 .33.26.6.583.6H1.78c.127.92.83 1.645 1.726 1.776 1.11.16 2.14-.634 2.298-1.777H9.36c.128.92.83 1.645 1.727 1.776 1.11.16 2.14-.634 2.298-1.777h.032c.322 0 .583-.27.583-.6V6.9a.61.61 0 0 0-.098-.332l-1.75-2.708a.577.577 0 0 0-.485-.267H9.924V.6c0-.33-.26-.6-.583-.6H.584A.592.592 0 0 0 0 .6z" />{/* tslint:disable:max-line-length */}
    </svg>
);

const IconDeliveryTour: React.SFC<IconProps> = (props) => (
    <Icon component={svg} className="anticon icon-delivery-tour" {...props} />
);

export default IconDeliveryTour;
