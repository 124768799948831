import { Action, ActionCreator } from 'redux';
import { LoginPayload } from '../api/auth';
import * as authTypes from '../types/auth';
import { DataAction } from '.';

export const login: ActionCreator<DataAction> = (data: LoginPayload) => ({
    type: authTypes.LOGIN,
    data,
});

export const loginSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: authTypes.LOGIN_SUCCESS,
    data,
});

export const loginFailed: ActionCreator<DataAction> = (data: any) => ({
    type: authTypes.LOGIN_FAILED,
    data,
});

export const logout: ActionCreator<Action> = () => ({
    type: authTypes.LOGOUT,
});

export const logoutSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: authTypes.LOGOUT_SUCCESS,
    data,
});

export const logoutFailed: ActionCreator<DataAction> = (data: any) => ({
    type: authTypes.LOGOUT_FAILED,
    data,
});

export const checkLoginStatus: ActionCreator<{}> = () => ({
    type: authTypes.CHECK_LOGIN_STATUS,
});

export const checkLoginStatusSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: authTypes.CHECK_LOGIN_STATUS_SUCCESS,
    data,
});

export const checkLoginStatusFailed: ActionCreator<DataAction> = (data: any) => ({
    type: authTypes.CHECK_LOGIN_STATUS_FAILED,
    data,
});
