import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter, Route } from 'react-router-dom';
import { Spin } from 'antd';

import * as AuthActions from '../../store/actions/auth';

interface ProtectedRouteProps extends RouteComponentProps {
    checkLoginStatus: () => void;
    hasCheckedLoginStatus: boolean;
    isConnected: boolean;
    loginPath: string;
    unauthorizedErrorOccured: () => void;
}

class ProtectedRoute extends React.Component<ProtectedRouteProps> {

    public componentDidMount() {
        const { checkLoginStatus, hasCheckedLoginStatus, unauthorizedErrorOccured } = this.props;

        window.addEventListener('unauthorized.error', unauthorizedErrorOccured); // dispatched from axios interceptor

        if (!hasCheckedLoginStatus) {
            checkLoginStatus();
        }
    }

    public componentWillUnmount() {
        window.removeEventListener('unauthorized.error', this.props.unauthorizedErrorOccured);
    }

    public render() {
        const { children, hasCheckedLoginStatus, isConnected, loginPath } = this.props;

        if (hasCheckedLoginStatus && !isConnected) {
            return <Redirect to={loginPath} />;
        } else if (hasCheckedLoginStatus && isConnected) {
            return children;
        } else {
            return (
                <Route>
                    <div id="initial-loader">
                        <Spin />
                    </div>
                </Route>
            );
        }
    }
}

const mapStateToProps = (state: any) => ({
    hasCheckedLoginStatus: state.auth.user.hasCheckedLoginStatus,
    isConnected: state.auth.user.isConnected,
});

export default withRouter(connect(
    mapStateToProps,
    {
        checkLoginStatus: AuthActions.checkLoginStatus,
        unauthorizedErrorOccured: AuthActions.loginFailed,
    },
)(ProtectedRoute));
