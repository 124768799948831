import { call, takeLatest, put } from 'redux-saga/effects';
import * as authActions from '../actions/auth';
import * as authTypes from '../types/auth';
import * as authApi from '../api/auth';

export function* loginSaga(action: any) {
    try {
        const response = yield call(authApi.login, {
            username: action.data.username,
            password: action.data.password,
        });

        return yield put(authActions.loginSuccess(response));
    } catch (error) {
        return yield put(authActions.loginFailed(error));
    }
}

export function* logoutSaga(action: any) {
    try {
        const response = yield call(authApi.logout);

        return yield put(authActions.logoutSuccess(response));
    } catch (error) {
        return yield put(authActions.logoutFailed(error));
    }
}

export function* checkLoginStatusSaga(action: any) {
    try {
        const response = yield call(authApi.getUserDetails);

        return yield put(authActions.checkLoginStatusSuccess(response));
    } catch (error) {
        return yield put(authActions.checkLoginStatusFailed(error));
    }
}

export default function* authSaga() {
    yield takeLatest(authTypes.LOGIN, loginSaga);
    yield takeLatest(authTypes.LOGOUT, logoutSaga);
    yield takeLatest(authTypes.CHECK_LOGIN_STATUS, checkLoginStatusSaga);
}
