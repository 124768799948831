import { defineMessages } from 'react-intl';

export default defineMessages({
    qualityAndIncidents: {
        id: 'dashboard.quality_and_incidents',
        defaultMessage: 'Quality and incidents',
        description: 'Dashboard section title',
    },
    taskStatuses: {
        id: 'dashboard.stats.task_statuses',
        defaultMessage: 'Tasks statuses',
        description: 'Dashboard stat label',
    },
    incidentsOverTime: {
        id: 'dashboard.quality_and_incidents.incidents_over_time',
        defaultMessage: 'Incidents over time',
        description: 'Dashboard chart card title',
    },
});
