export const LIST = 'organizations/LIST';
export const LIST_SUCCESS = 'organizations/LIST_SUCCESS';
export const LIST_FAILED = 'organizations/LIST_FAILED';

export const CREATE = 'organizations/CREATE';
export const CREATE_SUCCESS = 'organizations/CREATE_SUCCESS';
export const CREATE_FAILED = 'organizations/CREATE_FAILED';

export const DETAILS = 'organizations/DETAILS';
export const DETAILS_SUCCESS = 'organizations/DETAILS_SUCCESS';
export const DETAILS_FAILED = 'organizations/DETAILS_FAILED';

export const UPDATE = 'organizations/UPDATE';
export const UPDATE_SUCCESS = 'organizations/UPDATE_SUCCESS';
export const UPDATE_FAILED = 'organizations/UPDATE_FAILED';
