import { fork } from 'redux-saga/effects';
import agencies from './agencies';
import auth from './auth';
import customers from './customers';
import dashboard from './dashboard';
import deliveries from './deliveries';
import deliveryMen from './deliveryMen';
import deliveryTours from './deliveryTours';
import organizations from './organizations';
import permissions from './permissions';
import providers from './providers';
import telecoms from './telecoms';
import roles from './roles';
import ui from './ui';
import users from './users';
import vehicles from './vehicles';
import monitoring from './monitoring';

export default function* mainSaga() {
    yield fork(agencies);
    yield fork(auth);
    yield fork(customers);
    yield fork(dashboard);
    yield fork(deliveries);
    yield fork(deliveryMen);
    yield fork(deliveryTours);
    yield fork(organizations);
    yield fork(permissions);
    yield fork(providers);
    yield fork(telecoms);
    yield fork(roles);
    yield fork(ui);
    yield fork(users);
    yield fork(vehicles);
    yield fork(monitoring);
}
