import { Action } from 'redux';
import { delay } from 'redux-saga';
import { call, takeLatest, put } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import * as reduxActions from '../actions/users';
import * as reduxTypes from '../types/users';
import * as api from '../api/users';
import { SearchQuery } from '../api';
import { IdAction, DataAction } from '../actions';
import { User } from '../api/types';
import { user, arrayOfUsers } from '../schema';

export function* listSaga(action: Action & SearchQuery) {
    try {
        yield delay(action.throttling || 0);
        const { items, ...rest } = yield call(api.list, action);
        const normalizedItems = normalize(items, arrayOfUsers);

        return yield put(reduxActions.listSuccess({
            ...normalizedItems,
            ...rest,
        }));
    } catch (error) {
        return yield put(reduxActions.listFailed(error));
    }
}

export function* detailsSaga(action: IdAction<User['id']>) {
    try {
        const response = yield call(api.details, action.id);
        const normalizedResponse = normalize(response, user);

        return yield put(reduxActions.detailsSuccess(normalizedResponse, action.id));
    } catch (error) {
        return yield put(reduxActions.detailsFailed(error, action.id));
    }
}

export function* createSaga(action: DataAction<api.UserUpdateData>) {
    try {
        const response = yield call(api.create, action.data);
        return yield put(reduxActions.createSuccess(response));
    } catch (error) {
        return yield put(reduxActions.createFailed(error));
    }
}

export function* updateSaga(action: DataAction<api.UserUpdateData> & IdAction<User['id']>) {
    try {
        const response = yield call(api.update, action.id, action.data);
        return yield put(reduxActions.updateSuccess(response));
    } catch (error) {
        return yield put(reduxActions.updateFailed(error));
    }
}

export function* deleteSaga(action: IdAction<User['id']>) {
    try {
        const response = yield call(api.del, action.id);
        return yield put(reduxActions.delSuccess(response));
    } catch (error) {
        return yield put(reduxActions.delFailed(error));
    }
}

export default function* usersSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.CREATE, createSaga);
    yield takeLatest(reduxTypes.DETAILS, detailsSaga);
    yield takeLatest(reduxTypes.UPDATE, updateSaga);
    yield takeLatest(reduxTypes.DELETE, deleteSaga);
}
