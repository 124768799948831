import { combineReducers, Reducer } from 'redux';
import { denormalize } from 'normalizr';

import * as reduxTypes from '../types/deliveryMen';
import * as deliveryToursTypes from '../types/deliveryTours';

import { PaginatedListState, MainReducerState, RequestState } from '.';
import { DeliveryMan, DeliveryManStats } from '../api/types';
import {
    entityReducer, paginateReducer, requestReducer, EntityReducerState, PaginationRequestState,
} from './_generics';
import { arrayOfDeliveryMen, deliveryMan, Entities } from '../schema';

export interface DeliveryMenListState extends PaginatedListState<DeliveryMan> {}

export type DeliveryManStatsResult = Readonly<DeliveryManStats> & RequestState;

export interface DeliveryManStatsState {
    readonly [id: string]: DeliveryManStatsResult;
}

export interface DeliveryMenState {
    readonly entities: EntityReducerState<DeliveryMan>;
    readonly pagination: PaginationRequestState;
    readonly reads: RequestState;
    readonly stats: DeliveryManStatsState;
}

const statsInitialState: DeliveryManStatsState = {};

const stats: Reducer<DeliveryManStatsState> = (state = statsInitialState, { type, data, id }) => {
    switch (type) {
        case reduxTypes.STATS:
            return {
                ...state,
                [id]: {
                    ...state[id],
                    error: undefined,
                    loading: true,
                },
            };
        case reduxTypes.STATS_SUCCESS: {
            return {
                ...state,
                [id]: {
                    ...state[id],
                    ...data,
                    loading: false,
                },
            };
        }
        case reduxTypes.STATS_FAILED:
            return {
                ...state,
                [id]: {
                    ...state[id],
                    loading: false,
                    error: data,
                },
            };

        default:
            return state;
    }
};

export default combineReducers<DeliveryMenState>({
    entities: entityReducer([
        reduxTypes.LIST_SUCCESS,
        reduxTypes.DETAILS_SUCCESS,
        deliveryToursTypes.LIST_SUCCESS,
        deliveryToursTypes.DETAILS_SUCCESS,
    ], Entities.deliveryMen),
    pagination: paginateReducer({
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    }),
    reads: requestReducer({
        START: reduxTypes.DETAILS,
        SUCCESS: reduxTypes.DETAILS_SUCCESS,
        FAILED: reduxTypes.DETAILS_FAILED,
    }),
    stats,
});

export const getIds = (state: MainReducerState) => state.deliveryMen.pagination.ids;

export const getDeliveryManById = (state: MainReducerState, id: DeliveryMan['id']) =>
    denormalize(id, deliveryMan, { [Entities.deliveryMen]: state.deliveryMen.entities }) || undefined;

export const getDeliveryMen = (state: MainReducerState) =>
    denormalize(getIds(state), arrayOfDeliveryMen, { [Entities.deliveryMen]: state.deliveryMen.entities });

export const getPagination = (state: MainReducerState) => state.deliveryMen.pagination;

export const getReads = (state: MainReducerState) => state.deliveryMen.reads;

export const getDeliveryManStatsById = (state: MainReducerState, id: DeliveryMan['id']) =>
    state.deliveryMen.stats[id!] || undefined;
