import { Action } from 'redux';
import { delay } from 'redux-saga';
import { call, takeEvery, takeLatest, put, fork } from 'redux-saga/effects';
import { DateTime } from 'luxon';
import { normalize } from 'normalizr';

import * as reduxActions from '../actions/deliveryTours';
import * as reduxTypes from '../types/deliveryTours';
import * as api from '../api/deliveryTours';
import { SearchPaginationQuery } from '../api';
import { DeliveryTour, Delivery, NeerbyTag } from '../api/types';
import { hasOwnProperty } from '../../utils';
import { DeliveryToursListDateMode } from '../reducers/deliveryTours';
import { IdAction, DataAction } from '../actions';
import { arrayOfDeliveryTours, deliveryTour } from '../schema';

const preparationTags = ['deliveryMan.login', 'parcel.ready', 'parcel.missing', 'parcel.damaged'];

const addNeerbyTags = (dt: DeliveryTour, filteredEvents: NeerbyTag[]) => {
    const result: DeliveryTour = {
        ...dt,
        preparationTags: [],
        deliveries: [],
    };

    filteredEvents.forEach((event: NeerbyTag) => {

        if (event && typeof event === 'object' && hasOwnProperty(event, 'type')) {

            if (preparationTags.indexOf(event.type) > -1) {
                result.preparationTags!.push(event);
            } else if (
                dt.deliveries &&
                dt.deliveries.length &&
                hasOwnProperty(event, 'data') &&
                event.data &&
                hasOwnProperty(event.data, 'delivery')
            ) {
                const matchingDeliveries = dt.deliveries.filter((d) => d.reference === event.data!.delivery);
                const delivery = matchingDeliveries[0] || null;

                if (typeof delivery === 'object') {
                    if (hasOwnProperty(delivery, 'neerbyTags') && Array.isArray(delivery.neerbyTags)) {
                        delivery.neerbyTags.unshift(event);
                    } else {
                        delivery.neerbyTags = [event];
                    }

                    result.deliveries = [
                        ...result.deliveries!.filter((d) => d.reference !== delivery.reference),
                        delivery,
                    ];
                }
            }

        }

    });

    return result;
};

const sortDeliveryTourTasks = (dt: DeliveryTour) => {
    const result = {...dt};

    // sort tasks by end date or by timeframe start date
    const sortedDoneTasks = result.deliveries ? result.deliveries
        .filter((d: Delivery) => hasOwnProperty(d, 'endDate'))
        .sort((a: Delivery, b: Delivery) => {
            if (a.endDate !== undefined && b.endDate !== undefined) {
                return a.endDate.localeCompare(b.endDate);
            }
            return 0;
        }) : [];

    const sortedTodoTasks = result.deliveries ? result.deliveries
        .filter((d: Delivery) => !hasOwnProperty(d, 'endDate'))
        .sort((a: Delivery, b: Delivery) => {
            if (a.timeframe.from !== undefined && b.timeframe.from !== undefined) {
                return a.timeframe.from.localeCompare(b.timeframe.from);
            }
            return 0;
        }) : [];

    const sortedTasks = sortedDoneTasks.concat(sortedTodoTasks);

    result.deliveries = [...sortedTasks];

    return result;
};

export function* detailsSaga(action: IdAction<DeliveryTour['id']>) {
    try {
        // do routing request in parallel
        yield fork(matchedRoutesSaga, action);

        const detailsResponse = yield call(api.details, action.id);

        // do events request and then augment details response but not stopping the initial details call
        yield fork(eventsSaga, action, detailsResponse);

        const detailsWithSortedTasks = sortDeliveryTourTasks(detailsResponse);

        const normalizedResponse = normalize(detailsWithSortedTasks, deliveryTour);

        return yield put(reduxActions.detailsSuccess(normalizedResponse, action.id));
    } catch (error) {
        return yield put(reduxActions.detailsFailed(error, action.id));
    }
}

export function* eventsSaga(action: IdAction<DeliveryTour['id']>, dt: DeliveryTour) {
    try {
        const eventsResponse = yield call(api.events, action.id);
        const filteredEvents = eventsResponse.filter(Boolean);

        const deliveryTourWithNeerbyTags = addNeerbyTags(dt, filteredEvents);

        const detailsWithSortedTasks = sortDeliveryTourTasks(deliveryTourWithNeerbyTags);
        const normalizedResponse = normalize(detailsWithSortedTasks, deliveryTour);

        yield put(reduxActions.detailsSuccess(normalizedResponse, action.id));

        return yield put(reduxActions.eventsSuccess(filteredEvents, action.id));
    } catch (error) {
        return yield put(reduxActions.eventsFailed(error, action.id));
    }
}

export function* matchedRoutesSaga(action: IdAction<DeliveryTour['id']>) {
    try {
        const matchedRoutesResponse = yield call(api.matchedRoutes, action.id);
        const filteredMatchedRoutes = matchedRoutesResponse.filter(Boolean);

        return yield put(reduxActions.matchedRoutesSuccess(filteredMatchedRoutes, action.id));
    } catch (error) {
        return yield put(reduxActions.matchedRoutesFailed(error, action.id));
    }
}

export function* listSaga(action: Action & SearchPaginationQuery & {
    dateMode: DeliveryToursListDateMode,
}) {
    try {
        yield delay(action.throttling || 0);
        const payload = {
            ...action,
        };

        switch (action.dateMode) {
            case DeliveryToursListDateMode.today:
                payload.fromDate = DateTime.local().startOf('day').toISO();
                payload.toDate = DateTime.local().endOf('day').toISO();
                break;

            case DeliveryToursListDateMode.yesterday:
                payload.fromDate = DateTime.local().minus({ days: 1 }).startOf('day').toISO();
                payload.toDate = DateTime.local().minus({ days: 1 }).endOf('day').toISO();
                break;

            default:
                payload.sort = 'date';
                payload.sortOrder = 'desc';
                break;
        }

        const { items, ...rest } = yield call(api.list, payload);
        const normalizedItems = normalize(items, arrayOfDeliveryTours);

        return yield put(reduxActions.listSuccess({
            ...normalizedItems,
            ...rest,
        }));
    } catch (error) {
        return yield put(reduxActions.listFailed(error));
    }
}

export function* sendMessageSaga(action: DataAction<{ message: string }> & IdAction) {
    try {
        const response = yield call(api.sendMessage, action.id, action.data);
        return yield put(reduxActions.sendMessageSuccess(response));
    } catch (error) {
        return yield put(reduxActions.sendMessageFailed(error));
    }
}

export default function* deliveryToursSaga() {
    yield takeEvery(reduxTypes.DETAILS, detailsSaga);
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.SEND_MESSAGE, sendMessageSaga);
}
