import { ActionCreator } from 'redux';

import { ProvidersListResponse  } from '../api/providers';
import * as reduxTypes from '../types/providers';
import { DataAction, QueryListPayload, QueryListAction } from '.';

// LIST

export const list: ActionCreator<QueryListAction> = (payload: QueryListPayload) => ({
    ...payload,
    type: reduxTypes.LIST,
});

export const listSuccess: ActionCreator<DataAction> = (data: ProvidersListResponse) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAILED,
    data,
});
