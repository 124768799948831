export const LIST = 'permissions/LIST';
export const LIST_SUCCESS = 'permissions/LIST_SUCCESS';
export const LIST_FAILED = 'permissions/LIST_FAILED';

export const CREATE = 'permissions/CREATE';
export const CREATE_SUCCESS = 'permissions/CREATE_SUCCESS';
export const CREATE_FAILED = 'permissions/CREATE_FAILED';

export const UPDATE = 'permissions/UPDATE';
export const UPDATE_SUCCESS = 'permissions/UPDATE_SUCCESS';
export const UPDATE_FAILED = 'permissions/UPDATE_FAILED';

export const DELETE = 'permissions/DELETE';
export const DELETE_SUCCESS = 'permissions/DELETE_SUCCESS';
export const DELETE_FAILED = 'permissions/DELETE_FAILED';
