import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { injectIntl, InjectedIntlProps } from 'react-intl';

import { User, PermissionRight } from '../../store/api/types';
import { isUserAllowed } from '../../utils/permissions';
import { RoutePathName, getRawRoute } from '../../routes';
import { Languages } from '../IntlProvider';

interface CanProps extends InjectedIntlProps, RouteProps {
    edit?: string;
    renderDisabled?: React.ReactChild;
    renderFailure?: React.ReactChild;
    see?: string;
    validate?: (user: User) => boolean;
    visit?: string;
    user: User;
}

class Can extends React.PureComponent<CanProps> {
    public canSeeOrEdit = (permission: string, permissionRight: PermissionRight, method: string) => {
        const {
            children, renderDisabled, renderFailure, user, validate,
        } = this.props;

        // if a custom validation method is provided, use it
        if (typeof validate === 'function') {
            if (validate(user)) {
                return children;
            } else if (renderFailure !== undefined) {
                console.warn(`[Can] not authorized to ${permission} '${permissionRight}'`);
                return renderFailure;
            } else {
                console.warn(`[Can] not authorized to ${permission} '${permissionRight}'`);
                return null;
            }
        }

        const isAllowed = isUserAllowed(user, permission, permissionRight);

        if (isAllowed === PermissionRight.disabled && renderDisabled !== undefined) {
            console.warn(`[Can] not authorized to ${method} ${permission} '${permissionRight}' (disabled)`);
            return renderDisabled;
        } else if (isAllowed) {
            return children;
        } else {
            console.warn(`[Can] not authorized to ${method} ${permission} '${permissionRight}'`);
            return null;
        }
    }

    public renderVisitFailure() {
        const { intl } = this.props;
        const unauthorizedPathname = getRawRoute(intl.locale as Languages, RoutePathName.unauthorized);

        console.warn(`[Can] not authorized to visit '${this.props.visit}'`);

        if (location.pathname !== unauthorizedPathname) {
            return <Redirect exact to={unauthorizedPathname} />;
        } else {
            return null;
        }
    }

    public render() {
        const {
            children, edit, renderDisabled, renderFailure, see, user, validate, visit, ...props
        } = this.props;

        // if it's a visit based rule, render a Route or Redirect
        if (visit) {
            if (!user) {
                return this.renderVisitFailure();
            }

            // if a custom validation method is provided, use it
            if (typeof validate === 'function') {
                if (validate(user)) {
                    return <Route {...props} />;
                } else {
                    console.warn('[Can] didn\'t validate custom permissions');
                    return this.renderVisitFailure();
                }
            }

            const isAllowed = isUserAllowed(user, visit, PermissionRight.read);

            if (isAllowed && isAllowed !== PermissionRight.disabled) { // default permission check
                return <Route {...props} />;
            } else {
                return this.renderVisitFailure();
            }
        } else if (!user) {
            return null;
        } else if (typeof validate === 'function') {
            if (validate(user)) {
                return children;
            } else if (renderFailure !== undefined) {
                console.warn('[Can] didn\'t validate custom permissions');
                return renderFailure;
            } else {
                console.warn('[Can] didn\'t validate custom permissions');
                return null;
            }
        } else if (see) {
            return this.canSeeOrEdit(see, PermissionRight.read, 'see');
        } else if (edit) {
            return this.canSeeOrEdit(edit, PermissionRight.write, 'edit');
        }

        return children;
    }
}

const mapStateToProps = (state: any) => ({
    user: state.auth.user,
});

export default injectIntl(connect(
    mapStateToProps,
)(Can));
