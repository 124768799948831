import { defineMessages } from 'react-intl';

export default defineMessages({
    noName: {
        id: 'customers.no_name',
        defaultMessage: 'No name',
        description: 'Placeholder when there is no name for a customer',
    },
    noAddress: {
        id: 'customers.no_address',
        defaultMessage: 'No address',
        description: 'Placeholder when there is no address for a customer',
    },
    noEmail: {
        id: 'customers.no_email',
        defaultMessage: 'No email',
        description: 'Placeholder when there is no email for a customer',
    },
    noPhone: {
        id: 'customers.no_phone',
        defaultMessage: 'No phone',
        description: 'Placeholder when there is no phone for a customer',
    },
});
