import { ActionCreator } from 'redux';

import { DeliveryTour, NeerbyTag, GeoJson } from '../api/types';
import { DeliveryToursListResponse } from '../api/deliveryTours';
import * as reduxTypes from '../types/deliveryTours';
import { IdAction, DataAction, QueryListPayload, QueryListAction } from '.';
import { DeliveryToursListDateMode } from '../reducers/deliveryTours';

// DETAILS

export const details: ActionCreator<IdAction> = (id: DeliveryTour['id']) => ({
    type: reduxTypes.DETAILS,
    id,
});

export const detailsSuccess: ActionCreator<DataAction> = (data: DeliveryTour, id: DeliveryTour['id']) => ({
    type: reduxTypes.DETAILS_SUCCESS,
    data,
    id,
});

export const detailsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_FAILED,
    data,
});

// LIST

export const list: ActionCreator<QueryListAction> = (payload: QueryListPayload & {
    dateMode: DeliveryToursListDateMode,
}) => ({
    ...payload,
    type: reduxTypes.LIST,
});

export const listSuccess: ActionCreator<DataAction> = (data: DeliveryToursListResponse) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAILED,
    data,
});

// SEND MESSAGE

export const sendMessage: ActionCreator<DataAction & IdAction> = (id: DeliveryTour['id'], data) => ({
    type: reduxTypes.SEND_MESSAGE,
    id,
    data,
});

export const sendMessageSuccess: ActionCreator<DataAction & IdAction> = (id: DeliveryTour['id'], data: any) => ({
    type: reduxTypes.SEND_MESSAGE_SUCCESS,
    id,
    data,
});

export const sendMessageFailed: ActionCreator<DataAction & IdAction> = (id: DeliveryTour['id'], data: any) => ({
    type: reduxTypes.SEND_MESSAGE_FAILED,
    id,
    data,
});

// EVENTS

export const events: ActionCreator<IdAction> = (id: DeliveryTour['id']) => ({
    type: reduxTypes.EVENTS,
    id,
});

export const eventsSuccess: ActionCreator<DataAction> = (data: NeerbyTag[], id: DeliveryTour['id']) => ({
    type: reduxTypes.EVENTS_SUCCESS,
    data,
    id,
});

export const eventsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.EVENTS_FAILED,
    data,
});

// ROUTING

export const matchedRoutes: ActionCreator<IdAction> = (id: DeliveryTour['id']) => ({
    type: reduxTypes.MATCHED_ROUTES,
    id,
});

export const matchedRoutesSuccess: ActionCreator<DataAction> = (data: GeoJson[], id: DeliveryTour['id']) => ({
    type: reduxTypes.MATCHED_ROUTES_SUCCESS,
    data,
    id,
});

export const matchedRoutesFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.MATCHED_ROUTES_FAILED,
    data,
});
