export const LIST = 'deliveryMen/LIST';
export const LIST_SUCCESS = 'deliveryMen/LIST_SUCCESS';
export const LIST_FAILED = 'deliveryMen/LIST_FAILED';

export const DETAILS = 'deliveryMen/DETAILS';
export const DETAILS_SUCCESS = 'deliveryMen/DETAILS_SUCCESS';
export const DETAILS_FAILED = 'deliveryMen/DETAILS_FAILED';

export const STATS = 'deliveryMen/STATS';
export const STATS_SUCCESS = 'deliveryMen/STATS_SUCCESS';
export const STATS_FAILED = 'deliveryMen/STATS_FAILED';
