export const DETAILS = 'deliveryTours/DETAILS';
export const DETAILS_SUCCESS = 'deliveryTours/DETAILS_SUCCESS';
export const DETAILS_FAILED = 'deliveryTours/DETAILS_FAILED';

export const LIST = 'deliveryTours/LIST';
export const LIST_SUCCESS = 'deliveryTours/LIST_SUCCESS';
export const LIST_FAILED = 'deliveryTours/LIST_FAILED';

export const EVENTS = 'deliveryTours/EVENTS';
export const EVENTS_SUCCESS = 'deliveryTours/EVENTS_SUCCESS';
export const EVENTS_FAILED = 'deliveryTours/EVENTS_FAILED';

export const MATCHED_ROUTES = 'deliveryTours/MATCHED_ROUTES';
export const MATCHED_ROUTES_SUCCESS = 'deliveryTours/MATCHED_ROUTES_SUCCESS';
export const MATCHED_ROUTES_FAILED = 'deliveryTours/EVENTS_FAILED';

export const SEND_MESSAGE = 'deliveryTours/SEND_MESSAGE';
export const SEND_MESSAGE_SUCCESS = 'deliveryTours/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILED = 'deliveryTours/SEND_MESSAGE_FAILED';
