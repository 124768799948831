import React from 'react';
import { List, Row, Col } from 'antd';

import { Price } from '../store/api/types';

import GenericMessages from '../locale/Generic.messages';
import { FormattedMessage, FormattedNumber } from 'react-intl';

interface PriceDetailsProps {
    price: Price;
}

type MessagesKeys = keyof Price;

const messages: {
    [key in MessagesKeys]: FormattedMessage.MessageDescriptor
} = {
    initialWithoutTax: GenericMessages.initialWithoutTax,
    initialPrice: GenericMessages.initialPrice,
    missing: GenericMessages.missing,
    damaged: GenericMessages.priceDamaged,
    refused: GenericMessages.refused,
    discounts: GenericMessages.discount,
    adjusted: GenericMessages.priceAdjusted,
    coupons: GenericMessages.coupon,
    additional: GenericMessages.additional,
    withoutTax: GenericMessages.priceWithoutTax,
    price: GenericMessages.price,
};

const PriceDetails: React.SFC<PriceDetailsProps> = ({ price }) => {
    const data = Object.keys(messages).map((key) => ({
        label: (
            <FormattedMessage
                {...messages[key]}
                values={{ count: price[key] }}
            />
        ),
        value: (
            <FormattedNumber
                value={price[key]}
                style="currency"
                currency="EUR"
            />
        ),
    }));

    return (
        <List
            size="small"
            dataSource={data}
// tslint:disable-next-line: jsx-no-lambda
            renderItem={(item) => (
                <List.Item>
                    <Row
                        type="flex"
                        justify="space-between"
                        gutter={16}
                        style={{ width: '100%', minWidth: 260 }}
                    >
                        <Col>{item.label}</Col>
                        <Col>{item.value}</Col>
                    </Row>
                </List.Item>
            )}
        />
    );
};

export default PriceDetails;
