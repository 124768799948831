import { schema } from 'normalizr';

export enum Entities {
    customers = 'customers',
    deliveries = 'deliveries',
    deliveryTours = 'deliveryTours',
    loggedRequest = 'loggedRequest',
    monitoringSynthesis = 'monitoringSynthesis',
    deliveryMen = 'deliveryMen',
    organizations = 'organizations',
    permissions = 'permissions',
    roles = 'roles',
    users = 'users',
}

export const organization = new schema.Entity(Entities.organizations);
export const arrayOfOrganizations = new schema.Array(organization);

export const deliveryMan = new schema.Entity(Entities.deliveryMen);
export const arrayOfDeliveryMen = new schema.Array(deliveryMan);

export const delivery = new schema.Entity(Entities.deliveries);
export const arrayOfDeliveries = new schema.Array(delivery);

export const deliveryTour = new schema.Entity(Entities.deliveryTours, {
    deliveries: arrayOfDeliveries,
    deliveryMen: arrayOfDeliveryMen,
});

export const arrayOfDeliveryTours = new schema.Array(deliveryTour);

export const loggedRequest = new schema.Entity(Entities.loggedRequest);
export const arrayOfLoggedRequests = new schema.Array(loggedRequest);
export const monitoringSynthesis = new schema.Entity(Entities.monitoringSynthesis);

export const customer = new schema.Entity(Entities.customers);
export const arrayOfCustomers = new schema.Array(customer);

export const role = new schema.Entity(Entities.roles);
export const arrayOfRoles = new schema.Array(role);

export const permission = new schema.Entity(Entities.permissions);
export const arrayOfPermissions = new schema.Array(permission);

export const user = new schema.Entity(Entities.users);
export const arrayOfUsers = new schema.Array(user);
