import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';
import { Tag, Button, Icon } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import * as DeliveriesActions from '../../store/actions/deliveries';
import { SearchQueryComponentState, SearchPaginationQuery } from '../../store/api';
import { Delivery, getDeliveryStatus, DeliveryType, getDeliveryType } from '../../store/api/types';
import {
    getDeliveries, getPagination,
} from '../../store/reducers/deliveries';

import messages from '../deliveryTours/DeliveryTourSider.messages';
import { ListItemTitle, List } from '../../components/list';
import { ListProps } from '../../components/list/List';
import { PaginationRequestState } from '../../store/reducers/_generics';
import RelativeDateWithTooltip from '../../components/RelativeDateWithTooltip';
import DeliveryDetailsDrawer from './DeliveryDetailsDrawer';

export type DeliveriesListColumns = Array<ColumnProps<Delivery>>;

interface DeliveriesListWrapperProps extends InjectedIntlProps,
                                               Partial<ListProps<Delivery>> {
    deliveries: Delivery[];
    fetchDeliveries: typeof DeliveriesActions.list;
    fetchPayload?: {};
    pagination: PaginationRequestState;
    setColumns?: (columns: DeliveriesListColumns) => DeliveriesListColumns;
}

interface DeliveriesListWrapperState extends SearchQueryComponentState {
    delivery?: Delivery;
}

class DeliveriesListWrapper extends React.Component<DeliveriesListWrapperProps, DeliveriesListWrapperState> {
    public state: DeliveriesListWrapperState = {};
    private columns: Array<ColumnProps<Delivery>> = [
        {
            dataIndex: 'id',
            render: (text, delivery, index) => (
                <ListItemTitle
                    index={(this.props.pagination.pageSize * this.props.pagination.page) + index + 1}
                    text={delivery.reference}
                />
            ),
        }, {
            dataIndex: 'date',
            render: (d) => (
                <RelativeDateWithTooltip
                    value={d}
                />
            ),
        }, {
            dataIndex: 'type',
            render: (text, delivery) => (
                <span>
                    <FormattedMessage
                        {...messages[getDeliveryType(delivery.type)]}
                        values={
                            delivery.type === DeliveryType.thirdParty ?
                                { thirdParty: `${delivery.partner ? delivery.partner.name : ''}` } :
                                undefined
                        }
                    />
                    {
                        (
                            delivery.type === DeliveryType.basic ||
                            delivery.type === DeliveryType.thirdParty
                        ) && (
                            <>
                                {' — '}
                                <FormattedMessage
                                    {...messages.numberOfParcels}
                                    values={{ count: delivery.parcels.length }}
                                />
                            </>
                        )
                    }
                </span>
            ),
        }, {
            dataIndex: 'status',
            render: (status) => (
                <Tag>
                    <FormattedMessage {...messages[getDeliveryStatus(status)]} />
                </Tag>
            ),
        }, {
            dataIndex: 'action',
            width: 32,
            render: (text, delivery) => (
                <Button
                    onClick={this.showDrawer.bind(null, delivery)}
                    shape="circle"
                    size="small"
                    type="default"
                >
                    <Icon type="eye" />
                </Button>
            ),
        },
    ];

    public componentDidMount() {
        this.fetchData();
    }

    public fetchData = (query?: SearchPaginationQuery) => {
        const { fetchDeliveries, fetchPayload, pagination } = this.props;
        const { page, pageSize } = pagination;

        fetchDeliveries({
            page,
            pageSize,
            ...fetchPayload,
            ...query,
        });
    }

    public rowKey = (record: Delivery) => record.id!.toString();

    public onChangePage = (page: number) => {
        const { search } = this.state;

        this.fetchData({
            search,
            page: page - 1,
        });
    }

    public onSearch = (search: string) => {
        this.setState({ search });
        this.fetchData({
            search,
            throttling: 200,
        });
    }

    public onCloseDrawer = () => {
        this.setState({
            delivery: undefined,
        });
    }

    public showDrawer = (delivery: Delivery) => {
        this.setState({
            delivery,
        });
    }

    public render() {
        const { deliveries, emptyStateRenderer, listTitle, pagination, setColumns } = this.props;
        const { delivery } = this.state;

        const columns = typeof setColumns === 'function' ? setColumns(this.columns) : this.columns;

        return (
            <>
                <List<Delivery>
                    columns={columns}
                    dataState={{
                        data: deliveries,
                        loading: pagination.loading,
                        ...pagination,
                    }}
                    emptyStateRenderer={emptyStateRenderer}
                    listTitle={listTitle}
                    onChangePage={this.onChangePage}
                    onSearch={this.onSearch}
                    rowKey={this.rowKey}
                />
                <DeliveryDetailsDrawer
                    onClose={this.onCloseDrawer}
                    delivery={delivery}
                />
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
    deliveries: getDeliveries(state),
    pagination: getPagination(state),
});

export default injectIntl(connect(
    mapStateToProps,
    { fetchDeliveries: DeliveriesActions.list },
    undefined,
    { forwardRef: true },
)(DeliveriesListWrapper), { withRef: true });
