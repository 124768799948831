import { defineMessages } from 'react-intl';

export default defineMessages({
    permission: {
        id: 'roles.permission',
        defaultMessage: 'Permission',
        description: 'Roles table header',
    },
    see: {
        id: 'roles.see',
        defaultMessage: 'See',
        description: 'Roles table header',
    },
    edit: {
        id: 'roles.edit',
        defaultMessage: 'Edit',
        description: 'Roles table header',
    },
    disabled: {
        id: 'roles.disabled',
        defaultMessage: 'Disabled',
        description: 'Roles table header',
    },
    permissionEdit: {
        id: 'roles.permission_edit',
        defaultMessage: 'Permission edit',
        description: 'Roles table header',
    },
    addRole: {
        id: 'roles.add_role',
        defaultMessage: 'Create a role',
        description: 'Role create button',
    },
    roleNamePlaceholder: {
        id: 'roles.name.placeholder',
        defaultMessage: 'Role name',
        description: 'Role name field placeholder',
    },
    forOrganization: {
        id: 'roles.add_role.for_organization',
        defaultMessage: 'For organization',
        description: 'Role create "for organization" text',
    },
});
