import { Layout } from 'antd';
import * as React from 'react';

import Sidebar from './Sidebar';

const MainLayout: React.SFC = ({ children, ...rest }) => (
    <Layout id="main-layout" {...rest}>
        <Sidebar />
        <Layout>
            {children}
        </Layout>
    </Layout>
);

export default MainLayout;
