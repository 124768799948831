import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

export const agencyMarkerPath = `
  M8.864 0l.005.002c3.446-.054 6.62 1.914 8.17 5.067a9.39 9.39 0 0 1-.892
  9.736L9.216 24l-7.19-8.97C-.154 12.295-.614 8.523.84 5.32 2.298 2.118
  5.415.052 8.865 0z
`;

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 18 24">
        <path d={agencyMarkerPath} />
    </svg>
);

const IconAgencyMarker: React.SFC<IconProps> = (props) => (
    <Icon component={svg} className="icon-agency-marker" {...props} />
);

export default IconAgencyMarker;
