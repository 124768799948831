import api, { PaginatedListResponse, urlWithQuery, UpdateData } from './_client';
import { QueryListPayload } from '../actions';
import { Role } from './types';

export interface RolesListResponse extends PaginatedListResponse<Role> {}
export type RoleUpdateData = UpdateData<Role>;

export const list = (payload: QueryListPayload) => {
    return api.get<RolesListResponse>(urlWithQuery('/admin/roles', payload));
};

export const create = (body: RoleUpdateData) => {
    return api.post<Role>('/admin/roles', body);
};

export const del = (id: Role['id']) => {
    return api.delete(`/admin/roles/${id}`);
};

export const update = (id: Role['id'], body: RoleUpdateData) => {
    return api.post<Role>(`/admin/roles/${id}`, body);
};
