import { combineReducers, Reducer } from 'redux';
import * as reduxTypes from '../types/agencies';

import { PaginatedListState, MainReducerState } from '.';
import { Agency } from '../api/types';
import constants from '../../utils/constants';

export interface AgenciesListState extends PaginatedListState<Agency> {}

export interface AgenciesState {
    readonly list: AgenciesListState;
}

const listInitialState: AgenciesListState = {
    count: 0,
    data: [],
    filteredCount: 0,
    loading: false,
    page: 0,
    pageSize: constants.PAGE_SIZE,
    totalCount: 0,
};

const list: Reducer<AgenciesListState> = (state = listInitialState, { type, data }) => {
    switch (type) {
        case reduxTypes.LIST:
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        case reduxTypes.LIST_SUCCESS: {
            const { items, ...pagination } = data;

            return {
                ...state,
                ...pagination,
                data: items,
                loading: false,
            };
        }
        case reduxTypes.LIST_FAILED:
            return {
                ...state,
                loading: false,
                error: data,
            };

        default:
            return state;
    }
};

export default combineReducers<AgenciesState>({
    list,
});

export const getAgencies = (state: MainReducerState) => state.agencies.list;
